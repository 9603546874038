/* eslint-disable react/display-name */
import React, { useContext } from "react";
import { CreateSurveyContext } from "./CreateSurveyContext";
import { QuestionTypes } from "./CreateSurveyConstants";
import { MultipleChoiceEditor, TextEditor } from "./QuestionComponents";

const questionEditorMap = {
  [QuestionTypes.checkboxes]: (question, updateQuestion) => {
    return (
      <MultipleChoiceEditor
        question={question}
        updateQuestion={updateQuestion}
      />
    );
  },
  [QuestionTypes.dropdown]: (question, updateQuestion) => {
    return (
      <MultipleChoiceEditor
        question={question}
        updateQuestion={updateQuestion}
      />
    );
  },
  [QuestionTypes.multiChoice]: (question, updateQuestion) => {
    return (
      <MultipleChoiceEditor
        question={question}
        updateQuestion={updateQuestion}
      />
    );
  },
  [QuestionTypes.singleLineText]: (question, updateQuestion) => {
    return <TextEditor question={question} updateQuestion={updateQuestion} />;
  },
  [QuestionTypes.multiLineText]: (question, updateQuestion) => {
    return <TextEditor question={question} updateQuestion={updateQuestion} />;
  },
};

export const EditQuestionPanel = () => {
  const {
    survey: { currentQuestionId, questions },
    editQuestion,
  } = useContext(CreateSurveyContext);
  const activeQuestion = questions[currentQuestionId] || {};
  let mapped = <div />;
  if (questionEditorMap[activeQuestion.type]) {
    mapped = questionEditorMap[activeQuestion.type](
      activeQuestion,
      editQuestion
    );
  }
  return <div>{mapped}</div>;
};
