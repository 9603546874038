import {MANAGE_PARTICIPANTS} from '../constants/index';

export const userFormReducer = (state = {full_name: '',phone: '', email: '', password: '',state: 'Lagos', lga: 'Ikeja' }, {type, payload}) => {
    if(type === MANAGE_PARTICIPANTS.USERFORMDATA){
        let loginObject = Object.assign({}, state)
        loginObject[payload[0]] = payload[1];
        console.log(loginObject);
        return loginObject;
    }


    return state;
}
