import axios from "axios";
import { domain } from "../../domain";
import { userSurveyByEmailEndpoint } from '../../routes'




export const fetchSurveyByEmail = (payload) =>  {
    return axios({
      method: "GET",
      url: `${domain}${userSurveyByEmailEndpoint(payload.email)}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((response) => response);
}
