import styled from "styled-components";
import { Menu as AntMenu, Button as AntButton } from "antd";

export const LocationContainer = styled.div`
  width: 100%;
  border: 1px solid #00000029;
  padding: 2rem;
  display: flex;
  height: 8vh;
  align-items: center;
  background: white;
`;
export const BlockButton = styled(AntButton)`
  background: #c82e29;
  color: white;
  ${"" /* width: 98px; */}
  text-align: center;
  border-radius: 20px;
  outline: none;
  font-size: 12px;

  border: none;
  &:focus,
  &:hover {
    background: #c82e29;
    color: white;
  }
`;

export const UnBlockButton = styled(AntButton)`
  background: #72be00;
  font-size: 12px;

  color: white;
  ${"" /* width: 98px; */}
  text-align: center;
  border-radius: 20px;
  outline: none;
  border: none;
  &:focus,
  &:hover {
    background: #72be00;
    color: white;
  }
`;
export const WalletButton = styled(AntButton)`
  background: #007aaf;
  font-size: 12px;

  color: white;
  font: 12px;
  ${"" /* width: 98px; */}
  text-align: center;
  border-radius: 20px;
  outline: none;
  border: none;
  &:focus,
  &:hover {
    background: #007aaf;
    color: white;
  }
`;
