import { put, takeEvery, takeLatest, call, all } from "redux-saga/effects";
import {
  MANAGE_AGENT,
  LOGIN,
  MANAGE_PARTICIPANTS,
  OVERVIEW,
  SURVEY_TEMPLATE,
  MANAGE_SURVEY,
  MANAGE_USER,
} from "./constants/index";
import {
  downloadAssignedLinkAction,
  downloadUnassignedLinkAction,
  registerAgentNotificationAction,
  loginNotificationAction,
  agentListAction,
  userRegisterNotificationAction,
  adminOverviewAction,
  surveyOverViewAction,
  deleteAgentNotificationAction,
  userBatchRegisterNotificationAction,
  createSurveyTemplateNotificationAction,
  setActiveTemplateIdAction,
  setActiveTemplateNameAction,
  setSingleTemplateAction,
  saveTemplateQuestionNotifyAction,
  setTemplateListAction,
  deleteTemplateNotificationAction,
  deleteQuestionNotificationAction,
  surveyListAction,
  userListAction,
  surveyDetailAction,
  blockUserNotificationAction,
  unblockUserNotificationAction,
  userProfileAction,
  paymentHistory,
  surveyAnalysisAction,
  assignedSurveyAction,
  paidSurveyHistoryAction,
  updateTemplateNameNotificationAction,
  participantLoginNotificationAction,
  participantDetailsAction,
  participantAssignedSurveyListAction,
  participantSingleSurveyAction,
  surveyForAgentAction,
  participantForSurveyAction,
  screeningResponseNotifyAction,
  mainResponseNotifyAction,
  recentlyRegisteredUsersAction,
  surveyTakenParticipantsAction,
} from "./actions/index";

import { SideEffect } from "./sideEffects/index";
import { notificationAlert } from "./functions/alertNotification";

function* _registerAgent(action) {
  try {
    yield call(SideEffect.registerAgent, action.payload);
    yield put(registerAgentNotificationAction("success"));
  } catch (e) {
    yield put(registerAgentNotificationAction("error"));
  }
}

function* _loginAdmin(action) {
  try {
    const response = yield call(SideEffect.loginAdmin, action.payload);

    const { token, user_type } = response?.data;
    if (user_type === "admin" || user_type === "agent") {
      localStorage.token = token;
      localStorage.user_type = user_type;
      yield put(
        loginNotificationAction({
          status: "success",
          message: `You are logged in as an ${user_type}`,
        })
      );
    } else {
      yield put(
        loginNotificationAction({
          status: "failed",
          message: "You are not allowed",
        })
      );
    }
  } catch (e) {
    console.log(e.response.data.message);
    yield put(
      loginNotificationAction({
        status: "failed",
        message: e?.response?.data?.message ?? "Error occurred",
      })
    );
  }
}
function* _downloadAssignedSurvey(action) {
  try {
    const response = yield call(
      SideEffect.downloadAssignedSurveys,
      action.payload
    );
    yield put(downloadAssignedLinkAction(response?.config?.url));
    console.log("the response is ", response?.config?.url);
  } catch (e) {
    console.log(e);
  }
}
function* _fetchUnassignSurveyParticipant(action) {
  // yield put(unassignSurveyParticipantAction());

  try {
    const response = yield call(
      SideEffect.removeSurveyParticipant,
      action.payload
    );
    // yield put(unassignSurveyParticipantAction(""));
    console.log("unassign response ", response);
  } catch (e) {
    console.log(e);
  }
}

function* _fetchAssignSurveyParticipant(action) {
  try {
    yield call(SideEffect.assignSurveyParticipant, action.payload);
    // yield put(assignSurveyParticipantAction(response?.data))

    notificationAlert(
      "success",
      "Survey was reassigned",
      "Survey was successfully reassigned"
    );
  } catch (e) {
    notificationAlert(
      "error",
      "failed",
      e.response ? e.response.data.message : "Please try again"
    );
  }
}

function* _downloadUnassignedSurvey(action) {
  try {
    const response = yield call(
      SideEffect.downloadUnassignedSurveys,
      action.payload
    );
    yield put(downloadUnassignedLinkAction(response?.config?.url));
    // console.log(
    //   "the response for unassigned survey is ",
    //   response?.config?.url
    // );
  } catch (e) {
    console.log(e);
  }
}

function* _loginAgent(action) {
  try {
    const response = yield call(SideEffect.loginAdmin, action.payload);
    const { token, user_type } = response?.data;
    if (user_type === "admin" || user_type === "agent") {
      localStorage.token = token;
      localStorage.user_type = user_type;
      yield put(
        loginNotificationAction({
          status: "success",
          message: `You are logged in as an ${user_type}`,
        })
      );
    } else {
      yield put(
        loginNotificationAction({
          status: "failed",
          message: "You are not allowed",
        })
      );
    }
  } catch (e) {
    console.log(e.response.data.message);
    yield put(
      loginNotificationAction({
        status: "failed",
        message: e?.response?.data?.message ?? "Error occurred",
      })
    );
  }
}

function* _loginParticipant(action) {
  try {
    const response = yield call(SideEffect.loginParticipant, action.payload);
    console.log("participant response", response);
    localStorage.setItem("participantToken", response.data.token);
    const participantInfo = yield call(
      SideEffect.fetchParticipantInfo,
      response.data.token
    );
    console.log({ participantInfo });

    yield put(participantDetailsAction(participantInfo.data.data));
    yield put(participantLoginNotificationAction("success"));
  } catch (error) {
    console.log(error);
    yield put(participantLoginNotificationAction("error"));
  }
}

function* _fetchparticipantInfo() {
  try {
    const participantInfo = yield call(
      SideEffect.fetchParticipantInfo,
      localStorage.participantToken
    );

    yield put(participantDetailsAction(participantInfo.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* _registerSingleParticipant(action) {
  try {
    const response = yield call(SideEffect.registerParticipant, action.payload);
    yield put(userRegisterNotificationAction("success"));
    console.log("registerParticipant", response);
  } catch (e) {
    yield put(userRegisterNotificationAction("error"));
    console.log(e);
  }
}

function* _registerBatchParticipant(action) {
  try {
    yield call(SideEffect.registerParticipant, action.payload);

    yield put(
      userBatchRegisterNotificationAction({
        status: "success",
        email: action.payload.email,
      })
    );
  } catch (e) {
    yield put(
      userBatchRegisterNotificationAction({
        status: "error",
        email: action.payload.email,
      })
    );
  }
}

function* _fetchParticipantAssignedSurvey(action) {
  try {
    const response = yield call(
      SideEffect.fetchParticipantAssignedSurvey,
      action.payload
    );

    yield put(participantAssignedSurveyListAction(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* _fetchParticipantSingleSurvey(action) {
  try {
    const response = yield call(
      SideEffect.fetchParticipantSingleSurvey,
      action.payload
    );
    const { questionnaire, screening_question } = response.data;

    yield put(
      participantSingleSurveyAction({ questionnaire, screening_question })
    );
  } catch (error) {
    console.log(error);
  }
}

function* _submitScreeningResponse(action) {
  try {
    const response = yield call(
      SideEffect.submitScreeningResponse,
      action.payload
    );
    console.log({ screeningResponse: response });
    yield put(
      screeningResponseNotifyAction({
        status: "success",
        result: response.data.message,
      })
    );
  } catch (error) {
    yield put(screeningResponseNotifyAction({ status: "error", result: "" }));
    console.log(error);
  }
}

function* _submitMainResponse(action) {
  try {
    const response = yield call(SideEffect.submitMainResponse, action.payload);
    yield put(mainResponseNotifyAction("success"));
    console.log({ screeningResponse: response });
  } catch (error) {
    yield put(mainResponseNotifyAction("error"));
    console.log(error);
  }
}

function* _fetchAllAgents(action) {
  try {
    const response = yield call(
      SideEffect.fetchAgents,
      action.payload,
      action.route
    );

    /* let newList = response.data.data.map((item) => {
      return {
        name: item.name,
        email: item.email,
        key: item.id,
      };
    }); */

    console.log(response.data.data);
    yield put(agentListAction(response.data.data));
  } catch (e) {
    console.log(e);
  }
}

function* _fetchRecentAgents(action) {
  try {
    const response = yield call(
      SideEffect.fetchAgents,
      action.payload,
      action.route
    );

    yield put(agentListAction(response.data.data));
  } catch (e) {
    console.log(e);
  }
}

function* _fetchAdminOverview() {
  try {
    const response = yield call(SideEffect.fetchAdminOverview);
    yield put(adminOverviewAction(response.data));
  } catch (e) {
    console.log(e);
  }
}

function* _fetchSurveyOverview() {
  try {
    const response = yield call(SideEffect.fetchSurveyOverView);
    yield put(surveyOverViewAction(response.data));
  } catch (e) {
    console.log(e);
  }
}

function* _deleteAgent(action) {
  try {
    yield call(SideEffect.deleteAgent, action.payload);
    yield put(deleteAgentNotificationAction("success"));
  } catch (error) {
    yield put(deleteAgentNotificationAction("error"));
  }
}

function* _createSurveyTemplate(action) {
  try {
    const response = yield call(
      SideEffect.createSurveyTemplate,
      action.payload
    );

    yield put(setActiveTemplateIdAction(response.data.data.id));
    yield put(setActiveTemplateNameAction(action.payload["templateName"]));
    yield put(createSurveyTemplateNotificationAction("success"));
  } catch (error) {
    console.log(error);
    yield put(createSurveyTemplateNotificationAction("error"));
  }
}

function* _fetchSingleTemplate(action) {
  try {
    const response = yield call(SideEffect.fetchSingleTemplate, action.payload);

    console.log({ setSingleTemplateAction: response.data });
    yield put(setSingleTemplateAction(response.data));
  } catch (error) {
    console.log(error);
  }
}

function* _saveTemplateQuestion(action) {
  console.log({ setSingleTemplateAction: action.payload });
  try {
    yield call(SideEffect.saveTemplateQuestion, action.payload);

    yield put(saveTemplateQuestionNotifyAction("success"));
  } catch (error) {
    console.log(error);
    yield put(saveTemplateQuestionNotifyAction("error"));
  }
}

function* _fetchTemplateList(action) {
  try {
    const response = yield call(SideEffect.fetchTemplateList, action.payload);
    console.log(response.data.data);
    yield put(setTemplateListAction(response.data.data.surveys));
  } catch (error) {
    console.log(error);
  }
}

function* _deleteTemplate(action) {
  try {
    yield call(SideEffect.deleteTemplate, action.payload);

    yield put(deleteTemplateNotificationAction("success"));
  } catch (error) {
    yield put(deleteTemplateNotificationAction("error"));
  }
}

function* _deleteQuestion(action) {
  try {
    yield call(SideEffect.deleteQuestion, action.payload);
    yield put(deleteQuestionNotificationAction("success"));
  } catch (error) {
    yield put(deleteQuestionNotificationAction("error"));
  }
}

function* _fetchRecentSurvey(action) {
  try {
    const response = yield call(
      SideEffect.fetchSurvey,
      action.payload,
      action.route
    );

    yield put(surveyListAction(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* _fetchAllSurvey(action) {
  try {
    const response = yield call(
      SideEffect.fetchSurvey,
      action.payload,
      action.route
    );

    console.log({ allSurvey: response.data.data });
    yield put(surveyListAction(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* _fetchCompletedSurvey(action) {
  try {
    const response = yield call(
      SideEffect.fetchSurvey,
      action.payload,
      action.route
    );
    yield put(surveyListAction(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* _fetchActiveSurvey(action) {
  try {
    const response = yield call(
      SideEffect.fetchSurvey,
      action.payload,
      action.route
    );
    yield put(surveyListAction(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* _searchSurvey(action) {
  try {
    const response = yield call(SideEffect.searchSurvey, action.payload);

    yield put(surveyListAction(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* _fetchRecentUsers(action) {
  try {
    const response = yield call(
      SideEffect.fetchUsers,
      action.payload,
      action.route
    );
    const users = response.data.data.users.map((user) => ({
      ...user,
      key: user.id,
    }));

    yield put(userListAction({ ...response.data.data, users }));
  } catch (error) {
    console.log(error);
  }
}

function* _fetchAllUsers(action) {
  try {
    const response = yield call(
      SideEffect.fetchUsers,
      action.payload,
      action.route
    );

    const users = response.data.data.users.map((user) => ({
      ...user,
      key: user.id,
    }));

    yield put(userListAction({ ...response.data.data, users }));
  } catch (error) {
    console.log(error);
  }
}

function* _searchUser(action) {
  try {
    const response = yield call(SideEffect.searchUser, action.payload);
    const users = response.data.data.users.map((user) => ({
      ...user,
      key: user.id,
    }));
    yield put(userListAction({ ...response.data.data, users }));
  } catch (error) {
    console.log(error);
  }
}

function* _fetchDeletedUsers(action) {
  try {
    const response = yield call(
      SideEffect.fetchUsers,
      action.payload,
      action.route
    );
    const users = response.data.data.users.map((user) => ({
      ...user,
      key: user.id,
    }));

    yield put(userListAction({ ...response.data.data, users }));
  } catch (error) {
    console.log(error);
  }
}

function* _fetchSponsorUsers(action) {
  try {
    const response = yield call(
      SideEffect.fetchUsers,
      action.payload,
      action.route
    );
    const users = response.data.data.users.map((user) => ({
      ...user,
      key: user.id,
    }));

    yield put(userListAction({ ...response.data.data, users }));
  } catch (error) {
    console.log(error);
  }
}

function* _fetchParticipantsUsers(action) {
  try {
    const response = yield call(
      SideEffect.fetchUsers,
      action.payload,
      action.route
    );
    const users = response.data.data.users.map((user) => ({
      ...user,
      key: user.id,
    }));

    yield put(userListAction({ ...response.data.data, users }));
  } catch (error) {
    console.log(error);
  }
}

function* _fetchRecentlyRegisteredUsers(action) {
  try {
    const response = yield call(
      SideEffect.fetchUsers,
      action.payload,
      action.route
    );
    // console.log("the response is")
    const users = response.data.data.users.map((user) => ({
      ...user,
      key: user.id,
    }));
    yield put(recentlyRegisteredUsersAction(users));
  } catch (error) {
    console.log(error);
  }
}

function* _blockUser(action) {
  try {
    yield call(SideEffect.blockUser, action.payload);
    yield put(blockUserNotificationAction("success"));
  } catch (error) {
    console.log(error);
    yield put(blockUserNotificationAction("error"));
  }
}
function* _unblockUser(action) {
  try {
    yield call(SideEffect.unblockUser, action.payload);
    yield put(unblockUserNotificationAction("success"));
  } catch (error) {
    console.log(error);
    yield put(unblockUserNotificationAction("error"));
  }
}
function* _fetchUserProfile(action) {
  try {
    const response = yield call(SideEffect.fetchUserProfile, action.payload);
    console.log(response);
    yield put(userProfileAction(response.data.data));
  } catch (error) {
    console.log(error);
  }
}
function* _fetchUserPaymentHistory(action) {
  try {
    const response = yield call(
      SideEffect.fetchUserPaymentHistory,
      action.payload
    );
    console.log("Payment History", response);
    yield put(paymentHistory(response.data.data));
    // console.log("wallet history", response);
  } catch (error) {
    console.log(error);
  }
}

function* _fetchSurveyDetail(action) {
  try {
    const response = yield call(SideEffect.fetchSurveyDetail, action.payload);

    console.log({ surveyCreator: response });
    yield put(surveyDetailAction(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* _fetchSurveyAnalysis(action) {
  try {
    const response = yield call(SideEffect.fetchSurveyAnalysis, action.payload);

    console.log(response.data.data);

    const refinedData = response.data.data.map((item) => {
      return item["survey_quetions"][0];
    });

    yield put(surveyAnalysisAction(refinedData));
  } catch (error) {
    console.log(error);
  }
}
function* _fetchPaidSurveyHistory(action) {
  try {
    const response = yield call(
      SideEffect.fetchPaidSurveyHistory,
      action.payload
    );
    console.log("fetch Paid survey History", response.data.data);
    yield put(paidSurveyHistoryAction(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* _fetchSurveyAssignedUser(action) {
  try {
    const response = yield call(
      SideEffect.fetchSurveyAssignedUser,
      action.payload
    );

    console.log({ fetchSurveyAssignedUser: response.data.data });
    const newList = response.data.data.users.map((item) => {
      item.status = "waiting";
      item.key = item.id;
      return item;
    });

    yield put(
      assignedSurveyAction({
        participantList: newList,
        page: response.data.data.pages,
      })
    );
  } catch (error) {
    console.log(error);
  }
}

function* _fetchSurveyRespondedUser(action) {
  try {
    const response = yield call(
      SideEffect.fetchSurveyRespondedUser,
      action.payload
    );

    console.log("SURVEY RESPONDED", response);
    const newList = response.data.data.users.map((item) => {
      item.status = "taken";
      return item;
    });

    yield put(
      surveyTakenParticipantsAction({
        participantList: newList,
        page: response.data.data.pages,
      })
    );
  } catch (error) {
    console.log(error);
  }
}

function* _updateTemplateTitle(action) {
  try {
    yield call(SideEffect.updateTemplateTitle, action.payload);

    yield put(updateTemplateNameNotificationAction("success"));
  } catch (err) {
    yield put(updateTemplateNameNotificationAction("error"));
  }
}

function* _fetchSurveyForAgent() {
  try {
    const response = yield call(SideEffect.fetchSurveyForAgent);
    console.log(response);

    yield put(surveyForAgentAction(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* _fetchParticipantForSurvey(action) {
  try {
    const response = yield call(
      SideEffect.fetchParticipantForSurvey,
      action.payload
    );
    yield put(participantForSurveyAction(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* _loginAdminSaga() {
  yield takeLatest(LOGIN.ADMIN, _loginAdmin);
}

function* _downloadAssignedSurveySaga() {
  yield takeLatest(MANAGE_SURVEY.DOWNLOAD_ASSIGNED, _downloadAssignedSurvey);
}

function* _downloadUnassignedSurveySaga() {
  yield takeLatest(
    MANAGE_SURVEY.DOWNLOAD_UNASSIGNED,
    _downloadUnassignedSurvey
  );
}

function* _loginAgentSaga() {
  yield takeLatest(LOGIN.AGENT, _loginAgent);
}

function* _loginParticipantSaga() {
  yield takeLatest(MANAGE_PARTICIPANTS.LOGIN, _loginParticipant);
}

function* _registerAgentSaga() {
  yield takeLatest(MANAGE_AGENT.REGISTER, _registerAgent);
}

function* _fetchAllAgentsSaga() {
  yield takeLatest(MANAGE_AGENT.FETCH_ALL_AGENT, _fetchAllAgents);
}

function* _fetchRecentAgentSaga() {
  yield takeLatest(MANAGE_AGENT.FETCH_RECENT_AGENT, _fetchRecentAgents);
}

function* _registerSingleParticipantSaga() {
  yield takeLatest(
    MANAGE_PARTICIPANTS.SINGLEREGISTER,
    _registerSingleParticipant
  );
}

function* _registerBatchParticipantSaga() {
  yield takeEvery(MANAGE_PARTICIPANTS.BATCHREGISTER, _registerBatchParticipant);
}

function* _deleteAgentSaga() {
  yield takeLatest(MANAGE_AGENT.DELETE, _deleteAgent);
}
function* _fetchAdminOverviewSaga() {
  yield takeLatest(OVERVIEW.FETCH_ADMIN_OVERVIEW, _fetchAdminOverview);
}
function* _fetchSurveyOverviewSaga() {
  yield takeLatest(OVERVIEW.FETCH_SURVEY, _fetchSurveyOverview);
}

function* _createSurveyTemplateSaga() {
  yield takeLatest(SURVEY_TEMPLATE.CREATE, _createSurveyTemplate);
}

function* _fetchSingleTemplateSaga() {
  yield takeLatest(SURVEY_TEMPLATE.FETCH_SINGLE_TEMPLATE, _fetchSingleTemplate);
}

function* _saveTemplateQuestionSaga() {
  yield takeEvery(
    SURVEY_TEMPLATE.SAVE_TEMPLATE_QUESTION,
    _saveTemplateQuestion
  );
}

function* _fetchTemplateListSaga() {
  yield takeLatest(SURVEY_TEMPLATE.FETCH_TEMPLATE_LIST, _fetchTemplateList);
}

function* _deleteTemplateSaga() {
  yield takeLatest(SURVEY_TEMPLATE.DELETE_TEMPLATE, _deleteTemplate);
}

function* _deleteQuestionSaga() {
  yield takeLatest(SURVEY_TEMPLATE.DELETE_QUESTION, _deleteQuestion);
}

function* _fetchActiveSurveySaga() {
  yield takeLatest(MANAGE_SURVEY.FETCH_ACTIVE_SURVEY, _fetchActiveSurvey);
}

function* _fetchAllSurveySaga() {
  yield takeLatest(MANAGE_SURVEY.FETCH_ALL_SURVEY, _fetchAllSurvey);
}

function* _fetchCompletedSurveySaga() {
  yield takeLatest(MANAGE_SURVEY.FETCH_COMPLETED_SURVEY, _fetchCompletedSurvey);
}

function* _fetchRecentSurveySaga() {
  yield takeLatest(MANAGE_SURVEY.FETCH_RECENT_SURVEY, _fetchRecentSurvey);
}

function* _fetchRecentUsersSaga() {
  yield takeLatest(MANAGE_USER.FETCH_RECENT_USER, _fetchRecentUsers);
}

function* _fetchAllUsersSaga() {
  yield takeLatest(MANAGE_USER.FETCH_ALL_USER, _fetchAllUsers);
}
function* _fetchRecentlyRegisteredUsersSaga() {
  yield takeLatest(
    MANAGE_USER.FETCH_RECENTLY_REGISTERED_USERS,
    _fetchRecentlyRegisteredUsers
  );
}

function* _fetchParticipantUsersSaga() {
  yield takeLatest(MANAGE_USER.FETCH_ALL_PARTICIPANT, _fetchParticipantsUsers);
}

function* _fetchSponsorUsersSaga() {
  yield takeLatest(MANAGE_USER.FETCH_ALL_SPONSOR, _fetchSponsorUsers);
}

function* _fetchDeletedUsersSaga() {
  yield takeLatest(MANAGE_USER.FETCH_DELETED_USER, _fetchDeletedUsers);
}

function* _fetchSurveyDetailSaga() {
  yield takeLatest(MANAGE_SURVEY.FETCH_SURVEY_DETAIL, _fetchSurveyDetail);
}

function* _blockUserSaga() {
  yield takeLatest(MANAGE_USER.BLOCK_USER, _blockUser);
}
function* _unblockUserSaga() {
  yield takeLatest(MANAGE_USER.UNBLOCK_USER, _unblockUser);
}
function* _fetchUserProfileSaga() {
  yield takeLatest(MANAGE_USER.FETCH_USER_PROFILE, _fetchUserProfile);
}
function* _fetchUserPaymentHistorySaga() {
  yield takeLatest(MANAGE_USER.FETCH_PAYMENT_HISTORY, _fetchUserPaymentHistory);
}

function* _fetchSurveyAnalysisSaga() {
  yield takeLatest(MANAGE_SURVEY.FETCH_SURVEY_ANALYSIS, _fetchSurveyAnalysis);
}
function* _fetchPaidSurveyHistorySaga() {
  yield takeLatest(
    MANAGE_USER.FETCH_PAID_SURVEY_HISTORY,
    _fetchPaidSurveyHistory
  );
}

function* _fetchSurveyAssignedUserSaga() {
  yield takeLatest(
    MANAGE_SURVEY.FETCH_SURVEY_ASSIGNED_USER,
    _fetchSurveyAssignedUser
  );
}

function* _fetchSurveyRespondedUserSaga() {
  yield takeLatest(
    MANAGE_SURVEY.FETCH_SURVEY_RESPONDED_USER,
    _fetchSurveyRespondedUser
  );
}

function* _updateTemplateTitleSaga() {
  yield takeLatest(SURVEY_TEMPLATE.UPDATE_TITLE, _updateTemplateTitle);
}

function* _fetchparticipantInfoSaga() {
  yield takeLatest(
    MANAGE_PARTICIPANTS.FETCH_PARTICIPANT_INFO,
    _fetchparticipantInfo
  );
}

function* _fetchParticipantAssignedSurveySaga() {
  yield takeLatest(
    MANAGE_PARTICIPANTS.FETCH_PARTICIPANT_ASSIGNED_SURVEY,
    _fetchParticipantAssignedSurvey
  );
}

function* _fetchParticipantSingleSurveySaga() {
  yield takeLatest(
    MANAGE_PARTICIPANTS.FETCH_PARTICIPANT_SINGLE_SURVEY,
    _fetchParticipantSingleSurvey
  );
}

function* _submitScreeningResponseSaga() {
  yield takeLatest(
    MANAGE_PARTICIPANTS.SUBMIT_SCREENING_RESPONSE,
    _submitScreeningResponse
  );
}

function* _fetchUnassignSurveyParticipantSaga() {
  yield takeLatest(
    MANAGE_SURVEY.FETCH_UNASSIGN_SURVEY_PARTICIPANT,
    _fetchUnassignSurveyParticipant
  );
}

function* _fetchAssignSurveyParticipantSaga() {
  yield takeLatest(
    //MANAGE_SURVEY.ASSIGN_SURVEY_PARTICIPANT,
    MANAGE_SURVEY.FETCH_ASSIGN_SURVEY_PARTICIPANT,
    _fetchAssignSurveyParticipant
  );
}

function* _submitMainResponseSaga() {
  yield takeLatest(
    MANAGE_PARTICIPANTS.SUBMIT_MAIN_RESPONSE,
    _submitMainResponse
  );
}

function* __fetchSurveyForAgentSaga() {
  yield takeLatest(MANAGE_AGENT.FETCH_SURVEY_FOR_AGENT, _fetchSurveyForAgent);
}

function* _fetchParticipantForSurveySaga() {
  yield takeLatest(
    MANAGE_AGENT.FETCH_PARTICIPANT_FOR_SURVEY,
    _fetchParticipantForSurvey
  );
}

function* _searchSurveySaga() {
  yield takeLatest(MANAGE_SURVEY.SEARCH_SURVEY, _searchSurvey);
}

function* _searchUserSaga() {
  yield takeLatest(MANAGE_USER.SEARCH_USER, _searchUser);
}

export function* rootSaga() {
  yield all([
    _fetchUnassignSurveyParticipantSaga(),
    _fetchAssignSurveyParticipantSaga(),
    _downloadUnassignedSurveySaga(),
    _downloadAssignedSurveySaga(),
    _searchUserSaga(),
    _searchSurveySaga(),
    _fetchParticipantForSurveySaga(),
    __fetchSurveyForAgentSaga(),
    _submitMainResponseSaga(),
    _submitScreeningResponseSaga(),
    _fetchParticipantSingleSurveySaga(),
    _fetchParticipantAssignedSurveySaga(),
    _fetchparticipantInfoSaga(),
    _updateTemplateTitleSaga(),
    _fetchSurveyRespondedUserSaga(),
    _fetchSurveyAssignedUserSaga(),
    _fetchSurveyAnalysisSaga(),
    _fetchSurveyDetailSaga(),
    _fetchDeletedUsersSaga(),
    _fetchSponsorUsersSaga(),
    _fetchParticipantUsersSaga(),
    _fetchAllUsersSaga(),
    _fetchRecentUsersSaga(),
    _fetchRecentSurveySaga(),
    _fetchCompletedSurveySaga(),
    _fetchActiveSurveySaga(),
    _fetchAllSurveySaga(),
    _registerAgentSaga(),
    _loginAdminSaga(),
    _fetchAllAgentsSaga(),
    _fetchRecentAgentSaga(),
    _loginAgentSaga(),
    _registerSingleParticipantSaga(),
    _deleteAgentSaga(),
    _fetchAdminOverviewSaga(),
    _fetchSurveyOverviewSaga(),
    _registerBatchParticipantSaga(),
    _loginParticipantSaga(),
    _createSurveyTemplateSaga(),
    _fetchSingleTemplateSaga(),
    _saveTemplateQuestionSaga(),
    _fetchTemplateListSaga(),
    _deleteTemplateSaga(),
    _deleteQuestionSaga(),
    _blockUserSaga(),
    _unblockUserSaga(),
    _fetchUserProfileSaga(),
    _fetchUserPaymentHistorySaga(),
    _fetchPaidSurveyHistorySaga(),
    _fetchRecentlyRegisteredUsersSaga(),
  ]);
}
