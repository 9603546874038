import { MANAGE_PARTICIPANTS } from "../constants"

export const participantAssignedSurveyReducer = (state={surveys: [], pages: 0, total_survey: 0}, {type, payload}) => {
    if(type === MANAGE_PARTICIPANTS.PARTICIPANT_ASSIGNED_SURVEY_LIST){
        return payload;
    }

    return state;
}

export const participantSingleSurveyReducer = (state = {questionnaire: [], screening_question: []}, {type, payload}) => {
    if(type === MANAGE_PARTICIPANTS.PARTICIPANT_SINGLE_SURVEY){
        let {questionnaire, screening_question} = payload;

        let newQuestionnaire = questionnaire.map(item => { 
           let options =  item.options.map(opt => opt.replace(/<!&&!>/g, ','))
           let category = item.category.map(opt => opt.replace(/<!&&!>/g, ','))
           item.options = options;
           item.category = category;
           return item;
        });

        let newScreening_question = screening_question.map(item => { 
            let options =  item.options.map(opt => opt.replace(/<!&&!>/g, ','))
            let category = item.category.map(opt => opt.replace(/<!&&!>/g, ','))
            item.options = options;
            item.category = category;
            return item;
         });

         console.log({newQuestionnaire, newScreening_question})
        
        return {
            questionnaire: newQuestionnaire,
            screening_question: newScreening_question
        };
    }

    return state;
}