/* eslint-disable no-new */
import React, { useRef, useEffect, FC } from "react";
import Chart from "chart.js";
import { theme } from "../../../styles/theme";

const datasets = [
  {
    label: "Responses",
    backgroundColor: [
      theme.colors.green[800],
      theme.colors.red[700],
      theme.colors.yellow[600],
      theme.colors.blue[900],
    ],
    borderColor: [
      theme.colors.green[800],
      theme.colors.red[700],
      theme.colors.yellow[600],
      theme.colors.blue[900],
    ],
    data: [432, 220, 100, 190],
  },
];

export const HorizontalBarsChart = () => {
  const ref = useRef(null);
  const chart = useRef(null);
  useEffect(() => {
    if (ref.current) {
      chart.current = new Chart(ref.current, {
        type: "horizontalBar",
        data: {
          labels: ["A", "B", "C", "D"],
          datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          layout: {
            padding: {
              top: 5,
              left: 15,
              right: 15,
              bottom: 15,
            },
          },
        },
      });
    }
  }, []);

  return (
    <canvas
      ref={ref}
      aria-label="Bar Chart displaying data"
      role="img"
      width="150"
      height="100"
    >
      <span>Your browser does not support the canvas element.</span>
    </canvas>
  );
};
