import React, { useRef, useEffect, forwardRef } from "react";
import { MinusIcon, PlusIcon } from "../../../components/atoms/icons/index";
import nextId from "react-id-generator";

export const SingleLineText = ({ title, placeholder, _id }) => {
  return (
    <div>
      <h3 className="block font-medium leading-5">{title}</h3>
      <div>
        <input
          type="text"
          className="mt-1 form-input block w-3/5 py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-green focus:border-green-300 transition duration-150 ease-in-out"
          placeholder={placeholder}
          name={_id}
          disabled
        />
      </div>
    </div>
  );
};

export const MultiLineText = ({ title, placeholder = "", _id }) => {
  return (
    <div>
      <h3 className="block font-medium leading-5">{title}</h3>
      <div>
        <textarea
          rows={4}
          className="form-textarea mt-1 block rounded-md shadow-sm w-3/5 transition duration-150 ease-in-out"
          placeholder={placeholder}
          name={_id}
          disabled
        />
      </div>
    </div>
  );
};

export const MultiChoice = ({ title, options = [], _id }) => {
  return (
    <div>
      <h3 className="block font-medium leading-5">{title}</h3>
      <div className="mt-1">
        {options.map((option) => {
          return (
            <div key={option._id}>
              <label>
                <input
                  type="radio"
                  className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  name={_id}
                  value={option._id}
                  disabled
                />
                <span className="ml-3 leading-5">{option.content}</span>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const Dropdown = ({ title, options = [], _id }) => {
  return (
    <div>
      <h3 className="block font-medium leading-5">{title}</h3>
      <div>
        <select
          className="mt-1 block form-select w-3/5 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-green focus:border-green-800 transition duration-150 ease-in-out"
          name={_id}
        >
          {options.map((option) => {
            return (
              <option key={option._id} value={option._id}>
                {option.content}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export const Checkboxes = ({ title, options = [], _id }) => {
  return (
    <div>
      <h3 className="block font-medium leading-5">{title}</h3>
      <div className="mt-1">
        {options.map((option, index) => {
          return (
            <div className="checkbox" key={index}>
              <label>
                <input
                  type="checkbox"
                  disabled
                  className="form-checkbox h-4 w-4 text-green-800 transition duration-150 ease-in-out"
                  name={`${_id}[]`}
                  value={option._id}
                />
                <span className="ml-3 leading-5">{option.content}</span>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const OptionField = forwardRef(
  ({ content, canRemove, onChange, onClone, onRemove }, ref) => {
    return (
      <div>
        <input
          type="text"
          className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-green focus:border-green-300 transition duration-150 ease-in-out"
          value={content}
          ref={ref}
          onChange={onChange}
        />
        <div className="pt-2 pr-2">
          <button
            type="button"
            onClick={onClone}
            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 leading-5 font-medium text-green-800 hover:text-green-700 focus:z-10 focus:outline-none focus:border-green-300 focus:shadow-outline-green active:bg-gray-100 active:text-green-800 transition ease-in-out duration-150"
          >
            <PlusIcon />
          </button>
          {canRemove ? (
            <button
              type="button"
              className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 leading-5 font-medium text-green-800 hover:text-green-700 focus:z-10 focus:outline-none focus:border-green-300 focus:shadow-outline-green active:bg-gray-100 active:text-green-800 transition ease-in-out duration-150"
              onClick={onRemove}
            >
              <MinusIcon />
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
);

export const MultipleChoiceEditor = ({ question, updateQuestion }) => {
  const titleEl = useRef(null);
  const optionsRef = useRef([]);
  const updateHeader = () => {
    updateQuestion(question._id, {
      title: titleEl.current?.value,
    });
  };

  useEffect(() => {
    optionsRef.current = optionsRef.current.slice(
      0,
      question.options?.length || 1
    );
  }, [question.options]);

  const renderOptions = () => {
    if (Array.isArray(question.options)) {
      return question.options.map((option, index) => {
        return (
          <OptionField
            canRemove={index !== 0}
            key={option._id}
            content={option.content}
            questionId={question._id}
            ref={(el) => {
              optionsRef.current[index] = el;
            }}
            onChange={(e) => {
              updateQuestion(question._id, {
                options: [
                  ...question.options.slice(0, index),
                  {
                    _id: option._id,
                    content: e.target.value,
                  },
                  ...question.options.slice(index + 1),
                ],
              });
            }}
            onClone={() => {
              updateQuestion(question._id, {
                options: [
                  ...question.options.slice(0, index + 1),
                  { _id: nextId(), content: option.content },
                  ...question.options.slice(index + 1),
                ],
              });
            }}
            onRemove={() => {
              updateQuestion(question._id, {
                options: [
                  ...question.options.slice(0, index),
                  ...question.options.slice(index + 1),
                ],
              });
            }}
          />
        );
      });
    }
    return null;
  };

  return (
    <div>
      <form>
        <div className="mb-5">
          <label className="block font-medium leading-5">Field Label</label>
          <input
            type="text"
            className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-green focus:border-green-300 transition duration-150 ease-in-out"
            value={question.title}
            ref={titleEl}
            onChange={updateHeader}
          />
        </div>
        <div>
          <label className="block font-medium leading-5">Options</label>
          {renderOptions()}
        </div>
      </form>
    </div>
  );
};

export const TextEditor = ({ question, updateQuestion }) => {
  const titleEl = useRef(null);
  const placeholderEl = useRef(null);
  const editQuestion = () => {
    updateQuestion(question._id, {
      title: titleEl.current?.value,
      placeholder: placeholderEl.current?.value || question.placeholder,
    });
  };

  return (
    <div>
      <form>
        <div className="mb-5">
          <label className="block font-medium leading-5">Field Label</label>
          <input
            type="text"
            className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-green focus:border-green-300 transition duration-150 ease-in-out"
            value={question.title}
            ref={titleEl}
            onChange={editQuestion}
          />
        </div>
        <div>
          <label className="block font-medium leading-5">Placeholder</label>
          <input
            type="text"
            className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-green focus:border-green-300 transition duration-150 ease-in-out"
            value={question.placeholder}
            ref={placeholderEl}
            onChange={editQuestion}
          />
        </div>
      </form>
    </div>
  );
};
