import styled from "styled-components";
import { device } from "../../../globalComponents/mediaQueries";

export const NavbarContainer = styled.div`
  background: white;
  box-shadow: 3px 3px 7px #00000013;
  height: 90px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  padding: 0 15px;
  font-size: 1rem;
  align-items: center ;
`;
export const Navtitle = styled.h2`
  font-weight: 400;
  color: black;
  font-size: 16px;
  //margin-left: 10px;

  @media ${device.laptop}{
    font-size: 2vw;
  }
`;
export const NavBellIcon = styled.div`
  margin: 0 5px;
`;
export const NavAdminContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
export const ImageAvatar = styled.img`
  height: 50px;
  width: 50%;
  border-radius: 50%;
`;
export const AdminNameandTitle = styled.div`
  display: block;
  line-height: 0.1;
  margin: 0 10px;
  margin-top: 15px;
`;
export const AdminName = styled.p`
  font-weight: 500;
  font-size: 14px;
`;
export const AdminTitle = styled.h5`
  font-weight: 500;
  color: ${({ agent }) => (agent ? "blue" : "#72BE00")};
  margin-top: -5px;
  font-size: 12px;
`;
