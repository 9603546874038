import { MANAGE_USER } from "../constants/index";

export const fetchPaidSurveyHistoryReducer = (
  state = "",
  { type, payload }
) => {
  if (type === MANAGE_USER.FETCH_PAID_SURVEY_HISTORY) {
    return payload;
  }
  return state;
};

export const paidSurveyHistoryReducer = (
  state = { paidSurveyHistory: [], page: 1 },
  { type, payload }
) => {
  if (type === MANAGE_USER.PAID_SURVEY_HISTORY) {
    let paidSurveyHistoryState = { ...state };
    paidSurveyHistoryState.paidSurveyHistory = payload.paid_survey_history;
    paidSurveyHistoryState.page = payload.pages;
    return paidSurveyHistoryState;
  }
  return state;
};
