import { UPLOAD_EXCEL } from "../constants/index";



export const uploadFromExcelReducer = (state=[], {type, payload}) => {
    if(type === UPLOAD_EXCEL.IMPORT){
        return payload;
    }

    return state;
}