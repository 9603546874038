import React from "react";
import Table from "../Table/index";
import { Doughnut } from "../atoms/charts/index";
import {
  HeroContainer,
  ChartContainer,
  ChartTitle,
  ChartHr,
  Chart,
} from "./Styles";

const UserChart = () => {
  return (
    <HeroContainer>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-9">
            <Table />
          </div>
          <div className="col-lg-3">
            <ChartContainer>
              <ChartTitle>NEW USERS CHART</ChartTitle>
              <ChartHr />
              <Chart>
                <Doughnut />
              </Chart>
            </ChartContainer>
          </div>
        </div>
      </div>
    </HeroContainer>
  );
};
export default UserChart;
