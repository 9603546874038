import { MANAGE_USER } from "../constants/index";
export const blockUserNotificationReducer = (state = "", { type, payload }) => {
  if (type === MANAGE_USER.BLOCK_USER_NOTIFICATION) {
    return payload;
  }
  return state;
};

export const unblockUserNotificationReducer = (
  state = "",
  { type, payload }
) => {
  if (type === MANAGE_USER.UNBLOCK_USER_NOTIFICATION) {
    return payload;
  }
  return state;
};
