import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NavBar from "../../components/atoms/Navbar/index";
import { Spring } from "react-spring/renderprops";
import { HeroContainer } from "./Style";
import Filter from "./Filter";
import AgentTable from "./Table/index";
import AddAgent from "./AddAgent";
import { notificationAlert } from "../../functions/alertNotification";
import {
  registerAgentNotificationAction,
  registerAgentAction,
  fetchAllAgentAction,
} from "../../actions";
import BaseScaffold from "../BaseScaffold";

const AgentManagement = () => {
  const registerAgentNotification = useSelector(
    (state) => state.registerAgentNotification
  );
  const [isOpen, setOpen] = useState(false);
  const [agentEmail, setAgentEmail] = useState("");

  const dispatcher = useDispatch();

  useEffect(() => {
    dispatcher(fetchAllAgentAction(1));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (registerAgentNotification !== "") {
      if (registerAgentNotification === "success") {
        notificationAlert(
          "success",
          "Agent Added",
          `Agent with the email ${agentEmail} has been added`
        );
        setOpen(false);
        dispatcher(fetchAllAgentAction(1));
      }
      if (registerAgentNotification === "error") {
        notificationAlert("error", "Failed", "Please try again");
      }

      dispatcher(registerAgentNotificationAction(""));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerAgentNotification]);

  const toggle = () => {
    setOpen(!isOpen);
    console.log("is Open", isOpen);
  };

  const handleAddAgent = (e) => {
    setAgentEmail(e.target.value);
  };

  const handleRegisterAgent = (e) => {
    e.preventDefault();
    dispatcher(registerAgentAction(agentEmail));
  };

  return (
    <BaseScaffold title='Agent Management'>
      <Filter toggle={toggle} />
      <AgentTable />
      <AddAgent
        toggle={toggle}
        isOpen={isOpen}
        handleCancel={toggle}
        onInputChange={handleAddAgent}
        onButtonClick={handleRegisterAgent}
      />
    </BaseScaffold>
  );
};
export default AgentManagement;
