import {MANAGE_AGENT, MANAGE_PARTICIPANTS} from '../constants/index';


export const userRegistrationNotificationReducer = (state='', {type, payload}) => {
    if(type === MANAGE_PARTICIPANTS.USER_REGISTER_NOTIFICATION){
        return payload;
    }

    return state;
}
