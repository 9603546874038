import styled from "styled-components";

export const HeroContainer = styled.div`
  width: 100%;
  padding: 0 5px;
  margin-top: 10px;
`;
export const ChartContainer = styled.div`
  box-shadow: 0px 3px 3px #00000029;
  padding: 10px 0;
  width: 100%;
`;
export const ChartTitle = styled.small`
  padding: 0 10px;
  font-weight: bold;
`;
export const ChartHr = styled.hr`
  background-color: #00000029;
  margin-top: 0.5rem;
`;

export const Chart = styled.div`
  padding: 16px;
`;
