import { MANAGE_USER } from "../constants/index";

export const recentlyRegisteredUsersReducer = (
  state = [],
  { type, payload }
) => {
  if (type === MANAGE_USER.RECENTLY_REGISTERED_USERS) {
    return payload;
  }

  return state;
};
