import React, { useMemo } from "react";
import {
  NavbarContainer,
  Navtitle,
  NavBellIcon,
  NavAdminContainer,
  ImageAvatar,
  AdminNameandTitle,
  AdminName,
  AdminTitle,
} from "./Styles";
import { Avatar, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { AiOutlineMenu } from "react-icons/ai";
import { useSideBarContext } from "../../../dashboard/AgentDashboard/context";
import { BellIcon } from "../../atoms/icons/Bell";

const NavBar = ({ title, agent, onClick }) => {
  const isAdmin = useMemo(() => localStorage.user_type === "admin", []);
  const sideBarContext = useSideBarContext();

  return (
    <NavbarContainer>
      <Navtitle>{title}</Navtitle>
      {window.innerWidth > 768 && (
        <NavAdminContainer>
          <div>
            <Avatar
              size={{ xs: 30, sm: 30, md: 30, lg: 30, xl: 30, xxl: 30 }}
              icon={<UserOutlined />}
            />
          </div>
          <AdminNameandTitle>
            <AdminName>SurveyPlus LTD</AdminName>

            <AdminTitle agent={agent}>{isAdmin ? "Admin" : "Agent"}</AdminTitle>
          </AdminNameandTitle>
        </NavAdminContainer>
      )}

      {window.innerWidth <= 768 && (
        <Button
          onClick={sideBarContext.onOpenSideBar}
          icon={<AiOutlineMenu />}
        />
      )}
    </NavbarContainer>
  );
};

export default NavBar;
