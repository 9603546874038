import React, {useState, useRef} from 'react';
import { Checkbox, Radio, Input, Select} from 'antd';
import styled from 'styled-components';

const { Option } = Select;

const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;

const CheckboxOption = (props) => {
    const optionList = props?.options?.map((item, index) => {
        return  <div key={index}>
                    <Checkbox  value={item}>{item}</Checkbox>
                    <br/>
                </div>
    })

    return (
        <CheckboxGroup onChange={props.onChange}>
            {optionList}
        </CheckboxGroup>
    );
};

const RadioGroupOption = (props) => {
    const optionList = props.options?.map((item, index) => {
             return  <div key={index}>
                        <Radio  value={item}>{item}</Radio>
                        {/* <br/> */}
                     </div>
    })

    return (
        <Radio.Group onChange={props.onChange}/*  value={props.value} */>
            {optionList}
        </Radio.Group>
    )
}

const SingleInput = (props) => {
    return (
        <Input onChange={props.onChange}/>
    )
}

const MultiLineInput = (props) => {
    return (
        <TextArea autoSize style={{resize: 'none'}} onChange={props.onChange}/>
    )
}

const DropDown = (props) => {

    const optionList = props?.options?.map((item, index) => {
        return <Option key={index} value={item}>{item}</Option>
           
    })

    return (
        <StyledSelect defaultValue={props?.options[0]} onChange={props.onChange}>
              {optionList}
        </StyledSelect>
    )
}

/* const options = ['option1', 'option2', 'option3', 'option4'];
const categories = ['category1', 'category2', 'category3', 'category4']; */

export const Matrix = (props) => {
  
    const catList = props.categories?.map((cat, index) => {
        return <CategoryGroup 
        onChange={props.onChange} 
        category={cat} 
        options={props.options} 
        showOption={index === 0}
        key={index}
        />
    })
   
    return (
        <div>
            {catList}
        </div>
    )
}


const CategoryGroup = (props) => {
    const radioList = props.options?.map((item, index) => {
        return <SingleRadio 
                onChange={props.onChange} 
                key={index} 
                category={props.category} 
                option={item} 
                showCategory = {index === 0} 
                showOption={props.showOption}
            />
    })
    return (
        <StyleMatrixRadioGroup>
            {radioList}
        </StyleMatrixRadioGroup>
    )
}


const SingleRadio = ({showOption, showCategory, category, onChange, option}) =>{ 

    const inputRef = useRef();
    return (   
        <StyledSingleRadio>

            {  showCategory&& 
            <div className='category' style={{textAlign: 'left'}}>
                <p>{category}</p>
            </div>
            
            }
            <div >
                {showOption && 
                    <>
                        <label for="age2">{option}</label>
                        <br/>
                    </>
                }
                <StyledCustomRadio>
                    <input ref={inputRef} /* onChange={handleChange}  */type="radio" id="age3" name={category} value={option}/>
                    <div onClick={() => onChange(option, category, inputRef)} className='checkmark'>
                        <div className='checkmark-inner'>

                        </div>
                    </div>
                </StyledCustomRadio>
                
            </div>
            
        </StyledSingleRadio>
    )
};

const StyledCustomRadio = styled.div`
  display: grid;
  place-items: center;
  margin-top: 40px;

/* Hide the browser's default radio button */
& input {
  display: none;
}

/* Create a custom radio button */
& .checkmark {

  height: 15px;
  width: 15px;
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

& .checkmark-inner{
    height: 7px;
    width: 7px;
    border-radius: 50%;
    cursor: pointer;
}



/* On mouse-over, add a grey background color */
&:hover input ~ .checkmark {
  border-color: #007AAF;
}

/* Show the indicator (dot/circle) when checked */
& input:checked ~  .checkmark .checkmark-inner {
  display: block;
  background-color: #007AAF;
}

& input:checked ~  .checkmark {
    border-color: #007AAF;
}


`

const StyleMatrixRadioGroup = styled.div`
    display: flex;
`
const StyledSingleRadio = styled.div`
    display: flex;
    align-items: flex-end;

    & .category{
        width: 10vw;
    }

    & div{
        text-align: center;
        width: 8vw;
    }

    & p{
        margin-bottom: 2px;
    }

  
`

const StyledSelect = styled(Select)`
    width: 100%;
`

const StyledRadio = styled.div`
    display: flex;
    align-items: center;

    & > p {
        margin-bottom: 0px;
        margin-right: 10px;
        color: black;
        font-size: 14px;
        border: 1px solid yellow;
    }
`



/* const StyledCheckboxGroup  = styled(CheckboxGroup)`
    .ant-checkbox-checked .ant-checkbox-inner{
        background-color: #0F6281 !important;
    }
` */
export {
    CheckboxOption,
    RadioGroupOption,
    SingleInput,
    MultiLineInput,
    DropDown
}