import React, { useEffect, useState, useMemo } from "react";
import { FileTextOutlined, FiRefres } from "@ant-design/icons";
import { Spring } from "react-spring/renderprops";
import { Modal, Button, Input } from "antd";
import { FiRefreshCcw } from "react-icons/fi";
import { TemplateForm } from "./TemplateForm";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined, EditFilled } from "@ant-design/icons";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { Table as AntTable } from "antd";
import styled from "styled-components";
import { SearchBar } from "../../globalComponents/SearchBar";

import {
  Link,
  useHistory,
  useRouteMatch,
  Route,
  Switch,
} from "react-router-dom";
import Navbar from "../../components/atoms/Navbar/index";
import Table from "../../components/Table/index";
import {
  HeroContainer,
  FilterContainer,
  /* SearchInput, */
  FilterInput,
  FilterCon,
  LinkContainer,
  IconBg,
  Text,
} from "./Styles";
import { FunnelIcon, PlusIcon } from "../../components/atoms/icons/index";
import {
  createSurveyTemplateAction,
  createSurveyTemplateNotificationAction,
  deleteTemplateAction,
  deleteTemplateNotificationAction,
  fetchTemplateListAction,
  searchSurveyAction,
} from "../../actions";
import { notificationAlert } from "../../functions/alertNotification";
import { SurveyDashboard } from "./surveySystem/SurveyDashboard";
import { adminColor } from "../../globalComponents/globalAccet";
import BaseScaffold from "../BaseScaffold";

const surveyHeaders = [
  { title: "Survey ID", dataIndex: "id", key: "id" },
  { title: "Survey Name", dataIndex: "survey_name", key: "survey_name" },
  { title: "Created by", dataIndex: "created_by", key: "created_by" },
  { title: "Phone Number", dataIndex: "phone", key: "phone" },
  {
    title: "Number of Participants",
    dataIndex: "num_participant",
    key: "num_participant",
  },
  /* {
    title: "Survey Participated",
    dataIndex: "survey_participanted",
    key: "survey_participanted",
  }, */
  /*  { 
    title: "Delete Survey",
    dataIndex: "deleteSurvey",
    key: 'deleteSurvey',  
    render : (id, template )=> <StyledFiTrash2 onClick={() => onDeleteSurvey(id, template.template_name)}/>
  }, */
];

const Survey = () => {
  const [isTemplate, setIsTemplate] = useState(false);
  const [templateModalVisible, setTemplateModalVisible] = useState(false);
  const [isTemplateCreating, setIsTemplateCreating] = useState(false);
  const [searchInputData, setSearchInputData] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isTemplateDeleteModalVisible, setIsTemplateDeleteModalVisible] =
    useState(false);
  const [activeTemplateSummaryInfo, setActiveTemplateSummaryInfo] = useState({
    id: null,
    name: "",
  });
  const createSurveyTemplateNotification = useSelector(
    (state) => state.createSurveyTemplateNotification
  );
  const activeTemplate = useSelector((state) => state.activeTemplate);
  const deleteTemplateNotification = useSelector(
    (state) => state.deleteTemplateNotification
  );
  const templateSummaryList = useSelector((state) => state.templateSummaryList);
  const dispatcher = useDispatch();
  const history = useHistory();
  const { path, url } = useRouteMatch();

  useEffect(() => {
    dispatcher(fetchTemplateListAction(1));
  }, []);

  useEffect(() => {
    if (deleteTemplateNotification !== "") {
      if (deleteTemplateNotification === "success") {
        notificationAlert(
          "success",
          "Template Deleted",
          `${activeTemplateSummaryInfo.name} has been deleted`
        );
      }

      if (deleteTemplateNotification === "error") {
        notificationAlert("error", "Failed", "Please try again");
      }

      dispatcher(deleteTemplateNotificationAction(""));
      dispatcher(fetchTemplateListAction(1));
    }
  }, [deleteTemplateNotification]);

  useEffect(() => {
    if (createSurveyTemplateNotification !== "") {
      if (createSurveyTemplateNotification === "success") {
        notificationAlert(
          "success",
          "Template Created",
          `${activeTemplate["name"]} was created`
        );
        setIsTemplateCreating(false);
        history.push(`${url}/${activeTemplate["id"]}`);
      } else if (createSurveyTemplateNotification === "error") {
        setIsTemplateCreating(false);
        notificationAlert(
          "error",
          "Failed",
          `${activeTemplate["name"]} could not be created, try again`
        );
      }

      dispatcher(createSurveyTemplateNotificationAction(""));
    }
  }, [createSurveyTemplateNotification]);

  const handleOpenTemplateModal = () => {
    setTemplateModalVisible(true);
  };

  const handleCancelTemplateModal = () => {
    setIsTemplateCreating(false);
    setTemplateModalVisible(false);
  };

  const toggleTemplate = () => {
    setIsTemplate(!isTemplate);
  };

  const handleCreateTemplate = (values) => {
    setIsTemplateCreating(true);
    dispatcher(createSurveyTemplateAction(values));
  };

  const onEditTemplate = (id) => {
    history.push(`${url}/${id}`);
  };

  const onDeleteTemplate = (id, name) => {
    let temp = { ...activeTemplateSummaryInfo };

    temp.id = id;
    temp.name = name;
    setActiveTemplateSummaryInfo(temp);
    setIsTemplateDeleteModalVisible(true);
  };

  const templateSummaryArray = [...templateSummaryList].map((item, index) => {
    return {
      sn: index + 1,
      template_name: item.survey_name,
      number: item.num_questions,
      edit: item.id,
      delete: item.id,
    };
  });

  const handleCancelTemplateDeleteModal = () => {
    setIsTemplateDeleteModalVisible(false);
  };

  const handlDeleteTemplate = () => {
    setIsTemplateDeleteModalVisible(false);
    dispatcher(deleteTemplateAction(activeTemplateSummaryInfo.id));
  };

  const handleSearchSurvey = () => {
    setIsSearching(true);
    dispatcher(
      searchSurveyAction({ word: searchInputData, page: 1, pageSize: 10 })
    );
  };

  const onSearchInputChange = (event) => {
    setSearchInputData(event.target.value);
  };

  const cancelSearch = () => {
    setIsSearching(false);
    setSearchInputData("");
  };

  let tabView = isTemplate
    ? "Switch to Survey Table"
    : "Switch to Template Table";

  const isAdmin = useMemo(() => localStorage.user_type === "admin", []);

  return (
    <BaseScaffold title="Survey Management">
      <FilterContainer>
        <SearchBar
          searchInputData={searchInputData}
          onSearchInputChange={onSearchInputChange}
          placeholder="Enter survey name"
          onClick={handleSearchSurvey}
        />
        {isAdmin && (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <LinkContainer onClick={handleOpenTemplateModal}>
              <IconBg>
                <PlusIcon />
              </IconBg>
              <Text>Create Survey Template</Text>
            </LinkContainer>{" "}
            {/*  </Link> */}
            <Link onClick={toggleTemplate}>
              <LinkContainer>
                <StyledFiRefreshCcw />
                <Text>{tabView}</Text>
              </LinkContainer>{" "}
            </Link>
          </div>
        )}
      </FilterContainer>
      <Table
        templateData={templateSummaryArray}
        isTemplate={isTemplate}
        toggleTemplate={toggleTemplate}
        onEditTemplate={onEditTemplate}
        onDeleteTemplate={onDeleteTemplate}
        isSearching={isSearching}
        searchInputData={searchInputData}
        cancelSearch={cancelSearch}
      />

      <Modal
        title="Create Survey Template"
        open={templateModalVisible}
        onCancel={handleCancelTemplateModal}
        footer={false}
        centered={true}
      >
        <TemplateForm
          onFinish={handleCreateTemplate}
          isLoading={isTemplateCreating}
        />
      </Modal>

      <Modal
        title="Delete Template"
        visible={isTemplateDeleteModalVisible}
        onCancel={handleCancelTemplateDeleteModal}
        okText="Save"
        cancelText="Discard"
        footer={false}
      >
        <StyledUnsavedQuestionModal>
          <p>
            Do you want to delete{" "}
            <strong>{`${activeTemplateSummaryInfo.name}`}</strong>?
          </p>
          <StyledUnsavedQuestionModalFooter>
            <StyledCancelButton onClick={handleCancelTemplateDeleteModal}>
              Cancel
            </StyledCancelButton>
            <StyledSaveButton onClick={handlDeleteTemplate}>
              Delete
            </StyledSaveButton>
          </StyledUnsavedQuestionModalFooter>
        </StyledUnsavedQuestionModal>
      </Modal>
    </BaseScaffold>
  );
};

const StyledUnsavedQuestionModal = styled.div``;
const StyledFiRefreshCcw = styled(FiRefreshCcw)`
  font-size: 1.1rem;
  margin-right: 10px;
  color: #61d926;
`;

const StyledUnsavedQuestionModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const StyledButton = styled.button`
  background-color: ${adminColor};
  outline: none;
  border: none;
  color: #fff;
  padding: 15px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  box-shadow: 0px 4px 9px 0px rgba(204, 204, 204, 0.68);

  &:hover,
  &:focus {
    background-color: ${adminColor};
    color: #fff;
    outline: none;
  }

  &:hover {
    box-shadow: none;
  }
`;
const StyledCancelButton = styled(StyledButton)`
  background-color: #ff0000;

  &:hover,
  &:focus {
    background-color: #ff0000;
  }
`;
const StyledSaveButton = styled(StyledButton)`
  margin-left: 10px;
`;

const StyledSearchDiv = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInput = styled.div`
  display: block;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  outline: none;
  border: none;
  padding: 11px;
  width: ${({ agent }) => (agent ? "30rem" : "30rem")};
  box-shadow: 0px 4px 9px 0px rgba(204, 204, 204, 0.68);
  &:focus {
    outline: none;
    border: none;
    transition: all 500 ease-in-out;
  }
`;
export default Survey;
