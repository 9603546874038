import { MANAGE_AGENT } from "../constants"

export const surveyForAgentReducer = (state={pages: 0, users: [], user_count: 0}, {type, payload}) => {

    if(type === MANAGE_AGENT.SURVEY_FOR_AGENT){
        return payload
    }


    return state;

}

export const participantForSurveyReducer = (state={pages: 0, users: []}, {type, payload}) => {
    if(type === MANAGE_AGENT.PARTICIPANT_FOR_SURVEY){
        return payload;
    }

    return state;
}