import React from 'react';
import styled from 'styled-components';
import { notificationAlert } from '../../../../functions/alertNotification';
import { QuestionButton } from '../atom/QuestionButton';
import {useQuestionContext} from '../questionContext';
import { questionModeEnum } from '../questionMode';
import { questionTypes } from '../questionTypes';



const questionTypeNames = [
    {name: 'Single Line Text'},
/*     {name: 'Multiple Line Text'}, */
    {name: 'Radio Group'},
    {name: 'Checkbox'},
    {name: 'Dropdown'},
    /* {name: 'Matrix'} */
]

export const AddQuestionComponent = (props) => {
    const {questionList, setQuestion, questionMode} = useQuestionContext();

    const getQuestionProps = (name) => {
        switch(name){
            case 'Single Line Text':
                return {
                    question:'Write question here',
                    type: questionTypes.singleLine,
                    isSaved: false
                }
            case 'Multiple Line Text':
                return {
                    question:'Write question here',
                    type: questionTypes.multipleLine,
                    isSaved: false
                }
            case 'Radio Group':
                return {
                    question:'Write question here',
                    options: ['option1', 'option2', 'option3'],
                    type: questionTypes.radioGroup,
                    isSaved: false
                }
            case 'Checkbox':
                return {
                    question:'Write question here',
                    options: ['option1', 'option2', 'option3'],
                    type: questionTypes.checkBox,
                    isSaved: false
                }
            case 'Dropdown':
                return {
                    question:'Write question here',
                    options: ['option1', 'option2', 'option3'],
                    type: questionTypes.dropDown,
                    isSaved: false
                }
            case 'Matrix':
                return {
                    question:'Write question here',
                    options: ['option1', 'option2', 'option3'],
                    categories: [],
                    type: questionTypes.matrix,
                    isSaved: false
                }
            default:
                return;
        }
    }

    const createQuestion = (name) => {
        let newQuestionList
        if(questionMode === questionModeEnum.screeningQuestion && questionList.length === 1){
            const newQuestion = getQuestionProps(name);
            newQuestionList = [
            newQuestion
            ].map((item, index) => {
                if(!item.isSaved){
                    item.questionId = `${item.type}-${index + 1}`;

                    return item;
                }
                return item;
            });
        }else{

            const newQuestion = getQuestionProps(name);
             newQuestionList = [
                ...questionList,
                newQuestion
            ].map((item, index) => {
                if(!item.isSaved){
                    item.questionId = `${item.type}-${index + 1}`;

                    return item;
                }
                return item;
            });
        }
        
        setQuestion(newQuestionList)
    }
    
    const questionNameList = questionTypeNames.map((item, index) => 
     <QuestionButton key={index} buttonText={item.name} onClick={() => createQuestion(item.name)}/>
    )
    return (
        <StyledAddQuestion>
            {questionNameList}
        </StyledAddQuestion>
    );
};


const StyledAddQuestion = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
`