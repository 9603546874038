import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Spring, Transition, animated } from "react-spring/renderprops";
import { CloseOutlined, AppstoreOutlined } from "@ant-design/icons";
import SideNavItem from "./SideNavItem";
import {
  SideNavIconContainer,
  CollapseButton,
  SideNavIconUl,
  SideNavIcon,
  LogIcon,
  SideNavContainer,
} from "./Style";
import { Logout } from "../../atoms/icons/index";
import { Drawer } from "antd";
import { useSideBarContext } from "../../../dashboard/AgentDashboard/context";

const Nav = ({
  navIcons,
  admin,
  navItems,
  iconhoverToggler,
  agentNavTrue,
  textHoverToggler,
  iconData,
  setIconHover,
  onCloseDrawerNav,
  openDrawerNav,
}) => {
  const [isOpen, setIsOpen] = useState(false);


  const sideBarContext = useSideBarContext();


  return (
    <SideNavContainer>
      {window.innerWidth > 768 && (
        <SideNavIconContainer admin={admin}>
          <Spring
            from={{ opacity: 0, transform: "translateY(-30%)" }}
            to={{ opacity: 1, transform: "translateY(0)" }}
            config={{ duration: 700, delay: 500 }}
          >
            {(props) => (
              <>
                <CollapseButton
                  style={props}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {isOpen ? (
                    <span>
                      <CloseOutlined />
                    </span>
                  ) : (
                    <span>
                      <AppstoreOutlined />
                    </span>
                  )}
                </CollapseButton>

                <SideNavIconUl style={props}>
                  {navIcons.map(
                    (nav) =>
                      nav.icon !== null && (
                        <Fragment key={nav.text}>
                          <Link to={nav.href}>
                            <SideNavIcon admin={admin}>{nav.icon}</SideNavIcon>
                          </Link>
                        </Fragment>
                      )
                  )}
                </SideNavIconUl>
                <LogIcon style={props}>
                  <Logout />
                </LogIcon>
              </>
            )}
          </Spring>
        </SideNavIconContainer>
      )}

      {/* {isOpen ? ( */}
      <Transition
        native
        items={isOpen}
        from={{ opacity: 0, transform: "translateX(+10%)" }}
        enter={{ opacity: 1, transform: "translateX(0)" }}
        leave={{ opacity: 0.3, transform: "translateX(+5%)" }}
        config={{ delay: 100, duration: 300 }}
      >
        {(show) =>
          show &&
          ((props) => (
            <animated.div style={props}>
              <SideNavItem
                textHoverToggler={textHoverToggler}
                iconHover={iconData}
                isOpen={isOpen}
                agentNavTrue={agentNavTrue}
                navItems={navItems}
                admin={admin}
              />
            </animated.div>
          ))
        }
      </Transition>
      {/* ) : null} */}
      {window.innerWidth <= 768 && (
        <Drawer
          placement="left"
          onClose={sideBarContext.onOpenSideBar}
          open={sideBarContext.openSideBar}
        >
          <SideNavItem
            textHoverToggler={textHoverToggler}
            iconHover={iconData}
            isOpen={isOpen}
            agentNavTrue={agentNavTrue}
            navItems={navItems}
            admin={admin}
            closeDrawerNav={onCloseDrawerNav}
          />
        </Drawer>
      )}
    </SideNavContainer>
  );
};
export default Nav;
