import axios from "axios";
import { domain } from "../domain";
import {
  deleteAgentRoute,
  fetchAdminOverviewRoute,
  fetchSurveyOverviewRoute,
  registerParticipantRoute,
  agentLoginRoute,
  adminLoginRoute,
  loginRoute,
  registerAgentRoute,
  loginParticipantRoute,
  createSurveyTemplateRoute,
  fetchSingleTemplateRoute,
  saveTemplateQuestionRoute,
  fetchTemplateListRoute,
  deleteTemplateRoute,
  deleteQuestionRoute,
  fetchRecentSurveyRoute,
  fetchActiveSurveyRoute,
  fetchCompletedSurveyRoute,
  fetchAllSurveyRoute,
  fetchSponsorUsersRoute,
  fetchPaticipantUsersRoute,
  fetchAllUserRoute,
  fetchRecentUsersRoute,
  fetchDeletedUserRoute,
  fetchRecentAgentRoute,
  fetchAllAgentsRoute,
  fetchSurveyDetail,
  blockUser,
  unblockUser,
  fetchUserProfile,
  fetchUserPaymentHistory,
  fetchSurveyAnalysis,
  fetchSurveyAssignedUser,
  fetchSurveyRespondedUser,
  fetchPaidHistorySurvey,
  updateTemplateName,
  fetchParticipantInfo,
  fetchParticipantAssignedSurvey,
  fetchParticipantSingleSurvey,
  submitScreeningResponse,
  submitMainResponse,
  fetchSurveyForAgent,
  fetchParticipantsForSurvey,
  searchSurveyRoute,
  searchUserRoute,
  downloadAssignedSurvey,
  downloadUnassignedSurvey,
  assignSurveyParticipantRoute,
  removeSurveyParticipantRoute,
} from "../routes";

export class SideEffect {
  static deleteAgent(payload) {
    let bodyFormData = new FormData();
    bodyFormData.append("email", payload);

    return axios({
      method: "post",
      url: `${domain}${deleteAgentRoute}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
      data: bodyFormData,
    }).then((res) => res);
  }

  static fetchAdminOverview() {
    console.log(localStorage.token);
    return axios({
      method: "GET",
      url: `${domain}${fetchAdminOverviewRoute}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((response) => response);
  }

  static fetchSurveyOverView() {
    return axios({
      method: "GET",
      url: `${domain}${fetchSurveyOverviewRoute}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((response) => response);
  }

  static registerParticipant(payload) {
    let newFormData = new FormData();
    newFormData.append("full_name", payload["full_name"]);
    newFormData.append("password", payload["password"]);
    newFormData.append("phone", payload["phone"]);
    newFormData.append("email", payload["email"]);
    newFormData.append("state", payload["state"]);
    newFormData.append("lga", payload["lga"]);

    return axios({
      method: "POST",
      url: `${domain}${registerParticipantRoute}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
      data: newFormData,
    }).then((response) => response);
  }

  static fetchAgents(payload, routeType) {
    let route;
    switch (routeType) {
      case "recent":
        route = fetchRecentAgentRoute;
        break;
      case "all":
      default:
        route = fetchAllAgentsRoute;
        break;
    }

    return axios({
      method: "GET",
      url: `${domain}${route}${payload}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((response) => response);
  }

  static loginAgent(payload) {
    const { email, password } = payload;

    let bodyFormData = new FormData();
    bodyFormData.append("email", email);
    bodyFormData.append("password", password);

    return axios({
      method: "post",
      url: `${domain}${agentLoginRoute}`,
      data: bodyFormData,
    }).then((res) => res);
  }

  static loginAdmin(payload) {
    const { email, password } = payload;

    let bodyFormData = new FormData();
    bodyFormData.append("email", email);
    bodyFormData.append("password", password);

    return axios({
      method: "post",
      url: `${domain}${loginRoute}`,
      data: bodyFormData,
    }).then((res) => res);
  }

  static registerAgent = (payload) => {
    let newFormData = new FormData();
    newFormData.append("email", payload);

    return axios({
      method: "POST",
      url: `${domain}${registerAgentRoute}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
      data: newFormData,
    }).then((response) => response);
  };

  static loginParticipant(payload) {
    let newFormData = new FormData();

    newFormData.append("phone", payload["phone"]);
    newFormData.append("email", payload["email"]);

    return axios({
      method: "POST",
      url: `${domain}${loginParticipantRoute}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
      data: newFormData,
    }).then((response) => response);
  }

  static fetchParticipantInfo(token) {
    return axios({
      method: "GET",
      url: `${domain}${fetchParticipantInfo}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }

  static fetchParticipantAssignedSurvey(payload) {
    return axios({
      method: "GET",
      url: `${domain}${fetchParticipantAssignedSurvey}${payload}`,
      headers: {
        Authorization: `Token ${localStorage.participantToken}`,
      },
    });
  }

  static fetchParticipantSingleSurvey(payload) {
    return axios({
      method: "GET",
      url: `${domain}${fetchParticipantSingleSurvey}${payload}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
  }

  static submitScreeningResponse(payload) {
    const newFormData = new FormData();

    newFormData.set("quetion_id", payload["survey_id"]);
    newFormData.set("answer", JSON.stringify(payload["answerList"]));
    newFormData.set("user_id", payload["user_id"]);

    return axios({
      method: "POST",
      url: `${domain}${submitScreeningResponse}`,
      data: newFormData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
        "content-type": "application/x-www-form-urlencoded",
      },
    }).then((response) => response);
  }

  static submitMainResponse(payload) {
    const newFormData = new FormData();
    newFormData.set("survey_id", payload["survey_id"]);
    newFormData.set("data", JSON.stringify(payload["answerList"]));
    newFormData.set("user_id", payload["user_id"]);

    return axios({
      method: "POST",
      url: `${domain}${submitMainResponse}`,
      data: newFormData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
        "content-type": "application/x-www-form-urlencoded",
      },
    }).then((response) => response);
  }

  static createSurveyTemplate(payload) {
    let newFormData = new FormData();

    newFormData.append("name", payload["templateName"]);
    newFormData.append("template", 1);

    return axios({
      method: "POST",
      url: `${domain}${createSurveyTemplateRoute}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
      data: newFormData,
    }).then((response) => response);
  }

  static fetchSingleTemplate(payload) {
    return axios({
      method: "GET",
      url: `${domain}${fetchSingleTemplateRoute}${payload}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((response) => response);
  }

  static saveTemplateQuestion(payload) {
    let newFormData = new FormData();

    newFormData.append("question", payload.question);
    newFormData.append("options", payload.options);
    newFormData.append("category", payload.category);
    newFormData.append("question_type", payload.question_type);
    newFormData.append("survey_id", payload.survey_id);
    newFormData.append("screening_type", payload.screening_type);

    return axios({
      method: "POST",
      url: `${domain}${saveTemplateQuestionRoute}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
      data: newFormData,
    }).then((response) => response);
  }

  static fetchTemplateList(payload) {
    return axios({
      method: "GET",
      url: `${domain}${fetchTemplateListRoute}${payload}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((res) => res);
  }

  static deleteTemplate(payload) {
    return axios({
      method: "GET",
      url: `${domain}${deleteTemplateRoute}${payload}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((res) => res);
  }

  static deleteQuestion(payload) {
    return axios({
      method: "GET",
      url: `${domain}${deleteQuestionRoute}${payload}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((res) => res);
  }

  static blockUser(payload) {
    return axios({
      method: "GET",
      url: `${domain}${blockUser}${payload}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((res) => res);
  }
  static unblockUser(payload) {
    return axios({
      method: "GET",
      url: `${domain}${unblockUser}${payload}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((res) => res);
  }
  static fetchUserProfile(payload) {
    return axios({
      method: "GET",
      url: `${domain}${fetchUserProfile}${payload}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((res) => res);
  }

  static fetchSurvey(payload, routeType) {
    let route;
    switch (routeType) {
      case "recent":
        route = `${fetchRecentSurveyRoute}&page=${payload.page}&limit=${payload.limit}`;
        break;
      case "active":
        route = `${fetchActiveSurveyRoute}&page=${payload.page}&limit=${payload.limit}`;
        break;
      case "completed":
        route = `${fetchCompletedSurveyRoute}&page=${payload.page}&limit=${payload.limit}`;
        break;
      case "all":
        route = `${fetchAllSurveyRoute}&page=${payload.page}&limit=${payload.limit}`;
        break;
      default:
        break;
    }


    return axios({
      method: "GET",
      url: `${domain}${route}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((res) => res);
  }

  static fetchUsers(payload, routeType) {
    let route;
    switch (routeType) {
      case "recent":
        route = `${fetchRecentUsersRoute}&page=${payload.page}&limit=${payload.limit}`;
        break;
      case "deleted":
        route = `${fetchDeletedUserRoute}&page=${payload.page}&limit=${payload.limit}`;
        break;
      case "participants":
        route = `${fetchPaticipantUsersRoute}&page=${payload.page}&limit=${payload.limit}`;
        break;
      case "sponsors":
        route = `${fetchSponsorUsersRoute}&page=${payload.page}&limit=${payload.limit}`;
        break;
      case "all":
        route = `${fetchAllUserRoute}&page=${payload.page}&limit=${payload.limit}`;
        break;
      default:
        break;
    }

    return axios({
      method: "GET",
      url: `${domain}${route}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((res) => res);
  }

  static fetchSurveyDetail(payload) {
    return axios({
      method: "get",
      url: `${domain}${fetchSurveyDetail}${payload}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((response) => response);
  }
  static fetchUserPaymentHistory(payload) {
    return axios({
      method: "GET",
      url: `${domain}${fetchUserPaymentHistory}${payload.id}&page=${payload.page}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((res) => res);
  }

  static fetchSurveyAnalysis(payload) {
    return axios({
      method: "GET",
      url: `${domain}${fetchSurveyAnalysis}${payload}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((res) => res);
  }

  static fetchSurveyAssignedUser(payload) {
    console.log("IMPORTANT!!!", payload);
    return axios({
      method: "GET",
      url: `${domain}${fetchSurveyAssignedUser}${payload.surveyId}&page=${
        payload.page
      }&limit=${payload.pageSize || 10}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((res) => res);
  }

  static fetchSurveyRespondedUser(payload) {
    console.log("IMPORTANT!!!", payload);
    return axios({
      method: "GET",
      url: `${domain}${fetchSurveyRespondedUser}${payload.surveyId}&page=${
        payload.page
      }&limit=${payload.pageSize || 10}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((res) => res);
  }

  static fetchPaidSurveyHistory(payload, page) {
    return axios({
      method: "GET",
      url: `${domain}${fetchPaidHistorySurvey}${payload.id}&page=${payload.page}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((res) => res);
  }

  static updateTemplateTitle(payload, page) {
    let newFormData = new FormData();

    newFormData.append("id", payload["id"]);
    newFormData.append("name", payload["name"]);

    return axios({
      method: "POST",
      url: `${domain}${updateTemplateName}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
      data: newFormData,
    }).then((res) => res);
  }

  static fetchSurveyForAgent() {
    return axios({
      method: "GET",
      url: `${domain}${fetchSurveyForAgent}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((res) => res);
  }

  static fetchParticipantForSurvey(payload) {
    return axios({
      method: "GET",
      url: `${domain}${fetchParticipantsForSurvey}${payload}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((res) => res);
  }

  static searchSurvey(payload) {
    return axios({
      method: "GET",
      url: `${domain}${searchSurveyRoute}${payload.word}&page=${
        payload.page || 0
      }&limit=${payload.pageSize || 10}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((res) => res);
  }

  static searchUser(payload) {
    return axios({
      method: "GET",
      url: `${domain}${searchUserRoute}${payload.word}&page=${
        payload.page || 0
      }&limit=${payload.pageSize || 10}&state=${payload.state}&start=${
        payload.start
      }&end=${payload.end}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((res) => res);
  }
  static downloadAssignedSurveys(payload) {
    console.log(payload);
    return axios({
      method: "GET",
      url: `${domain}${downloadAssignedSurvey}${payload}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((res) => res);
  }
  static downloadUnassignedSurveys(payload) {
    return axios({
      method: "GET",
      url: `${domain}${downloadUnassignedSurvey}${payload}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((res) => res);
  }
  static removeSurveyParticipant(payload) {
    let newFormData = new FormData();

    newFormData.append("email", payload.email);
    newFormData.append("survey_id", payload.survey_id);
    return axios({
      method: "POST",
      url: `${domain}${removeSurveyParticipantRoute}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
      data: newFormData,
    }).then((response) => response);
  }
  static assignSurveyParticipant(payload) {
    let newFormData = new FormData();

    newFormData.append("email", payload.email);
    newFormData.append("survey_id", payload.survey_id);
    return axios({
      method: "POST",
      url: `${domain}${assignSurveyParticipantRoute}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
      data: newFormData,
    }).then((response) => response);
  }
}
