import React, { useState, useMemo } from "react";
import { HeroContainer } from "./style";
import Nav from "../../ui/navigation/index";
import {
  Dashboard,
  Landscape,
  User,
  EditUser,
  Profile,
} from "../../atoms/icons/index";
import DashboardIndex from "../../../dashboard/index";
import { loadProgressBar } from "axios-progress-bar";
import { RiSurveyFill } from "react-icons/ri";
import { SideBarContext } from "../../../dashboard/AgentDashboard/context";

const AdminDashboard = () => {
  const [openSideBar, setOpenSideBar] = useState(false);
  loadProgressBar({ easing: "ease", speed: 500 });
  const adminNavs = [
    {
      icon: <Dashboard />,
      text: "Dashboard",
      href: "/dashboard",
      hover: false,
    },
    {
      icon: <Landscape />,
      text: "Survey Management",
      href: "/dashboard/survey-management",
      hover: false,
    },
    {
      icon: <User />,
      text: "Users Management",
      href: "/dashboard/user-management",
      hover: false,
    },
    {
      icon: <Profile />,
      text: "Agents Management",
      href: "/dashboard/agent-management",
      hover: false,
    },
  ];

  const agentNavs = [
    {
      icon: <Dashboard />,
      text: "Dashboard",
      href: "/dashboard",
      hover: false,
    },
    {
      icon: <Landscape />,
      text: "Survey Management",
      href: "/dashboard/survey-management",
      hover: false,
    },
    {
      icon: <RiSurveyFill />,
      text: "Take Survey",
      href: "/dashboard/takesurvey",
      hover: false,
    },
    {
      icon: <EditUser />,
      text: "Register Users",
      href: "/dashboard/batch-record",
      hover: false,
    },
  ];

  const [iconData, setIconHover] = useState({});
  const [iconDataText, setDataText] = useState("");

  const iconhoverToggler = (data) => {
    setIconHover(data);
    if (iconData) {
      setDataText(iconData.text);
      if (iconDataText === data.text) {
        console.log(data.text);
        return false;
      }
    }
  };

  const admin = true;
  const isAdmin = useMemo(() => localStorage.user_type === "admin",[]);
  return (
    <SideBarContext.Provider
    value={{
      openSideBar,
      onOpenSideBar: () => {
        setOpenSideBar((prevState) => !prevState);
      },
    }}
  >
    <HeroContainer>
      <Nav
        admin={admin}
        iconhoverToggler={iconhoverToggler}
        setIconHover={setIconHover}
        iconData={iconDataText}
        textHoverToggler={iconhoverToggler}
        // iconHover={iconHover}
        navIcons={isAdmin ? adminNavs : agentNavs}
        navItems={isAdmin ? adminNavs : agentNavs}
      />
      <DashboardIndex isAdmin={isAdmin} />
    </HeroContainer>
    </SideBarContext.Provider>
  );
};
export default AdminDashboard;
