import { MANAGE_PARTICIPANTS } from "../constants"

export const screeningResponseNotifyReducer = (state = {status: '', result: false}, {type, payload}) => {
    if(type === MANAGE_PARTICIPANTS.SCREENING_RESPONSE_NOTIFY){
        return payload;
    }

    return state;
}


export const mainResponseNotifyReducer = (state =  '', {type, payload}) => {
    if(type === MANAGE_PARTICIPANTS.MAIN_RESPONSE_NOTIFY){
        return payload;
    }

    return state;
}


