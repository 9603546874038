import { useState } from "react";
import endpoints from "../helpers/endpoints";
import http from "../../../sideEffects/http";
import { useDispatch } from "react-redux";
import { fetchAllUsersAction } from "../../../actions";
import { notificationAlert } from "../../../functions/alertNotification";

export default function useDeleteUsers() {
    const [selectedUserId, setSelectedUsers] = useState([]);
  const dispatcher = useDispatch();
  const [isDeletingUsers, setIsDeletingUsers] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteUsers = async () => {
    try {
      
      setIsDeletingUsers(true);
      await http.delete(endpoints.deleteUsersEndpoint, {
        ids: selectedUserId,
      });
      dispatcher(fetchAllUsersAction({ page: 1, limit: 10 }));
      setIsDeletingUsers(false);
      setDeleteModalOpen(false);
      setSelectedUsers([]);
      notificationAlert(
        "success",
        "Successful",
        "Selected users have been deleted"
      );
    } catch (error) {
      setIsDeletingUsers(false);
      notificationAlert("error", "Error occurred", "Please try again");
    }
  };

  return {
    deleteUsers,
    isDeletingUsers,
    deleteModalOpen,
    onDeleteModalCancel: () => setDeleteModalOpen(false),
    openDeleteModal: () => setDeleteModalOpen(true),
    selectedUserId, setSelectedUsers
  };
}
