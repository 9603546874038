import axios from "axios";
import { domain } from "../domain";

class Http {
  get(url) {
    return axios({
      method: "GET",
      url: `${domain}${url}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
  }

  async delete(url, data) {
    return axios({
      method: "DELETE",
      url: `${domain}${url}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
      data,
    });
  }

  async post(url, data) {
    return axios({
      method: "POST",
      url: `${domain}${url}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
      data,
    });
  }
}

export default new Http();
