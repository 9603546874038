import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Table } from "antd";
import { fetchParticipantForSurveyAction } from "../../actions/index";

import { UseActiveSurveyId, UseActiveParticipantId } from "./context";
import BaseScaffold from "../BaseScaffold";

const columns = [
  {
    title: "Participant ID",
    dataIndex: "user_id",
    key: "user_id",
  },
  {
    title: "Participant Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
];

export default function SurveyParticipants(){
  const dispatcher = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const participantForSurvey = useSelector(
    (state) => state.participantForSurvey
  );
  const { activeParticipantId, setActiveParticipantId } =
    UseActiveParticipantId();
  const { activeSurveyId, setActiveSurveyId } = UseActiveSurveyId();

  useEffect(() => {
    dispatcher(fetchParticipantForSurveyAction(id));
    setActiveSurveyId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setActiveParticipantId(record.user_id);
        history.push(`/dashboard/take-survey/${activeSurveyId}`);
      },
    };
  };

  return (
    <BaseScaffold title="Registered Participants">
      <Table
        columns={columns}
        dataSource={participantForSurvey.users.filter(
          (item) => item.user_id !== activeParticipantId
        )}
        onRow={handleRow}
        /* onRow={handleRow}
        pagination={participantLogin ? {defaultCurrent: 1, total: (particiapantAssignedSurvey.pages * particiapantAssignedSurvey.total_survey), onChange:handleParginationChange}: {}} */
      />
    </BaseScaffold>
  );
};
