import { MANAGE_SURVEY } from '../constants';

export const assignedSurveyReducer = (
  state = { assignedParticipantList: [], assignedPages: 0 },
  { type, payload }
) => {
  if (type === MANAGE_SURVEY.ASSIGNED_SURVEY) {
    let prevState = {};

    prevState.assignedParticipantList = payload.participantList.filter(
      (item) => item.email !== 'DELETED'
    );
    prevState.assignedPages = payload.page;

    return prevState;
  }

  return state;
};

export const takenSurveyParticipantsReducer = (
  state = { takenParticipantList: [], takenPages: 0 },
  { type, payload }
) => {
  if (type === MANAGE_SURVEY.SURVEY_TAKEN_PARTICIPANTS) {
    let prevState = {};

    prevState.takenParticipantList = payload.participantList.filter(
        (item) => item.email !== 'DELETED'
      );;
    prevState.takenPages = payload.page;

    return prevState;
  }

  return state;
};
