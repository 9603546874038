import React from "react";

export const Profile = ({ width = "20", height = "20", fill = "white" }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="currentColor"
    >
      <path d="M9 18c-4.971 0-9-4.029-9-9s4.029-9 9-9v0c4.971 0 9 4.029 9 9s-4.029 9-9 9v0zM6.3 5.4v1.8c0 1.491 1.209 2.7 2.7 2.7s2.7-1.209 2.7-2.7v0-1.8c0-1.491-1.209-2.7-2.7-2.7s-2.7 1.209-2.7 2.7v0zM3.015 12.996c1.31 1.939 3.501 3.198 5.985 3.198s4.675-1.258 5.969-3.172l0.016-0.025c-1.756-0.826-3.814-1.308-5.985-1.308s-4.229 0.482-6.073 1.345l0.088-0.037z" />
    </svg>
  );
};
