import { notification } from "antd";
import React, { useState } from "react";

export const notificationAlert = (type, message, description) => {
  notification[type]({
    message,
    description,
  });
};

export const percentageRatio = (isParticipant, participant, users) => {
  let total = participant + users;
  let participantRatio = ((participant / total) * 100).toFixed(2);
  let usersRatio = ((users / total) * 100).toFixed(2);
  const ratio = isParticipant === true ? participantRatio : usersRatio;
  return ratio;
};

export const getPercentage = (singleValue, ...allValue) => {
  const totalValue = allValue.reduce((totalValue, currValue) => {
    return totalValue + currValue;
  }, 0);

  return ((singleValue / totalValue) * 100).toFixed();
};

export const App = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
};
