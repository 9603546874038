import styled from "styled-components";
import { Button as AntButton } from "antd";
import { SendOutlined } from "@ant-design/icons";

export const NotificationButton = () => {
  return <Button icon={<SendOutlined />}>Send Notification</Button>;
};

const Button = styled(AntButton)`
  background: #ffffff;
  border-radius: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
`;
