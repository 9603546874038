import {MANAGE_USER} from '../constants/index';

export const usersReducer = (state = {users:[], pages: 1}, {type, payload}) => {
    if(type === MANAGE_USER.USER_LIST){
        let tempState = {...state};
        tempState.users = payload.users;
        tempState.pages = payload.pages;
        return tempState;
    }



    return state;
}