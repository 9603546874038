import React, { useEffect } from "react";
import { UserOutlined } from "@ant-design/icons";

import { Avatar } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchRecentUsersAction,
  fetchRecentlyRegisteredUsersAction,
} from "../../actions/index";
import {
  RecentUsersContainer,
  RecentUserHr,
  RecentUsersTitle,
  RecentUserContainer,
  UserDetailsContainer,
  UserImage,
  UserInfoContainer,
  UserInfoName,
  UserInfoId,
  UserOnlineIndicator,
} from "./Styles";

const RecentUser = () => {
  const dispatcher = useDispatch();
  const { users } = useSelector((state) => state.userList);
  const recentUser = useSelector((state) => state.recentlyRegisteredUser);
  // console.log("the recent users are", recentUser);
  useEffect(() => {
    dispatcher(fetchRecentUsersAction({page: 1, limit: 10}));
  }, []);

  useEffect(() => {
    dispatcher(fetchRecentlyRegisteredUsersAction({page: 1, limit: 10}));
  }, []);

  // console.log("Ths is the list of users", users);
  console.log("the recently registered users", recentUser);
  const recentUsers = (
    <RecentUsersContainer>
      <RecentUsersTitle>
        <span>RECENTLY REG. USERS</span>
      </RecentUsersTitle>
      <RecentUserHr />{" "}
      {recentUser.map((userInfo) => (
        <RecentUserContainer key={userInfo.id}>
          <UserDetailsContainer>
            {userInfo.profile_pics ? (
              <UserImage
                src={`data:image/png;base64,${userInfo.profile_pics}`}

                // "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              />
            ) : (
              <div style={{ height: "2rem", marginTop: "16px", width: "2rem" }}>
                <Avatar
                  size={{ xs: 30, sm: 30, md: 30, lg: 30, xl: 30, xxl: 30 }}
                  icon={<UserOutlined />}
                />
              </div>
            )}

            <UserInfoContainer>
              <UserInfoName>{userInfo.name}</UserInfoName>
              <UserInfoId>{userInfo.phone}</UserInfoId>
            </UserInfoContainer>
          </UserDetailsContainer>
          <UserOnlineIndicator></UserOnlineIndicator>
        </RecentUserContainer>
      ))}
    </RecentUsersContainer>
  );
  return <>{recentUsers}</>;
};
export default RecentUser;
