import React, { useEffect, useState, useRef, useMemo } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Button as AntButton,
  Table,
  Select,
  Input,
  Divider,
  Tabs,
  Modal,
  Form,
  Space,
} from "antd";
import styled from "styled-components";
import { UserSurveyTable } from "./atoms/Table";
import { HorizontalBarsChart } from "../../../components/atoms/charts/index";
import { HeroContainer } from "../Styles";
import { Nav } from "./atoms/Nav";
import { WalletFilled, CopyFilled, ExportOutlined } from "@ant-design/icons";
import { MdInsertChart, MdPeople, MdRateReview } from "react-icons/md";
import { InfoNav } from "./atoms/Info";
import { Uploads } from "./atoms/Upload";
import Navbar from "../../../components/atoms/Navbar/index";
import { SideEffect } from "../../../sideEffects/index";
import {
  assignedSurveyAction,
  fetchSurveyAnalysisAction,
  fetchSurveyAssignedUsers,
  fetchSurveyDetailAction,
  fetchSurveyRespondedUser,
  surveyTakenParticipantsAction,
  downloadAssignedSurveyAction,
  downloadUnassignedSurveyAction,
  fetchUnassignSurveyParticipantAction,
  fetchAssignSurveyParticipantAction,
} from "../../../actions";
import { device } from "../../../globalComponents/mediaQueries";
import { theme } from "../../../styles/theme";
import { FiRefreshCcw, FiGrid, FiSearch } from "react-icons/fi";
import { LineChartt } from "./atoms/LineChart";
import { BackButton } from "../../../globalComponents/backButton";
import { DownloadOutlined } from "@ant-design/icons";
import useUnassignUsers from "../hooks/useUnassignUsers.js";
const { TabPane } = Tabs;

// import { Info } from "../../../components/atoms/Navbar/index";

const { Option } = Select;

const columns = [
  {
    title: "Options",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Number of Response",
    dataIndex: "reponds",
    key: "reponds",
  },
  {
    title: "% of Response",
    dataIndex: "percent",
    key: "percent",
  },
];

const SurveyManagement = () => {

  const [pageType, setPageType] = useState("survey analysis");
  const surveyDetail = useSelector((state) => state.surveyDetail);
  const assignedLink = useSelector((state) => state.downloadAssignedLink);
  const unassignedLink = useSelector((state) => state.downloadUnassignedLink);
  // const unAssignParticipant =useSelector
  const [isChart, setIsChart] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [activeQuestionNumber, setActiveQuestionNumber] = useState(1);
  const [activeQuestion, setActiveQuestion] = useState([]);
  const [questionSelectList, setSelectList] = useState([]);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userDetails, setuserDetails] = useState();
  const surveyAnalysis = useSelector((state) => state.surveyAnalysis);
  const assignedSurvey = useSelector((state) => state.assignedSurvey);
  const [assignedUserCurrentPage, setAssignedUserCurrentPage] = useState(1);
  const [takenUserCurrentPage, setTakenUserCurrentPage] = useState(1);
  const [takenUserTablePageSize, setTakenUserTablePageSize] = useState(10);
  const [waitingUserTablePageSize, setWaitingUserTablePageSize] = useState(10);
  const surveyTakenParticipants = useSelector(
    (state) => state.surveyTakenParticipants
  );
  const {
    unassignUsers,
    isLoading: isUnassigningUsers,
    unassignModalOpen,
    onUnassignModalCancel,
    openUnassignModal,
    setSelectedUsers,
    selectedUserId
  } = useUnassignUsers();

  let { id } = useParams();
  const dispatcher = useDispatch();
  const { url, path } = useRouteMatch();
  const history = useHistory();

  const NavItem = [
    /*  {
    icon: <WalletFilled />,
    text: 'View Survey Questions',
    onClick: 'onClick action',
  }, */
    {
      icon: <ExportOutlined />,
      text: "Export Data",
      onClick: "dispatcher(downloadAssignedSurveyAction(id))",
    },
    {
      icon: <CopyFilled />,
      text: "Create Survey Link",
      onClick: "onClick action",
    },
  ];

  const participantColumn = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone number",
      dataIndex: "phone",
      key: "phone",
    },

    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color = "";
        if (status === "waiting") {
          color = "#f7936f";
        } else {
          color = "#2ABA14";
        }
        return (
          <StyledOvalButton style={{ backgroundColor: color }}>
            {status}
          </StyledOvalButton>
        );
      },
    },
  ];
  const waitingParticipantColumn = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color = "";
        if (status === "waiting") {
          color = "#f7936f";
        } else {
          color = "#2ABA14";
        }
        return (
          <StyledOvalButton style={{ backgroundColor: color }}>
            {status}
          </StyledOvalButton>
        );
      },
    },
    {
      title: "Remove",
      dataIndex: "remove",
      key: "remove",
      render: (status, details) => {
        let color = "red";
        console.log("user details", details);
        return (
          <StyledOvalButton
            onClick={() => {
              setShowModal(true);
              setuserDetails(details);
            }}
            style={{ backgroundColor: color }}
          >
            unassign
          </StyledOvalButton>
        );
      },
    },
  ];
  useEffect(() => {
    dispatcher(downloadUnassignedSurveyAction(id));
    dispatcher(downloadAssignedSurveyAction(id));
    dispatcher(assignedSurveyAction({ participantList: [], page: 0 }));
    dispatcher(surveyTakenParticipantsAction({ participantList: [], page: 0 }));
    dispatcher(fetchSurveyDetailAction(id));
    dispatcher(fetchSurveyAnalysisAction(id));
  }, [id]);

  useEffect(() => {
    setActiveQuestion(surveyAnalysis[0]);

    let newList = surveyAnalysis.map((item, index) => {
      return index + 1;
    });

    setSelectList(newList);
  }, [surveyAnalysis]);

  const handleSwitch = () => {
    setIsChart((prevState) => !prevState);
  };

  const onQuestionChange = (number) => {
    setActiveQuestionNumber(number);
    setActiveQuestion(surveyAnalysis[number - 1]);
  };

  const handleActionButtonClicked = (name) => {
    if (name === "assigned participant") {
      dispatcher(
        fetchSurveyAssignedUsers({
          surveyId: id,
          page: assignedUserCurrentPage,
        })
      );
      dispatcher(
        fetchSurveyRespondedUser({ surveyId: id, page: takenUserCurrentPage })
      );
    }

    if (name === "survey question") {
      history.push(`/dashboard/assigned-survey/${id}`);
    } else {
      setPageType(name);
    }
  };

  const handleAssignedUserPagination = (page, pageSize) => {
    dispatcher(fetchSurveyAssignedUsers({ surveyId: id, page, pageSize }));
    setAssignedUserCurrentPage(page);
    setWaitingUserTablePageSize(pageSize);
  };

  const handleTakenUserPargination = (page, pageSize) => {
    dispatcher(fetchSurveyRespondedUser({ surveyId: id, page, pageSize }));
    setTakenUserCurrentPage(page);
    setTakenUserTablePageSize(pageSize);
  };
  const handleRemoveParticipant = () => {
    dispatcher(
      fetchUnassignSurveyParticipantAction({
        email: userDetails?.email,
        survey_id: id,
      })
    );
    setShowModal(false);
  };

  const handleAssignParticipant = () => {
    dispatcher(
      fetchAssignSurveyParticipantAction({ email: emailValue, survey_id: id })
    );
    setEmailValue("");
    setShowReassignModal(false);
  };

  const isAdmin = useMemo(() => localStorage.user_type === "admin", []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedUsers(selectedRowKeys);
    },
  };

  return (
    <HeroContainer>
      <Modal
        title="Unassign Users"
        open={unassignModalOpen}
        onCancel={onUnassignModalCancel}
        centered={true}
        onOk={() =>
          unassignUsers({
            surveyId: id,
            page: assignedUserCurrentPage,
          })
        }
        okButtonProps={{ loading: isUnassigningUsers }}
      >
        Do you wish to unassign selected users?
      </Modal>
      <Modal
        closable={false}
        open={showReassignModal}
        style={{ top: 50 }}
        footer={false}
        onCancel={() => setShowReassignModal(false)}
      >
        <ModalContent>
          <h3>Re-assign survey to another participant</h3>
          <Form onFinish={handleAssignParticipant}>
            {" "}
            <div>
              <Form.Item>
                <input
                  placeholder="Participant email"
                  required
                  type="email"
                  onChange={(e) => setEmailValue(e.target.value)}
                />
              </Form.Item>
              <ReassignButton htmlType="submit">Re-assign User</ReassignButton>
            </div>
          </Form>
        </ModalContent>
      </Modal>
      <Modal
        closable={false}
        open={showModal}
        style={{ top: 50 }}
        footer={false}
        onCancel={() => setShowModal(false)}
      >
        <ModalContent>
          <h3>
            Are you sure you want to unassign <span>{userDetails?.name}</span>,{" "}
            <span>{userDetails?.email}</span> from survey?{" "}
          </h3>
          <div>
            <button onClick={handleRemoveParticipant}>Continue</button>
            <button className="button" onClick={() => setShowModal(false)}>
              Cancel
            </button>
          </div>
        </ModalContent>
      </Modal>
      <Navbar title={"Survey Management"} />
      <Nav surveyNumber={id} />
      <Space direction={window.innerWidth > 425 ? "horizontal" : "vertical"}>
        {isAdmin && (
          <StyledAnchor href={assignedLink} target="_blank">
            Download Taken Surveys
          </StyledAnchor>
        )}
        {isAdmin && (
          <StyledAnchor href={unassignedLink} target="_blank">
            Download Waiting Surveys
          </StyledAnchor>
        )}
      </Space>

      <ActionNavBar onClick={handleActionButtonClicked} isAdmin={isAdmin} />
      <InfoNav data={surveyDetail.surveyCreatorInfo} />
      <Uploads data={surveyDetail.surveyDataInfo}>
        {/* <Button>View Details</Button> */}
      </Uploads>

      {/*  <UserSurveyTable TableHead={TableHead} TableData={TableData} /> */}
      {pageType === "assigned participant" ? (
        <>
          <StyledMargin />
          <StyledCategory>
            <h3>Assigned Participants</h3>
          </StyledCategory>{" "}
          <Space style={{ margin: "10px 0 0 14.5vw" }}>
            <ReassignButton onClick={() => setShowReassignModal(true)}>
              Re-assign
            </ReassignButton>
            <Button
              onClick={openUnassignModal}
              type="primary"
              danger
              size="middle"
              disabled={!Boolean(selectedUserId.length)}
            >
              unassign users
            </Button>
          </Space>
          <StyledAssignedParticipantsDiv>
            <Tabs
              tabBarStyle={{ color: "#A0AEC0", padding: "0px 23px" }}
              defaultActiveKey="1"
            >
              <TabPane tab="Taken" key="1">
                <StyledTableContainer>
                  <StyledTable
                    columns={participantColumn}
                    dataSource={surveyTakenParticipants.takenParticipantList}
                    pagination={{
                      showSizeChanger: true,
                      pageSize: takenUserTablePageSize,
                      defaultCurrent: 1,
                      current: takenUserCurrentPage,
                      total:
                        surveyTakenParticipants.takenPages *
                        takenUserTablePageSize,
                      onChange: handleTakenUserPargination,
                    }}
                  />
                </StyledTableContainer>
              </TabPane>
              <TabPane tab="Waiting" key="2">
                <StyledTableContainer>
                  <StyledTable
                    rowSelection={rowSelection}
                    columns={waitingParticipantColumn}
                    dataSource={assignedSurvey.assignedParticipantList}
                    pagination={{
                      showSizeChanger: true,
                      pageSize: waitingUserTablePageSize,
                      defaultCurrent: 1,
                      current: assignedUserCurrentPage,
                      total:
                        assignedSurvey.assignedPages * waitingUserTablePageSize,
                      onChange: handleAssignedUserPagination,
                    }}
                  />
                </StyledTableContainer>
              </TabPane>
            </Tabs>
          </StyledAssignedParticipantsDiv>
        </>
      ) : (
        <StyledAnalysisComponent>
          <StyledAnalysisActionDiv>
            <QuestionNumberList
              data={questionSelectList}
              defaultValue={activeQuestionNumber}
              onChange={onQuestionChange}
            />
            <StyledSwitch onClick={handleSwitch}>
              <StyledFiRefreshCcw />
              <p>Switch to {isChart ? "Table" : "Chart"}</p>
            </StyledSwitch>
          </StyledAnalysisActionDiv>

          <StyledQuestionDiv>
            <h3>{activeQuestion?.question_title}</h3>
          </StyledQuestionDiv>

          {activeQuestion?.question_type !== "single_input" && (
            <GroupQuestion
              data={activeQuestion?.options}
              showChart={isChart}
              dataSource={activeQuestion?.options}
            />
          )}
        </StyledAnalysisComponent>
      )}
    </HeroContainer>
  );
};

const SingleInputRespnse = (props) => {
  return (
    <StyledResponse>
      <div>
        <h4>{props.number}.</h4>
        <p>{props.text}</p>
      </div>
      <Divider />
    </StyledResponse>
  );
};

//This is for single input type of question. DO NOT DELETE
const SingleInputSurvey = (props) => {
  const list = props.data.map((item, index) => {
    return <SingleInputRespnse number={index + 1} text={item} key={index} />;
  });
  return (
    <StyledSingleIput>
      <StyledSingleInputHeader>
        <StyledInputContainer>
          <p>Filter Responses</p>
          <StyledInputWrapper>
            <Input
              prefix={<FiSearch />}
              bordered={false}
              loading
              onChange={props.onChange}
            />
          </StyledInputWrapper>
        </StyledInputContainer>
        <StyledStatDiv>
          <StatCard title="Total Number of Response" number={500} />
          <StatCard title="Total Filtered" number={80} />
        </StyledStatDiv>
      </StyledSingleInputHeader>
      <StyledSingleInputResponses>{list}</StyledSingleInputResponses>
    </StyledSingleIput>
  );
};

const StatCard = (props) => {
  return (
    <StyledCard>
      {/* <StyledIconWrapper>
          <FiGrid/>
        </StyledIconWrapper> */}
      <StyledStat>
        <p>{props.title}</p>
        <p>{props.number}</p>
      </StyledStat>
    </StyledCard>
  );
};

const GroupQuestion = (props) => {
  const [chartWidth, setChartWidth] = useState(window.innerWidth - 200);

  const questionRef = useRef();
  useEffect(() => {
    setChartWidth(questionRef?.current.getBoundingClientRect().width / 2);
  }, []);
  return (
    <StyledGroupQuestion ref={questionRef}>
      {props.showCategory && (
        <StyledCategory>
          <h3>What is your favourite color</h3>
        </StyledCategory>
      )}
      {props.showChart ? (
        <LineChartt
          width={
            window.innerWidth > 768
              ? window.innerWidth * 0.5
              : window.innerWidth
          }
          data={props.data}
        />
      ) : (
        <StyledTable
          columns={columns}
          dataSource={props.dataSource}
          pagination={false}
        />
      )}
    </StyledGroupQuestion>
  );
};

const QuestionNumberList = (props) => {
  const options = props.data.map((item, index) => {
    return (
      <Option key={index} value={item}>
        {item}
      </Option>
    );
  });
  return (
    <StyledQuestionNumberList>
      <p>Select Question</p>
      <StyledSelect
        defaultValue={props.defaultValue}
        bordered={false}
        onChange={props.onChange}
      >
        {options}
      </StyledSelect>
    </StyledQuestionNumberList>
  );
};

const ActionNavBar = (props) => {
  return (
    <StyledActionNavBar>
      {props.isAdmin && (
        <NavButton
          icon={<StyledMdInsertChart className="navIcon" />}
          text="Survey Analysis"
          onClick={() => props.onClick("survey analysis")}
        />
      )}
      <NavButton
        icon={<StyledMdRateReview className="navIcon" />}
        text="Survey Questions"
        onClick={() => props.onClick("survey question")}
      />
      <NavButton
        icon={<StyledMdPeople className="navIcon" />}
        text="Assigned Participants"
        onClick={() => props.onClick("assigned participant")}
      />
    </StyledActionNavBar>
  );
};
const NavButton = (props) => {
  return (
    <StyledButton onClick={props.onClick}>
      {props.icon}
      <p>{props.text}</p>
    </StyledButton>
  );
};

const mutedColor = "#A9AEB5";

const Button = styled(AntButton)`
  border-radius: 5px;
  margin-top: 5px;
  background: #007aaf;
  color: white;
`;

const StyledActionNavBar = styled.div`
  width: 92%;
  margin: 10px auto;
  display: flex;
  align-items: center;
  padding: 20px 0px;
  background-color: #fff;
  border-radius: 5px;
  flex-wrap: wrap;
`;

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  margin-left: 0px;
  margin-bottom: 10px;

  &:first-child {
    margin-left: 0px;
  }

  &:focus,
  &:hover {
    border: none;
    outline: none;
    background-color: transparent;

    & .navIcon {
      color: #2aba14;
    }
  }

  & p {
    margin-bottom: 0px;
    margin-left: 10px;
    font-size: 15px;
    color: #007aaf;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  @media ${device.laptop} {
    margin-left: 20px;
    margin-bottom: 0px;
    & p {
      font-size: 1vw;
    }
  }
`;

const iconStyle = `
  font-size: 24px;
  color: #61D926;
`;
const StyledMdInsertChart = styled(MdInsertChart)`
  ${iconStyle}
`;
const StyledMdPeople = styled(MdPeople)`
  ${iconStyle}
`;

const StyledMdRateReview = styled(MdRateReview)`
  ${iconStyle}
`;
const StyledAnalysisComponent = styled.div`
  width: 92%;
  margin: 20px auto;
  margin-bottom: 50px;
  background-color: #fff;
  padding: 5px;

  @media${device.laptop} {
    padding: 20px;
  }
`;
const StyledSelect = styled(Select)`
  width: fit-content;
  font-size: 15px;
  font-weight: bold;

  @media ${device.laptop} {
    font-size: 1.1vw;
  }
`;

const StyledQuestionNumberList = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid ${mutedColor};
  background-color: #fff;
  padding: 3px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  justify-content: space-between;

  & p {
    margin-bottom: 0px;
    margin-right: 15px;
    font-size: 12px;
  }

  @media ${device.laptop} {
    margin-bottom: 0px;
    width: fit-content;
    border-radius: 20px;
    & p {
      font-size: 1.1vw;
    }
  }
`;

const StyledGroupQuestion = styled.div`
  margin-top: 20px;
  display: grid;
  place-items: center;
  width: 100%;
  overflow: auto;

  @media${device.laptop} {
    margin-top: 20px;
  }
`;

const StyledQuestionDiv = styled.div`
  text-align: center;
  width: 100%;
  padding: 10px;
  margin-top: 20px;

  & h3 {
    font-size: 16px;
    margin: 0px;
  }

  @media ${device.laptop} {
    width: 70%;
    margin-top: 0px;
    margin: 0 auto;
    & h3 {
      font-size: 1.5vw;
    }
  }
`;

const StyledCategory = styled.div`
  text-align: center;
  background-color: #2aba14;
  width: 70%;
  margin: 0 auto;
  padding: 10px;

  & h3 {
    font-size: 18px;
    color: #fff;
    margin: 0px;
  }

  @media ${device.laptop} {
    & h3 {
      font-size: 1.2vw;
    }
  }
`;

const StyledTable = styled(Table)`
  margin: 0 auto;
`;

const StyledAnalysisActionDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media${device.laptop} {
    flex-wrap: nowrap;
  }
`;

const StyledSwitch = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${mutedColor};
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  & p {
    margin: 0px;
    font-size: 12px;
  }

  @media ${device.laptop} {
    width: fit-content;
    padding: 5px 20px;
    border-radius: 20px;
  }
`;

const StyledFiRefreshCcw = styled(FiRefreshCcw)`
  font-size: 20px;
  margin-right: 10px;
  color: #61d926;

  @media ${device.laptop} {
    font-size: 1.5vw;
  }
`;

const StyledSingleIput = styled.div`
  width: 100%;
`;

const StyledAnchor = styled.a`
  margin-left: 60px;
  font-weight: 500;
`;

const StyledCard = styled.div`
  border-radius: 5px;
  display: flex;
  border: 1px solid ${mutedColor};
  align-items: center;
  /*  padding: 20px; */
  width: fit-content;
  min-width: 15vw;
  margin-right: 30px;
`;

const StyledIconWrapper = styled.div`
  padding: 15px;
  background-color: #d0e7f1;
  color: #007aaf;
  font-size: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledStat = styled.div`
  margin-left: 10px;
  & > p:first-child {
    margin-bottom: 0px;
    margin-left: 5px;
    font-size: 14px;
    color: #adacab;
    font-weight: bolder;
  }

  & > p:nth-child(2) {
    margin-bottom: 0px;
    margin-left: 5px;
    font-size: 24px;
    color: #000;
    font-weight: bolder;
  }
`;

const StyledSingleInputHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`;

const StyledStatDiv = styled.div`
  display: flex;
  margin-top: 70px;
`;

const StyledInputWrapper = styled.div`
  border: 1px solid ${mutedColor};
  border-radius: 5px;
  width: 100%;
`;
const StyledInputContainer = styled.div`
  width: 30%;
  & p {
    font-size: 14px;
  }
`;

const StyledSingleInputResponses = styled.div`
  width: 70%;
  margin: 100px auto;
`;

const StyledResponse = styled.div`
  text-align: center;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;

    & h4 {
      font-size: 15px;
      margin: 0px;
      color: ${mutedColor};
      margin-right: 20px;
    }

    & p {
      margin: 0px;
      flex-basis: 95%;
    }
  }
`;

const StyledOvalButton = styled.button`
  border: none;
  color: #fff;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 25px;
  background-color: #61d926;

  &:focus {
    outline: none;
  }
`;

const StyledMargin = styled.div`
  height: 50px;
`;

const StyledAssignedParticipantsDiv = styled.div`
  width: 100%;
  margin: 0 auto;

  @media${device.laptop} {
    width: 70%;
  }
`;

const ModalContent = styled.div`
  width: 100%;
  padding: 1.5vw 2vw;
  text-align: center;
  & > h3 {
    font-size: 1.1vw;
    span {
      color: red;
    }
  }
  & > div {
    text-align: end;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 2vw;
    & > button {
      padding: 0.5vw;
      text-align: end;
      border: none;
      font-weight: 500;
      background: #2aba14;
      outline: none;
      color: white;
      cursor: pointer;
    }
    & .button {
      padding: 0.5vw;
      text-align: end;
      border: none;
      font-weight: 500;
      background: red;
      outline: none;
      color: white;
      margin-left: 2vw;
      cursor: pointer;
    }
  }
  input {
    width: 100%;
    border: 1px solid #2aba14;
    outline: none;
  }
`;
const ReassignButton = styled(Button)`
  text-align: end;
  border: none;
  font-weight: 500;
  background: #2aba14;
  outline: none;
  color: white;
  cursor: pointer;
  :active,
  :hover,
  :focus {
    color: white;
    border: none;
    outline: none;
    background: #1d920b;
  }
`;

const StyledTableContainer = styled.div`
  width: 100%;
  overflow: auto;
  background-color: #ffffff;
`;
export default SurveyManagement;
