import { combineReducers } from "redux";
import { createSurveyReducer } from "./CreateSurveyReducer";
import { uploadFromExcelReducer } from "./uploadFromExcelReducer";
import { registerAgentNotificationReducer } from "./registerAgentNotification";
import { loginFormReducer, loginNotificationReducer } from "./loginFormReducer";
import { agentListReducer } from "./agentListReducer";
import { userFormReducer } from "./userFormDataReducer";
import { userRegistrationNotificationReducer } from "./userRegistrationNotificationReducer";
import { deleteAgentNotificationReducer } from "./deleteAgentNotification";
import { adminOverviewReducer, surveyOverviewReducer } from "./overview";
import { userBatchRegisterNotificationReducer } from "./userBatchRegisterNotification";
import { createSurveyTemplateNotificationReducer } from "./createSurveyTemplateNotificationReducer";
import {
  activeTemplateReducer,
  saveTemplateQuestionNotifyReducer,
} from "./activeTemplateReducer";
import { templateListReducer } from "./templateListReducer";
import { deleteQuestionNotificationReducer } from "./deleteQuestionNotificationReducer";
import {
  surveyReducer,
  downloadAssignedLinkReducer,
  downloadUnassignedLinkReducer,
  assignSurveyParticipantReducer,
  unassignSurveyParticipantReducer,
} from "./surveyReducer";
import { usersReducer } from "./usersReducer";
import { surveyDetailReducer } from "./surveyDetailReducer";
import { blockUser, unblockUser } from "./userAccessReducer";
import {
  blockUserNotificationReducer,
  unblockUserNotificationReducer,
} from "./userAccessNotificationReducer";
import { userProfileReducer } from "./userProfileReducer";
import {
  fetchPaymentHistoryReducer,
  paymentHistoryReducer,
} from "./paymentHistoryReducer";
import { participantLoginReducer } from "./partcipantLoginReducer";
import { modalReducer } from "./modalReducer";

import { surveyAnalysisReducer } from "./surveyAnalysisReducer";
import {
  assignedSurveyReducer,
  takenSurveyParticipantsReducer,
} from "./assignedSurveyReducer";
import { paidSurveyHistoryReducer } from "./paidSurveyHistoryReducer";
import { updateTemplateNotificationReducer } from "./updateTemplateNameNotify";
import {
  loginParticipantNotificationReducer,
  participantDetailsReducer,
} from "./loginParticipantNotificationReducer";
import {
  participantAssignedSurveyReducer,
  participantSingleSurveyReducer,
} from "./participantSurveyReducer";
import {
  surveyForAgentReducer,
  participantForSurveyReducer,
} from "./surveyForAgentReducer";
import {
  screeningResponseNotifyReducer,
  mainResponseNotifyReducer,
} from "./questionSubmissionResponse";
import { recentlyRegisteredUsersReducer } from "./recentlyRegisteredUsersReducer";

const rootReducer = combineReducers({
  unassignSurveyParticipantReducer,
  assignSurveyParticipantReducer,
  mainResponseNotify: mainResponseNotifyReducer,
  screeningResponseNotify: screeningResponseNotifyReducer,
  participantForSurvey: participantForSurveyReducer,
  surveyForAgent: surveyForAgentReducer,
  uploadedParticipants: uploadFromExcelReducer,
  registerAgentNotification: registerAgentNotificationReducer,
  loginFormData: loginFormReducer,
  loginNotification: loginNotificationReducer,
  agentList: agentListReducer,
  downloadAssignedLink: downloadAssignedLinkReducer,
  downloadUnassignedLink: downloadUnassignedLinkReducer,
  userFormData: userFormReducer,
  userRegistrationNotification: userRegistrationNotificationReducer,
  deleteAgentNotification: deleteAgentNotificationReducer,
  adminOverView: adminOverviewReducer,
  surveyOverView: surveyOverviewReducer,
  userBatchRegisterNotification: userBatchRegisterNotificationReducer,
  createSurveyTemplateNotification: createSurveyTemplateNotificationReducer,
  activeTemplate: activeTemplateReducer,
  saveTemplateQuestionNotify: saveTemplateQuestionNotifyReducer,
  templateSummaryList: templateListReducer,
  deleteTemplateNotification: deleteAgentNotificationReducer,
  deleteQuestionNotification: deleteQuestionNotificationReducer,
  surveyList: surveyReducer,
  userList: usersReducer,
  surveyDetail: surveyDetailReducer,
  // blockUser,
  // unblockUser,
  blockUserNotification: blockUserNotificationReducer,
  unblockUserNotification: unblockUserNotificationReducer,
  // fetchUserProfile: fetchUserProfileReducer,
  userProfile: userProfileReducer,
  fetchPaymentHistory: fetchPaymentHistoryReducer,
  paymentHistory: paymentHistoryReducer,
  surveyAnalysis: surveyAnalysisReducer,
  assignedSurvey: assignedSurveyReducer,
  surveyTakenParticipants: takenSurveyParticipantsReducer,
  paidSurveyHistory: paidSurveyHistoryReducer,
  updateTemplateNameNotify: updateTemplateNotificationReducer,
  participantLogin: participantLoginReducer,
  isOpen: modalReducer,
  loginParticipantNotification: loginParticipantNotificationReducer,
  participantDetails: participantDetailsReducer,
  particiapantAssignedSurvey: participantAssignedSurveyReducer,
  participantSingleSurvey: participantSingleSurveyReducer,
  recentlyRegisteredUser: recentlyRegisteredUsersReducer,
});
/* const rootReducer = createSurveyReducer; */
export default rootReducer;
