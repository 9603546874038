import styled from "styled-components";
import { Button as AntButton } from "antd";
import { Link } from "react-router-dom";

export const HeroContainer = styled.div`
  width: 100%;
`;
export const ButtonLogin = styled(AntButton)`
  &&& {
    border: none;
    outline: none;
    padding: 0 auto;
    background-color: #0e6180;
    border-radius: 5px;
    color: white;
    font-weight: 500;
    &:hover {
      cursor: pointer;
      background: #326172;
      ${"" /* color: #00baff; */}
    }
  }
`;
export const TableNav = styled.div`
  width: 100%;
  background-color: ${({ admin }) => (admin ? "#249B00" : "#0E6180")};
  display: flex;
  padding: 10px;
`;
export const NavLink = styled(Link)`
  color: white;
  font-weight: 500;
  margin: auto 1.5rem;
  font-size: 0.9rem;
  width: wrap-content;
  border-radius: 50px;
  padding: 10px;
  text-decoration: none;
  &:focus {
    color: black;
    background-color: white;
  }
`;

export const Tabl = styled.table`
  text-align: center;
  width: 100%;
`;
export const TableHead = styled.thead`
  border-bottom: 1px solid white;
`;
export const TableRow = styled.tr``;
export const TableHeadContent = styled.th`
  background-color: "#f9f9f9";
  border-bottom: 0.1em solid #00000014;
  font-size: small;
  font-weight: 700;
  padding: 1rem;
`;
export const TableBody = styled.tbody`
  background-color: #ffffff;
`;
export const TableData = styled.td`
  font-size: smaller;
  font-weight: 500;
  border-bottom: 0.1em solid #00000029;
  margin: 2rem;
  padding: 0.8rem;
  background-color: white;
`;
export const ResendButton = styled(AntButton)`
  background-color: white;
  outline: none;
  border: none;
  color: black;
  font-weight: bold;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    box-shadow: 2px 2px 3px grey;
  }
`;
