import styled from "styled-components";
import { Button } from "antd";

export const HeroContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(
      to bottom,
      rgba(25, 49, 27, 0.5) 0%,
      rgba(36, 155, 0, 0.5) 100%
    ),
    url(${(props) => props.src});
  background-size: cover;
`;

export const LoginContainer = styled.div`
  display: block;
  width: 375px;
  height: 450px;
  background: #ffffff;
  justify-content: center;
  border-radius: 19px;
`;
export const Logo = styled.img`
  height: 3rem;
  margin: 1rem auto;
  width: 100%;
`;
export const Hr = styled.div`
  height: 0.5px;
  background-color: #00000029;
  margin: 10px 2px;
`;

export const InputContainer = styled.div`
  width: 100%;
  padding: 0 25px;
`;

export const Title = styled.h3`
  font-size: 1.2rem;
  text-align: center;
  font-weight: 600;
  margin-top: 10px 0;
  color: ${({ admin }) => (admin ? "#249B00" : "#0E6180")};
`;

export const Input = styled.input`
  width: 100%;
  height: 25px;
  border: none;
  font-size: 0.9rem;
  padding: 0 20px;
  border-bottom: 1px solid #00000029;
  margin: 20px auto;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;
export const Forgot = styled.h5`
  width: 90%;
  font-size: smaller;
  font-weight: 400;
  //margin-top: -10px;
  // margin-left: 270px;
  color: grey;
  text-align: right;

  &:hover {
    color: #249b00;
    cursor: pointer;
  }
`;
export const LoginButton = styled(Button)`
  ${"" /* width:150px; */}
  height:35px;
  border: none;
  outline: none;
  color: white;
  margin: 20px auto;
  text-align: center;
  padding-top: 5px;
  border-radius: 7px;
  background-color: ${({ admin }) => (admin ? "#249B00" : "#0E6180")};
  font-size: small;
  font-weight: 400;
  &:hover {
    background-color: black;
    cursor: pointer;
  }
`;
export const LoginButton2 = styled(Button)`
  background-color: ${({ admin }) => (admin ? "#249B00" : "#0E6180")};
`;

export const NewloggerContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0 50px;
  height: 30px;
  width: 100%;
`;
export const NewloggerAccount = styled.h5`
  font-size: smaller;
  font-weight: 300;
  color: black;
`;
export const Newlogger = styled.h5`
  font-size: smaller;
  font-weight: 300;
  color: #249b00;
  &:hover {
    cursor: pointer;
  }
`;
export const ToggleAgent = styled.div`
  width: 75%;
  height: 35px;
  text-align: center;
  padding-top: 5px;
  border: none;
  outline: none;
  color: ${({ admin }) => (admin ? "#0E6180" : "green")};
  margin: 20px auto;
  border-radius: 20px;
  background: transparent;
  font-size: small;
  font-weight: ${({ admin }) => (admin ? "smaller" : "bold")};
  &:hover {
    cursor: pointer;
  }
`;
