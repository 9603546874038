import styled from "styled-components";
import { PaperClipOutlined } from "@ant-design/icons";
import { Button as AntButton } from "antd";
import React from "react";

export const Nav = ({ surveyNumber, navItem, link }) => {
  const NavConst = navItem?.map((item) => (
    <div
      style={{
        display: "flex",
        // justifyContent: "space-between",
        alignItems: "center",
      }}
      key={item.icon}
    >
      {item.icon}
      <TextComponent onClick={item.onClick}>{item.text}</TextComponent>
    </div>
  ));
  return (
    <NavContainer>
      <div>
        <Title>Survey{surveyNumber}</Title>
      </div>
      <>
        {/* <NavItemContainer>
          {NavConst} <LinkInput value={link} disabled />{" "}
          <CopyButton icon={<PaperClipOutlined />}>Copy</CopyButton>
        </NavItemContainer> */}
      </>
    </NavContainer>
  );
};
const NavContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 54px;
  padding: 10px;
  padding-left: 60px;
  background: #61d926;
  color: white;
`;
const Title = styled.h3`
  font-weight: 500;
  color: white;
`;
// const
const TextComponent = styled.h5`
  margin: 0 10px;
  margin-right: 30px;
  font-size: 16px;
  font-weight: 450;
  color: white;
  &:hover {
    cursor: pointer;
  }
`;
const NavItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LinkInput = styled.input`
  border-radius: 20px;
  color: #00000059;
  background: white;
  outline: none;
  height: 25px;
  border: none;
  &:hover {
    cursor: pointer;
  }
`;
const CopyButton = styled(AntButton)`
  color: black;
  background: white;
  border-radius: 05px;
  margin: 0 5px;
  &:hover,
  &:after {
    background: white;
    color: black;
    outline: none;
    border: none;
  }
`;
