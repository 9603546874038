function _getCategoryLength(questionList) {
    let category = [];
    let totalNumberOfCategory = 0;
  
    questionList.forEach((item) => {
      if (item.hasOwnProperty('category') && item.category.length > 0) {
        totalNumberOfCategory += 1;
        const temporaryCat = item['category'];
        category = [...category, ...temporaryCat];
      }
    });
  
    return {
      'categoryLength': category.length,
      'totalNumberOfCategory': totalNumberOfCategory
    };
  }
  
   function getQuestionLength(currentQuestionnaire,currentScreeningQuestion) {
    const questionnaireCategory = _getCategoryLength(currentQuestionnaire);
    const screeningQuestionCategory =
        _getCategoryLength(currentScreeningQuestion);
  
    const questionnaireLength = currentQuestionnaire.length -
        questionnaireCategory['totalNumberOfCategory'];
    const screeningQuestionLength = currentScreeningQuestion.length -
        screeningQuestionCategory['totalNumberOfCategory'];
   const questionnaireCategoryLength =
        questionnaireCategory['categoryLength'];
    const screeningQuestionCategoryLength =
        screeningQuestionCategory['categoryLength'];
  
    const totalNumberOfQuestion = questionnaireLength +
        screeningQuestionLength +
        questionnaireCategoryLength +
        screeningQuestionCategoryLength;
  
    return totalNumberOfQuestion;
  }
  

  export default getQuestionLength;