function getAnswerLength(surveyAnswerList) {
    let answerLength = 0;
  
    surveyAnswerList.forEach((element) => {
      if (element['question_type'] !== 'matrix') {
        answerLength += 1;
      }
  
      if (element['question_type'] === 'matrix') {
        answerLength += element['answer'].length;
      }    
    });
  
    return answerLength;
  }
  
  export default getAnswerLength
  