import { LOGIN } from "../constants/index";

export const loginFormReducer = (
  state = { email: "", password: "" },
  { type, payload }
) => {
  if (type === LOGIN.EMAIL) {
    let loginObject = Object.assign({}, state);
    loginObject.email = payload;
    return loginObject;
  }

  if (type === LOGIN.PASSWORD) {
    let loginObject = Object.assign({}, state);
    loginObject.password = payload;
    return loginObject;
  }

  return state;
};

export const loginNotificationReducer = (
  state = { status: "", message: "" },
  { type, payload }
) => {
  if (type === LOGIN.NOTIFICATION) {
    return payload;
  }

  return state;
};
