import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';

import {rootSaga}from '../saga';

import rootReducer from '../reducers/index';

const configureStore = () => {
    const sagaMiddleware = createSagaMiddleware();
    const composeEnhancers = window._REDUX_DEVTOOLS_EXTENTION_COMPOSE_ || compose();
    const store = createStore(rootReducer,composeEnhancers(applyMiddleware(sagaMiddleware)))
    sagaMiddleware.run(rootSaga);
    return store;
};

export default configureStore;
