import { OVERVIEW } from "../constants/index";

export const adminOverviewReducer = (state = {}, { type, payload }) => {
  if (type === OVERVIEW.ADMIN_OVERVIEW) {
    return payload;
  }

  return state;
};

export const surveyOverviewReducer = (state = {}, { type, payload }) => {
  if (type === OVERVIEW.SURVEY) {
    return payload;
  }
  return state;
};
