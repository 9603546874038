import { MANAGE_USER } from "../constants/index";

export const fetchUserProfileReducer = (state = "", { type, payload }) => {
  if (type === MANAGE_USER.FETCH_USER_PROFILE) {
    return payload;
  }
  return state;
};

export const userProfileReducer = (state = {}, { type, payload }) => {
  if (type === MANAGE_USER.USER_PROFILE) {
    return payload;
  }
  return state;
};
