import React, {useState} from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { PoweroffOutlined } from '@ant-design/icons';
import { adminColor } from '../../globalComponents/globalAccet';
import styled from 'styled-components';

const layout = {
  labelCol: { span: 16 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 16 },
};

export const TemplateForm = (props) => {
  const [isLoading, setIsLoading] =useState(false);

  const onFinish = values => {
    setIsLoading(true)
    console.log('Success:', values);
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      {...layout}
      name="basic"
      layout='vertical'
      onFinish={props.onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Template Name"
        name="templateName"

      >
        <Input />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <StyledButton size='medium' type="primary" htmlType="submit" loading={props.isLoading}>
          Create Template
        </StyledButton>
      </Form.Item>
    </Form>
  );
};

const StyledButton = styled(Button)`
  background-color: ${adminColor};
  outline: none;
  border: none;

  &:hover, &:focus{
    background-color: ${adminColor};
  }
`
