import styled from "styled-components";
import React from "react";
import device from "../../../../utils/breakPoints";

export const InfoNav = ({ data }) => {
  return (
    <HeroContainer>
      <div>
        <InfoText>{data.name}</InfoText>
        <InfoText>
          {data.date}
          <TimeText>{data.time} </TimeText>
        </InfoText>
        <InfoText>{data.phoneNumber}</InfoText>
        <InfoText>{data.email}</InfoText>
      </div>
    </HeroContainer>
  );
};

const HeroContainer = styled.div`
  width: 92%;
  padding: 10px;
  display: block;
  background-color: #ffffff;
  border-radius: 5px;
  margin: 0 auto;
  margin-bottom: 10px;
  @media ${device.laptop} {
    padding-left: 60px;
  }
`;
const InfoText = styled.h5`
  color: #656565;
  font-size: small;
`;
const TimeText = styled.span`
  color: black;
  margin-left: 5px;
  font-size: small;
`;
