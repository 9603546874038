import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import FinancialStats from "./Financialstats";
import Survey from "./surveys";
import CreateSurvey from "./surveys/CreateSurvey/index";
import UserManagement from "./User-Management/index";
import WalletManagement from "./Wallet-Management/index";
import SurveyManagement from "../dashboard/surveys/Survey-management(users)/index";

import AgentManagement from "./Agent-Management/Index";
import { SurveyDashboard } from "./surveys/surveySystem/SurveyDashboard";
import { AssignedSurvey } from "./surveys/surveySystem/AssignedSurvey";
import ErrorPage from "../Errorpage";
import AgentDashboard from "./AgentDashboard/AgentDashboard";
import AgentSurveyDashboard from "./AgentDashboard/SurveyDashboard";
import ShowBatchRecord from "./AgentDashboard/BatchParticipant/ShowBatchRecord";
import SurveyParticipants from "./AgentDashboard/SurveyParticipants";
import SurveyGround from "./AgentDashboard/TakeSurvey/SurveyGround";
import BatchRegister from "./AgentDashboard/BatchParticipant/RegisterParticipant";
import {
  SurveyQuestionAnwserContext,
  ActiveParticipantIdContext,
  ActiveSurveyIdContext,
  SideBarContext,
} from "./AgentDashboard/context";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { closeModalAction } from "../actions/index";
import Register from "../register";

const Dashboard = ({ isAdmin }) => {
  const dispatcher = useDispatch();
  const handleModalCancel = () => {
    dispatcher(closeModalAction(false));
  };
  const [activeParticipantId, setActiveParticipantId] = useState(0);
  const [activeSurveyId, setActiveSurveyId] = useState(0);
  const [questionAnswer, setQuestionAnswer] = useState([]);
 

  const isOpen = useSelector((state) => state.isOpen);
  return (
    <ActiveParticipantIdContext.Provider
      value={{ activeParticipantId, setActiveParticipantId }}
    >
      <ActiveSurveyIdContext.Provider
        value={{ activeSurveyId, setActiveSurveyId }}
      >
        <SurveyQuestionAnwserContext.Provider
          value={{ questionAnswer, setQuestionAnswer }}
        >
         
            <Modal
              closable={false}
              style={{ top: 50 }}
              footer={false}
              open={isOpen}
              onCancel={handleModalCancel}
            >
              <Register title="Register User" admin={true} />
            </Modal>

            <Switch>
              {isAdmin && (
                <Route
                  exact
                  path="/dashboard/user-management/wallet/:id"
                  component={WalletManagement}
                />
              )}
              <Route
                exact
                path="/dashboard/survey-management/survey/:id"
                component={SurveyManagement}
              />
              {isAdmin && (
                <Route
                  exact
                  path="/dashboard/user-management"
                  component={UserManagement}
                />
              )}
              {isAdmin && (
                <Route
                  exact
                  path="/dashboard/agent-management"
                  component={AgentManagement}
                />
              )}
              <Route
                exact
                path="/dashboard/survey-management"
                component={Survey}
              />
              <Route
                exact
                path="/dashboard/survey-management/:id"
                component={SurveyDashboard}
              />
              <Route
                exact
                path="/dashboard"
                component={isAdmin ? FinancialStats : AgentDashboard}
              />
              <Route path="/dashboard/assigned-survey/:id">
                <AssignedSurvey />
              </Route>
              <Route exact path={`/dashboard/batch-register`}>
                <BatchRegister />
              </Route>
              <Route path={`/dashboard/take-survey/:id`}>
                <SurveyGround />
              </Route>
              <Route path={`/dashboard/survey-participants/:id`}>
                <SurveyParticipants />
              </Route>
              <Route exact path={`/dashboard/batch-record`}>
                <ShowBatchRecord />
              </Route>
              <Route exact path={`/dashboard/takesurvey`}>
                <AgentSurveyDashboard isAdmin={isAdmin} />
              </Route>

              <Route path="*" component={ErrorPage} />
            </Switch>
   
        </SurveyQuestionAnwserContext.Provider>
      </ActiveSurveyIdContext.Provider>
    </ActiveParticipantIdContext.Provider>
  );
};
export default Dashboard;
