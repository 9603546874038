import React from "react";
import { Table } from "antd";
import styled from 'styled-components';

const TableComponent = ({ column, data, onRow, pagination, onChange}) => {
  return (
    <>
      <StyledTable columns={column} dataSource={data} onRow={onRow} pagination={pagination} onChange={onChange}/>
    </>
  );
};

const StyledTable = styled(Table)`
  & tr{
    cursor: pointer;
  }
`
export default TableComponent;
