import React from "react";
import { Spin, Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styled from "styled-components";

export const Spinner = ({ tip }) => {
  const antIcon = (
    <LoadingOutlined style={{ color: "#2ABA14", fontSize: 24 }} spin />
  );

  return (
    // Return value should be component
    <Spin tip={tip} indicator={antIcon} />
  );
};
