import React, { useEffect, useState } from "react";
import { Table, Modal, Button as AntButton } from "antd";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { device } from "../../../globalComponents/mediaQueries";

import { Navbar, Menu, MenuItem } from "../../../components/atoms/TableNavs";
import { HeroContainer } from "../../../components/Table/Styles";
//import TableBody from "../Table/TableBody";
import {
  deleteAgentAction,
  deleteAgentNotificationAction,
  fetchAllAgentAction,
  fetchRecentAgentAction,
} from "../../../actions";
import { notificationAlert } from "../../../functions/alertNotification";
import { loadProgressBar } from "axios-progress-bar";
const headers = [
  " ",
  "Name",
  "Membership",
  "Location",
  "Phone Number",
  "Status",
  "Date Added",
  "Members Created",
  "",
  "",
];

const AgentTable = () => {
  loadProgressBar({ easing: "ease", speed: 500 });
  const [activeTab, setActiveTab] = useState(1);
  const [deletedAgentEmail, setDeletedAgentEmail] = useState("");
  const agentList = useSelector((state) => state.agentList);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const deleteAgentNotification = useSelector(
    (state) => state.deleteAgentNotification
  );
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const dispatcher = useDispatch();

  useEffect(() => {
    if (deleteAgentNotification !== "") {
      if (deleteAgentNotification === "success") {
        notificationAlert(
          "success",
          "Agent Deleted",
          `Agent with the email ${deletedAgentEmail} has been deleted`
        );
        dispatcher(fetchAllAgentAction(1));
      }

      if (deleteAgentNotification === "error") {
        notificationAlert("error", "Failed", "Please try again");
      }

      dispatcher(deleteAgentNotificationAction(""));
    }
  }, [deleteAgentNotification]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        const handleDelete = () => {
          setDeletedAgentEmail(record.email);
          setDeleteModalVisible(true);
        };

        return (
          <BlockButton size={"small"} onClick={handleDelete}>
            Delete
          </BlockButton>
        );
      },
    },
  ];

  const onDeleteModalCancel = () => {
    setDeleteModalVisible(false);
  };

  const OnDeleteAgent = () => {
    setDeleteModalVisible(false);
    dispatcher(deleteAgentAction(deletedAgentEmail));
  };

  const handleTablePagination = (pageNumber) => {
    setCurrentPageNumber(pageNumber);
    if (activeTab === 1) {
      dispatcher(fetchAllAgentAction(pageNumber));
    } else if (activeTab === 2) {
      dispatcher(fetchRecentAgentAction(pageNumber));
    }
  };

  const handleFetchAllAgents = () => {
    setActiveTab(1);
    setCurrentPageNumber(1);
    dispatcher(fetchAllAgentAction(1));
  };

  const hanldeFetchRecentAgents = () => {
    setActiveTab(2);
    setCurrentPageNumber(1);
    dispatcher(fetchRecentAgentAction(1));
  };

  return (
    <HeroContainer>
      <StyledTableBar>
        <StyledMenu
          StyledMenu
          active={activeTab === 1}
          onClick={handleFetchAllAgents}
        >
          All Agents
        </StyledMenu>
        <StyledMenu active={activeTab === 2} onClick={hanldeFetchRecentAgents}>
          New Agents
        </StyledMenu>
      </StyledTableBar>
      <StyledTableContainer>
        <Table
          dataSource={agentList.agentList}
          columns={columns}
          pagination={{
            defaultCurrent: currentPageNumber,
            total: 10 * agentList.pages,
            current: currentPageNumber,
            onChange: handleTablePagination,
            showSizeChanger: false,
          }}
        />
      </StyledTableContainer>
      <Modal
        title="Delete Agent"
        visible={deleteModalVisible}
        onCancel={onDeleteModalCancel}
        onOk={OnDeleteAgent}
        centered={true}
      >
        <p>Do you which to delete agent with the email {deletedAgentEmail}</p>
      </Modal>
    </HeroContainer>
  );
};

const StyledTableContainer = styled.div`
  width: 100%;
  overflow: auto;
  background-color: #ffffff;
`;
const StyledTableBar = styled.div`
  display: flex;
  padding: 0px 13px;
  background-color: #61d926;
  color: #fff;
`;

export const BlockButton = styled(AntButton)`
  background: #c82e29;
  color: white;
  ${"" /* width: 98px; */}
  text-align: center;
  border-radius: 20px;
  outline: none;
  font-size: 12px;

  border: none;
  &:focus,
  &:hover {
    background: #c82e29;
    color: white;
  }
`;

const StyledMenu = styled.div`
  cursor: pointer;
  padding: 5px 10px;
  font-size: 10px;
  font-weight: 500;

  background-color: ${(props) => (props.active ? "#fff" : "transparent")};
  color: ${(props) => (props.active ? "green" : "#fff")};
  ${"" /* transition: visibility 0s, opacity 0.5s linear; */}
  &:hover {
    ${"" /* background-color: #fff; */}
    color: green;
  }

  &:first-child {
    padding-left: 10px;
  }

  @media ${device.laptop} {
    font-size: 1vw;
  }
`;

const StyledLink = styled.p`
  cursor: pointer;
  color: blue;
`;
export default AgentTable;
