import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { CreateQuestionnaire } from './CreateQuestionnaire'; 
import Navbar from "../../../components/atoms/Navbar/index";
import { device } from '../../../globalComponents/mediaQueries';
import {QuestionContext} from './questionContext';
import { QuestionDashboard } from './QuestionDashboard';
import { adminColor } from '../../../globalComponents/globalAccet';
import { BackButton } from '../../../globalComponents/backButton';
import {Button, Modal} from 'antd';
import { FiSave, FiToggleLeft, FiCornerUpLeft } from 'react-icons/fi';
import {questionTypes} from './questionTypes';
import { useSelector, useDispatch } from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
import { deleteQuestionNotificationAction, fetchSingleTemplateAction, saveTemplateQuestionAction, saveTemplateQuestionNotifyAction, setTemplateQuestionnaireAction, setTemplateScreeningQuestionAction } from '../../../actions';
import { notificationAlert } from '../../../functions/alertNotification';
import { questionModeEnum } from './questionMode';


export const AssignedSurvey = (props) => {
    const [questionList, setQuestionList] = useState([]);
    const [questionMode, setQuestionMode] = useState(questionModeEnum.screeningQuestion);
    const [isSavingTemplate, setIsSavingTemplate] = useState(false);
    const [refinedQuestionList, setrefinedQuestionList] = useState([]);
    const [tracker, setTracker] = useState(0);
    const [isUnsavedQuestionModalVisible, setIsUnsavedQuestionModalVisible] = useState(false);
    const [saveAndSwitch, setSaveAndSwitch] = useState(false);
    const activeTemplate = useSelector(state => state.activeTemplate);
    const saveTemplateQuestionNotify = useSelector(state => state.saveTemplateQuestionNotify);
    const deleteQuestionNotification = useSelector(state => state.deleteQuestionNotification);
    const {id} = useParams();
    const dispatcher = useDispatch();
    const history = useHistory();

    console.log({questionList});    

    const questionBackendFormat = item => {
        let screeningType = questionMode === questionModeEnum.questionnaire? 0:1
        if(item.type === questionTypes.singleLine || item.type === questionTypes.multipleLine){
            return {
                question: item.question,
                question_type: 'single_input',
                options: null,
                category: null,
                survey_id: parseInt(id),
                screening_type: screeningType,
                isSaved: item.isSaved,
    

            }
        }else{
            return {
                question: item.question,
                question_type: item.type,
                options: item.options.toString(),
                category: null,
                survey_id: parseInt(id),
                screening_type: screeningType,
                isSaved: item.isSaved,
            }
        }
    }

    const questionFrontendFormat = item => {
        if(item.question_type === questionTypes.singleLine){
            return {
                question: item.question,
                type: item.question_type,
                questionId: item.question_id,
                isSaved: true
            }
        }else{

            let newOptions = typeof item.options === 'string'? item.options.split(','): item.options;
            return {
                question: item.question,
                type: item.question_type,
                options: newOptions,
                category: item.category,
                questionId: item.question_id,
                isSaved: true
            }
        }
       
    }

    useEffect(() => {
        let newQuestionList;
        if(questionMode === questionModeEnum.questionnaire){
            newQuestionList = activeTemplate.details.questionnaire?.slice().map(questionFrontendFormat)
        }else if(questionMode === questionModeEnum.screeningQuestion){
            newQuestionList = activeTemplate.details.screening_question?.slice().map(questionFrontendFormat)
        }

        setQuestionList(newQuestionList);
    }, [activeTemplate, questionMode])


   

    useEffect(() => {
       
        if(saveTemplateQuestionNotify !== ''){
            if( 
                tracker > 0 
                && 
                tracker < refinedQuestionList.slice().length
            ){
                dispatcher(saveTemplateQuestionAction(refinedQuestionList.slice()[tracker]));
                setTracker(prevState => prevState + 1);
            }
            if(saveTemplateQuestionNotify === 'success' && tracker === refinedQuestionList.slice().length && refinedQuestionList.slice().length !== 0){
                notificationAlert('success', 'Questions Added',`${refinedQuestionList.slice().length} questions has been added to ${activeTemplate.details?.survey_name}`)
                setIsSavingTemplate(false);
            }else if(saveTemplateQuestionNotify === 'error'){
                notificationAlert('error', 'Failed', 'Please try again');
                setIsSavingTemplate(false);
            }

            if(tracker === refinedQuestionList.slice().length){
                setIsSavingTemplate(false);
                setTracker(0);
                dispatcher(saveTemplateQuestionNotifyAction(''));
                dispatcher(fetchSingleTemplateAction(id));
                if(saveAndSwitch){
                    setSaveAndSwitch(false);
                    handleQuestionModeSwitch();
                }
            }
        }
    }, [saveTemplateQuestionNotify, tracker])


    useEffect(() => {
        if(deleteQuestionNotification !== ''){
          if(deleteQuestionNotification === 'success'){
              console.log('SUCCESS!!!');
            notificationAlert('success', 'Question deleted', 'Question was deleted')
          }
    
          if(deleteQuestionNotification === 'error'){
            notificationAlert('error', 'Failed', 'Please try again')
          }
    
          dispatcher(deleteQuestionNotificationAction(''));
          dispatcher(fetchSingleTemplateAction(id));
        }
      }, [deleteQuestionNotification])

    useEffect(() => {
        dispatcher(fetchSingleTemplateAction(id));
    }, [id])

    const saveSurvey = () => {
        const finalQuestionList = questionList.slice().map(questionBackendFormat)

        setrefinedQuestionList(finalQuestionList.filter(item => !item.isSaved));

        if(finalQuestionList.filter(item => !item.isSaved).length > 0){
            setIsSavingTemplate(true);
            dispatcher(saveTemplateQuestionAction(finalQuestionList[0]));
            setTracker(1);
        
        } 

        if(questionMode === questionModeEnum.questionnaire){
            dispatcher(setTemplateQuestionnaireAction(finalQuestionList));
        }else{
            dispatcher(setTemplateScreeningQuestionAction(finalQuestionList));
        }
    }
    const handleSurveySubmit = (e) => {
        e.preventDefault();
        saveSurvey();
    }

    const handleQuestionModeSwitch = () => {
        if(questionList.some(item => !item.isSaved)){
           setIsUnsavedQuestionModalVisible(true);
           return;
        }

        
        setrefinedQuestionList([]); 
        setTracker(0);
        dispatcher(saveTemplateQuestionNotifyAction(''));

        let formatedQuestionList = questionList.slice().map(questionBackendFormat);

        if(questionMode === questionModeEnum.questionnaire){
            dispatcher(setTemplateQuestionnaireAction(formatedQuestionList));

            setQuestionMode(questionModeEnum.screeningQuestion);
        }else{
            dispatcher(setTemplateScreeningQuestionAction(formatedQuestionList));

            setQuestionMode(questionModeEnum.questionnaire);
        }

    }

    const handleCancelUnsavedQuestionModal = () => {
        setIsUnsavedQuestionModalVisible(false);
        if(questionMode === questionModeEnum.questionnaire){
            setQuestionMode(questionModeEnum.screeningQuestion);
        }else{
            setQuestionMode(questionModeEnum.questionnaire);
        }
    }

    const handleSurveySubmitAndChangePage = () => {
        setSaveAndSwitch(true);
        setIsUnsavedQuestionModalVisible(false);
        saveSurvey();

    }


    const handleBackButton = () => {
        history.goBack();
    }

    return (
        <QuestionContext.Provider value={{questionList, setQuestion: setQuestionList, questionMode}}>
            <StyledContainer>
            <Navbar title={"Survey Questions"} />
            {/* <StyledBackButtonDiv>
            <StyledBackButton onClick={handleBackButton}>
                <StyledFiCornerUpLeft/>
                <p>Back</p>
            </StyledBackButton>
            </StyledBackButtonDiv> */}
            <BackButton/>
            <StyledActionContainer>
                <StyledSaveTemplateButton onClick={handleQuestionModeSwitch} icon={<StyledFiToggleLeft/>} size='large'>Switch to {questionMode === questionModeEnum.questionnaire? questionModeEnum.screeningQuestion: questionModeEnum.questionnaire}</StyledSaveTemplateButton>
                {/* <StyledSaveTemplateButton onClick={handleSurveySubmit} loading={isSavingTemplate} icon={<StyledFiSave/>} size='large'>Save Template</StyledSaveTemplateButton> */}
            </StyledActionContainer>    
            <StyledHeader>{ activeTemplate.details?.survey_name}</StyledHeader>
            <StyledBodyContainer>
              {/*   <CreateQuestionnaire/> */}
             
                <QuestionDashboard surveyName={questionMode}/>
            </StyledBodyContainer>
            </StyledContainer>
           
        </QuestionContext.Provider>
    );
};
const StyledContainer = styled.div`
    width: 100%;
`
const StyledBodyContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    position:relative; 

    @media ${device.laptop}{
        display: flex;
        justify-content: space-around;
    }
`

/* const StyledSaveTemplateButton = styled.button`
    background-color: ${adminColor};
    border-radius: 50%;
    padding: 30px;
    height: 5vh;
    width: 5vh;
    display: flex;
    justify-content: center;
    align-items:center;
    position: absolute;
    top: 80%;
    left: 90%;
    border: none;
    box-shadow: 0px 1px 3px 0px rgba(188,202,209,0.5);
    color: #fff;

    &:focus{
        border: none;
        outline: none;
    }
` */

const StyledSaveTemplateButton = styled(Button)`
     padding-left: 30px;
     padding-right: 30px;
     color: #249B00;
     border: none;
     background-color: transparent !important;

     &:hover, &:focus{
        color: #249B00;
        border:none;
     }
`

const StyledActionContainer = styled.div`
    width: 90%;
    display: flex;
    margin: 0 auto; 
    justify-content: space-between;
    margin-top: 20px;
`
const StyledFiSave = styled(FiSave)`
    margin-right: 10px;
`

const StyledFiToggleLeft = styled(FiToggleLeft)`
    margin-right: 10px;
`

const StyledHeader = styled.h3`
    text-align: center;
`

const StyledUnsavedQuestionModal = styled.div`
`

const StyledUnsavedQuestionModalFooter = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;

`
const StyledButton = styled(Button)`
  background-color: ${adminColor};
  outline: none;
  border: none;
  color: #fff;

  &:hover, &:focus{
    background-color: ${adminColor};
    color: #fff;
  }
`
const StyledCancelButton = styled(StyledButton)`
    background-color: #ff0000;
   
    &:hover, &:focus{
        background-color: #ff0000;
    }

`
const StyledSaveButton = styled(StyledButton)`
    margin-left: 10px;
`

const StyledBackButton = styled.div`
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;

    & p{
        margin: 0px;
        margin-left: 10px;
    }

    @media ${device.laptop}{
        font-size: 1.1vw
    }
`

const StyledFiCornerUpLeft = styled(FiCornerUpLeft)`
    font-size: 24px;

    @media ${device.laptop}{
        font-size: 1.6vw
    }
`

const StyledBackButtonDiv = styled.div`
    width: 85%;
    margin: 40px auto;
`