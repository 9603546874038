import React from "react";
import { AddAgentHero, ExitButton } from "./Style";
import { MailOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import {
  StyledInput,
  Title,
  RegisterContainer,
  Form,
  HeroContainer,
  Logo,
  StyledButtonDiv,
  RegisterButton2,
} from "../../register/Styles";
import SurveyPlus from "../../assets/images/surveypluslogo.svg";
import { Cancel } from "../../components/atoms/icons/index";

const AddAgent = ({
  isModalVisible,
  showModal,
  handleCancel,
  handleOk,
  isOpen,
  onInputChange,
  onButtonClick,
}) => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      visible={isOpen}
      closable={false}
      title={false}
      footer={false}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={handleCancel}
      style={{ alignItems: "center" }}
    >
      <HeroContainer>
        <RegisterContainer>
         {/*  <Logo src={SurveyPlus} /> */}
          {/* <Logo
          // src={SurveyPlus}
          /> */}
          <Title admin={true}>Register Agent</Title>

          <Form
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input email!",
                },
                {
                  type: "email",
                  message: "Please input a valid mail",
                },
              ]}
            >
              <StyledInput
                onChange={onInputChange}
                prefix={<MailOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            {/* <StyledButtonDiv> */}
            <RegisterButton2
              onClick={onButtonClick}
              type="submit"
              size="large"
              admin={true}
              htmlType="submit"
            >
              Register
            </RegisterButton2>
            {/* </StyledButtonDiv>{" "} */}
          </Form>
        </RegisterContainer>
  
      </HeroContainer>
    </Modal>
  );
};


export default AddAgent;