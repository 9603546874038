import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { Spring } from "react-spring/renderprops";
import { CreateQuestionnaire } from "./CreateQuestionnaire";
import Navbar from "../../../components/atoms/Navbar/index";
import { device } from "../../../globalComponents/mediaQueries";
import { QuestionContext } from "./questionContext";
import { QuestionDashboard } from "./QuestionDashboard";
import { adminColor } from "../../../globalComponents/globalAccet";
import { Button, Modal } from "antd";
import { FiSave, FiToggleLeft, FiCornerUpLeft } from "react-icons/fi";
import { questionTypes } from "./questionTypes";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  deleteQuestionNotificationAction,
  fetchSingleTemplateAction,
  saveTemplateQuestionAction,
  saveTemplateQuestionNotifyAction,
  setActiveTemplateNameAction,
  setTemplateQuestionnaireAction,
  setTemplateScreeningQuestionAction,
  updateTemplateNameAction,
  updateTemplateNameNotificationAction,
} from "../../../actions";
import { notificationAlert } from "../../../functions/alertNotification";
import { questionModeEnum } from "./questionMode";
import { EditTemplate } from "./molecule/EditTemplateTitleForm";
import BaseScaffold from "../../BaseScaffold";

export const SurveyDashboard = (props) => {
  const [questionList, setQuestionList] = useState([]);
  const [questionMode, setQuestionMode] = useState(
    questionModeEnum.screeningQuestion
  );
  const [isSavingTemplate, setIsSavingTemplate] = useState(false);
  const [refinedQuestionList, setrefinedQuestionList] = useState([]);
  const [tracker, setTracker] = useState(0);
  const [isUnsavedQuestionModalVisible, setIsUnsavedQuestionModalVisible] =
    useState(false);
  const [saveAndSwitch, setSaveAndSwitch] = useState(false);
  const activeTemplate = useSelector((state) => state.activeTemplate);
  const saveTemplateQuestionNotify = useSelector(
    (state) => state.saveTemplateQuestionNotify
  );
  const deleteQuestionNotification = useSelector(
    (state) => state.deleteQuestionNotification
  );
  const [showTemplateNameModal, setShowTemplateNameModal] = useState(false);
  const updateTemplateNameNotify = useSelector(
    (state) => state.updateTemplateNameNotify
  );
  const [newTemplateName, setNewTemplateName] = useState("");
  const { id } = useParams();
  const dispatcher = useDispatch();

  const questionBackendFormat = (item) => {
    let screeningType = questionMode === questionModeEnum.questionnaire ? 0 : 1;
    if (
      item.type === questionTypes.singleLine ||
      item.type === questionTypes.multipleLine
    ) {
      return {
        question: item.question,
        question_type: "single_input",
        options: null,
        category: null,
        survey_id: parseInt(id),
        screening_type: screeningType,
        isSaved: item.isSaved,
      };
    } else {
      return {
        question: item.question,
        question_type: item.type,
        options: item.options
          .map((item) => item.replace(/,/g, "<!&&!>"))
          .toString(),
        category: null,
        survey_id: parseInt(id),
        screening_type: screeningType,
        isSaved: item.isSaved,
      };
    }
  };

  const questionFrontendFormat = (item) => {
    if (item.question_type === questionTypes.singleLine) {
      return {
        question: item.question,
        type: item.question_type,
        questionId: item.question_id,
        isSaved: true,
      };
    } else {
      console.log({ option: item.options });
      let newOptions =
        typeof item.options === "string"
          ? item.options.split(",").map((item) => item.replace(/<!&&!>/g, ","))
          : item.options.map((item) => item.replace(/<!&&!>/g, ","));

      return {
        question: item.question,
        type: item.question_type,
        options: newOptions,
        category: item.category,
        questionId: item.question_id,
        isSaved: true,
      };
    }
  };

  useEffect(() => {
    let newQuestionList;
    if (questionMode === questionModeEnum.questionnaire) {
      newQuestionList = activeTemplate.details.questionnaire
        ?.slice()
        .map(questionFrontendFormat);
    } else if (questionMode === questionModeEnum.screeningQuestion) {
      newQuestionList = activeTemplate.details.screening_question
        ?.slice()
        .map(questionFrontendFormat);
    }

    setQuestionList(newQuestionList);
  }, [activeTemplate, questionMode]);

  useEffect(() => {
    if (saveTemplateQuestionNotify !== "") {
      if (tracker > 0 && tracker < refinedQuestionList.slice().length) {
        dispatcher(
          saveTemplateQuestionAction(refinedQuestionList.slice()[tracker])
        );
        setTracker((prevState) => prevState + 1);
      }
      if (
        saveTemplateQuestionNotify === "success" &&
        tracker === refinedQuestionList.slice().length &&
        refinedQuestionList.slice().length !== 0
      ) {
        notificationAlert(
          "success",
          "Questions Added",
          `${refinedQuestionList.slice().length} questions has been added to ${
            activeTemplate.details?.survey_name
          }`
        );
        setIsSavingTemplate(false);
      } else if (saveTemplateQuestionNotify === "error") {
        notificationAlert("error", "Failed", "Please try again");
        setIsSavingTemplate(false);
      }

      if (tracker === refinedQuestionList.slice().length) {
        setIsSavingTemplate(false);
        setTracker(0);
        dispatcher(saveTemplateQuestionNotifyAction(""));
        dispatcher(fetchSingleTemplateAction(id));
        if (saveAndSwitch) {
          setSaveAndSwitch(false);
          handleQuestionModeSwitch();
        }
      }
    }
  }, [saveTemplateQuestionNotify, tracker]);

  useEffect(() => {
    if (deleteQuestionNotification !== "") {
      if (deleteQuestionNotification === "success") {
        notificationAlert(
          "success",
          "Question deleted",
          "Question was deleted"
        );
      }

      if (deleteQuestionNotification === "error") {
        notificationAlert("error", "Failed", "Please try again");
      }

      dispatcher(deleteQuestionNotificationAction(""));
      dispatcher(fetchSingleTemplateAction(id));
    }
  }, [deleteQuestionNotification]);

  useEffect(() => {
    dispatcher(fetchSingleTemplateAction(id));
    if (saveAndSwitch) {
      setSaveAndSwitch(false);
      handleQuestionModeSwitch();
    }
  }, [saveTemplateQuestionNotify, tracker]);

  useEffect(() => {
    dispatcher(fetchSingleTemplateAction(id));
  }, [id]);

  const saveSurvey = () => {
    const finalQuestionList = questionList.slice().map(questionBackendFormat);

    setrefinedQuestionList(finalQuestionList.filter((item) => !item.isSaved));

    if (finalQuestionList.filter((item) => !item.isSaved).length > 0) {
      setIsSavingTemplate(true);
      dispatcher(
        saveTemplateQuestionAction(
          finalQuestionList.filter((item) => !item.isSaved)[0]
        )
      );
      setTracker(1);
    }

    if (questionMode === questionModeEnum.questionnaire) {
      dispatcher(setTemplateQuestionnaireAction(finalQuestionList));
    } else {
      dispatcher(setTemplateScreeningQuestionAction(finalQuestionList));
    }
  };

  const handleSurveySubmit = (e) => {
    e.preventDefault();
    saveSurvey();
  };

  const handleQuestionModeSwitch = () => {
    if (questionList.some((item) => !item.isSaved)) {
      setIsUnsavedQuestionModalVisible(true);
      return;
    }

    setrefinedQuestionList([]);
    setTracker(0);
    dispatcher(saveTemplateQuestionNotifyAction(""));

    let formatedQuestionList = questionList.slice().map(questionBackendFormat);

    if (questionMode === questionModeEnum.questionnaire) {
      dispatcher(setTemplateQuestionnaireAction(formatedQuestionList));

      setQuestionMode(questionModeEnum.screeningQuestion);
    } else {
      dispatcher(setTemplateScreeningQuestionAction(formatedQuestionList));

      setQuestionMode(questionModeEnum.questionnaire);
    }
  };

  useEffect(() => {
    if (updateTemplateNameNotify !== "") {
      setShowTemplateNameModal(false);
      if (updateTemplateNameNotify === "success") {
        notificationAlert(
          "success",
          "Template Changed",
          `The template name has been changed to ${newTemplateName}`
        );
        dispatcher(setActiveTemplateNameAction(newTemplateName));
      }

      if (updateTemplateNameNotify === "error") {
        notificationAlert("error", "Failed", `Please try again`);
      }

      dispatcher(updateTemplateNameNotificationAction(""));
    }
  }, [updateTemplateNameNotify]);

  const onFinish = (values) => {
    values.id = id;
    setNewTemplateName(values.name);
    dispatcher(updateTemplateNameAction(values));
  };

  const onEditTemplateTitleShow = () => {
    setShowTemplateNameModal(true);
  };

  const handleModalCancel = () => {
    setShowTemplateNameModal(false);
  };

  const handleCancelUnsavedQuestionModal = () => {
    setIsUnsavedQuestionModalVisible(false);
    if (questionMode === questionModeEnum.questionnaire) {
      setQuestionMode(questionModeEnum.screeningQuestion);
    } else {
      setQuestionMode(questionModeEnum.questionnaire);
    }
  };

  const handleSurveySubmitAndChangePage = () => {
    setSaveAndSwitch(true);
    setIsUnsavedQuestionModalVisible(false);
    saveSurvey();
  };

 

  return (
    <BaseScaffold title="Create Survey Template">
      <QuestionContext.Provider
        value={{ questionList, setQuestion: setQuestionList, questionMode }}
      >
        <StyledActionContainer>
          <StyledSaveTemplateButton
            onClick={handleQuestionModeSwitch}
            icon={<StyledFiToggleLeft />}
            size="large"
          >
            Switch to{" "}
            {questionMode === questionModeEnum.questionnaire
              ? questionModeEnum.screeningQuestion
              : questionModeEnum.questionnaire}
          </StyledSaveTemplateButton>
          <StyledSaveTemplateButton
            onClick={handleSurveySubmit}
            loading={isSavingTemplate}
            icon={<StyledFiSave />}
            size="large"
          >
            Save Template
          </StyledSaveTemplateButton>
        </StyledActionContainer>
        <StyledHeader onDoubleClick={onEditTemplateTitleShow}>
          {activeTemplate.details?.survey_name}
        </StyledHeader>
        <StyledBodyContainer>
          <CreateQuestionnaire />
          <QuestionDashboard surveyName={questionMode} />
        </StyledBodyContainer>
        <Modal
          title="Unsaved Questions"
          visible={isUnsavedQuestionModalVisible}
          onCancel={handleCancelUnsavedQuestionModal}
          okText="Save"
          cancelText="Discard"
          footer={false}
        >
          <StyledUnsavedQuestionModal>
            <p>
              You have unsaved question(s), do you which to save them or discard
            </p>
            <StyledUnsavedQuestionModalFooter>
              <StyledCancelButton onClick={handleCancelUnsavedQuestionModal}>
                Discard
              </StyledCancelButton>
              <StyledSaveButton onClick={handleSurveySubmitAndChangePage}>
                Save
              </StyledSaveButton>
            </StyledUnsavedQuestionModalFooter>
          </StyledUnsavedQuestionModal>
        </Modal>
        <EditTemplate
          onFinish={onFinish}
          isOpen={showTemplateNameModal}
          handleCancel={handleModalCancel}
        />
      </QuestionContext.Provider>
    </BaseScaffold>
  );
};

const StyledContainer = styled.div`
  width: 100%;
`;
const StyledBodyContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  position: relative;

  @media ${device.laptop} {
    display: flex;
    justify-content: space-around;
  }
`;

/* const StyledSaveTemplateButton = styled.button`
    background-color: ${adminColor};
    border-radius: 50%;
    padding: 30px;
    height: 5vh;
    width: 5vh;
    display: flex;
    justify-content: center;
    align-items:center;
    position: absolute;
    top: 80%;
    left: 90%;
    border: none;
    box-shadow: 0px 1px 3px 0px rgba(188,202,209,0.5);
    color: #fff;

    &:focus{
        border: none;
        outline: none;
    }
` */

const StyledSaveTemplateButton = styled(Button)`
  padding-left: 30px;
  padding-right: 30px;
  color: #249b00;
  border: none;
  background-color: transparent !important;

  &:hover,
  &:focus {
    color: #249b00;
    border: none;
  }
`;

const StyledActionContainer = styled.div`
  width: 90%;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  margin-top: 20px;
`;
const StyledFiSave = styled(FiSave)`
  margin-right: 10px;
`;

const StyledFiToggleLeft = styled(FiToggleLeft)`
  margin-right: 10px;
`;

const StyledHeader = styled.h3`
  text-align: center;
  cursor: pointer;
`;

const StyledUnsavedQuestionModal = styled.div``;

const StyledUnsavedQuestionModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const StyledButton = styled(Button)`
  background-color: ${adminColor};
  outline: none;
  border: none;
  color: #fff;

  &:hover,
  &:focus {
    background-color: ${adminColor};
    color: #fff;
  }
`;

const StyledCancelButton = styled(StyledButton)`
  background-color: #ff0000;

  &:hover,
  &:focus {
    background-color: #ff0000;
  }
`;

const StyledSaveButton = styled(StyledButton)`
  margin-left: 10px;
`;
