import {MANAGE_AGENT} from '../constants/index';


export const registerAgentNotificationReducer = (state='', {type, payload}) => {
    if(type === MANAGE_AGENT.REGISTERED_NOTIFICATION){
        return payload;
    }

    return state;
}
