import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Table } from "antd";
import { SearchBar } from "../../globalComponents/SearchBar";
import { FilterContainer } from "../surveys/Styles.jsx";
import { fetchSurveyByEmail } from "./services.js";
import { validateEmail } from "./helper";
import { UseActiveParticipantId } from "./context";
import { notificationAlert } from "../../functions/alertNotification";
import BaseScaffold from "../BaseScaffold";

const columns = [
  {
    title: "Survey ID",
    dataIndex: "survey_id",
    key: "survey_id",
  },
  {
    title: "Survey Name",
    dataIndex: "survey_name",
    key: "survey_name",
  },
  {
    title: "Participant email",
    dataIndex: "email",
    key: "email",
  },
];

const SurveyDashboard = ({ isAdmin }) => {
  const history = useHistory();
  const [participantEmail, setParticipantEmail] = useState("");
  const [fetchedSurvey, setFetchedSurvey] = useState([]);
  const [fetchingSurvey, setFetchingSurvey] = useState(false);
  const { setActiveParticipantId } = UseActiveParticipantId();

  const onSearchInputChange = (data) => {
    setParticipantEmail(data.target.value?.trim());
  };

  const handleSearchSurvey = async () => {
    try {
      if (validateEmail(participantEmail)) {
        setFetchingSurvey(true);
        const response = await fetchSurveyByEmail({ email: participantEmail });
        const fetchedSurvey = response.data?.data?.assigned_surveys.map(
          (survey) => {
            survey.email = response?.data?.data?.email;
            return survey;
          }
        );

        if (!fetchedSurvey.length) {
          notificationAlert(
            "success",
            "No Survey",
            "Participant has not been assigned a survey yet"
          );
        }
        setFetchedSurvey(fetchedSurvey);
        setActiveParticipantId(response?.data?.data?.user_id);
        setFetchingSurvey(false);
      }
    } catch (error) {
      if (error.response) {
        notificationAlert(
          "error",
          "Invalid email",
          error.response.data.message
        );
      }
      setFetchingSurvey(false);
    }
  };

  const userLogger = (
    <FilterContainer>
      <SearchBar
        placeholder="Search survey by participant email..."
        searchInputData={participantEmail}
        onSearchInputChange={onSearchInputChange}
        onClick={handleSearchSurvey}
        showButton={true}
        isAgent={isAdmin}
        isLoading={fetchingSurvey}
      />
    </FilterContainer>
  );

  const handleRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        history.push(`/dashboard/take-survey/${record.survey_id}`);
      },
    };
  };

  return (
    <BaseScaffold title="Survey Dashboard">
      {userLogger}
      <Table columns={columns} dataSource={fetchedSurvey} onRow={handleRow} />
    </BaseScaffold>
  );
};
export default SurveyDashboard;
