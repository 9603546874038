import React from 'react';
import styled from 'styled-components';
import {device } from '../../../globalComponents/mediaQueries';
import {CheckboxOption, RadioGroupOption, Matrix, MultiLineInput, DropDown} from '../../../QuestionComponents/SingleQuestions';

const SingleQuestionComponent = (props) => {
    return (
        <StyledSingQuestion>
            <StyledHeader>
                <p>{props.questionNumber}.</p>
                <p>{props.question}</p>
            </StyledHeader>
            <div>
                {props.children}
            </div>
        </StyledSingQuestion>
    )
}


const RadioGroupComponent = (props) => {
    return (
        <SingleQuestionComponent questionNumber={props.questionNumber} question={props.question}>
            <RadioGroupOption options={props.options} onChange={props.onChange}/>
        </SingleQuestionComponent>
    )
}

const CheckboxComponent = (props) => {
    return (
        <SingleQuestionComponent questionNumber={props.questionNumber} question={props.question}>
            <CheckboxOption options={props.options} onChange={props.onChange}/>
        </SingleQuestionComponent>
    )
}

const MatrixComponent = (props) => {
    return (
        <SingleQuestionComponent questionNumber={props.questionNumber} question={props.question}>
            <Matrix inputRef={props.inputRef} onChange={props.onChange} options={props.options} categories={props.categories}/>
        </SingleQuestionComponent>
    )
}

const SingleInputComponent = (props) => {
    return (
        <SingleQuestionComponent questionNumber={props.questionNumber} question={props.question}>
            <StyledDropDownDiv>
                <MultiLineInput onChange={props.onChange}/>
            </StyledDropDownDiv>
        </SingleQuestionComponent>
    )
}

const DropDownComponent = (props) => {
    return (
        <SingleQuestionComponent questionNumber={props.questionNumber} question={props.question}>
            <StyledDropDownDiv>
                <DropDown options={props.options} onChange={props.onChange}/>
            </StyledDropDownDiv>
        </SingleQuestionComponent>
    )
}





const StyledDropDownDiv = styled.div`
    width: 50%;
`

const StyledSingQuestion = styled.div`
     margin: 10px;

     margin-bottom: 40px;

     & > div:nth-child(2){
         margin-left: 20px;
     }
`

const StyledHeader = styled.div`
    display: flex;
    font-size: 16px;

    & p:first-child{
        color: #00000029;
        margin-right: 10px;
    }

    @media ${device.laptop}{
        font-size: 1.15vw;
    }
`


export {
    RadioGroupComponent,
    CheckboxComponent,
    SingleInputComponent,
    DropDownComponent,
    MatrixComponent
}