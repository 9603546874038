import React, {useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {QuestionBoard} from '../../../QuestionComponents/QuestionBoard';
import {device } from '../../../globalComponents/mediaQueries';
import { 
    RadioGroupComponent,
    CheckboxComponent,
    SingleInputComponent,
    DropDownComponent,
    MatrixComponent
} from './questionGroups';

import { Button } from 'antd';
import { BackButton } from '../../../globalComponents/backButton';
import { useParams, useHistory} from 'react-router-dom';
import { fetchParticipantSingleSurveyAction, mainResponseNotifyAction, screeningResponseNotifyAction, submitMainResponseAction, submitScreeningResponseAction } from '../../../actions';
import {UseSurveyQuestionAnswerContext, UseActiveParticipantId} from './../context';
import { notificationAlert } from '../../../functions/alertNotification';
import { AlertDialog } from './successModal';
import getQuestionLength from './getQuestionLength';
import getAnswerLength from './getAnswerLength';



export default function SurveyGround(props){
    const { id } = useParams();
    const participantSingleSurvey = useSelector(state => state.participantSingleSurvey);
    const [activeQuestionSet, setActiveQuestionSet] = useState([])
   /*  const [questionAnswer, setQuestionAnswer] = useState([]); */
    const [responseType, setResponseType] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const dispatcher = useDispatch();
  /*   const inputRef = useRef(); */
    const history = useHistory();
    const {questionAnswer, setQuestionAnswer} = UseSurveyQuestionAnswerContext();
    const {activeParticipantId} = UseActiveParticipantId()
    const [matrixQuestionAnswer, setMatrixQuestionAnswer] = useState([]);
    const mainResponseNotify = useSelector(state => state.mainResponseNotify);
    const screeningResponseNotify = useSelector(state => state.screeningResponseNotify);
    useEffect(() => {
        dispatcher(fetchParticipantSingleSurveyAction(id));
    }, [])


    useEffect(() => {
        if(participantSingleSurvey.screening_question.length > 0){
            setActiveQuestionSet(participantSingleSurvey.screening_question)
            setResponseType('screeningQuestion');
        }else{
            setActiveQuestionSet(participantSingleSurvey.questionnaire);
            setResponseType('questionnaire');
        }
    }, [participantSingleSurvey])

    useEffect(() => {

        if(screeningResponseNotify.status !== ''){
            if(screeningResponseNotify.status === 'success'){
                if(screeningResponseNotify.result){
                    setActiveQuestionSet(participantSingleSurvey.questionnaire);
                    setResponseType('questionnaire');
                    setMatrixQuestionAnswer([]);
                    setQuestionAnswer([]);
                }else{
                    setIsModalVisible(true);
                }

            }

            if(screeningResponseNotify.status === 'error'){
                notificationAlert('error', 'Failed', 'Please try again')
            }

            dispatcher(screeningResponseNotifyAction({status: '', result: null}))
        }

    }, [screeningResponseNotify])


    useEffect(() => {
        if(mainResponseNotify !== ''){
            if(mainResponseNotify === 'success'){
                setIsModalVisible(true);
            }
        }

        if(mainResponseNotify === 'error'){
            notificationAlert('error', 'Submission failed!', 'Please try again later');
        }

        setMatrixQuestionAnswer([]);
        setQuestionAnswer([]);

        dispatcher(mainResponseNotifyAction(''));
    }, [mainResponseNotify])


    const handleChange = (e, type, id) => {
        const temporalQuestionAnswer = questionAnswer.slice();
       
        let singleAnswer = type === 'checkbox'? e: type === 'dropDown'? [e] : [e.target.value];
        if(temporalQuestionAnswer.some(item => item.question_id === id)){
            const newQuestionAnswer = temporalQuestionAnswer.map(answer => {
                if(answer.question_id === id){
                    return {question_id: id, answer: singleAnswer, question_type: type};
                }
                return answer;
            })

            setQuestionAnswer(newQuestionAnswer);
        }else{
            setQuestionAnswer([...temporalQuestionAnswer, {question_id: id, answer: singleAnswer, question_type: type}])
        }
    }


    const handleMatrixChange = (option, category, inputRef, questionId) => {
        const previousAnswer = matrixQuestionAnswer.slice();
        const activeAnswerObject = previousAnswer.find(item => item.question_id === questionId);
        if(activeAnswerObject){
            let activeAnswer = activeAnswerObject?.answer?.map(item => {
                if(item.category === category){
                    return {category, answer: option}
                }

                return item;
            });

            if(!activeAnswer.some(item => item.category === category)){
                activeAnswer = [...activeAnswer, {category, answer: option}]
            }

            activeAnswerObject.answer = activeAnswer;

            const newMatrixQuestionAnswer = previousAnswer.map(item => {
                if(item.question_id === activeAnswerObject.question_id){
                    return activeAnswerObject;
                }

                return item;
            })

            setMatrixQuestionAnswer(newMatrixQuestionAnswer);
        }else{
            let newAnswer = {question_id: questionId, answer: [{category, answer: option}], question_type: 'matrix'};
            setMatrixQuestionAnswer([...previousAnswer, newAnswer]);
        }
        

        inputRef.current.checked = true;
    }


    const handleSubmit = () => {
        if(responseType === 'screeningQuestion'){
            dispatcher(submitScreeningResponseAction({survey_id: id, answerList: [...questionAnswer, ...matrixQuestionAnswer], user_id: activeParticipantId}))
        }else if(responseType === 'questionnaire'){
            const answerList = [...questionAnswer, ...matrixQuestionAnswer];
        
           if( getQuestionLength(activeQuestionSet, []) ===
           getAnswerLength(answerList)){
               dispatcher(submitMainResponseAction({survey_id: id, answerList:[...questionAnswer, ...matrixQuestionAnswer], user_id: activeParticipantId}));
           }else {
            notificationAlert('error', 'Incomplete Answers', 'Please attempt all questions ');
            return;
           }
          
        }
        
    }

    const questionList = activeQuestionSet.map((item, index) => {
        if(item.question_type === 'radio_group'){
            return <RadioGroupComponent 
                        questionNumber={index + 1} 
                        question={item.question} 
                        key={index} 
                        options={item.options} 
                        onChange={(e) => handleChange(e, item.question_type, item.question_id)}
                    />
        }

        if(item.question_type === 'checkbox'){
            return <CheckboxComponent 
                        key={index}  
                        questionNumber={index + 1} 
                        question={item.question} 
                        options={item.options} 
                        onChange={(e) => handleChange(e, item.question_type, item.question_id)}
                    />
        }

        if(item.question_type === 'dropDown'){
            return <DropDownComponent 
                         key={index}  
                        questionNumber={index + 1} 
                        question={item.question} 
                        options={item.options} 
                        onChange={(e) => handleChange(e, item.question_type, item.question_id)}
                    />
        }

        if(item.question_type === 'single_input'){
            return <SingleInputComponent  
                        key={index} 
                        questionNumber={index + 1} 
                        question={item.question}  
                        onChange={(e) => handleChange(e, item.question_type, item.question_id)}
                    />
        }

        if(item.question_type === 'matrix'){
            return <MatrixComponent 
                        onChange={(option, category, inputRef) => handleMatrixChange(option, category, inputRef, item.question_id)} 
                        categories={item.category} 
                        options={item.options} 
                        questionNumber={index + 1} 
                        question={item.question} 
                        key={index}
                    />
        }
    })


    
    const handleOk = () => {
        setIsModalVisible(false);
        history.push('/dashboard/takesurvey')
    }
    
    return (
        <StyledSurveyGround>
            <BackButton style={{marginTop: '10px', marginLeft: '0'}}/>
            <QuestionBoard title='Questionnaire'>
               {questionList}
            </QuestionBoard> 
            <PrimaryButtonComponent buttonText='Submit' onClick = {handleSubmit}/>   
            <AlertDialog
                open={isModalVisible}
                handleClose={handleOk}
            />
        </StyledSurveyGround>
    );
};


const PrimaryButtonComponent = (props) => {
    return (
        <StyledButton size='large' loading={false} onClick={props.onClick}>{props.buttonText}</StyledButton>
    );
};

const StyledButton = styled(Button)`
    border-radius: 10px;
    /* padding: 13px 14px; */
    /* padding-bottom: 26px; */
    background-color: #0F6281;
    color: #fff;
    font-weight: 500;
    border: none;
    font-size: 16px;
    cursor: pointer;
    box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.08);
    min-width: 100%;
    letter-spacing: 0.2px;
    margin-top: 30px;

    &:hover, &:focus{
        background-color: #0F6281;
        color: #fff;
        outline: none;
    }

    &:hover{
        box-shadow: none;
    }

    @media ${device.laptop}{
        width: 50%;
        font-size: 1.2vw;

    }

    @media ${device.desktopL}{
        padding: 20px 17px;
    } 
`



const StyledSurveyGround = styled.div`
    width: 100%;
    padding: 0 7px;
    &  .ant-checkbox-checked .ant-checkbox-inner{
        background-color: #0F6281;
        border: 1px solid #0F6281;
    }

    & .ant-radio-checked .ant-radio-inner{
        border: 1px solid #0F6281;
    }

    & .ant-radio-inner::after{
        background-color: #0F6281;
    }

    & .ant-input{
        border-radius: 5px;
    }

    & .ant-input:hover, & .ant-input:focus{
        border-color: #0F6281;
        outline: 0;
        box-shadow: none;
       
    }

    & .ant-select:not(.ant-select-customize-input) .ant-select-selector{
        border-radius: 5px;
    }

    & .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
        border-color: #0F6281;
       
    }

@media ${device.laptop}{
       
   width: 50%;
    margin: 10px auto;
     }

`

const StyledSingQuestion = styled.div`
     margin: 10px;

     & > div:nth-child(2){
         margin-left: 20px;
     }
`



const StyledHeader = styled.div`
    display: flex;
  /*   padding: 10px 10px 0px 10px; */
    font-size: 16px;

    & p:first-child{
        color: #00000029;
        margin-right: 10px;
    }

    @media ${device.laptop}{
        font-size: 1.15vw;
    }
`