import styled from "styled-components";
import { Link } from "react-router-dom";

export const HeroContainer = styled.div`
  width: 100%;
`;
export const Navbar = styled.div`
  width: 100%;
  background-color: #61d926;
  display: flex;
  padding: 10px;
`;
export const NavLink = styled(Link)`
  color: white;
  font-weight: 500;
  margin: auto 1.5rem;
  font-size: 0.9rem;
  width: wrap-content;
  border-radius: 50px;
  padding: 10px;
  text-decoration: none;
  &:focus {
    color: black;
    background-color: white;
  }
`;
export const Tabl = styled.table`
  ${"" /* text-align:center; */}
  padding:5px;
  width: 100%;
`;
export const TableHead = styled.thead`
  border-bottom: 1px solid white;
`;
export const TableRow = styled.tr``;
export const TableHeadContent = styled.th`
  background-color: "#F8F8F8";
  border: none;
  font-size: small;
  padding: 1rem;
`;
export const TableBody = styled.tbody`
  background-color: #ffffff;
  padding-left: 5px;
`;
export const TableData = styled.td`
  font-size: smaller;
  font-weight: 500;
  border-bottom: 0.1em solid #00000029;
  margin: 2rem;
  padding: 0.8rem;
  background-color: white;
`;

export const Checkbox = styled.input`
  margin: 7px 5px;
  height: 1rem;
  width: 1rem;
  background-color: white;
  border: 1px solid grey;
  border-radius: 5px;
`;
