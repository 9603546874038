import React, { useEffect, useRef, useState } from "react";

import { ExcelRenderer } from "react-excel-renderer";

import { useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { TableNav, ResendButton } from "../Styles";
import { Table, Space, Tag } from "antd";

import { batchParticipantRegisterAction } from "../../../actions";
import {
  importExcelAction,
  userBatchRegisterNotificationAction,
} from "../../../actions/index";
import { notificationAlert } from "../../../functions/alertNotification";
import BaseScaffold from "../../BaseScaffold";
import styled from "styled-components";

const ShowRecord = ({ onOpenNav }) => {
  const uploadedParticipants = useSelector(
    (state) => state.uploadedParticipants
  );
  const dispatcher = useDispatch();
  const fileInput = useRef();
  const { url, path } = useRouteMatch();

  const agent = true;
  const userBatchRegisterNotification = useSelector(
    (state) => state.userBatchRegisterNotification
  );
  const [refinedData, setRefinedData] = useState([]);
  const [tracker, setTracker] = useState(0);

  const columns = [
    {
      title: "Full Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phonenumber",
      key: "phonenumber",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "LGA",
      dataIndex: "lga",
      key: "lga",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        console.log(status);
        let color =
          status === "Not Registered"
            ? "#108ee9"
            : status === "Registered"
            ? "#87d068"
            : "#f50";

        return <Tag color={color}>{status}</Tag>;
      },
    },
  ];

  const openFileBrowser = () => {
    fileInput.current.click();
  };
  const fileHandler = (event) => {
    let fileObj = event.target.files[0];

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      console.log({ path, url });
      if (err) {
        console.log(err);
      } else {
        let data = resp.rows.slice(1); // removed the first array which contains the titles

        // edit the uploaded data to conform to the data set expected by table
        const newData = data.map((item, index) => {
          return {
            key: index,
            fullname: item[0],
            email: item[1],
            phonenumber: item[2],
            state: item[3],
            lga: item[4],
            status: "Not Registered",
          };
        });

        dispatcher(importExcelAction(newData));

        // navigate to the table once data is uploaded
      }
    });
  };

  useEffect(() => {
    if (tracker < refinedData.length && tracker !== 0) {
      dispatcher(batchParticipantRegisterAction(refinedData[tracker]));
      setTracker((prevState) => prevState + 1);
    } else if (tracker === refinedData.length) {
      setTracker(0);
    }

    if (userBatchRegisterNotification.status !== "") {
      if (userBatchRegisterNotification.status === "success") {
        const dataSet = [...uploadedParticipants].map((data) => {
          if (data.email === userBatchRegisterNotification.email) {
            data.status = "Registered";
            return data;
          }

          return data;
        });

        dispatcher(importExcelAction(dataSet));

        notificationAlert(
          "success",
          "Participant Added",
          `A participant with the email ${userBatchRegisterNotification.email} added`
        );
      }

      if (userBatchRegisterNotification.status === "error") {
        const dataSet = [...uploadedParticipants].map((data) => {
          if (data.email === userBatchRegisterNotification.email) {
            data.status = "Failed";
            return data;
          }

          return data;
        });

        dispatcher(importExcelAction(dataSet));

        notificationAlert(
          "error",
          "Failed",
          `This Participant was previously registered`
        );
      }

      dispatcher(
        userBatchRegisterNotificationAction({ status: "", email: "" })
      );
    }
  }, [userBatchRegisterNotification]);

  const handleRegisterAll = () => {
    const participantDataList = uploadedParticipants.map((data) => {
      const { fullname, email, phonenumber, lga, state } = data;
      return {
        full_name: fullname,
        email,
        phonenumber,
        lga,
        state,
      };
    });

    setRefinedData(participantDataList);
    dispatcher(batchParticipantRegisterAction(participantDataList[0]));
    setTracker(1);

    /*  redefinedData.forEach(element => {
      dispatcher(batchParticipantRegisterAction(redefinedData));
    }); */
  };

  return (
    <BaseScaffold title="Show Batch Record">
      <TableNav admin>
        <Space wrap="wrap">
          <ResendButton>
            <div>
              {" "}
              <div onClick={() => openFileBrowser()}>Import List</div>
              <input
                type="file"
                style={{ display: "none" }}
                ref={fileInput}
                onChange={fileHandler}
              />
            </div>
          </ResendButton>
          <ResendButton onClick={handleRegisterAll}> Register all</ResendButton>
          <ResendButton> Resend Failed Batch</ResendButton>
        </Space>
      </TableNav>
      <StyledTableContainer>
        <Table dataSource={uploadedParticipants} columns={columns} />
      </StyledTableContainer>
    </BaseScaffold>
  );
};

const StyledTableContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  overflow: auto;
`;
export default ShowRecord;
