import React from "react";
import { Spring } from "react-spring/renderprops";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Logo,
  SideNavItemContainer,
  SideNavItemLi,
  SideNavItemUl,
  Logout,
} from "./Style";
import { useDispatch } from "react-redux";
import { openModalAction, closeModalAction } from "../../../actions/index";
import surverplus from "../../../assets/images/surveypluslogo.svg";
import { Menu, Dropdown } from "antd";
import { useSideBarContext } from "../../../dashboard/AgentDashboard/context";

const SideNavItem = ({
  navItems,
  admin,
  isOpen,
  iconHover,
  agentNavTrue,
  textHoverToggler,
  closeDrawerNav,
}) => {
  const history = useHistory();
  const dispatcher = useDispatch();
  const handleLogOut = () => {
    localStorage.removeItem("token");
    history.replace("/");
  };

  const sideBarContext = useSideBarContext();

  const menu = (
    <Menu style={{ boxShadow: "none" }}>
      <Menu.Item
        onClick={() => dispatcher(openModalAction(true))}
        style={{ padding: "10px 20px" }}
      >
        Single
      </Menu.Item>
      <Menu.Item
        style={{ padding: "10px 20px" }}
        onClick={() => history.push(navItems[1].href)}
      >
        Batch
      </Menu.Item>
    </Menu>
  );

  const items = [
    {
      label: "Single",
      key: "1",
    },
    {
      label: "Batch",
      key: "2",
    },
  ];

  const onClick = ({ key }) => {
    if (key === "1") {
      dispatcher(openModalAction(true));
    } else {
      history.push("/dashboard/batch-record");
    }
    sideBarContext.onOpenSideBar();
  };

  return (
    <Spring
      from={{ opacity: 0.2, transform: "translateX(+2%)" }}
      to={{ opacity: 1, transform: "translateX(0)" }}
      // leave={{ transform: "translateX(+4%)" }}
      config={{ duration: 300, delay: 170 }}
    >
      {(props) => (
        <SideNavItemContainer style={props} isOpen={isOpen}>
          <Logo src={surverplus} />
          <SideNavItemUl>
            {agentNavTrue ? (
              <React.Fragment>
                <Link onMouseDown={closeDrawerNav} to={navItems[0].href}>
                  <SideNavItemLi>{navItems[0].text}</SideNavItemLi>
                </Link>
                <Link onMouseDown={closeDrawerNav} to={navItems[1].href}>
                  <SideNavItemLi>Take Survey</SideNavItemLi>
                </Link>
                <Link onMouseDown={closeDrawerNav} to={navItems[2].href}>
                  <Dropdown overlay={menu}>
                    <SideNavItemLi admin={true}>
                      Register Participant
                    </SideNavItemLi>
                  </Dropdown>
                </Link>
              </React.Fragment>
            ) : (
              navItems.map(
                (nav) =>
                  nav.icon !== null && (
                    <React.Fragment key={nav.text}>
                      {nav.text === "Register Users" ? (
                        <Dropdown
                          menu={{
                            items,
                            onClick,
                          }}
                        >
                          <SideNavItemLi admin={true}>
                            Register Participant
                          </SideNavItemLi>
                        </Dropdown>
                      ) : (
                        <Link
                          to={nav.href}
                          onMouseUp={sideBarContext.onOpenSideBar}
                        >
                          <SideNavItemLi
                            // iconHover={textHoverToggler(nav)}
                            admin={admin}
                          >
                            {nav.text}
                          </SideNavItemLi>
                        </Link>
                      )}
                    </React.Fragment>
                  )
              )
            )}
          </SideNavItemUl>
          <Logout onClick={handleLogOut}> Logout</Logout>
        </SideNavItemContainer>
      )}
    </Spring>
  );
};
export default SideNavItem;
