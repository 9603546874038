import React from "react";
import { useSelector } from "react-redux";
import {
  CardContainer,
  Card,
  CardTitle,
  Hr,
  ValueContainer,
  Value,
  ValueSub,
  Button,
} from "./Styles";
import { Statistic } from "antd";
import styled from "styled-components";
import { device } from "../../globalComponents/mediaQueries.js";

const CardComponent = () => {
  const adminOverView = useSelector((state) => state.adminOverView);

  return (
    <CardContainer>
      <RevenueCard
        gradient={true}
        title="TOTAL REVENUE"
        amount={adminOverView.total_revenue}
        text="Total Revenues for the last 30 days"
      />
      <RevenueCard
        gradient={false}
        title="PENDING PAYOUT"
        amount={adminOverView.pending_payout}
        text="Total amount yet to be paid for the last 30 days"
      />
    </CardContainer>
  );
};

const RevenueCard = (props) => {
  return (
    <Card gradient={props.gradient}>
      <CardTitle gradient={props.gradient}>{props.title}</CardTitle>
      <Hr />
      <ValueContainer>
        <StyledStatistics
          gradient={props.gradient}
          prefix={<StyledIcon gradient={props.gradient}>₦</StyledIcon>}
          value={props.amount}
        />
        {/* <Value gradient={props.gradient}>&#8358; {props.amount}</Value> */}
        <ValueSub gradient={props.gradient}>{props.text}</ValueSub>
      </ValueContainer>
      <Button>View Details</Button>
    </Card>
  );
};

const StyledStatistics = styled(Statistic)`
  font-size: 40px !important;

  & .ant-statistic-content-value-int {
    font-size: 40px !important;
    color: ${(props) => (props.gradient ? "#fff" : "#000")};
  }
  @media ${device.laptop} {
    & .ant-statistic-content-value-int {
      font-size: 3.5vw !important;
    }
  }
`;

const StyledIcon = styled.span`
  font-size: 40px !important;
  color: ${(props) => (props.gradient ? "#fff" : "#000")};

  @media ${device.laptop} {
    font-size: 3.5vw !important;
  }
`;

export default CardComponent;
