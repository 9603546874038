import styled from "styled-components";
import device from "../../utils/breakPoints";

export const FilterContainer = styled.div`
  width: 100%;
  border-top: 1px solid #00000029;
  //padding: 2rem;
  display: flex;
  justify-content: space-between;
  //height: 15vh;
  //margin-top: 1rem;
  box-shadow: 0 2px 5px #00000049;
  align-items: center;
  background: white;
  flex-wrap: wrap;
  padding: 20px 10px;
`;

export const HeroContainer = styled.div`
  width: 100%;
`;
export const Search = styled.label``;
export const SearchInput = styled.input`
  display: block;
  height: 2rem;
  border-radius: 40px;
  outline: none;
  padding: 1.5rem 3rem;
  border: none;
  width: ${({ agent }) => (agent ? "30rem" : "30rem")};
  box-shadow: 0px 4px 9px 0px rgba(204, 204, 204, 0.68);
  &:focus {
    outline: none;
    border: none;
    transition: all 500 ease-in-out;
  }
`;

export const FilterCon = styled.div`
  display: flex;
  margin-left: 4rem;
`;

export const FilterInput = styled.input`
  width: 10rem;
  border: none;
  padding: 0 1rem;
  border-bottom: 1px solid gray;
`;
export const IconBg = styled.span`
  background-color: #61d926;
  border-radius: 100%;
  ${"" /* padding: -5px; */}
  height: fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Text = styled.span`
  margin-top: 2px;
  margin-left: 3px;
  font-size: 13px;
`;

export const LinkContainer = styled.div`
  margin-top: 20px;
  margin-left: 12px;
  color: black;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }

  @media ${device.laptop} {
    margin-top: 0px;
    margin-left: 3rem;
  }
`;
