import styled from "styled-components";

export const HeroContainer = styled.div`
  width: 100%;
  position: relative;
`;
export const SearchContainer = styled.div`
  width: 100%;
  border: 1px solid #00000029;
  padding: 2rem 3rem;
  padding-top: ${({ rear }) => rear && "1rem"};
  display: flex;
  justify-content: space-between;
  //height: ${({ rear }) => (rear ? "10vh" : "15vh")};
  margin-top: ${({ rear }) => (rear ? "0" : "1rem")};
  box-shadow: 0 2px 5px #00000029;
  border-bottom: ${({ top }) => top && "none"};
  align-items: center;
  background: white;
  flex-wrap: wrap;
`;
export const TopFilterContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
`;
export const ChatButton = styled.div`
  width: 50px;
  height: 30px;
  border-radius: 40%;
  background-color: #61d926;
  font-size: small;
  text-align: center;
  padding-top: 5px;
  &:hover {
    cursor: pointer;
  }
`;
//Add Agent
export const AddAgentHero = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 30px 0 0 0;
  top: 0;
  right: 0;
  z-index: 2000;
  position: absolute;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  background-color: rgba(230, 230, 230, 0.6);
  justify-content: center;
`;

export const ExitButton = styled.div`
  display: flex;
  justify-content: flex-end;
  box-shadow: 1px 1px 1px #00000049;
  padding: 10px;
  width: 70px;
  height: 40px;
  border-radius: 40%;
  margin-left: -32px;
  background-color: white;
  &:hover {
    cursor: pointer;
  }
`;
