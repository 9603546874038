import React from "react";

export const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.2rem"
      height="1.2rem"
      viewBox="0 0 512 512"
      stroke="#fff"
      fill="white"
    >
      <title>Plus</title>
      <line
        x1="256"
        y1="112"
        x2="256"
        y2="400"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="20"
      />
      <line
        x1="400"
        y1="256"
        x2="112"
        y2="256"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="20"
      />
    </svg>
  );
};
