import styled from 'styled-components'

export const HeroContainer = styled.div`
width:100%
`
export const QuestionareContainer = styled.div`
display:flex;
justify-content:space-around;
width:100%;
padding:20px
`
export const CreateQuestionContainer = styled.div`
margin-top:20px;
width:750px;
display:block;
box-shadow: 0px 3px 6px #00000029;
background-color:#FFFFFF; 
`
export const Title = styled.h5`
background-color:#61D926;
border-radius: 10px 10px 0px 0px;
color:white;
padding:10px;
font-size:1rem
`

export const QuestionPreviewContainer = styled.div`
width:750px;
margin-top:20px;
box-shadow: 0px 3px 6px #00000029;

${'' /* overflow-y:show; */}
display:block;
border-radius:1 0px
`
export const QuestionListButton = styled.div`
width:100px;
background-color:white;
box-shadow: 0px 3px 6px #00000029;
height:30px;
font-size:medium;
color:gray;
border:1px solid #00000029;
&:hover{
    color:green;
    box-shadow: 3px 3px 6px #00000029;

}

`
//  className="inline-flex rounded-md border justify-center shadow px-5 py-3 text-base leading-6 font-medium text-gray-900 bg-white hover:text-green-700 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"