import React from 'react';
import styled from 'styled-components';
import { device } from '../globalComponents/mediaQueries';
import {FiCornerUpLeft } from 'react-icons/fi';
import { useHistory} from 'react-router-dom';


export const BackButton = (props) => {
    const history = useHistory();


    const handleBackButton = () => {
        history.goBack();
    }

    return (
        <StyledBackButtonDiv {...props}>
        <StyledBackButton onClick={handleBackButton}>
            <StyledFiCornerUpLeft/>
            <p>Back</p>
        </StyledBackButton>
        </StyledBackButtonDiv>
    );
};



const StyledBackButton = styled.div`
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;

    & p{
        margin: 0px;
        margin-left: 10px;
    }

    @media ${device.laptop}{
        font-size: 1.1vw
    }
`

const StyledFiCornerUpLeft = styled(FiCornerUpLeft)`
    font-size: 24px;

    @media ${device.laptop}{
        font-size: 1.6vw
    }
`

const StyledBackButtonDiv = styled.div`
    width: 85%;
    margin: 40px auto;
`