import React, { useState, useEffect, useRef } from "react";
import { Table, Modal } from "antd";
import { Checkbox } from "../../../components/Table/Styles";
import { BlockButton, UnBlockButton, WalletButton } from "../styles";
import { useHistory } from "react-router-dom";
import { Navbar, Menu, MenuItem } from "../../../components/atoms/TableNavs";
import { NotificationButton } from "../../../components/atoms/NotificationButton";
import { device } from "../../../globalComponents/mediaQueries";
import styled from "styled-components";
import { adminColor } from "../../../globalComponents/globalAccet";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { notificationAlert } from "../../../functions/alertNotification";
import {
  fetchAllDeletedUsersAction,
  fetchAllParticipantUsersAction,
  fetchAllSponsorUsersAction,
  fetchAllUsersAction,
  fetchRecentUsersAction,
  blockUserAction,
  unblockUserAction,
  blockUserNotificationAction,
  unblockUserNotificationAction,
  fetchUserProfileAction,
  userProfileAction,
  paymentHistory,
  paidSurveyHistoryAction,
  searchUserAction,
} from "../../../actions";

function UserTable({ isSearching, searchInputData, cancelSearch, setSelectedUsers }) {
 
  const ref = useRef(null);

  const { url } = useRouteMatch();
  let history = useHistory();
  const [name, setName] = useState("");
  const [blockStatus, setBlockStatus] = useState();
  const [id, setId] = useState();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const userList = useSelector((state) => state.userList);
  const onDeleteModalCancel = () => {
    setDeleteModalVisible(false);
  };
  const blockUserNotification = useSelector(
    (state) => state.blockUserNotification
  );
  const unblockUserNotification = useSelector(
    (state) => state.unblockUserNotification
  );

  const dispatcher = useDispatch();
  useEffect(() => {
    if (blockUserNotification !== "") {
      if (blockUserNotification === "success") {
        notificationAlert(
          "success",
          "User blocked",
          `User ${name} has been blocked`
        );
      }
      if (blockUserNotification === "error") {
        notificationAlert("error", "Failed", "Please try again");
      }
      handleTablePagination(currentPageNumber);
      dispatcher(blockUserNotificationAction(""));
    }
  }, [blockUserNotification]);

  useEffect(() => {
    if (unblockUserNotification !== "") {
      if (unblockUserNotification === "success") {
        notificationAlert(
          "success",
          "User unblocked",
          `User ${name} has been unblocked`
        );
      }
      if (unblockUserNotification === "error") {
        notificationAlert("error", "Failed", "Please try again");
      }

      dispatcher(unblockUserNotificationAction(""));
      handleTablePagination(currentPageNumber);
    }
  }, [unblockUserNotification]);

  useEffect(() => {
    dispatcher(fetchAllUsersAction({ page: 1, limit: pageSize }));
  }, []);

  const onViewAllUsers = () => {
    setActiveTab(1);
    setCurrentPageNumber(1);
    dispatcher(fetchAllUsersAction({ page: 1, limit: pageSize }));
  };

  const handleBacktoTable = () => {
    cancelSearch();
    onViewAllUsers();
  };

  const onViewRecentUsers = () => {
    setActiveTab(2);
    setCurrentPageNumber(1);
    dispatcher(fetchRecentUsersAction({ page: 1, limit: pageSize }));
  };

  const onViewParticipantUsers = () => {
    setActiveTab(3);
    setCurrentPageNumber(1);
    dispatcher(fetchAllParticipantUsersAction({ page: 1, limit: pageSize }));
  };

  const onViewSponsorUsers = () => {
    setActiveTab(4);
    setCurrentPageNumber(1);
    dispatcher(fetchAllSponsorUsersAction({ page: 1, limit: pageSize }));
  };

  const onViewDeletedUsers = () => {
    setActiveTab(5);
    setCurrentPageNumber(1);
    dispatcher(fetchAllDeletedUsersAction({ page: 1, limit: pageSize }));
  };
  const handleTablePagination = (pageNumber, pageSize) => {
    setCurrentPageNumber(pageNumber);
    setPageSize(pageSize);
    if (isSearching) {
      dispatcher(
        searchUserAction({ word: searchInputData, page: pageNumber, pageSize })
      );
    } else if (activeTab === 1) {
      dispatcher(fetchAllUsersAction({ page: pageNumber, limit: pageSize }));
    } else if (activeTab === 2) {
      dispatcher(fetchRecentUsersAction({ page: pageNumber, limit: pageSize }));
    } else if (activeTab === 3) {
      dispatcher(
        fetchAllParticipantUsersAction({ page: pageNumber, limit: pageSize })
      );
    } else if (activeTab === 4) {
      dispatcher(
        fetchAllSponsorUsersAction({ page: pageNumber, limit: pageSize })
      );
    } else if (activeTab === 5) {
      dispatcher(
        fetchAllDeletedUsersAction({ page: pageNumber, limit: pageSize })
      );
    }
  };


  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedUsers(selectedRowKeys);
    },
  };

  const userColumn =
    activeTab === 3
      ? [
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Membership",
            dataIndex: "user_type",
            key: "user_type",
          },
          {
            title: "Location",
            dataIndex: "state",
            key: "state",
          },
          {
            title: "Phone Number",
            dataIndex: "phone",
            key: "phone",
          },
          {
            title: "Date Joined",
            dataIndex: "date",
            key: "date",
          },

          {
            title: "Survey Participated",
            dataIndex: "survey_participated",
            key: "survey_participated",
          },
          {
            title: "View Wallet",
            dataIndex: "viewwallet",
            key: "viewwallet",
            render: (userList, others) => (
              <WalletButton
                size={"small"}
                onClick={(userList) => {
                  history.push({
                    pathname: `${url}/wallet/${others.id}`,
                    state: { others },
                  });
                  dispatcher(fetchUserProfileAction(others.id));
                  dispatcher(userProfileAction(false));
                  dispatcher(paymentHistory(false));
                  dispatcher(paidSurveyHistoryAction(false));
                }}
              >
                {" "}
                View Profile
              </WalletButton>
            ),
          },
          {
            title: "Block User ",
            dataIndex: "blockuser",
            key: "blockuser",
            render: (data, others) =>
              others.blocked === 0 ? (
                <BlockButton
                  size={"small"}
                  onClick={() =>
                    //  dispatcher(blockUserAction(others.id));
                    {
                      setDeleteModalVisible(true);
                      setName(others.name);
                      setBlockStatus(0);
                      setId(others.id);
                    }
                  }
                >
                  Block User
                </BlockButton>
              ) : (
                <UnBlockButton
                  size={"small"}
                  onClick={() =>
                    //  dispatcher(unblockUserAction(others.id));
                    {
                      setDeleteModalVisible(true);
                      setName(others.name);
                      setBlockStatus(1);
                      setId(others.id);
                    }
                  }
                >
                  Unblock User
                </UnBlockButton>
              ),
          },
        ]
      : [
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Membership",
            dataIndex: "user_type",
            key: "user_type",
          },
          {
            title: "Location",
            dataIndex: "state",
            key: "state",
          },
          {
            title: "Phone Number",
            dataIndex: "phone",
            key: "phone",
          },
          // {
          //   title: "Email",
          //   dataIndex: "email",
          //   key: "email",
          // }, doesn't have an email field from the backend
          {
            title: "Date Joined",
            dataIndex: "date",
            key: "date",
          },
          {
            title: "Survey Created",
            dataIndex: "survey_created",
            key: "survey_created",
          },
          {
            title: "Survey Participated",
            dataIndex: "survey_participated",
            key: "survey_participated",
          },
          {
            title: "View Wallet",
            dataIndex: "viewwallet",
            key: "viewwallet",
            render: (userList, others) => (
              <WalletButton
                size={"small"}
                onClick={(userList) => {
                  history.push({
                    pathname: `${url}/wallet/${others.id}`,
                    state: { others },
                  });
                  dispatcher(fetchUserProfileAction(others.id));
                  dispatcher(userProfileAction(false));
                  dispatcher(paymentHistory(false));
                  dispatcher(paidSurveyHistoryAction(false));
                }}
              >
                {" "}
                View Profile
              </WalletButton>
            ),
          },
          {
            title: "Block User ",
            dataIndex: "blockuser",
            key: "blockuser",
            render: (data, others) =>
              others.blocked === 0 ? (
                <BlockButton
                  size={"small"}
                  onClick={() =>
                    //  dispatcher(blockUserAction(others.id));
                    {
                      setDeleteModalVisible(true);
                      setName(others.name);
                      setBlockStatus(0);
                      setId(others.id);
                    }
                  }
                >
                  Block User
                </BlockButton>
              ) : (
                <UnBlockButton
                  size={"small"}
                  onClick={() =>
                    //  dispatcher(unblockUserAction(others.id));
                    {
                      setDeleteModalVisible(true);
                      setName(others.name);
                      setBlockStatus(1);
                      setId(others.id);
                    }
                  }
                >
                  Unblock User
                </UnBlockButton>
              ),
          },
        ];
  let modalComponent = (
    <Modal
      title="Delete User"
      open={deleteModalVisible}
      onCancel={onDeleteModalCancel}
      centered={true}
      onOk={() => {
        onDeleteModalCancel();
        blockStatus === 0
          ? dispatcher(blockUserAction(id))
          : dispatcher(unblockUserAction(id));
      }}
    >
      Do you wish to {blockStatus === 0 ? "block" : "unblock"} {name}?
    </Modal>
  );
  // console.log("User List:", userList.users);
  return (
    <>
      <StyledTableBar>
        {!isSearching ? (
          <StyledMenuDiv>
            <StyledMenu active={activeTab === 1} onClick={onViewAllUsers}>
              All Members
            </StyledMenu>
            <StyledMenu active={activeTab === 2} onClick={onViewRecentUsers}>
              New Members
            </StyledMenu>
            <StyledMenu
              active={activeTab === 3}
              onClick={onViewParticipantUsers}
            >
              Participants
            </StyledMenu>
            <StyledMenu active={activeTab === 4} onClick={onViewSponsorUsers}>
              Sponsors
            </StyledMenu>
            <StyledMenu active={activeTab === 5} onClick={onViewDeletedUsers}>
              Deleted Accounts
            </StyledMenu>
          </StyledMenuDiv>
        ) : (
          <StyledMenu
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            Search Result
            <p
              onClick={handleBacktoTable}
              style={{ marginLeft: "auto", marginBottom: "0px" }}
            >
              Cancel
            </p>
          </StyledMenu>
        )}
      </StyledTableBar>
      <StyledTableContainer>
        <Table
          rowSelection={rowSelection}
          columns={userColumn}
          dataSource={userList.users}
          pagination={{
            defaultCurrent: currentPageNumber,
            total: pageSize * userList.pages,
            current: currentPageNumber,
            onChange: handleTablePagination,
            showSizeChanger: true,
          }}
        />{" "}
      </StyledTableContainer>

      {modalComponent}
    </>
  );
}

const StyledMenu = styled.div`
  cursor: pointer;
  padding: 5px 10px;
  font-size: 10px;

  background-color: ${(props) => (props.active ? "#fff" : "transparent")};
  color: ${(props) => (props.active ? "green" : "#fff")};
  font-weight: 500;
  &:hover {
    ${"" /* background-color: #fff; */}
    color: green;
  }

  &:first-child {
    padding-left: 10px;
  }

  @media ${device.laptop} {
    font-size: 1vw;
  }
`;

const StyledTableBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 13px;
  background-color: ${adminColor};
  color: #fff;
  width: 100%;
  overflow-x: auto;
`;

const StyledMenuDiv = styled.div`
  display: flex;
`;

const StyledTableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export default UserTable;
