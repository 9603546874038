import { notificationAlert } from "../../functions/alertNotification";

export function validateEmail(mail) {
  if (/^\S+@\S+\.\S+$/.test(mail)) {
    return true;
  }
  notificationAlert(
    "error",
    "Invalid email",
    "You have entered an invalid email address!"
  );
  throw new Error("Invalid Email");
}
