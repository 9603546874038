import styled from "styled-components";
import { Button as AntButton } from "antd";

export const HeroContainer = styled.div`
  width: 100%;
  padding: 20px;
`;

//Graph style
export const GraphContainer = styled.div`
  background: white;
  ${"" /* border: 1px solid grey; */}
  border-radius: 3px;
  width: 100%;
  height: 94%;
  box-shadow: 0px 3px 3px #00000029;
  display: block;
`;
export const Title = styled.h5`
  font-weight: 500;
  font-size: 25px;
  border-radius: 3px 3px 0 0;
  color: white;
  margin-bottom: 0;
  background: #909590;
  ${"" /* border-bottom: 1px solid #00000029; */}
  padding: 4px 0px;
  padding-left: 20px;
  width: 100%;
`;
export const LineChart = styled.div`
  padding: 16px;
`;
//card components
export const CardContainer = styled.div`
  display: block;
  ${"" /* width:100%; */}
`;
export const Card = styled.div`
  display: block;
  border-radius: 3px;
  width: 100%;
  text-align: center;
  ${"" /* margin-top:10px; */}
  ${"" /* text-align:center; */}
background-image: linear-gradient(112deg, #00A53C , #7DB6DB );
  background: ${({ gradient }) => !gradient && "white"};
  height: 230px;
  margin-bottom: 30px;
  box-shadow: 0px 3px 3px #00000029;
`;

export const CardTitle = styled.h4`
  font-size: 15px;
  font-weight: 500;
  padding: 10px 20px 10px 20px;
  text-align: left;
  color: ${({ gradient }) => (gradient ? "white" : "black")};
`;

export const Hr = styled.hr`
  background-color: ${(props) => (props ? props.color : "white")};
  margin-top: -0.5rem;
`;

export const ValueContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  text-align: center;
`;
export const Value = styled.h1`
  ${"" /* font-size:16px; */}
  color: ${({ gradient }) => (gradient ? "white" : "black")};
  font-weight: 500;
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 3rem;
`;
export const ValueSub = styled.small`
  ${"" /* font-size:16px; */}
  color: ${({ gradient }) => (gradient ? "white" : "black")};
  font-weight: 400;
  font-size: 0.85rem;
  margin-top: 10px;
`;

export const Button = styled(AntButton)`
  box-shadow: 4px 4px 3px #00000029;
  color: black;
  ${"" /* box-sizing: border-box; */}
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 13px;
  background: white;
  text-align: center;
  margin: 2vh;
  margin-top: auto;
  float: right;

  ${"" /* margin:20px 330px; */}
  &:hover {
    cursor: pointer;
    color: black;
  }
`;
//Recent Users
export const RecentUsersContainer = styled.div`
  ${"" /* min-width: 100%; */}
  padding-top:5px;
  box-shadow: 0px 3px 3px #00000029;
  overflow-y: auto;
  height: 94%;
  border-radius: 5px;
`;
export const RecentUsersTitle = styled.small`
  padding: 10px 10px;
  ${"" /* margin-top: 20px; */}
  ${"" /* text-align: center; */}
  font-weight: bold;
`;
export const RecentUserHr = styled.hr`
  background-color: #00000029;
  margin-top: 0.6rem;
`;
export const RecentUserContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: -4px;
  justify-content: space-between;
  align-items: center;
  padding: 0 7px;
  border-bottom: 1px solid #00000010;
  &:last-child {
    border-bottom: "none";
  }
`;
export const UserDetailsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
export const UserImage = styled.img`
  height: 2rem;
  margin-top: 16px;
  width: 2rem;
  border-radius: 50%;
`;
export const UserInfoContainer = styled.div`
  display: block;
  margin-left: 5px;
`;
export const UserInfoName = styled.p`
  font-weight: 600;
  margin-top: 10px;
  font-size: 14px;
`;
export const UserInfoId = styled.p`
  font-weight: 400;
  font-size: 13px;

  margin-top: -1rem;
`;

export const UserOnlineIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-top: 20px;
  background-color: red;
`;
