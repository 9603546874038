import { MANAGE_SURVEY } from "../constants/index";

export const surveyReducer = (
  state = { surveys: [], pages: 1, totalSurvey: 0 },
  { type, payload }
) => {
  if (type === MANAGE_SURVEY.SURVEY_LIST) {
    let tempState = { ...state };
    tempState.surveys = payload.surveys;
    tempState.pages = payload.pages;
    tempState.totalSurvey = payload.total_survey;
    return tempState;
  }
  return state;
};

export const downloadAssignedLinkReducer = (state = "", { type, payload }) => {
  if (type === MANAGE_SURVEY.DOWNLOAD_ASSIGNED_LINK) {
    return payload;
  }
  return state;
};

export const downloadUnassignedLinkReducer = (
  state = "",
  { type, payload }
) => {
  if (type === MANAGE_SURVEY.DOWNLOAD_UNASSIGNED_LINK) {
    return payload;
  }
  return state;
};

export const unassignSurveyParticipantReducer = (
  state = "",
  { type, payload }
) => {
  if (type === MANAGE_SURVEY.UNASSIGN_SURVEY_PARTICIPANT) {
    return payload;
  }
  return state;
};

export const assignSurveyParticipantReducer = (
  state = "",
  { type, payload }
) => {
  if (type === MANAGE_SURVEY.ASSIGN_SURVEY_PARTICIPANT) {
    return payload;
  }
  return state;
};
