const QUESTION_SURVEY = {
  ADD_QUESTION: "ADD_QUESTION",
  SET_ACTIVE_QUESTION: "SET_ACTIVE_QUESTION",
  REMOVE_QUESTION: "REMOVE_QUESTION",
  CLONE_QUESTION: "CLONE_QUESTION",
  SORT_QUESTION_UP: "SORT_QUESTION_UP",
  SORT_QUESTION_DOWN: "SORT_QUESTION_DOWN",
  EDIT_QUESTION: "EDIT_QUESTION",
  EDIT_SURVEY_HEADER: "EDIT_SURVEY_HEADER",
};

const UPLOAD_EXCEL = {
  IMPORT: "IMPORT FROM EXCEL",
};

const MANAGE_AGENT = {
  REGISTER: "REGISTER AGENT",
  REGISTERED_NOTIFICATION: "REGISTERED NOTIFICATION",
  FETCH_ALL_AGENT: "FETCH ALL AGENT",
  FETCH_RECENT_AGENT: "FETCH RECENT AGENT",
  AGENT_LIST: "AGENT LIST",
  DELETE: "DELETE AGENT",
  DELETE_NOTIFICATION: "DELETE NOTIFICATION",
  FETCH_SURVEY_FOR_AGENT: "FETCH SURVEY FOR AGENT",
  SURVEY_FOR_AGENT: "SURVEY FOR AGENT",
  FETCH_PARTICIPANT_FOR_SURVEY: "FETCH_PARTICIPANT_FOR_SURVEY",
  PARTICIPANT_FOR_SURVEY: "PARTICIPANT FOR SURVEY",
};

const OVERVIEW = {
  ADMIN_OVERVIEW: "ADMIN_OVERVIEW",
  FETCH_ADMIN_OVERVIEW: "FETCH_ADMIN_OVERVIEW",
  SURVEY: "SURVEY",
  FETCH_SURVEY: "FETCH_SURVEY",
};

const LOGIN = {
  EMAIL: "EMAIL",
  PASSWORD: "PASSWORD",
  ADMIN: "ADMIN LOGIN",
  AGENT: "AGENT LOGIN",
  NOTIFICATION: "NOTIFICATION",
  PARTICIPANT: "PARTICIPANT LOGIN",
  PARTICIPANT_LOGIN_NOTIFICATION: "PARTICIPANT LOGIN NOTIFICATION",
};

const MANAGE_PARTICIPANTS = {
  SINGLEREGISTER: "SINGLE PARTICIPANT REGISTER",
  BATCHREGISTER: "BATCH PARTICIPANT REGISTER",
  LOGIN: "LOGIN PARTICIPANT",
  USERFORMDATA: "USER FORM DATA",
  USER_REGISTER_NOTIFICATION: "USER REGISTER NOTIFICATION",
  USER_BATCH_REGISTER_NOTIFICATION: "USER BATCH REGISTER NOTIFICATION",
  FETCH_PARTICIPANT_INFO: "FETCH PARTICIPANT INFO",
  PARTICIPANT_DETAILS: "PARTICIPANT DETAILS",
  FETCH_PARTICIPANT_ASSIGNED_SURVEY: "FETCH PARTICIPANT ASSIGNED SURVEY",
  PARTICIPANT_ASSIGNED_SURVEY_LIST: "PARTICIPANT ASSIGNED SURVEY LIST",
  FETCH_PARTICIPANT_SINGLE_SURVEY: "FETCH PARTICIPANT SIGNLE SURVEY",
  PARTICIPANT_SINGLE_SURVEY: "PARTICIPANT_SINGLE_SURVEY",
  SUBMIT_SCREENING_RESPONSE: "SUBMIT SCREENING RESPONSE",
  SUBMIT_MAIN_RESPONSE: "SUBMIT MAIN RESPONSE",
  SCREENING_RESPONSE_NOTIFY: "SCREENING RESPONSE NOTIFICATION",
  MAIN_RESPONSE_NOTIFY: "MAIN RESPONSE NOTIFICATION",
};

const SURVEY_TEMPLATE = {
  CREATE: "CREATE TEMPLATE",
  NOTIFICATION: "CREATE TEMPLATE NOTIFICATION",
  SET_ACTIVE_TEMPLATE_ID: "ACTIVE TEMPLATE ID",
  SET_ACTIVE_TEMPLATE_NAME: "ACTIVE TEMPLATE NAME",
  FETCH_SINGLE_TEMPLATE: "FETCH SINGLE TEMPLATE",
  SET_SINGLE_TEMPLATE: "SET SINGLE TEMPLATE",
  SAVE_TEMPLATE_QUESTION: "SAVE TEMPLATE QUESTIONS",
  SET_TEMPLATE_QUESTIONNAIRE: "SET QUESTIONNAIRE",
  SET_TEMPLATE_SCREENING_QUESTION: "SET SCREENING QUESTION",
  SAVE_TEMPLATE_QUESTION_NOTIFY: "SAVE TEMPLATE QUESTION NOTIFICATION",
  FETCH_TEMPLATE_LIST: "FETCH TEMPLATE LIST",
  SET_TEMPLATE_LIST: "SET TEMPLATE LIST",
  DELETE_TEMPLATE: "DELETE TEMPLATE",
  DELETE_NOTIFICATION: "DELETE NOTIFICATION",
  DELETE_QUESTION: "DELETE QUESTION",
  DELETE_QUESTION_NOTIFICATION: "DELETE QUESTION NOTIFICATION",
  UPDATE_TITLE: "UPDATE TITLE",
  UPDATE_TITLE_NOTIFICATION: "UPDATE TITLE NOTIFICATION",
};

const MANAGE_SURVEY = {
  FETCH_RECENT_SURVEY: "FETCH RECENT SURVEY",
  FETCH_ALL_SURVEY: "FETCH ALL SURVEY",
  FETCH_ACTIVE_SURVEY: "FETCH ACTIVE SURVEY",
  FETCH_COMPLETED_SURVEY: "FETCH COMPLETED SURVEY",
  SURVEY_LIST: "SURVEY LIST",
  FETCH_SURVEY_DETAIL: "FETCH SURVEY DETAIL",
  SURVEY_DETAIL: "SURVEY_DETAIL",
  FETCH_SURVEY_ANALYSIS: "FETCH SURVEY ANALYSIS",
  SURVEY_ANALYSIS: "SURVEY ANALYSIS",
  FETCH_SURVEY_ASSIGNED_USER: "FETCH SURVEY ASSIGNED USER",
  FETCH_SURVEY_RESPONDED_USER: "FETCH SURVEY RESPONDED USER",
  ASSIGNED_SURVEY: "ASSIGNED SURVEY",
  SURVEY_TAKEN_PARTICIPANTS: "SURVEY_TAKEN_PARTICIPANTS",
  SEARCH_SURVEY: "SEARCH SURVEY",
  DOWNLOAD_ASSIGNED: "DOWNLOAD_ASSIGNED",
  DOWNLOAD_ASSIGNED_LINK: "DOWNLOAD_ASSIGNED_LINK",
  DOWNLOAD_UNASSIGNED: "DOWNLOAD_UNASSIGNED",
  DOWNLOAD_UNASSIGNED_LINK: "DOWNLOAD_UNASSIGNED_LINK",
  FETCH_UNASSIGN_SURVEY_PARTICIPANT: "FETCH UNASSIGN SURVEY PARTICIPANT",
  UNASSIGN_SURVEY_PARTICIPANT: "UNASSIGN SURVEY PARTICIPANT",
  FETCH_ASSIGN_SURVEY_PARTICIPANT: "FETCH ASSIGN SURVEY PARTICIPANT",
  ASSIGN_SURVEY_PARTICIPANT: "ASSIGN SURVEY PARTICIPANT",

  /* FETCH_RECENT_SURVEY: "FETCH RECENT SURVEY",
  FETCH_ALL_SURVEY: "FETCH ALL SURVEY",
  FETCH_ACTIVE_SURVEY: "FETCH ACTIVE SURVEY",
  FETCH_COMPLETED_SURVEY: "FETCH COMPLETED SURVEY",
  SURVEY_LIST: "SURVEY LIST",
  FETCH_SURVEY_DETAIL: "FETCH SURVEY DETAIL",
  SURVEY_DETAIL: "SURVEY_DETAIL",
  FETCH_SURVEY_ANALYSIS: "FETCH SURVEY ANALYSIS",
  SURVEY_ANALYSIS: "SURVEY ANALYSIS", */
};
const MANAGE_USER = {
  FETCH_RECENT_USER: "FETCH RECENT USER",
  FETCH_ALL_USER: "FETCH ALL USER",
  FETCH_ALL_PARTICIPANT: "FETCH ALL PARTICIPANTS",
  FETCH_ALL_SPONSOR: "FETCH ALL SPONSOR",
  FETCH_DELETED_USER: "FETCH DELETED USERS",
  USER_LIST: "USERS LIST",
  BLOCK_USER: "BLOCK USER",
  UNBLOCK_USER: "UNBLOCK USER",
  BLOCK_USER_NOTIFICATION: "BLOCK USER NOTIFICATION",
  UNBLOCK_USER_NOTIFICATION: "UNBLOCK USER NOTIFICATION",
  FETCH_USER_PROFILE: "FETCH USER PROFILE",
  USER_PROFILE: "USER PROFILE",
  FETCH_PAYMENT_HISTORY: "FETCH PAYMENT HISTORY",
  PAYMENT_HISTORY: "FETCH PAYMENT HISTORY",
  FETCH_PAID_SURVEY_HISTORY: "FETCH PAID SURVEY HISTORY",
  PAID_SURVEY_HISTORY: "PAID SURVEY HISTORY",
  FETCH_RECENTLY_REGISTERED_USERS: "FETCH RECENTLY REGISTERED USERS",
  RECENTLY_REGISTERED_USERS: "RECENTLY_REGISTERED_USERS",
  SEARCH_USER: "SEARCH USER",
};

const UTILS = {
  MODALOPEN: "MODAL OPEN",
  MODALCLOSE: "MODAL CLOSE",
};

export {
  QUESTION_SURVEY,
  UPLOAD_EXCEL,
  MANAGE_AGENT,
  LOGIN,
  MANAGE_PARTICIPANTS,
  OVERVIEW,
  SURVEY_TEMPLATE,
  MANAGE_SURVEY,
  MANAGE_USER,
  UTILS,
};
