import React from "react";
import { Dashboard } from "../components/atoms/icons";
import { Spring } from "react-spring/renderprops";
import Navbar from "../components/atoms/Navbar/index";
import Stats from "../components/atoms/Stats/index";
import UserStats from "../components/UserStats/index";
import NewUser from "../components/UserChart/Index";
import { HeroContainer } from "./Style";
import BaseScaffold from "./BaseScaffold";

const FinancialStats = () => {
  return (
    <BaseScaffold title="Dashboard">
      <Stats />
      <UserStats />
      <NewUser />
    </BaseScaffold>
  );
};
export default FinancialStats;
