import React, { useState } from "react";
import styled from "styled-components";
import { device } from "../../globalComponents/mediaQueries";

import { Menu as AntMenu, Button as AntButton } from "antd";

export const StyledBar = ({ barData }) => {
  const [activeTab, setActiveTab] = useState(1);
  // const [current, setCurrent] = useState("1");
  const menu = barData.map((data, index) => (
    <MenuItem
      key={data.key.toString()}
      active={activeTab === data.key}
      onClick={() => {
        data.func();
        setActiveTab(data.key);
      }}
    >
      {data.title}
    </MenuItem>
  ));

  return (
    <Navbar>
      <Menu>{menu}</Menu>
    </Navbar>
  );
};

export const Navbar = styled.div`
  width: 100%;
  background-color: ${({ agent }) => (agent ? "#0e6180" : "#61d926")};
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
`;

export const Menu = styled(AntMenu)`
  display: flex;
  height: 100%;
  background: inherit;
  color: white;
  border: none;
  outline: none;
  font-weight: 500;
  &:active {
    color: blue;
  }
`;
export const MenuItem = styled(AntButton)`
  &&& {
    height: 100%;
    background: ${({ active }) => (active ? "white" : "inherit")};
    color: ${({ agent, active }) => (active ? "green" : "white")};
    border: none;
    outline: none;
    font-weight: 500;
  }
`;
const StyledMenu = styled.div`
  cursor: pointer;
  padding: 20px 10px;
  font-size: 16px;

  background-color: ${(props) => (props.active ? "#fff" : "transparent")};
  color: ${(props) => (props.active ? "#000" : "#fff")};

  &:hover {
    background-color: #fff;
    color: #000;
  }

  &:first-child {
    padding-left: 10px;
  }

  @media ${device.laptop} {
    font-size: 1.1vw;
  }
`;
