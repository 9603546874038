import React, { useState, useEffect } from "react";
import { StyledModal } from "../../globalComponents/Modal";
import { Spinner } from "../../components/atoms/Spinner";
import { useHistory, useParams } from "react-router-dom";
import { Statistic } from "antd";
import {
  fetchPaymentHistory,
  fetchPaidSurveyhistoryAction,
} from "../../actions/index";
import { useSelector, useDispatch } from "react-redux";
import { device } from "../../globalComponents/mediaQueries";
import { Button, Table, Form } from "antd";
import { Table as AntTable } from "antd";
import styled from "styled-components";
import { loading } from "axios-progress-bar";

const PaymentDetails = ({
  history,
  historyHeader,
  bankDetails,
  bankInfoHeader,
  balance,
  surveyTableHeader,
}) => {
  let allowHistory = useHistory();

  const userProfile = useSelector((state) => state.userProfile);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [currentSurveyPageNumber, setCurrentSurveyPageNumber] = useState(1);
  const dispatcher = useDispatch();
  const paymentHistory = useSelector((state) => state.paymentHistory);
  const paidSurveyHistory = useSelector((state) => state.paidSurveyHistory);
  // console.log("user profile", userProfile);
  const { id } = useParams();
  const [delayMessage, setDelayMessage] = useState("");
  useEffect(() => {
    const timer1 = setTimeout(() => {
      setDelayMessage("Please Wait, still loading...");
    }, 15000);
    const timer2 = setTimeout(() => {
      setDelayMessage("Loading, almost done!");
    }, 25000);
    return () => clearTimeout(timer1, timer2);
  }, []);
  // console.log("This is payment", paymentHistory.paymentHistory);
  useEffect(() => {
    dispatcher(
      fetchPaidSurveyhistoryAction({ id: id, page: currentSurveyPageNumber })
    );
    dispatcher(fetchPaymentHistory({ id: id, page: currentPageNumber }));
  }, []);
  // console.log("This is the paid survey history ", paidSurveyHistory.page);

  const handleSurveyTablePagination = (pageNumber) => {
    setCurrentSurveyPageNumber(pageNumber);
    dispatcher(fetchPaidSurveyhistoryAction({ id: id, page: pageNumber }));
  };

  const handleTablePagination = (pageNumber) => {
    setCurrentPageNumber(pageNumber);
    dispatcher(fetchPaymentHistory({ id: id, page: pageNumber }));
  };

  const handleViewPaidSurveyDetails = (singlePaidSurveyHistoryView) => {
    allowHistory.push(`/dashboard/survey-management/survey/${singlePaidSurveyHistoryView.id}
`);
  };
  const handleRowClick = (paidSurveyHistory, rowIndex) => ({
    onClick: () => handleViewPaidSurveyDetails(paidSurveyHistory),
  });
  console.log("paidSurveyHistory", paidSurveyHistory.paidSurveyHistory);

  let surveyInformation =
    userProfile.user_type === "business" ? (
      <DetailsContainer>
        <TableLabel>Survey Information</TableLabel>

        <Table
          pagination={{
            defaultCurrent: currentSurveyPageNumber,
            total: 5 * paidSurveyHistory.page,
            current: currentSurveyPageNumber,
            onChange: handleSurveyTablePagination,
          }}
          className="table-striped-rows"
          columns={surveyTableHeader}
          dataSource={paidSurveyHistory.paidSurveyHistory}
          onRow={handleRowClick}
        />
      </DetailsContainer>
    ) : (
      <DetailsContainer>
        <TableLabel>Bank Information</TableLabel>
        <AntTable
          className="table-striped-rows"
          columns={bankInfoHeader}
          // dataSource={bankDetails}
          dataSource={[{ ...userProfile }]}
        />
      </DetailsContainer>
    );
  let walletHistory =
    userProfile.user_type === "business" ? null : (
      <>
        <Balance>
          <h5>Total Amount in Wallet</h5>
          <div>
            <h5>Balance</h5>
            <StyledOutput
              prefix={
                <span style={{ fontWeight: 500, fontSize: "30px" }}>₦</span>
              }
              value={balance}
            />
          </div>
        </Balance>
        <DetailsContainer>
          <TableLabel>Payment Details</TableLabel>
          <PaymentLabel>Wallet History</PaymentLabel>
          <Table
            className="table-striped-rows"
            columns={historyHeader}
            pagination={{
              defaultCurrent: currentPageNumber,
              total: 10 * paymentHistory.page,
              current: currentPageNumber,
              onChange: handleTablePagination,
            }}
            dataSource={paymentHistory.paymentHistory}
          />
        </DetailsContainer>
      </>
    );
  let tableSpinner =
    (paidSurveyHistory.paidSurveyHistory && userProfile) ||
    (userProfile && paymentHistory.paymentHistory) ? (
      <Container>
        {walletHistory}
        {surveyInformation}
      </Container>
    ) : (
      <SpinnerContainer>
        <Spinner tip={delayMessage} />
      </SpinnerContainer>
    );
  return (
    <>
      {/* {walletHistory}
      {table} */}
      {tableSpinner}
    </>
  );
};
export default PaymentDetails;

const Container = styled.div`
  display: block;
  box-shadow: 4px 3px 6px #00000009;

  padding: 0 0;

  @media ${device.laptop} {
    /*    margin-right: 20px;
    margin-left: -10px; */
    padding: 10px 7px;
  }
`;

const SpinnerContainer = styled.div`
  background-color: #ffffff;
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Balance = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  padding: 20px 20px;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
`;
const StyledOutput = styled(Statistic)`
  & .ant-statistic-content-value-int {
    text-align: center !important;
    color: black !important;
  }
  @media ${device.laptop} {
    & .ant-statistic-content-value-int {
      font-size: 30px !important;
      font-weight: 500;
    }
  }
`;
const StyledPayment = styled(Statistic)`
  & .ant-statistic-content-value-int {
    text-align: center !important;
    color: black !important;
  }
  @media ${device.laptop} {
    & .ant-statistic-content-value-int {
      font-size: 20px !important;
      font-weight: 500;
    }
  }
`;
const DetailsContainer = styled.div`
  margin-top: 5px;
  box-shadow: 0px 3px 6px #0000001a;
`;
const TableLabel = styled.div`
  width: 100%;
  background: #61d926;
  color: #ffffff;
  font-weight: bold;
  padding: 10px 10px;
`;
const PaymentLabel = styled.div`
  width: 100%;
  font-weight: 600;
  background: #ffffff;
  padding: 10px 10px 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #00000019;
  color: black;
`;
const StyledButton = styled(Button)`
  background-color: #007aaf;
  margin-left: 10px;
  border-radius: 15px;
`;
