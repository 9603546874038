export const fetchParticipantInfo = "/api/user";
export const fetchParticipantAssignedSurvey = "/api/my-surveys?page=";
export const fetchParticipantSingleSurvey = "/api/view-surveys?survey_id=";
export const submitScreeningResponse = "/api/submit-screening_question-agent";
export const submitMainResponse = "/api/submit-responds-agent";
export const registerAgentRoute = "/api/add-agent";
export const adminLoginRoute = "/api/admin-login";
export const loginRoute = "/v1/auth/login";
export const registerParticipantRoute = "/api/agent-register-user";
export const fetchAllAgentsRoute = "/api/admin/view-agents?q=&page=";
export const fetchRecentAgentRoute = "/api/admin/view-agents?q=recent&page=";
export const agentLoginRoute = "/api/agent-login";
export const deleteAgentRoute = "/api/delete-agent";
export const fetchAdminOverviewRoute = "/api/admin_overview";
export const fetchSurveyOverviewRoute = "/api/survey_overview";
export const loginParticipantRoute = "/api/agent-user-login";
export const createSurveyTemplateRoute = "/api/add-survey";
export const saveTemplateQuestionRoute = "/api/add-questions";
export const fetchSingleTemplateRoute = "/api/view-surveys?survey_id=";
export const fetchTemplateListRoute = "/api/view-my-surveys?page=";
export const deleteTemplateRoute = "/api/delete-survey?survey_id=";
export const deleteQuestionRoute = "/api/delete-question?question_id=";
export const fetchRecentSurveyRoute = "/api/admin/view-survey?q=recent";
export const fetchAllSurveyRoute = "/api/admin/view-survey?q=all";
export const fetchActiveSurveyRoute = "/api/admin/view-survey?q=active";
export const fetchCompletedSurveyRoute = "/api/admin/view-survey?q=completed";
export const fetchRecentUsersRoute = "/api/admin/view-users?q=recent";
export const fetchAllUserRoute = "/api/admin/view-users?q=all";
export const fetchDeletedUserRoute = "/api/admin/view-users?q=deleted";
export const fetchPaticipantUsersRoute = "/api/admin/view-users?q=participants";
export const fetchSponsorUsersRoute = "/api/admin/view-users?q=sponsors";
export const fetchSurveyDetail = "/api/admin/view-survey_detail?survey_id=";
export const blockUser = "/api/admin/block?user_id=";
export const unblockUser = "/api/admin/unblock?user_id=";
export const fetchUserProfile = "/api/admin/user_detail?user_id=";
export const fetchUserPaymentHistory =
  "/api/admin/user/payment-history?user_id=";
export const fetchSurveyAnalysis = "/api/admin/view-analysis?survey_id=";
export const fetchSurveyAssignedUser =
  "/api/admin/survey-assigned-users?survey_id=";
export const fetchSurveyRespondedUser =
  "/api/admin/survey-responds-users?survey_id=";
export const fetchPaidHistorySurvey = "/api/admin/user/paid-survey?user_id=";
export const updateTemplateName = "/api/properties/survey-title";
export const fetchSurveyForAgent = "/api/agent/get-users-survey";
export const fetchParticipantsForSurvey =
  "/api/agent/user-under-aget-survey?survey_id=";
export const searchSurveyRoute = "/api/admin-serach-survey?input=";
export const searchUserRoute = "/api/admin-serach-users?input=";
export const downloadAssignedSurvey = `/api/admin/download-survey-assigned-users-taken-csv?survey_id=`;
export const downloadUnassignedSurvey = `/api/admin/download-survey-assigned-users-waiting-csv?survey_id=`;
export const removeSurveyParticipantRoute = "/api/remove-survey-participant";
export const assignSurveyParticipantRoute = "/api/assign-participant-survey";
export const userSurveyByEmailEndpoint = (email) => `/api/agent/user-surveys?email=${email}`
