import React from "react";
import { HeroContainer } from "./Styles";
import Graph from "./Graph";
import Card from "./Card";
import RecentUser from "./RecentUser";

const UserStats = () => {
  return (
    <HeroContainer>
      <div className="row">
        <div className="col-xs-11 col-sm-11 col-md-11 col-xl-6">
          <Graph />
        </div>
        <div className="col-xs-11 col-sm-11 col-md-6 col-xl-4 mt-md-2 mt-lg-0">
          <Card />
        </div>
        <div className="col-xs-11 col-sm-11 col-md-5 col-xl-2 mt-md-2 mt-lg-0">
          <RecentUser />
        </div>
      </div>
    </HeroContainer>
  );
};
export default UserStats;
