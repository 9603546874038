import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { device } from '../../../globalComponents/mediaQueries';
import { QuestionButton } from './atom/QuestionButton';
import { Tabs, Tab } from './atom/Tabs';
import { AddQuestionComponent } from './compound/AddQuestionComponent';
import {Board} from './Board';
import { EditQuestionBoard } from './compound/EditQuestionBoard';


/* const tabObj = [
    {name: 'Add Question', isActive: true},
    {name: 'Edit Question', isActive: false},
    {name: 'Edit Survey', isActive: false}
] */


export const CreateQuestionnaire = (props) => {
    const [tabObjState, setTabObjState] = useState([
        {name: 'Add Question', isActive: true},
        {name: 'Edit Question', isActive: false},
        {name: 'Edit Survey', isActive: false}
    ]);
    const [sectionName, setSectionName] = useState('Add Question');


    useEffect(() => {
        setTabObjState([
            {name: 'Add Question', isActive: true},
            {name: 'Edit Question', isActive: false},
        ])

        setSectionName('Add Question');
    }, [])

    const handleTabClick = (name) => {
        const newTabs = tabObjState.slice().map(item => {
            if(item.name === name){
                item.isActive = true;
                return item;
            }

            item.isActive = false;
            return item;
        });

        setSectionName(name);
        setTabObjState(newTabs);
    }

    const tabsArray = tabObjState.map((item, index) => 
        <Tab 
            key={index} 
            isActive={item.isActive} 
            onClick={() => handleTabClick(item.name)} 
            titleText={item.name}
        />)

    return (
        <Board title='Create Questionnaire'>
            <Tabs tabs={tabsArray}/>
            {tabObjState[0].isActive && <AddQuestionComponent/>}
            {tabObjState[1].isActive && <EditQuestionBoard/>}
        </Board>
      
    );
}



