import React, { useEffect, useState } from "react";
import {
  StatsContainer,
  SingleStats,
  StatsTitle,
  StatsRule,
  StatsValue,
  StatsFooter,
  StyledOutput,
} from "./style";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSurveyAction,
  fetchAdminOverviewAction,
} from "../../../actions/index";
import {
  percentageRatio,
  getPercentage,
} from "../../../functions/alertNotification";

const Stats = () => {
  const adminOverView = useSelector((state) => state.adminOverView);
  const surveyOverView = useSelector((state) => state.surveyOverView);

  const dispatcher = useDispatch();
  useEffect(() => {
    dispatcher(fetchAdminOverviewAction());
    dispatcher(fetchSurveyAction());
  }, []);
  return (
    <div>
      {" "}
      <StatsContainer className="row pt-4" style={{ padding: "0 20px" }}>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <SingleStats color="#61D926">
            <StatsTitle>Total Number of Partcipants</StatsTitle>
            <StatsValue>
              {" "}
              <StyledOutput value={adminOverView.participants} />
            </StatsValue>
            <StatsRule color="#b3e9a3" />
            <StatsFooter>
              Ratio{" "}
              {getPercentage(
                adminOverView.participants,
                adminOverView.participants,
                adminOverView.sponsors
              )}
              %
            </StatsFooter>
          </SingleStats>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <SingleStats color="#C82E29">
            <StatsTitle>Total Number of Sponsors</StatsTitle>
            <StatsValue>
              {" "}
              <StyledOutput value={adminOverView.sponsors} />{" "}
            </StatsValue>
            <StatsRule color="#df6363" />
            <StatsFooter>
              Ratio{" "}
              {getPercentage(
                adminOverView.sponsors,
                adminOverView.participants,
                adminOverView.sponsors
              )}
              %
            </StatsFooter>
          </SingleStats>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <SingleStats color="#A58A00">
            <StatsTitle>Number of Available Surveys</StatsTitle>
            <StatsValue>
              <StyledOutput value={surveyOverView.active_survey} />{" "}
            </StatsValue>
            <StatsRule color="#c8c365" />
            <StatsFooter>Today</StatsFooter>
          </SingleStats>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <SingleStats color="#1F0EBC">
            <StatsTitle>Completed Surveys</StatsTitle>
            <StatsValue>
              {" "}
              <StyledOutput value={surveyOverView.complete_survey} />{" "}
            </StatsValue>
            <StatsRule color="#6b62ce" />
            <StatsFooter>Today</StatsFooter>
          </SingleStats>
        </div>{" "}
      </StatsContainer>
    </div>
  );
};
export default Stats;
