import { useContext, createContext} from 'react';


export const ActiveSurveyIdContext = createContext(0);

export const UseActiveSurveyId = () => useContext(ActiveSurveyIdContext);

export const ActiveParticipantIdContext = createContext(0);

export const UseActiveParticipantId = () => useContext(ActiveParticipantIdContext);

export const SurveyQuestionAnwserContext = createContext([]);

export const UseSurveyQuestionAnswerContext = () => useContext(SurveyQuestionAnwserContext);

export const SideBarContext =  createContext({})

export const useSideBarContext = () => useContext(SideBarContext);