import React, { Fragment, useState } from "react";
import { Modal } from "antd";

import styled from "styled-components";

export const UserSurveyTable = ({ TableHead, TableData }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const showModal = (item) => {
    setIsModalVisible(true);
    setCurrentItem(item);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const TableHeader = TableHead.map((header) => (
    <Th key={header.title}>{header.title}</Th>
  ));

  const DataRow = TableData.map((data) => (
    <TableDataRow key={data.id}>
      <Td onClick={() => showModal(data)}>{data.title}</Td>
      <InDataTd>
        <InDataRowdash>{data.optionAPercent}</InDataRowdash>
        <InDataRow>{data.optionAValue}</InDataRow>
      </InDataTd>
      <InDataTd>
        <InDataRowdash>{data.optionBPercent}</InDataRowdash>
        <InDataRow>{data.optionBValue}</InDataRow>
      </InDataTd>
      <InDataTd>
        <InDataRowdash>{data.optionCPercent}</InDataRowdash>
        <InDataRow>{data.optionCValue}</InDataRow>
      </InDataTd>
      <InDataTd>
        <InDataRowdash>{data.optionDPercent}</InDataRowdash>
        <InDataRow>{data.optionDValue}</InDataRow>
      </InDataTd>
      <InDataTd>{data.chart}</InDataTd>
      <Modal
        key={data.title}
        onOk={handleOk}
        onCancel={handleCancel}
        title={currentItem.title}
        visible={isModalVisible}
      ></Modal>
    </TableDataRow>
  ));

  return (
    <HeroContainer>
      <Table>
        <TableRow>
          <th style={{ textAlign: "left" }}>Questions</th>
          {TableHeader}
        </TableRow>
        {DataRow}
      </Table>
    </HeroContainer>
  );
};
//
//StyledComponents

const HeroContainer = styled.div`
  width: 100%;
  padding: 0 10px;
  background: #ffffff;
  margin: 20px;
`;

const Table = styled.table`
  width: 100%;
  padding: 0 10px;
`;
const Th = styled.th`
  width: 12%;
  font-weight: bold;
  text-align: left;
  padding-left: 15px;
  font-size: 14px;
`;
const TableRow = styled.tr``;
const TableDataRow = styled.tr`
  border-bottom: 1.3px solid #00000039;
`;
const Td = styled.td`
  padding: 30px 30px;
  &:hover {
    cursor: pointer;
  }
`;
const InDataTd = styled.td`
  border-right: 1.3px solid #00000013;
  text-align: center;
`;
const InDataRow = styled.div`
  text-align: center;
`;
const InDataRowdash = styled.div`
  text-align: center;
  border-bottom: 1.3px dashed #00000029;
`;
