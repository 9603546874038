import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Spring } from "react-spring/renderprops";
import {
  SearchBar,
  SearchDate,
  SearchState,
} from "../../globalComponents/SearchBar";
import { HeroContainer, FilterContainer } from "../surveys/Styles";
import UserTable from "./Table/index";
import Navbar from "../../components/atoms/Navbar/index";
import { useDispatch, useSelector } from "react-redux";
import { searchUserAction } from "../../actions";
import { useStateLga } from "../../hooks/useStatesLgas";

import { PrimaryButton } from "../../globalComponents/buttons";
import BaseScaffold from "../BaseScaffold";
import styled from "styled-components";
import device from "../../utils/breakPoints";
import { Button, Space, Modal } from "antd";
import useDeleteUsers from "./hooks/useDeleteUsers";

const UserManagement = () => {

  const [states, lgas, setStateLgas] = useStateLga();
  const [stateInput, setStateInput] = useState("");
  const [datestartInput, setDateStartInput] = useState("");
  const [dateEndInput, setDateEndInput] = useState("");
  const [searchInputData, setSearchInputData] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const {
    deleteUsers,
    isDeletingUsers,
    deleteModalOpen,
    onDeleteModalCancel,
    openDeleteModal,
    selectedUserId, setSelectedUsers
  } = useDeleteUsers();
  const dispatcher = useDispatch();

  const onSearchInputChange = (event) => {
    setSearchInputData(event.target.value);
  };
  const dateFormat = "YYYY-MM-DD";

  const onSearchDateChange = (event) => {
    // setDateInput(event?.format(dateFormat));
    setDateStartInput(event[0]?.format(dateFormat));
    setDateEndInput(event[1]?.format(dateFormat));
  };

  const onSearchStateChange = (event) => {
    setStateInput(event.target.value);
  };

  const handleSearchUser = () => {
    setIsSearching(true);
    dispatcher(
      searchUserAction({
        start: datestartInput,
        end: dateEndInput,
        word: searchInputData,
        state: stateInput,
        page: 1,
        pageSize: 10,
      })
    );
  };

  const cancelSearch = () => {
    setIsSearching(false);
    setSearchInputData("");
  };

  const handleDeleteUsers = () => {};

  return (
    <BaseScaffold title="User Management">
      <FilterContainer>
        <StyledFilterContainer>
          <SearchBar
            placeholder="Enter the name of user"
            searchInputData={searchInputData}
            onSearchInputChange={onSearchInputChange}
            // onClick={handleSearchSurvey}
          />
          <SearchDate
            format={dateFormat}
            onSearchDateChange={onSearchDateChange}
            // onClick={handleDateSearch}
          />
          <SearchState
            onChange={onSearchStateChange}
            onClick={handleSearchUser}
            values={states}
          />
        </StyledFilterContainer>
        {/*  <Space> */}
        <StyledExportButton href="https://api.getsurveyplus.com/api/export-users">
          <PrimaryButton
            //style={{ width: "20vw" }}
            disabled
            admin
            buttonText="Export Participant Data"
          />
        </StyledExportButton>
        <Button
          onClick={openDeleteModal}
          type="primary"
          danger
          size="large"
          disabled={!Boolean(selectedUserId.length)}
        >
          Delete users
        </Button>
        {/*   </Space> */}
      </FilterContainer>

      <UserTable
        setSelectedUsers={setSelectedUsers}
        isSearching={isSearching}
        searchInputData={searchInputData}
        cancelSearch={cancelSearch}
      />
      <Modal
        title="Delete Users"
        open={deleteModalOpen}
        onCancel={onDeleteModalCancel}
        centered={true}
        onOk={() => deleteUsers(selectedUserId)}
        okButtonProps={{ loading: isDeletingUsers }}
      >
        Do you wish to delete selected users?
      </Modal>
    </BaseScaffold>
  );
};

const StyledFilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  @media ${device.tablet} {
    flex-wrap: nowrap;
    margin-bottom: 0px;
  }
`;

const StyledExportButton = styled.a`
  margin-left: 20px;
  @media ${device.tablet} {
    margin-left: 0px;
  }
`;

export default UserManagement;
