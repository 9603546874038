/* eslint-disable react/display-name */
import React  from 'react'
import {ArrowUpIcon, ArrowDownIcon, PlusIcon, MinusIcon} from '../../../components/atoms/icons/index'
import {QuestionTypes} from './CreateSurveyConstants'
import {
  SingleLineText,
  MultiLineText,
  MultiChoice,
  Checkboxes,
  Dropdown,
} from './QuestionComponents'

const questionMap = {
  [QuestionTypes.singleLineText]: (props) => (
    <SingleLineText {...props} />
  ),
  [QuestionTypes.multiLineText]: (props) => (
    <MultiLineText {...props} />
  ),
  [QuestionTypes.multiChoice]: (props) => (
    <MultiChoice {...props} />
  ),
  [QuestionTypes.checkboxes]: (props) => (
    <Checkboxes {...props} />
  ),
  [QuestionTypes.dropdown]: (props) => <Dropdown {...props} />,
}



export const QuestionPreviewWrapper = ({
  question,
  onActive,
  onRemove,
  isActive,
  onClone,
  onUp,
  onDown,
  showUp,
  showDown,
}) => {
  const QuestionComponent = questionMap[question.type](question)
  const classNames = isActive ? 'bg-gray-200 pt-2 px-2' : 'pt-2 px-2'

  return (
    <div onClick={onActive} className={classNames}>
      {QuestionComponent}
      <span
        className={`${
          isActive ? 'flex' : 'hidden'
        } relative z-0 flex justify-end shadow-sm mb-2`}
      >
        {showUp ? (
          <button
            type="button"
            onClick={onUp}
            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-green-800 leading-5 font-medium text-green-800 hover:text-green-700 focus:z-10 focus:outline-none focus:border-green-300 focus:shadow-outline-green active:bg-gray-100 active:text-green-800 transition ease-in-out duration-150"
          >
            <ArrowUpIcon className="h-5 w-5" />
          </button>
        ) : null}
        {showDown ? (
          <button
            type="button"
            onClick={onDown}
            className="-ml-px relative inline-flex items-center px-2 py-2 border border-gray-300 bg-green-800 leading-5 font-medium text-green-800 hover:text-green-700 focus:z-10 focus:outline-none focus:border-green-300 focus:shadow-outline-green active:bg-gray-100 active:text-green-800 transition ease-in-out duration-150"
          >
            <ArrowDownIcon className="h-5 w-5" />
          </button>
        ) : null}
        <button
          type="button"
          onClick={e => {
            e.stopPropagation()
            onClone()
          }}
          className="-ml-px relative inline-flex items-center px-2 py-2 border border-gray-300 bg-green-800 leading-5 font-medium text-green-800 hover:text-green-700 focus:z-10 focus:outline-none focus:border-green-300 focus:shadow-outline-green active:bg-gray-100 active:text-green-800 transition ease-in-out duration-150"
        >
          <PlusIcon className="h-5 w-5" />
        </button>
        <button
          type="button"
          onClick={onRemove}
          className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-green-800 leading-5 font-medium text-green-800 hover:text-green-700 focus:z-10 focus:outline-none focus:border-green-300 focus:shadow-outline-green active:bg-gray-100 active:text-green-800 transition ease-in-out duration-150"
        >
          <MinusIcon className="w-5 h-5" />
        </button>
      </span>
    </div>
  )
}
