import React from "react";
import styled from "styled-components";
import { Input } from "antd";
import { DatePicker, Space } from "antd";

import { adminColor, agentColor } from "./globalAccet";
import { device } from "./mediaQueries";

export const SearchBar = (props) => {
  return (
    <StyledSearchDiv style={{ borderRadius: "none" }}>
      <SearchInput style={{ borderRadius: "none" }}>
        <Input
          value={props.searchInputData}
          onChange={props.onSearchInputChange}
          placeholder={props.placeholder}
          bordered={false}
        />
      </SearchInput>
      {props.showButton && (
        <StyledButton
          disabled={props?.isLoading}
          isAgent={props?.isAgent}
          onClick={props.onClick}
        >
          {props?.isLoading ? "...Loading" : "Search"}
        </StyledButton>
      )}
    </StyledSearchDiv>
  );
};

export const SearchDate = (props) => {
  return (
    <StyledSearchDiv>
      <StyledSearchDate>
        <DatePicker.RangePicker
          format={props.format}
          value={props.searchInputData}
          onChange={props.onSearchDateChange}
          style={{ cursor: "pointer" }}
          bordered={false}
        />
      </StyledSearchDate>
      {/* <StyledButton onClick={props.onClick}>Search</StyledButton> */}
    </StyledSearchDiv>
  );
};

export const SearchState = ({ values, onClick, onChange }) => {
  return (
    <StyledSearchDiv>
      <StyledSearchDate>
        <Dropdown onChange={onChange}>
          <option value="">Select State</option>
          {values.map((value) => (
            <option key={value.key} value={value.text}>
              {value.text}
            </option>
          ))}
        </Dropdown>
      </StyledSearchDate>
      <StyledButton onClick={onClick}>Search</StyledButton>
    </StyledSearchDiv>
  );
};

const Dropdown = styled.select`
  &&& {
    outline: none;
    border: none;
    background: transparent;
    width: 100%;
    font-size: 14px;
    color: black;
    height: 100%;
    &:focus,
    &:active {
      outline: none;
      border: none;
    }
  }
`;

const StyledSearchDate = styled.div`
  width: 100%;
  display: block;
  border-left: 1px solid grey;
  height: 100%;
  outline: none;
  ${"" /* border: none; */}
  cursor: pointer;
  padding: 11px;
  box-shadow: 0px 4px 9px 0px rgba(204, 204, 204, 0.68);
  &:focus {
    outline: none;
    border: none;
    transition: all 500 ease-in-out;
  }
`;

const StyledSearchDiv = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
  @media ${device.laptop} {
    margin-top: 0px;
    width: 40%;
    margin-left: 10px;
  }
`;

const SearchInput = styled.div`
  display: block;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  outline: none;
  border: none;
  padding: 11px;
  width: 100%;
  box-shadow: 0px 4px 9px 0px rgba(204, 204, 204, 0.68);
  &:focus {
    outline: none;
    border: none;
    transition: all 500 ease-in-out;
  }

  @media ${device.laptop} {
    // width: ${({ agent }) => (agent ? "30rem" : "20rem")}
  }
`;

const StyledButton = styled.button`
  background-color: ${(props) => (props.isAgent ? agentColor : adminColor)};
  outline: none;
  border: none;
  color: #fff;
  padding: 15px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  box-shadow: 0px 4px 9px 0px rgba(204, 204, 204, 0.68);

  &:hover,
  &:focus {
    background-color: ${(props) => (props.isAgent ? agentColor : adminColor)};
    color: #fff;
    outline: none;
  }

  &:disabled {
    background-color: ${(props) => (props.isAgent ? agentColor : adminColor)};
    color: #fff;
    outline: none;
    opacity: 0.5;
  }

  &:hover {
    box-shadow: none;
  }
`;
