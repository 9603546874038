import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";

import {
  HeroContainer,
  RegisterContainer,
  Form,
  Title,
  StyledInput,
  Select,
  RegisterButton,
} from "../Styles";
import { useStateLga } from "../../../hooks/useStatesLgas";
import {
  singleParticipantRegisterAction,
  userFormDataAction,
  closeModalAction,
} from "../../../actions";

import BaseScaffold from "../../BaseScaffold";
const ShowRecord = () => {
  return <BaseScaffold title="Register Batch Participant"></BaseScaffold>;
};
export default ShowRecord;
