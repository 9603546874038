import { MANAGE_AGENT } from "../constants/index";

export const agentListReducer = (
  state = { agentList: [], pages: 1, totalSurvey: 0 },
  { type, payload }
) => {
  if (type === MANAGE_AGENT.AGENT_LIST) {
    let tempState = { ...state };
    tempState.agentList = payload.users;
    tempState.pages = payload.pages;
    return tempState;
  }
  return state;
};
