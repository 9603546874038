import { MANAGE_SURVEY } from '../constants';
import moment from 'moment';

const initialState = {
  surveyCreatorInfo: {
    name: '',
    date: '',
    time: '',
    phoneNumber: '',
    email: '',
  },

  surveyDataInfo: [
    { title: 'Agents Data Upload', stats: '0' },
    { title: 'Direct Data Upload', stats: '0' },
    { title: 'Required Data', stats: '0' },
    { title: 'Total Data Upload', stats: '0' },
  ],
};
export const surveyDetailReducer = (
  state = initialState,
  { type, payload }
) => {
  if (type === MANAGE_SURVEY.SURVEY_DETAIL) {
    const surveyCreatorInfo = {};
    const {
      agent_responds,
      daate_created,
      email,
      name,
      needed_responds,
      phone,
      total_responds,
      user_responds,
    } = payload;

    surveyCreatorInfo.name = name;
    surveyCreatorInfo.phoneNumber = phone;
    surveyCreatorInfo.email = email;
    surveyCreatorInfo.date = moment(daate_created, 'DD/MM/YYYY').format("dddd, MMMM Do YYYY");

   
    let surveyDataInfo = [
        { title: 'Agents Data Upload', stats: agent_responds},
        { title: 'Direct Data Upload', stats: user_responds },
        { title: 'Required Data', stats: needed_responds },
        { title: 'Total Data Upload', stats:  total_responds}
    ]

    return {surveyCreatorInfo, surveyDataInfo};

    
  }

  return state;
};
