import React from "react";
import styled from "styled-components";
import { Button } from "antd";

export const PrimaryButton = ({ admin, onClick, buttonText, ...props }) => {
  return (
    <StyledContainer {...props}>
      <StyledButton admin={admin} type="submit" size="large" onClick={onClick}>
        {buttonText}
      </StyledButton>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  min-width: 100%;
  display: grid;
  place-items: center;
`;

const StyledButton = styled(Button)`
  color: #fff;
  border: none;
  background-color: ${({ admin }) => (admin ? "#249B00" : "#0E6180")};
  //margin-top: 10px;
  //width: 85%;

  &:hover,
  &:focus {
    background-color: ${({ admin }) => (admin ? "#258405" : "#0E6180")};
    color: #fff;
    border: none;
  }
`;
