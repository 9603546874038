import React, { useState, useEffect, useMemo } from "react";
import { DeleteOutlined, EditFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Navbar, Menu, MenuItem, StyledBar } from "../atoms/TableNavs";
import SurveyTable from "../atoms/Table";
import { useHistory, useRouteMatch } from "react-router-dom";
import { HeroContainer } from "./Styles";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import styled from "styled-components";
import { device } from "../../globalComponents/mediaQueries";
import { adminColor } from "../../globalComponents/globalAccet";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchActiveSurveyAction,
  fetchAllSurveyAction,
  fetchCompletedSurveyAction,
  fetchRecentSurveyAction,
  searchSurveyAction,
} from "../../actions";
import { Table as AntTable } from "antd";

const Table = ({
  isTemplate,
  toggleTemplate,
  templateData,
  onEditTemplate,
  onDeleteTemplate,
  isSearching,
  searchInputData,
  cancelSearch,
}) => {
  const [activeTab, setActiveTab] = useState(1);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const surveyList = useSelector((state) => state.surveyList);
  const { url, path } = useRouteMatch();
  const dispatcher = useDispatch();

  useEffect(() => {
    dispatcher(fetchRecentSurveyAction({ page: 1, limit: pageSize }));
    setActiveTab(1);
  }, []);

  const onDeleteSurvey = (id, surveyName) => {};

  const templateHeaders = [
    { title: "S/N", dataIndex: "sn", key: "sn" },
    {
      title: "Template name",
      dataIndex: "template_name",
      key: "template_name",
      render: (name) => <StyledTemplateName>{name}</StyledTemplateName>,
    },
    { title: "No. of Questions", dataIndex: "number", key: "number" },
    {
      title: "Edit Question",
      dataIndex: "edit",
      key: "edit",
      render: (id) => <StyledFiEdit onClick={() => onEditTemplate(id)} />,
    },
    {
      title: "Delete Template",
      dataIndex: "delete",
      key: "delete",
      render: (id, template) => (
        <StyledFiTrash2
          onClick={() => onDeleteTemplate(id, template.template_name)}
        />
      ),
    },
  ];

  const isAdmin = useMemo(() => localStorage.user_type === "admin", []);

  const surveyHeaders = [
    { title: "Survey ID", dataIndex: "id", key: "id" },
    { title: "Survey Name", dataIndex: "survey_name", key: "survey_name" },
    { title: "Created by", dataIndex: "created_by", key: "created_by" },
    { title: "Phone Number", dataIndex: "phone", key: "phone" },
    {
      title: "Number of Participants",
      dataIndex: "num_participant",
      key: "num_participant",
    },
  ];

  const onViewRecentSurveys = () => {
    setActiveTab(1);
    setCurrentPageNumber(1);
    dispatcher(fetchRecentSurveyAction({ page: 1, limit: pageSize }));
  };

  const handleBacktoTable = () => {
    cancelSearch();
    onViewRecentSurveys();
  };

  const onViewAllSurvey = () => {
    setActiveTab(2);
    setCurrentPageNumber(1);
    dispatcher(fetchAllSurveyAction({ page: 1, limit: pageSize }));
  };

  const onViewActiveSurvey = () => {
    setActiveTab(3);
    setCurrentPageNumber(1);
    dispatcher(fetchActiveSurveyAction({ page: 1, limit: pageSize }));
  };

  const onViewCompletedSurvey = () => {
    setActiveTab(4);
    setCurrentPageNumber(1);
    dispatcher(fetchCompletedSurveyAction({ page: 1, limit: pageSize }));
  };

  const handleTablePagination = (pageNumber, pageSize) => {
    console.log({ pageNumber, pageSize });
    setCurrentPageNumber(pageNumber);
    setPageSize(pageSize);
    if (isSearching) {
      dispatcher(
        searchSurveyAction({
          word: searchInputData,
          page: pageNumber,
          pageSize,
        })
      );
    } else if (activeTab === 1) {
      dispatcher(
        fetchRecentSurveyAction({ page: pageNumber, limit: pageSize })
      );
    } else if (activeTab === 2) {
      dispatcher(fetchAllSurveyAction({ page: pageNumber, limit: pageSize }));
    } else if (activeTab === 3) {
      dispatcher(
        fetchActiveSurveyAction({ page: pageNumber, limit: pageSize })
      );
    } else if (activeTab === 4) {
      dispatcher(
        fetchCompletedSurveyAction({ page: pageNumber, limit: pageSize })
      );
    }
  };
  const tableBarData = [
    { title: "Recent Surveys", func: onViewRecentSurveys, key: 1 },
    { title: "All Surveys", func: onViewAllSurvey, key: 2 },
    { title: "Active Surveys", func: onViewActiveSurvey, key: 3 },
    { title: "Completed", func: onViewCompletedSurvey, key: 4 },
  ];

  /* const onViewSurveyDetail = (survey_name) => ({
    onClick: () =>
      history.push({
        pathname: `/dashboard/survey-management/survey/${survey_name.id}`,
        state: { survey_name },
      }),
  }) */

  const handleViewSurveyDetail = (singleSurveyDetail) => {
    history.push(
      `/dashboard/survey-management/survey/${singleSurveyDetail.id}`
    );
  };

  const handleRowClick = (singleSurveyDetail, rowIndex) => ({
    onClick: () => handleViewSurveyDetail(singleSurveyDetail),
  });
  let history = useHistory();

  const onShowSizeChange = (value, pageSize) => {
    dispatcher(fetchRecentSurveyAction({ page: 1, limit: pageSize }));
    setCurrentPageNumber(1);
    setPageSize(pageSize);
  };

  

  let view = isTemplate ? (
    <SurveyTable
      className="table-striped-rows"
      column={templateHeaders}
      data={templateData}
    />
  ) : (
    <>
      <StyledTableBar>
        {!isSearching ? (
          <>
            <StyledMenu active={activeTab === 1} onClick={onViewRecentSurveys}>
              Recent Surveys
            </StyledMenu>
            {isAdmin && (
              <StyledMenu active={activeTab === 2} onClick={onViewAllSurvey}>
                All Surveys
              </StyledMenu>
            )}
            <StyledMenu active={activeTab === 3} onClick={onViewActiveSurvey}>
              Active Surveys
            </StyledMenu>
            {isAdmin && (
              <StyledMenu
                active={activeTab === 4}
                onClick={onViewCompletedSurvey}
              >
                Completed
              </StyledMenu>
            )}
          </>
        ) : (
          <StyledMenu
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            Search Result
            <p
              onClick={handleBacktoTable}
              style={{ marginLeft: "auto", marginBottom: "0px" }}
            >
              Cancel
            </p>
          </StyledMenu>
        )}
      </StyledTableBar>

      <StyledTableContainer>
        <AntTable
          columns={surveyHeaders}
          dataSource={surveyList.surveys}
          onRow={handleRowClick}
          style={{ cursor: "pointer" }}
          pagination={{
            defaultCurrent: currentPageNumber,
            total: pageSize * surveyList.pages,
            current: currentPageNumber,
            onChange: handleTablePagination,
            showSizeChanger: true,
          }}
        />
      </StyledTableContainer>
    </>
  );

  return <HeroContainer>{view}</HeroContainer>;
};

const icon = `
  color: red;
  font-size: 16px;
  cursor: pointer;
  @media ${device.laptop}{
    font-size: 1.2vw;
  }
`;
const StyledFiTrash2 = styled(FiTrash2)`
  ${icon}
`;

const StyledFiEdit = styled(FiEdit)`
  ${icon}
  color: blue;
`;
const StyledTemplateName = styled.p`
  font-size: 16px;

  @media ${device.laptop} {
    font-size: 1vw;
  }
`;

const StyledMenu = styled.div`
  cursor: pointer;
  padding: 5px 10px;
  font-size: 10px;
  font-weight: 500;

  background-color: ${(props) => (props.active ? "#fff" : "transparent")};
  color: ${(props) => (props.active ? "green" : "#fff")};
  ${"" /* transition: visibility 0s, opacity 0.5s linear; */}
  &:hover {
    ${"" /* background-color: #fff; */}
    color: green;
  }

  &:first-child {
    padding-left: 10px;
  }

  @media ${device.laptop} {
    font-size: 1vw;
  }
`;

const StyledTableBar = styled.div`
  display: flex;
  padding: 0px 13px;
  background-color: ${adminColor};
  color: #fff;
`;

const StyledTableContainer = styled.div`
  width: 100vw;
  overflow: auto;
  background-color: #fff;
`;

export default Table;
