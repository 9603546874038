import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Spinner } from "../../components/atoms/Spinner";
import {
  blockUserAction,
  unblockUserAction,
  fetchUserProfileAction,
  fetchPaymentHistory,
  fetchPaidSurveyhistoryAction,
  blockUserNotificationAction,
  unblockUserNotificationAction,
} from "../../actions/index";
import styled from "styled-components";
import { Button as AntButton, Modal } from "antd";
import { notificationAlert } from "../../functions/alertNotification";
import device from "../../utils/breakPoints";

export const UserInfo = ({ image, name, descriptions, userType }) => {
  const { id } = useParams();
  const userProfile = useSelector((state) => state.userProfile);
  const blockUserNotification = useSelector(
    (state) => state.blockUserNotification
  );
  const unblockUserNotification = useSelector(
    (state) => state.unblockUserNotification
  );
  const paidSurveyHistory = useSelector((state) => state.paidSurveyHistory);
  const [userName, setUserName] = useState("");
  const [delayMessage, setDelayMessage] = useState("");

  const [blockStatus, setBlockStatus] = useState();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const history = useHistory();
  const dispatcher = useDispatch();
  const state = history.location.state;

  const onDeleteModalCancel = () => {
    setDeleteModalVisible(false);
  };

  useEffect(() => {
    if (blockUserNotification !== "") {
      if (blockUserNotification === "success") {
        notificationAlert(
          "success",
          "User blocked",
          `User ${userName} has been blocked`
        );
      }
      if (blockUserNotification === "error") {
        notificationAlert("error", "Failed", "Please try again");
        // dispatcher(fetchUserProfileAction(id));
      }
      // dispatcher(blockUserNotificationAction(""));
      dispatcher(fetchUserProfileAction(id));
    }
  }, [blockUserNotification]);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setDelayMessage("Please Wait, still loading...");
    }, 10000);
    const timer2 = setTimeout(() => {
      setDelayMessage("Loading, almost done!");
    }, 20000);
    return () => clearTimeout(timer1, timer2);
  }, []);

  useEffect(() => {
    dispatcher(fetchUserProfileAction(id));
    dispatcher(fetchPaymentHistory({ id: id, page: 1 }));
    // dispatcher(fetchPaidSurveyhistoryAction({ id: id, page: 1 }));
  }, []);

  useEffect(() => {
    if (unblockUserNotification !== "") {
      if (unblockUserNotification === "success") {
        notificationAlert(
          "success",
          "User Unblocked",
          `User ${userName} has been unblocked`
        );
      }
      if (unblockUserNotification === "error") {
        notificationAlert("error", "Failed", "Please try again");
      }
      // dispatcher(unblockUserNotificationAction(""));
      dispatcher(fetchUserProfileAction(id));
    }
  }, [unblockUserNotification]);

  let userInfo = (
    <Description>
      {descriptions.map((description) => (
        <DescriptionItem key={description.id}>
          <DescriptionTitle>{description.title}</DescriptionTitle>
          <DescriptionValue style={{ float: "right" }}>
            {description.value}
          </DescriptionValue>
        </DescriptionItem>
      ))}
    </Description>
  );
  // console.log("state is ", state);
  let access =
    userProfile.blocked_status === 0 ? (
      <BlockButton
        onClick={() =>
          //  dispatcher(blockUserAction(others.id));
          {
            setDeleteModalVisible(true);
            setUserName(userProfile.name);
            setBlockStatus(0);
            // setId(others.id);
          }
        }
      >
        Block User
      </BlockButton>
    ) : (
      <UnBlockButton
        onClick={() => {
          setDeleteModalVisible(true);
          setUserName(userProfile.name);
          setBlockStatus(1);
          // setId(others.id);
        }}
      >
        Unblock User
      </UnBlockButton>
    );
  let modalComponent = (
    <Modal
      title="Delete User"
      visible={deleteModalVisible}
      onCancel={onDeleteModalCancel}
      centered={true}
      onOk={() => {
        onDeleteModalCancel();
        blockStatus === 0
          ? dispatcher(blockUserAction(id))
          : dispatcher(unblockUserAction(id));
      }}
    >
      Do you wish to {blockStatus === 0 ? "block" : "unblock"} {name}?
    </Modal>
  );
  let profileSpinner = userProfile ? (
    <Container>
      {image}
      <Name>{name}</Name>
      <Title>{userType}</Title>
      {userInfo}
      {access}
    </Container>
  ) : (
    <SpinnerContainer>
      <Spinner tip={delayMessage} />
    </SpinnerContainer>
  );
  return (
    <>
      {profileSpinner}
      {modalComponent}
    </>
  );
};

const Container = styled.div`
  background-color: #ffffff;
  box-shadow: 1px 3px 6px #00000009;
  height: fit-content;
  margin-top: 10px;
  text-align: center;

  @media ${device.laptop} {
     padding: 25px 5px;
  }
`;
const SpinnerContainer = styled.div`
  background-color: #ffffff;
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: auto auto;
`;
const Name = styled.h5`
  font-weight: 500;
  margin-top: 15px;
`;
const Title = styled.h5`
  font-size: 15px;
  color: #0f6281;
  margin-top: 15px;
`;
const Description = styled.div`
  margin-top: 20px;
`;
const DescriptionItem = styled.div`
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
`;
const DescriptionTitle = styled.h5`
  color: #5a5a5a;
  font-size: 14px;
`;
const DescriptionValue = styled.p`
  color: #000000;
  font-size: 14px;
  text-align: center;
`;
export const BlockButton = styled(AntButton)`
  background: #c82e29;
  color: white;
  width: 98px;
  ${"" /* text-align: center; */}
  border-radius: 20px;
  outline: none;
  border: none;
  &:focus,
  &:hover {
    background: #c82e29;
    color: white;
  }
`;

export const UnBlockButton = styled(AntButton)`
  background: #72be00;
  color: white;
  ${"" /* width: 98px; */}
  ${"" /* text-align: center; */}
  border-radius: 20px;
  outline: none;
  border: none;
  &:focus,
  &:hover {
    background: #72be00;
    color: white;
  }
`;
