import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";

import {
  HeroContainer,
  RegisterContainer,
  Form,
  Title,
  StyledInput,
  Select,
  RegisterButton,
} from "./Styles";
import { useStateLga } from "../hooks/useStatesLgas";
import {
  singleParticipantRegisterAction,
  userFormDataAction,
  closeModalAction,
} from "../actions/index";

const Register = ({ title, admin }) => {
  const dispatcher = useDispatch();
  const [states, lgas, setStateLgas] = useStateLga();
  const userFormData = useSelector((state) => state.userFormData);

  const onFinish = (values) => {
    console.log("Success:", values);
    dispatcher(singleParticipantRegisterAction(userFormData));
    dispatcher(closeModalAction(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onStateChange = (event) => {
    setStateLgas(event.currentTarget.value);
    console.log(event.currentTarget.value);
  };

  const handleInputChange = (e) => {
    const data = {};
    data[e.target.name] = e.target.value;
    dispatcher(userFormDataAction(Object.entries(data).pop()));
  };

  return (
    <HeroContainer>
      <RegisterContainer>
        {/* <Logo src={SurveyPlus} /> */}
        <Title admin={admin}>{title}</Title>
        <Form
          layout="vertical"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Full Name"
            name="full_name"
            rules={[
              {
                required: true,
                message: "Please input Full Name!",
              },
            ]}
          >
            <StyledInput
              agent={true}
              bordered={false}
              placeholder="FullName"
              name="full_name"
              onChange={handleInputChange}
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
              {
                type: "email",
                message: "Please enter a valid email",
              },
            ]}
          >
            <StyledInput
              bordered={false}
              agent={true}
              placeholder="Email"
              name="email"
              onChange={handleInputChange}
              prefix={<MailOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            label="PhoneNumber"
            name="phone"
            rules={[
              {
                required: true,
                message: "Please input Phone Number!",
              },
            ]}
          >
            <StyledInput
              bordered={false}
              agent={true}
              placeholder="PhoneNumber"
              name="phone"
              onChange={handleInputChange}
              prefix={<PhoneOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            label="State"
            name="state"
            rules={[
              {
                required: true,
                message: "Please select state of residence!",
              },
            ]}
          >
            <Select placeholder="Select State" onChange={onStateChange}>
              <option value="" />
              {states.map((ngState) => (
                <option key={ngState.key} value={ngState.value}>
                  {ngState.text}
                </option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="LGA"
            name="lga"
            rules={[
              {
                required: true,
                message: "Please select local government",
              },
            ]}
          >
            <Select placeholder="Select Local Government">
              {lgas.map((stateLga) => (
                <option key={stateLga.key} value={stateLga.text}>
                  {stateLga.text}
                </option>
              ))}
            </Select>
          </Form.Item>
          <RegisterButton
            htmlType="submit"
            // onClick={handleUserRegistration}
            admin={admin}
            /*    loading={true} */
          >
            Register
          </RegisterButton>
        </Form>
      </RegisterContainer>
    </HeroContainer>
  );
};
export default Register;
