import React from 'react';
import styled from 'styled-components';
import { Board } from './Board';
import { SingleQuestionScaffold } from './elements/singleQuestionScaffold';
import { RadioGroupQuestion } from './molecule/RadioGroupQuestion';
import { SingleLineQuestion } from './molecule/SingleLineQuestion';
import { MultiLineQuestion } from './molecule/MultiLineQuestion';
import { DropDownQuestion } from './molecule/DropDownQuestion';
import { CheckBoxQuestion } from './molecule/CheckBoxQuestion';
import {useQuestionContext} from './questionContext';
import {questionTypes} from './questionTypes'
import {device} from './../../../globalComponents/mediaQueries';
import {useSelector, useDispatch} from 'react-redux';
import { deleteAgentAction } from '../../../actions';
import { EditTemplate } from './molecule/EditTemplateTitleForm';


export const  QuestionDashboard = (props) => {
    const {questionList, setQuestion} = useQuestionContext();
    const activeTemplate = useSelector(state => state.activeTemplate);
    const [showTemplateNameModal, setShowTemplateNameModal] = React.useState(false);

    const dispatcher = useDispatch();

    console.log({questionList});

    const questionListArray = [...questionList]?.map((item, index) => {

        switch(item.type){
            case questionTypes.singleLine:
                return <SingleLineQuestion 
                            key={index} 
                            questionObj={item} 
                            questionNumber={index + 1}
                         
                        />
            case questionTypes.multipleLine:
                return <MultiLineQuestion 
                            key={index} 
                            questionObj={item} 
                            questionNumber={index + 1}
                        />
            case questionTypes.checkBox:
                return <CheckBoxQuestion
                            key={index} 
                            questionObj={item} 
                            questionNumber={index + 1}
                        />
            case questionTypes.dropDown:
                return <DropDownQuestion
                            key={index} 
                            questionObj={item} 
                            questionNumber={index + 1}
                        />
            case questionTypes.radioGroup:
                return  <RadioGroupQuestion
                            key={index} 
                            questionObj={item} 
                            questionNumber={index + 1}                    
                        />
            default:
                return null;
        }
        
    })



    return (
        <Board title='Questionnaire Dashboard'>
            <StyledTitle >{props.surveyName}</StyledTitle>
            {questionListArray}
            
        </Board>
    );
}

const StyledQuestionDashoboard = styled.div`
`
const StyledTitle = styled.p`
    text-align: center;
    font-size: 18px;

    @media ${device.laptop}{
        font-size: 1.5vw;
    }
`