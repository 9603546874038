import {
  QUESTION_SURVEY,
  UPLOAD_EXCEL,
  MANAGE_AGENT,
  LOGIN,
  MANAGE_PARTICIPANTS,
  OVERVIEW,
  SURVEY_TEMPLATE,
  MANAGE_SURVEY,
  MANAGE_USER,
  UTILS,
} from "../constants/index";

const AddQuestionAction = (payload) => ({
  type: QUESTION_SURVEY.ADD_QUESTION,
  payload,
});

const CloneQuestionAction = (payload) => ({
  type: QUESTION_SURVEY.CLONE_QUESTION,
  payload,
});
const SetActiveQuestionAction = (payload) => ({
  type: QUESTION_SURVEY.SET_ACTIVE_QUESTION,
  payload,
});
const RemoveQuestionAction = (payload) => ({
  type: QUESTION_SURVEY.REMOVE_QUESTION,
  payload,
});
const SortQuestionUpAction = (payload) => ({
  type: QUESTION_SURVEY.SORT_QUESTION_UP,
  payload,
});
const SortQuestionDownAction = (payload) => ({
  type: QUESTION_SURVEY.SORT_QUESTION_DOWN,
  payload,
});

const EditQuestion = (payload) => ({
  type: QUESTION_SURVEY.EDIT_QUESTION,
  payload,
});

const EditSurveyHeader = (payload) => ({
  type: QUESTION_SURVEY.EDIT_SURVEY_HEADER,
  payload,
});

const importExcelAction = (payload) => ({
  type: UPLOAD_EXCEL.IMPORT,
  payload,
});

const registerAgentAction = (payload) => ({
  type: MANAGE_AGENT.REGISTER,
  payload,
});

const registerAgentNotificationAction = (payload) => ({
  type: MANAGE_AGENT.REGISTERED_NOTIFICATION,
  payload,
});

const addEmailAction = (payload) => ({
  type: LOGIN.EMAIL,
  payload,
});

const addPasswordAction = (payload) => ({
  type: LOGIN.PASSWORD,
  payload,
});

const adminLoginAction = (payload) => ({
  type: LOGIN.ADMIN,
  payload,
});

const agentLoginAction = (payload) => ({
  type: LOGIN.AGENT,
  payload,
});

const loginNotificationAction = (payload) => ({
  type: LOGIN.NOTIFICATION,
  payload,
});

const singleParticipantRegisterAction = (payload) => ({
  type: MANAGE_PARTICIPANTS.SINGLEREGISTER,
  payload,
});

const batchParticipantRegisterAction = (payload) => ({
  type: MANAGE_PARTICIPANTS.BATCHREGISTER,
  payload,
});

const fetchAllAgentAction = (payload) => ({
  type: MANAGE_AGENT.FETCH_ALL_AGENT,
  route: "all",
  payload,
});

const fetchRecentAgentAction = (payload) => ({
  type: MANAGE_AGENT.FETCH_RECENT_AGENT,
  route: "recent",
  payload,
});

const agentListAction = (payload) => ({
  type: MANAGE_AGENT.AGENT_LIST,
  payload,
});

const userFormDataAction = (payload) => ({
  type: MANAGE_PARTICIPANTS.USERFORMDATA,
  payload,
});

const userRegisterNotificationAction = (payload) => ({
  type: MANAGE_PARTICIPANTS.USER_REGISTER_NOTIFICATION,
  payload,
});

const userBatchRegisterNotificationAction = (payload) => ({
  type: MANAGE_PARTICIPANTS.USER_BATCH_REGISTER_NOTIFICATION,
  payload,
});

const deleteAgentAction = (payload) => ({
  type: MANAGE_AGENT.DELETE,
  payload,
});

const deleteAgentNotificationAction = (payload) => ({
  type: MANAGE_AGENT.DELETE_NOTIFICATION,
  payload,
});

const fetchAdminOverviewAction = () => ({
  type: OVERVIEW.FETCH_ADMIN_OVERVIEW,
});

const adminOverviewAction = (payload) => ({
  type: OVERVIEW.ADMIN_OVERVIEW,
  payload,
});

const fetchSurveyAction = () => ({
  type: OVERVIEW.FETCH_SURVEY,
});

const surveyOverViewAction = (payload) => ({
  type: OVERVIEW.SURVEY,
  payload,
});

const loginParticipantAction = (payload) => ({
  type: MANAGE_PARTICIPANTS.LOGIN,
  payload,
});

const participantDetailsAction = (payload) => ({
  type: MANAGE_PARTICIPANTS.PARTICIPANT_DETAILS,
  payload,
});

const createSurveyTemplateAction = (payload) => ({
  type: SURVEY_TEMPLATE.CREATE,
  payload,
});

const createSurveyTemplateNotificationAction = (payload) => ({
  type: SURVEY_TEMPLATE.NOTIFICATION,
  payload,
});

const setActiveTemplateIdAction = (payload) => ({
  type: SURVEY_TEMPLATE.SET_ACTIVE_TEMPLATE_ID,
  payload,
});

const setActiveTemplateNameAction = (payload) => ({
  type: SURVEY_TEMPLATE.SET_ACTIVE_TEMPLATE_NAME,
  payload,
});

const fetchSingleTemplateAction = (payload) => ({
  type: SURVEY_TEMPLATE.FETCH_SINGLE_TEMPLATE,
  payload,
});

const setSingleTemplateAction = (payload) => ({
  type: SURVEY_TEMPLATE.SET_SINGLE_TEMPLATE,
  payload,
});

const saveTemplateQuestionAction = (payload) => ({
  type: SURVEY_TEMPLATE.SAVE_TEMPLATE_QUESTION,
  payload,
});

const saveTemplateQuestionNotifyAction = (payload) => ({
  type: SURVEY_TEMPLATE.SAVE_TEMPLATE_QUESTION_NOTIFY,
  payload,
});

const setTemplateQuestionnaireAction = (payload) => ({
  type: SURVEY_TEMPLATE.SET_TEMPLATE_QUESTIONNAIRE,
  payload,
});

const setTemplateScreeningQuestionAction = (payload) => ({
  type: SURVEY_TEMPLATE.SET_TEMPLATE_SCREENING_QUESTION,
  payload,
});

const fetchTemplateListAction = (payload) => ({
  type: SURVEY_TEMPLATE.FETCH_TEMPLATE_LIST,
  payload,
});

const setTemplateListAction = (payload) => ({
  type: SURVEY_TEMPLATE.SET_TEMPLATE_LIST,
  payload,
});

const deleteTemplateAction = (payload) => ({
  type: SURVEY_TEMPLATE.DELETE_TEMPLATE,
  payload,
});

const deleteTemplateNotificationAction = (payload) => ({
  type: SURVEY_TEMPLATE.DELETE_NOTIFICATION,
  payload,
});

const deleteQuestionNotificationAction = (payload) => ({
  type: SURVEY_TEMPLATE.DELETE_QUESTION_NOTIFICATION,
  payload,
});

const deleteQuestionAction = (payload) => ({
  type: SURVEY_TEMPLATE.DELETE_QUESTION,
  payload,
});

const fetchRecentSurveyAction = (payload) => ({
  type: MANAGE_SURVEY.FETCH_RECENT_SURVEY,
  payload,
  route: "recent",
});
const fetchAllSurveyAction = (payload) => ({
  type: MANAGE_SURVEY.FETCH_ALL_SURVEY,
  payload,
  route: "all",
});

const fetchActiveSurveyAction = (payload) => ({
  type: MANAGE_SURVEY.FETCH_ACTIVE_SURVEY,
  payload,
  route: "active",
});

const fetchCompletedSurveyAction = (payload) => ({
  type: MANAGE_SURVEY.FETCH_COMPLETED_SURVEY,
  payload,
  route: "completed",
});

const surveyListAction = (payload) => ({
  type: MANAGE_SURVEY.SURVEY_LIST,
  payload,
});

const fetchRecentUsersAction = (payload) => ({
  type: MANAGE_USER.FETCH_RECENT_USER,
  payload,
  route: "recent",
});

const fetchAllUsersAction = (payload) => ({
  type: MANAGE_USER.FETCH_ALL_USER,
  payload,
  route: "all",
});

const fetchAllParticipantUsersAction = (payload) => ({
  type: MANAGE_USER.FETCH_ALL_PARTICIPANT,
  payload,
  route: "participants",
});

const fetchAllSponsorUsersAction = (payload) => ({
  type: MANAGE_USER.FETCH_ALL_SPONSOR,
  payload,
  route: "sponsors",
});

const fetchAllDeletedUsersAction = (payload) => ({
  type: MANAGE_USER.FETCH_DELETED_USER,
  payload,
  route: "deleted",
});

const userListAction = (payload) => ({
  type: MANAGE_USER.USER_LIST,
  payload,
});

const fetchSurveyDetailAction = (payload) => ({
  type: MANAGE_SURVEY.FETCH_SURVEY_DETAIL,
  payload,
});

const surveyDetailAction = (payload) => ({
  type: MANAGE_SURVEY.SURVEY_DETAIL,
  payload,
});

const blockUserAction = (payload) => ({
  type: MANAGE_USER.BLOCK_USER,
  payload,
});

const unblockUserAction = (payload) => ({
  type: MANAGE_USER.UNBLOCK_USER,
  payload,
});

const blockUserNotificationAction = (payload) => ({
  type: MANAGE_USER.BLOCK_USER_NOTIFICATION,
  payload,
});

const unblockUserNotificationAction = (payload) => ({
  type: MANAGE_USER.UNBLOCK_USER_NOTIFICATION,
  payload,
});

const fetchUserProfileAction = (payload) => ({
  type: MANAGE_USER.FETCH_USER_PROFILE,
  payload,
});

const userProfileAction = (payload) => ({
  type: MANAGE_USER.USER_PROFILE,
  payload,
});

const fetchPaymentHistory = (payload) => ({
  type: MANAGE_USER.FETCH_PAYMENT_HISTORY,
  payload,
});
const paymentHistory = (payload) => ({
  type: MANAGE_USER.PAYMENT_HISTORY,
  payload,
});

const fetchSurveyAnalysisAction = (payload) => ({
  type: MANAGE_SURVEY.FETCH_SURVEY_ANALYSIS,
  payload,
});

const surveyAnalysisAction = (payload) => ({
  type: MANAGE_SURVEY.SURVEY_ANALYSIS,
  payload,
});

const fetchSurveyAssignedUsers = (payload) => ({
  type: MANAGE_SURVEY.FETCH_SURVEY_ASSIGNED_USER,
  payload,
});

const fetchSurveyRespondedUser = (payload) => ({
  type: MANAGE_SURVEY.FETCH_SURVEY_RESPONDED_USER,
  payload,
});

const assignedSurveyAction = (payload) => ({
  type: MANAGE_SURVEY.ASSIGNED_SURVEY,
  payload,
});

const surveyTakenParticipantsAction = (payload) => ({
  type: MANAGE_SURVEY.SURVEY_TAKEN_PARTICIPANTS,
  payload,
});
const fetchPaidSurveyhistoryAction = (payload) => ({
  type: MANAGE_USER.FETCH_PAID_SURVEY_HISTORY,
  payload,
});

const paidSurveyHistoryAction = (payload) => ({
  type: MANAGE_USER.PAID_SURVEY_HISTORY,
  payload,
});

const updateTemplateNameAction = (payload) => ({
  type: SURVEY_TEMPLATE.UPDATE_TITLE,
  payload,
});

const updateTemplateNameNotificationAction = (payload) => ({
  type: SURVEY_TEMPLATE.UPDATE_TITLE_NOTIFICATION,
  payload,
});

const participantLoginAction = (payload) => ({
  type: LOGIN.PARTICIPANT,
  payload,
});

const participantLoginNotificationAction = (payload) => ({
  type: LOGIN.PARTICIPANT_LOGIN_NOTIFICATION,
  payload,
});

const openModalAction = (payload) => ({
  type: UTILS.MODALOPEN,
  payload,
});

const closeModalAction = (payload) => ({
  type: UTILS.MODALCLOSE,
  payload,
});

const fetchParticipantInfoAction = () => ({
  type: MANAGE_PARTICIPANTS.FETCH_PARTICIPANT_INFO,
});

const fetchParticipantAssignedSurveyAction = (payload) => ({
  type: MANAGE_PARTICIPANTS.FETCH_PARTICIPANT_ASSIGNED_SURVEY,
  payload,
});

const participantAssignedSurveyListAction = (payload) => ({
  type: MANAGE_PARTICIPANTS.PARTICIPANT_ASSIGNED_SURVEY_LIST,
  payload,
});

const fetchParticipantSingleSurveyAction = (payload) => ({
  type: MANAGE_PARTICIPANTS.FETCH_PARTICIPANT_SINGLE_SURVEY,
  payload,
});

const participantSingleSurveyAction = (payload) => ({
  type: MANAGE_PARTICIPANTS.PARTICIPANT_SINGLE_SURVEY,
  payload,
});

const submitScreeningResponseAction = (payload) => ({
  type: MANAGE_PARTICIPANTS.SUBMIT_SCREENING_RESPONSE,
  payload,
});

const submitMainResponseAction = (payload) => ({
  type: MANAGE_PARTICIPANTS.SUBMIT_MAIN_RESPONSE,
  payload,
});

const screeningResponseNotifyAction = (payload) => ({
  type: MANAGE_PARTICIPANTS.SCREENING_RESPONSE_NOTIFY,
  payload,
});

const mainResponseNotifyAction = (payload) => ({
  type: MANAGE_PARTICIPANTS.MAIN_RESPONSE_NOTIFY,
  payload,
});

const fetchSurveyForAgentAction = () => ({
  type: MANAGE_AGENT.FETCH_SURVEY_FOR_AGENT,
});

const surveyForAgentAction = (payload) => ({
  type: MANAGE_AGENT.SURVEY_FOR_AGENT,
  payload,
});

const fetchParticipantForSurveyAction = (payload) => ({
  type: MANAGE_AGENT.FETCH_PARTICIPANT_FOR_SURVEY,
  payload,
});

const participantForSurveyAction = (payload) => ({
  type: MANAGE_AGENT.PARTICIPANT_FOR_SURVEY,
  payload,
});

const fetchRecentlyRegisteredUsersAction = (payload) => ({
  type: MANAGE_USER.FETCH_RECENTLY_REGISTERED_USERS,
  payload,
  route: "recent",
});

const recentlyRegisteredUsersAction = (payload) => ({
  type: MANAGE_USER.RECENTLY_REGISTERED_USERS,
  payload,
});

const searchSurveyAction = (payload) => ({
  type: MANAGE_SURVEY.SEARCH_SURVEY,
  payload,
});

const searchUserAction = (payload) => ({
  type: MANAGE_USER.SEARCH_USER,
  payload,
});

const downloadAssignedSurveyAction = (payload) => ({
  type: MANAGE_SURVEY.DOWNLOAD_ASSIGNED,
  payload,
});

const downloadAssignedLinkAction = (payload) => ({
  type: MANAGE_SURVEY.DOWNLOAD_ASSIGNED_LINK,
  payload,
});

const downloadUnassignedSurveyAction = (payload) => ({
  type: MANAGE_SURVEY.DOWNLOAD_UNASSIGNED,
  payload,
});
const downloadUnassignedLinkAction = (payload) => ({
  type: MANAGE_SURVEY.DOWNLOAD_UNASSIGNED_LINK,
  payload,
});

const fetchUnassignSurveyParticipantAction = (payload) => ({
  type: MANAGE_SURVEY.FETCH_UNASSIGN_SURVEY_PARTICIPANT,
  payload,
});
const unassignSurveyParticipantAction = (payload) => ({
  type: MANAGE_SURVEY.UNASSIGN_SURVEY_PARTICIPANT,
  payload,
});

const fetchAssignSurveyParticipantAction = (payload) => ({
  type: MANAGE_SURVEY.FETCH_ASSIGN_SURVEY_PARTICIPANT,
  payload,
});
const assignSurveyParticipantAction = (payload) => ({
  type: MANAGE_SURVEY.ASSIGN_SURVEY_PARTICIPANT,
  payload,
});


export {
  fetchAssignSurveyParticipantAction,
  assignSurveyParticipantAction,
  unassignSurveyParticipantAction,
  fetchUnassignSurveyParticipantAction,
  downloadUnassignedLinkAction,
  downloadAssignedLinkAction,
  downloadUnassignedSurveyAction,
  downloadAssignedSurveyAction,
  searchUserAction,
  searchSurveyAction,
  surveyTakenParticipantsAction,
  participantForSurveyAction,
  fetchParticipantForSurveyAction,
  surveyForAgentAction,
  fetchSurveyForAgentAction,
  mainResponseNotifyAction,
  screeningResponseNotifyAction,
  submitMainResponseAction,
  submitScreeningResponseAction,
  participantSingleSurveyAction,
  fetchParticipantSingleSurveyAction,
  participantAssignedSurveyListAction,
  fetchParticipantAssignedSurveyAction,
  participantDetailsAction,
  fetchParticipantInfoAction,
  participantLoginNotificationAction,
  updateTemplateNameNotificationAction,
  updateTemplateNameAction,
  assignedSurveyAction,
  fetchSurveyRespondedUser,
  fetchSurveyAssignedUsers,
  surveyAnalysisAction,
  fetchSurveyAnalysisAction,
  surveyDetailAction,
  fetchSurveyDetailAction,
  fetchAllDeletedUsersAction,
  fetchAllSponsorUsersAction,
  fetchAllParticipantUsersAction,
  fetchAllUsersAction,
  fetchRecentUsersAction,
  AddQuestionAction,
  CloneQuestionAction,
  SetActiveQuestionAction,
  RemoveQuestionAction,
  SortQuestionUpAction,
  SortQuestionDownAction,
  EditQuestion,
  EditSurveyHeader,
  importExcelAction,
  registerAgentAction,
  registerAgentNotificationAction,
  addEmailAction,
  addPasswordAction,
  adminLoginAction,
  agentLoginAction,
  loginNotificationAction,
  fetchAllAgentAction,
  fetchRecentAgentAction,
  agentListAction,
  singleParticipantRegisterAction,
  batchParticipantRegisterAction,
  userFormDataAction,
  userRegisterNotificationAction,
  deleteAgentAction,
  deleteAgentNotificationAction,
  fetchAdminOverviewAction,
  adminOverviewAction,
  surveyOverViewAction,
  fetchSurveyAction,
  userBatchRegisterNotificationAction,
  loginParticipantAction,
  createSurveyTemplateAction,
  createSurveyTemplateNotificationAction,
  setActiveTemplateIdAction,
  setActiveTemplateNameAction,
  fetchSingleTemplateAction,
  setSingleTemplateAction,
  saveTemplateQuestionAction,
  saveTemplateQuestionNotifyAction,
  setTemplateQuestionnaireAction,
  setTemplateScreeningQuestionAction,
  fetchTemplateListAction,
  setTemplateListAction,
  deleteTemplateAction,
  deleteTemplateNotificationAction,
  deleteQuestionNotificationAction,
  deleteQuestionAction,
  fetchRecentSurveyAction,
  fetchAllSurveyAction,
  fetchActiveSurveyAction,
  fetchCompletedSurveyAction,
  surveyListAction,
  userListAction,
  blockUserAction,
  unblockUserAction,
  blockUserNotificationAction,
  unblockUserNotificationAction,
  fetchUserProfileAction,
  userProfileAction,
  fetchPaymentHistory,
  paymentHistory,
  fetchPaidSurveyhistoryAction,
  paidSurveyHistoryAction,
  participantLoginAction,
  openModalAction,
  closeModalAction,
  fetchRecentlyRegisteredUsersAction,
  recentlyRegisteredUsersAction,
};
// };
// export { CreateSurveyActions };
