import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import AdminDashboard from "./components/layout/Dashboard/Admin";
import Register from "./register/index";
import { AdminLogin } from "./login/adminLogin";
import { AgentLogin } from "./login/agentLogin";
import { AdminProtectedRoute } from "./login/adminProtectedRoute";
import ErrorPage from "./Errorpage";
import "axios-progress-bar/dist/nprogress.css";

import "antd/dist/antd.css";
import { useEffect } from "react";

const App = () => {
  useEffect(() => {
    if (!localStorage.user_type) {
      localStorage.removeItem("token");
    }
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={AdminLogin} />
        <Route exact path="/agent" component={AgentLogin} />
        <Route path="/register" component={Register} />
        <AdminProtectedRoute path="/dashboard" component={AdminDashboard} />
        <Route path="*" component={ErrorPage} />
      </Switch>
    </Router>
  );
};

export default App;
