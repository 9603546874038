import styled from 'styled-components';
import { Form as AntForm, Input, Button } from 'antd';
import { device } from '../globalComponents/mediaQueries';

export const HeroContainer = styled.div`
  display: flex;
`;
export const RegisterContainer = styled.div`
  border-radius: 7px;
  display: block;
  height: fit-content;

  background-color: white;

  @media ${device.laptop} {
    width: 33vw;
    z-index: 2020;
    padding: 10px 40px;
  }
`;

export const Form = styled(AntForm)`
  width: 100%;
`;

export const Logo = styled.img`
  display: block;
  height: 4rem;
  padding: 10px;
  margin: 10px auto;
`;
export const Title = styled.p`
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 1.4rem;
  color: ${({ admin }) => (admin ? '#249B00' : '#0E6180')};
`;
export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  height: 3rem;
  padding: 15px 10px;
  border-radius: 5px;
  border: 1px solid black;
`;
export const Label = styled.h5`
  margin-top: 10px;
  font-weight: 500;
  font-size: 14px;
  color: grey;
`;
export const StyledInput = styled(Input)`
  &&& {
    margin-left: 5px;
    margin-top: -3px;
    border: none;
    border-bottom: 1px solid #00000029;
    width: 100%;
    height: 150%;
    cursor: pointer;
    border-radius: none;
    font-size: 16px;
    font-weight: 500;
    &:focus,
    &:active,
    &:hover {
      box-shadow: 2px 3px 3px #00000009;
      background-color: white;
      border-bottom: ${({ agent }) =>
        agent ? '1px solid #0E6180' : '1px solid #249b00'};
    }
  }
`;
export const Select = styled.select`
  margin-left: 5px;
  margin-top: -3px;
  width: 100%;
  padding: 15px none;
  border: none;
  ${'' /* height: 150%; */}
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid #00000019;
  outline: none;
  &:focus,
  &:active,
  &:hover {
    box-shadow: 2px 3px 3px #00000009;
    background-color: white;
    border-bottom: 1px solid #0e6180;
  }
`;
export const RegisterButton = styled(Button)`
  display: block;
  height: 40px;
  width: 100%;
  ${'' /*140px; */}
  background-color: ${({ admin }) => (admin ? '#249B00' : '#0E6180')};
  ${'' /* padding: 10px; */}
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  outline: none;
  border: none;
  ${'' /* text-align: center; */}
  margin: 20px none;
  &:hover {
    cursor: pointer;
    background-color: ${({ admin }) => (admin ? '#249B00' : '#0E6180')};
  }
`;

export const RegisterButton2 = styled(Button)`
  background-color: #249b00;
  margin-left: 5px;
  color: #fff;
  border: none;
  background-color: ${({ admin }) => (admin ? '#249B00' : '#0E6180')};
  margin-top: 10px;

  &:hover,
  &:focus {
    background-color: ${({ admin }) => (admin ? '#258405' : '#0E6180')};
    color: #fff;
    border: none;
  }
`;
export const StyledButtonDiv = styled.div`
  min-width: 100%;
  display: grid;
  place-items: center;
`;
