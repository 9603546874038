import styled, { keyframes } from "styled-components";
import { device } from "../../../globalComponents/mediaQueries";

export const SideNavContainer = styled.div`
  display: flex;
  height:100vh; 
  max-width: 20rem;
  position: sticky;
  top: 0;
  ${"" /* transition: all 5s ease-in; */}
`;

export const SideNavIconContainer = styled.div`
  background: ${({ admin }) => (admin ? "#2ABA14" : "#0E6180")};
  width: 3rem;
  height: 100%;
  position: relative;
`;
export const SideNavIconUl = styled.ul`
  margin-top: 4.8rem;
  ${"" /* height: 100%; */}
  margin-left: -3rem;
`;

export const SideNavIcon = styled.li`
  list-style: none;
  color: white;
  margin: 1.65rem;
  ${"" /* display: flex;
  align-content: space-evenly; */}
  text-align: center;
  &:hover {
    color: ${({ admin }) => (admin ? "green" : "#3480d7")};
  }
`;

export const CollapseButton = styled.button`
  font-size: 1.5rem;
  font-weight: bold;
  background: transparent;
  border: none;
  margin: 10px;
  color: white;
  text-align: center;
  outline: none;
  &:hover,
  &:focus {
    cursor: pointer;
    border: none;
    outline: none;
  }
`;
export const LogIcon = styled.div`
  bottom: 50px;
  left: 15px;
  position: absolute;
  text-align: center;
  color: white;
  cursor: pointer;
  margin-top: 50px;
`;

export const SideNavLinkContainer = styled.div`
  background: green;
  padding: 4px;
  width: 60rem;
`;

const AnimationIn = keyframes`
 from{
   width:0rem; opacity:0; 
 }
  50% { width:6rem; opacity:0.5 }

 to{
   width: 12rem; opacity: 1
 }
`;
const AnimationOut = keyframes`
 from{
   width:50rem; opacity:0.5;font-size:0.5rem 
 }
  50% { width:6rem; opacity:0.5;font-size:0.5rem }

 to{
      width:0rem; opacity:0; font-size:0rem

 }
`;

const Animation2 = keyframes`

 from{
    opacity:0; font-size:0rem
 }
  50% { opacity:0.5;font-size:0.5rem }

 to{
    opacity: 1; font-size:1rem
 
}
`;

export const SideNavItemContainer = styled.div`
  ${"" /* opacity: ${(props) => (props.isOpen ? "1" : "0")}; */}
  background: white;
  width: 12rem;
  ${"" /* transition: all ease-in 1s; */}
  height: 100%
    ${
    "" /* visibility: ${(isOpen) => (!isOpen ? "hidden" : "visible")};
  animation: ${({ isOpen }) => (!isOpen ? AnimationOut : AnimationIn)} 0.2s;
  transition: visibility 1s linear; */
  };
`;

export const Logo = styled.img`
  height: 4rem;
  padding: 10px;

  @media ${device.laptop}{
    margin-top: 15px;
    margin-left: 3.5rem;
  }
`;
export const SideNavItemUl = styled.p`
  margin-top: 3.7rem;
  ${"" /* padding-right: 3rem; */}
`;
export const SideNavItemLi = styled.p`
  list-style: none;
  color: black;
  align-text: center;
  padding-left: 1rem;
  margin: 1.5rem 0;
  ${"" /* animation-name: ${Animation2};
  animation-duration: 0.2s; */}
  font-size: 1rem;
  background: ${({ admin, iconHover }) =>
    iconHover ? (admin ? "#2ABA14" : "0E6180") : null};
  &:hover,
  &:active,
  &:focus {
    transition: all ease-in-out 0.3s;
    background: ${({ admin }) => (admin ? "#2ABA14" : "#0E6180")};
    ${"" /* border-radius: 5px; */}
    ${"" /* height:30px; */}
    ${"" /* padding: 0 10px; */}
    color: white;
  }
`;
export const Logout = styled.h5`
  position: absolute;
  bottom: 40px;
  color: red;
  cursor: pointer;
  margin-top: 50px;
  font-size: 1rem;
  margin-left: 2.5rem;
  &:hover {
    color: white;
    background: red;
  }
`;
