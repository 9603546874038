import {MANAGE_PARTICIPANTS} from '../constants/index';


export const userBatchRegisterNotificationReducer = (state={status: '', email: ''}, {type, payload}) => {
    if(type === MANAGE_PARTICIPANTS.USER_BATCH_REGISTER_NOTIFICATION){
        return payload;
    }

    return state;
}
