import React from "react";
import styled from "styled-components";
import { adminColor } from "../../../../globalComponents/globalAccet";
import { device } from "../../../../globalComponents/mediaQueries";

export const QuestionButton = (props) => {
  return (
    <StyledButton onClick={props.onClick}>{props.buttonText}</StyledButton>
  );
};

const StyledButton = styled.button`
  border-radius: 5px;
  display: block;
  width: 45%;
  padding: 10px 0;
  font-size: 16px;
  border: 1px solid #e2e8f0;
  background-color: transparent;
  box-shadow: 0px 1px 3px 0px rgba(188, 202, 209, 0.5);
  font-weight: 500;
  margin-bottom: 10px;

  &:active,
  &:focus {
    border: 1px solid #e2e8f0;
    outline: none;
  }

  &:hover {
    box-shadow: none;
    color: ${adminColor};
  }

  @media ${device.laptop} {
    font-size: 1.2vw;
  }
`;
