import React from 'react'

export const MinusIcon=() => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"

      width="18"
      height="18"
      stroke='#000'
      viewBox="0 0 512 512"
      fill='#000'
    >
      <line
        x1="400"
        y1="256"
        x2="112"
        y2="256"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeWidth={20}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
