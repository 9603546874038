export const states = [
  {
    _id: '1',
    code: 'FC',
    name: 'Abuja (FCT)',
    geoPoliticalZone: 'North Central(Middle Belt)',
    long: 7.533328056335449,
    lat: 9.083333015441895,
    hcKey: 'ng-fc',
    lgas: [
      {_id: '1', name: 'Abaji', code: 'FC01'},
      {_id: '2', name: 'Abuja Municipal', code: 'FC02'},
      {_id: '3', name: 'Bwari', code: 'FC03'},
      {_id: '4', name: 'Gwagwalada', code: 'FC04'},
      {_id: '5', name: 'Kuje', code: 'FC05'},
      {_id: '6', name: 'Kwali', code: 'FC06'},
      {_id: '7', name: 'Asokoro', code: 'FC07'},
      {_id: '8', name: 'Central Business District', code: 'FC08'},
      {_id: '9', name: 'Chika', code: 'FC09'},
      {_id: '10', name: 'Dakidiya', code: 'FC10'},
      {_id: '11', name: 'Dakwo', code: 'FC11'},
      {_id: '12', name: 'Dei-Dei', code: 'FC12'},
      {_id: '13', name: 'Duboyi', code: 'FC13'},
      {_id: '14', name: 'Durumi', code: 'FC14'},
      {_id: '15', name: 'Dutse', code: 'FC15'},
      {_id: '16', name: 'Gaduwa', code: 'FC16'},
      {_id: '17', name: 'Galadimawa', code: 'FC17'},
      {_id: '18', name: 'Garki I', code: 'FC18'},
      {_id: '19', name: 'Garki II', code: 'FC19'},
      {_id: '20', name: 'Gudu', code: 'FC20'},
      {_id: '21', name: 'Guzape', code: 'FC21'},
      {_id: '23', name: 'Gwarinpa', code: 'FC23'},
      {_id: '24', name: 'Jabi', code: 'FC24'},
      {_id: '25', name: 'Jahi', code: 'FC25'},
      {_id: '26', name: 'Jukwoyi', code: 'FC26'},
      {_id: '27', name: 'Kabusa', code: 'FC27'},
      {_id: '28', name: 'Kado', code: 'FC28'},
      {_id: '29', name: 'Karmo', code: 'FC29'},
      {_id: '30', name: 'Karu', code: 'FC30'},
      {_id: '31', name: 'Katampe', code: 'FC31'},
      {_id: '32', name: 'Kaura', code: 'FC32'},
      {_id: '33', name: 'Kubwa', code: 'FC33'},
      {_id: '34', name: 'Kuchigworo', code: 'FC34'},
      {_id: '35', name: 'Lokogoma', code: 'FC35'},
      {_id: '36', name: 'Lugbe', code: 'FC36'},
      {_id: '37', name: 'Mabuchi', code: 'FC37'},
      {_id: '38', name: 'Maitama', code: 'FC38'},
      {_id: '39', name: 'Mpape', code: 'FC39'},
      {_id: '40', name: 'Nbora', code: 'FC40'},
      {_id: '41', name: 'Nyanya', code: 'FC41'},
      {_id: '42', name: 'Pyakassa', code: 'FC42'},
      {_id: '43', name: 'Utako', code: 'FC43'},
      {_id: '44', name: 'Wumba', code: 'FC44'},
      {_id: '45', name: 'Wuse II', code: 'FC45'},
      {_id: '46', name: 'Wuse', code: 'FC46'},
      {_id: '47', name: 'Wuye', code: 'FC47'},
    ],
  },
  {
    _id: '10',
    code: 'DE',
    name: 'Delta',
    geoPoliticalZone: 'South South',
    long: 5.680004596710205,
    lat: 5.890427112579346,
    hcKey: 'ng-de',
    lgas: [
      {_id: '163', name: 'Aniocha North', code: 'DT01'},
      {_id: '164', name: 'Aniocha South', code: 'DT02'},
      {_id: '165', name: 'Bomadi', code: 'DT03'},
      {_id: '166', name: 'Burutu', code: 'DT04'},
      {_id: '167', name: 'Ethiope East', code: 'DT05'},
      {_id: '168', name: 'Ethiope South', code: 'DT06'},
      {_id: '169', name: 'Ika North-East', code: 'DT07'},
      {_id: '170', name: 'Ika South', code: 'DT08'},
      {_id: '171', name: 'Isoko North', code: 'DT09'},
      {_id: '172', name: 'Isoko south', code: 'DT10'},
      {_id: '173', name: 'Ndokwa East', code: 'DT11'},
      {_id: '174', name: 'Ndokwa West', code: 'DT12'},
      {_id: '175', name: 'Okpe', code: 'DT13'},
      {_id: '176', name: 'Oshimili North', code: 'DT14'},
      {_id: '177', name: 'Oshimili', code: 'DT15'},
      {_id: '178', name: 'Patani', code: 'DT16'},
      {_id: '179', name: 'Sapele', code: 'DT17'},
      {_id: '180', name: 'Udu', code: 'DT18'},
      {_id: '181', name: 'Ughelli North', code: 'DT19'},
      {_id: '182', name: 'Ughelli South', code: 'DT20'},
      {_id: '183', name: 'Ukwani', code: 'DT21'},
      {_id: '184', name: 'Uvwie', code: 'DT22'},
      {_id: '185', name: 'Warri South', code: 'DT23'},
      {_id: '186', name: 'Warri North', code: 'DT24'},
      {_id: '187', name: 'Warri South West', code: 'DT25'},
    ],
  },
  {
    _id: '11',
    code: 'EB',
    name: 'Ebonyi',
    geoPoliticalZone: 'South East',
    long: 7.982540130615234,
    lat: 6.326479911804199,
    hcKey: 'ng-eb',
    lgas: [
      {_id: '188', name: 'Abakaliki', code: 'EB01'},
      {_id: '189', name: 'Afikpo North', code: 'EB02'},
      {_id: '190', name: 'Afikpo South', code: 'EB03'},
      {_id: '191', name: 'Ebonyi', code: 'EB04'},
      {_id: '192', name: 'Ezza North', code: 'EB05'},
      {_id: '193', name: 'Ezza South', code: 'EB06'},
      {_id: '194', name: 'lkwo', code: 'EB07'},
      {_id: '195', name: 'Ishielu', code: 'EB08'},
      {_id: '196', name: 'Ivo', code: 'EB09'},
      {_id: '197', name: 'Izzi', code: 'EB10'},
      {_id: '198', name: 'Ohaozara', code: 'EB11'},
      {_id: '199', name: 'Ohaukwu', code: 'EB12'},
      {_id: '200', name: 'Onicha', code: 'EB13'},
    ],
  },
  {
    _id: '14',
    code: 'EN',
    name: 'Enugu',
    geoPoliticalZone: 'South East',
    long: 7.383362770080566,
    lat: 6.867034435272217,
    hcKey: 'ng-en',
    lgas: [
      {_id: '235', name: 'Awgu', code: 'EN01'},
      {_id: '236', name: 'Aninri', code: 'EN02'},
      {_id: '237', name: 'Enugu East', code: 'EN03'},
      {_id: '238', name: 'Enugu North', code: 'EN04'},
      {_id: '239', name: 'Enugu South', code: 'EN05'},
      {_id: '240', name: 'Ezeagu', code: 'EN06'},
      {_id: '241', name: 'Igbo-Ekiti', code: 'EN07'},
      {_id: '242', name: 'Igbo-Eze North', code: 'EN08'},
      {_id: '243', name: 'Igbo-Eze South', code: 'EN09'},
      {_id: '244', name: 'Isi-Uzo', code: 'EN10'},
      {_id: '245', name: 'Nkanu East', code: 'EN11'},
      {_id: '246', name: 'Nkanu West', code: 'EN12'},
      {_id: '247', name: 'Nsukka', code: 'EN13'},
      {_id: '248', name: 'Oji-River', code: 'EN14'},
      {_id: '249', name: 'Udenu', code: 'EN15'},
      {_id: '250', name: 'Udi', code: 'EN16'},
      {_id: '251', name: 'Uzo-Uwani', code: 'EN17'},
    ],
  },
  {
    _id: '16',
    code: 'IM',
    name: 'Imo',
    geoPoliticalZone: 'South East',
    long: 7.026003360748291,
    lat: 5.492997169494629,
    hcKey: 'ng-im',
    lgas: [
      {_id: '263', name: 'Aboh-Mbaise', code: 'IM01'},
      {_id: '264', name: 'Ahiazu-Mbaise', code: 'IM02'},
      {_id: '265', name: 'Ehime-Mbano', code: 'IM03'},
      {_id: '266', name: 'Ezinihitte', code: 'IM04'},
      {_id: '267', name: 'Ideato North', code: 'IM05'},
      {_id: '268', name: 'Ideato South', code: 'IM06'},
      {_id: '269', name: 'Ihitte/Uboma', code: 'IM07'},
      {_id: '270', name: 'Ikeduru', code: 'IM08'},
      {_id: '271', name: 'Isiala Mbano', code: 'IM09'},
      {_id: '272', name: 'Isu', code: 'IM10'},
      {_id: '273', name: 'Mbaitoli', code: 'IM11'},
      {_id: '274', name: 'Ngor/Okpala', code: 'IM12'},
      {_id: '275', name: 'Njaba', code: 'IM13'},
      {_id: '276', name: 'Nwangele', code: 'IM14'},
      {_id: '277', name: 'Nkwerre', code: 'IM15'},
      {_id: '278', name: 'Obowo', code: 'IM16'},
      {_id: '279', name: 'Oguta', code: 'IM17'},
      {_id: '280', name: 'Ohaji/Egbema', code: 'IM18'},
      {_id: '281', name: 'Okigwe', code: 'IM19'},
      {_id: '282', name: 'Unuimo', code: 'IM20'},
      {_id: '283', name: 'Orlu', code: 'IM21'},
      {_id: '284', name: 'Orsu', code: 'IM22'},
      {_id: '285', name: 'Oru East', code: 'IM23'},
      {_id: '286', name: 'Oru West', code: 'IM24'},
      {_id: '287', name: 'Owerri-Municipal', code: 'IM25'},
      {_id: '288', name: 'Owerri North', code: 'IM26'},
      {_id: '289', name: 'Owerri West', code: 'IM27'},
    ],
  },
  {
    _id: '18',
    code: 'KD',
    name: 'Kaduna',
    geoPoliticalZone: 'North West',
    long: 7.710009574890137,
    lat: 11.079980850219727,
    hcKey: 'ng-kd',
    lgas: [
      {_id: '317', name: 'Birni-Gwari', code: 'KD01'},
      {_id: '318', name: 'Chikun', code: 'KD02'},
      {_id: '319', name: 'Giwa', code: 'KD03'},
      {_id: '320', name: 'Kajuru', code: 'KD04'},
      {_id: '321', name: 'Igabi', code: 'KD05'},
      {_id: '322', name: 'Ikara', code: 'KD06'},
      {_id: '323', name: 'Jaba', code: 'KD07'},
      {_id: '324', name: "Jema'a", code: 'KD08'},
      {_id: '325', name: 'Kachia', code: 'KD09'},
      {_id: '326', name: 'Kaduna North', code: 'KD10'},
      {_id: '327', name: 'Kaduna South', code: 'KD11'},
      {_id: '328', name: 'Kararko', code: 'KD12'},
      {_id: '329', name: 'Kaura', code: 'KD13'},
      {_id: '330', name: 'Kauru', code: 'KD14'},
      {_id: '331', name: 'Kubau', code: 'KD15'},
      {_id: '332', name: 'Kudan', code: 'KD16'},
      {_id: '333', name: 'Lere', code: 'KD17'},
      {_id: '334', name: 'Makarfi', code: 'KD18'},
      {_id: '335', name: 'Sabon-Gari', code: 'KD19'},
      {_id: '336', name: 'Sanga', code: 'KD20'},
      {_id: '337', name: 'Soba', code: 'KD21'},
      {_id: '338', name: 'Zango-Kataf', code: 'KD22'},
      {_id: '339', name: 'Zaria', code: 'KD23'},
    ],
  },
  {
    _id: '2',
    code: 'AB',
    name: 'Abia',
    geoPoliticalZone: 'South East',
    long: 7.486002445220947,
    lat: 5.532002925872803,
    hcKey: 'ng-ab',
    lgas: [
      {_id: '7', name: 'Aba North', code: 'AB01'},
      {_id: '8', name: 'Aba South', code: 'AB02'},
      {_id: '9', name: 'Arochukwu', code: 'AB03'},
      {_id: '10', name: 'Bende', code: 'AB04'},
      {_id: '11', name: 'Ikwuano', code: 'AB05'},
      {_id: '12', name: 'Isiala-Ngwa North', code: 'AB06'},
      {_id: '13', name: 'Isiala-Ngwa South', code: 'AB07'},
      {_id: '14', name: 'Isuikwato', code: 'AB08'},
      {_id: '15', name: 'Obi Ngwa', code: 'AB10'},
      {_id: '16', name: 'Ohafia', code: 'AB11'},
      {_id: '17', name: 'OsisiomaNgwa', code: 'AB12'},
      {_id: '18', name: 'Ngwa', code: 'AB13'},
      {_id: '19', name: 'Ugwunagbo', code: 'AB14'},
      {_id: '20', name: 'Ukwa East', code: 'AB15'},
      {_id: '21', name: 'Ukwa West', code: 'AB16'},
      {_id: '22', name: 'Umuahia North', code: 'AB17'},
      {_id: '23', name: 'Umuahia South', code: 'AB18'},
      {_id: '24', name: 'Umu-Neochi', code: 'AB9'},
    ],
  },
  {
    _id: '21',
    code: 'KE',
    name: 'Kebbi',
    geoPoliticalZone: 'North West',
    long: 4.199939727783203,
    lat: 12.450414657592773,
    hcKey: 'ng-ke',
    lgas: [
      {_id: '418', name: 'Aleiro', code: 'KB01'},
      {_id: '419', name: 'Arewa', code: 'KB02'},
      {_id: '420', name: 'Argungu', code: 'KB03'},
      {_id: '421', name: 'Augie', code: 'KB04'},
      {_id: '422', name: 'Bagudo', code: 'KB05'},
      {_id: '423', name: 'Birnin Kebbi', code: 'KB06'},
      {_id: '424', name: 'Bunza', code: 'KB07'},
      {_id: '425', name: 'Dandi Kamba', code: 'KB08'},
      {_id: '426', name: 'Danko/Wasagu', code: 'KB09'},
      {_id: '427', name: 'Fakai', code: 'KB10'},
      {_id: '428', name: 'Gwandu', code: 'KB11'},
      {_id: '429', name: 'Jega', code: 'KB12'},
      {_id: '430', name: 'Kalgo', code: 'KB13'},
      {_id: '431', name: 'Koko/Besse', code: 'KB14'},
      {_id: '432', name: 'Maiyama', code: 'KB15'},
      {_id: '433', name: 'Ngaski', code: 'KB16'},
      {_id: '434', name: 'Sakaba', code: 'KB17'},
      {_id: '435', name: 'Shanga', code: 'KB18'},
      {_id: '436', name: 'Suru', code: 'KB19'},
      {_id: '437', name: 'Yauri', code: 'KB20'},
      {_id: '438', name: 'Zuru', code: 'KB21'},
    ],
  },
  {
    _id: '25',
    code: 'NA',
    name: 'Nasarawa',
    geoPoliticalZone: 'North Central(Middle Belt)',
    long: 8.520037651062012,
    lat: 8.490423202514648,
    hcKey: 'ng-na',
    lgas: [
      {_id: '496', name: 'Akwanga', code: 'NA01'},
      {_id: '497', name: 'Awe', code: 'NA02'},
      {_id: '498', name: 'Doma', code: 'NA03'},
      {_id: '499', name: 'Karu', code: 'NA04'},
      {_id: '500', name: 'Keana', code: 'NA05'},
      {_id: '501', name: 'Keffi', code: 'NA06'},
      {_id: '502', name: 'Kokona', code: 'NA07'},
      {_id: '503', name: 'Lafia', code: 'NA08'},
      {_id: '504', name: 'Nasarawa', code: 'NA09'},
      {_id: '505', name: 'Nasarawa-Eggon', code: 'NA10'},
      {_id: '506', name: 'Obi', code: 'NA11'},
      {_id: '507', name: 'Toto', code: 'NA12'},
      {_id: '508', name: 'Wamba', code: 'NA13'},
    ],
  },
  {
    _id: '28',
    code: 'OD',
    name: 'Ondo',
    geoPoliticalZone: 'South West',
    long: 5.199982166290283,
    lat: 7.250395774841309,
    hcKey: 'ng-on',
    lgas: [
      {_id: '554', name: 'Akoko North East', code: 'OD01'},
      {_id: '555', name: 'Akoko North West', code: 'OD02'},
      {_id: '556', name: 'Akoko South West', code: 'OD03'},
      {_id: '557', name: 'Akoko South East', code: 'OD04'},
      {_id: '558', name: 'Akure North', code: 'OD05'},
      {_id: '559', name: 'Akure South', code: 'OD06'},
      {_id: '560', name: 'Idanre', code: 'OD07'},
      {_id: '561', name: 'Ifedore', code: 'OD08'},
      {_id: '562', name: 'Okitipupa', code: 'OD09'},
      {_id: '563', name: 'Ilaje', code: 'OD10'},
      {_id: '564', name: 'Ese-Odo', code: 'OD11'},
      {_id: '565', name: 'Ile-Oluji', code: 'OD12'},
      {_id: '566', name: 'Irele', code: 'OD13'},
      {_id: '567', name: 'Odigbo', code: 'OD14'},
      {_id: '568', name: 'Ondo East', code: 'OD15'},
      {_id: '569', name: 'Ondo West', code: 'OD16'},
      {_id: '570', name: 'Ose', code: 'OD17'},
      {_id: '571', name: 'Owo', code: 'OD18'},
    ],
  },
  {
    _id: '3',
    code: 'AD',
    name: 'Adamawa',
    geoPoliticalZone: 'North East',
    long: 13.270031929016113,
    lat: 10.270340919494629,
    hcKey: 'ng-ad',
    lgas: [
      {_id: '25', name: 'Demsa', code: 'AD01'},
      {_id: '26', name: 'Fufore', code: 'AD02'},
      {_id: '27', name: 'Ganaye', code: 'AD03'},
      {_id: '28', name: 'Gireri', code: 'AD04'},
      {_id: '29', name: 'Gombi', code: 'AD05'},
      {_id: '30', name: 'Guyuk', code: 'AD06'},
      {_id: '31', name: 'Hong', code: 'AD07'},
      {_id: '32', name: 'Jada', code: 'AD08'},
      {_id: '33', name: 'Lamurde', code: 'AD10'},
      {_id: '34', name: 'Madagali', code: 'AD11'},
      {_id: '35', name: 'Maiha', code: 'AD12'},
      {_id: '36', name: 'Mayo-Belwa', code: 'AD13'},
      {_id: '37', name: 'Michika', code: 'AD14'},
      {_id: '38', name: 'Mubi North', code: 'AD15'},
      {_id: '39', name: 'Mubi South', code: 'AD16'},
      {_id: '40', name: 'Numan', code: 'AD17'},
      {_id: '41', name: 'Shelleng', code: 'AD18'},
      {_id: '42', name: 'Song', code: 'AD19'},
      {_id: '43', name: 'Toungo', code: 'AD20'},
      {_id: '44', name: 'Yola North', code: 'AD09'},
      {_id: '45', name: 'Yola South', code: 'AD21'},
    ],
  },
  {
    _id: '31',
    code: 'PL',
    name: 'Plateau',
    geoPoliticalZone: 'North Central(Middle Belt)',
    long: 8.89004135131836,
    lat: 9.929973602294922,
    hcKey: 'ng-pl',
    lgas: [
      {_id: '618', name: 'Barikin Ladi', code: 'PL01'},
      {_id: '619', name: 'Bassa', code: 'PL02'},
      {_id: '620', name: 'Bokkos', code: 'PL03'},
      {_id: '621', name: 'Jos East', code: 'PL04'},
      {_id: '622', name: 'Jos North', code: 'PL05'},
      {_id: '623', name: 'Jos South', code: 'PL06'},
      {_id: '624', name: 'Kanam', code: 'PL07'},
      {_id: '625', name: 'Kanke', code: 'PL08'},
      {_id: '626', name: 'Langtang North', code: 'PL09'},
      {_id: '627', name: 'Langtang South', code: 'PL10'},
      {_id: '628', name: 'Mangu', code: 'PL11'},
      {_id: '629', name: 'Mikang', code: 'PL12'},
      {_id: '630', name: 'Pankshin', code: 'PL13'},
      {_id: '631', name: "Qua'an Pan", code: 'PL14'},
      {_id: '632', name: 'Riyom', code: 'PL15'},
      {_id: '633', name: 'Shendam', code: 'PL16'},
      {_id: '634', name: 'Wase', code: 'PL17'},
    ],
  },
  {
    _id: '37',
    code: 'AK',
    name: 'Akwa ibom',
    geoPoliticalZone: 'South South',
    long: 7.849998474121094,
    lat: 5.007996082305908,
    hcKey: 'ng-ak',
    lgas: [
      {_id: '729', name: 'Abak', code: 'AK01'},
      {_id: '730', name: 'Eastern obolo', code: 'AK02'},
      {_id: '731', name: 'Ekei', code: 'AK03'},
      {_id: '732', name: 'Nsitata', code: 'AK04'},
      {_id: '733', name: 'Essien Udim', code: 'AK05'},
      {_id: '734', name: 'Etim Ekpo', code: 'AK06'},
      {_id: '735', name: 'Etinan', code: 'AK07'},
      {_id: '736', name: 'Ibeno', code: 'AK08'},
      {_id: '737', name: 'Ibesikpoasutan', code: 'AK09'},
      {_id: '738', name: 'Ibiono Ibom', code: 'AK10'},
      {_id: '739', name: 'Ika', code: 'AK11'},
      {_id: '740', name: 'Ikono', code: 'AK12'},
      {_id: '741', name: 'Ikot Abasi', code: 'AK13'},
      {_id: '742', name: 'Ikotekpene', code: 'AK14'},
      {_id: '743', name: 'Inl', code: 'AK15'},
      {_id: '744', name: 'Juu', code: 'AK16'},
      {_id: '745', name: 'Mbo', code: 'AK17'},
      {_id: '746', name: 'Mkpat Enin', code: 'AK18'},
      {_id: '747', name: 'Nsit Ibom', code: 'AK19'},
      {_id: '748', name: 'Nsit Ubium', code: 'AK20'},
      {_id: '749', name: 'Obot Akara', code: 'AK21'},
      {_id: '750', name: 'Okobo', code: 'AK22'},
      {_id: '751', name: 'Onna', code: 'AK23'},
      {_id: '752', name: 'Oron', code: 'AK24'},
      {_id: '753', name: 'Oruk anam', code: 'AK25'},
      {_id: '754', name: 'Udung uko', code: 'AK26'},
      {_id: '755', name: 'Ukanafun', code: 'AK27'},
      {_id: '756', name: 'Esit Eket', code: 'AK28'},
      {_id: '757', name: 'Uruan', code: 'AK29'},
      {_id: '758', name: 'Urue offong/Oruk', code: 'AK30'},
      {_id: '759', name: 'Uyo', code: 'AK31'},
    ],
  },
  {
    _id: '5',
    code: 'BA',
    name: 'Bauchi',
    geoPoliticalZone: 'North East',
    long: 10.19001293182373,
    lat: 11.68040943145752,
    hcKey: 'ng-ba',
    lgas: [
      {_id: '67', name: 'Alkaleri', code: 'BA01'},
      {_id: '68', name: 'Bauchi', code: 'BA02'},
      {_id: '69', name: 'Bogoro', code: 'BA03'},
      {_id: '70', name: 'Damban', code: 'BA04'},
      {_id: '71', name: 'Darazo', code: 'BA05'},
      {_id: '72', name: 'Dass', code: 'BA06'},
      {_id: '73', name: 'Gamawa', code: 'BA07'},
      {_id: '74', name: 'Ganjuwa', code: 'BA08'},
      {_id: '75', name: 'Giade', code: 'BA09'},
      {_id: '76', name: 'Itas/Gadau', code: 'BA10'},
      {_id: '77', name: "Jama'are", code: 'BA11'},
      {_id: '78', name: 'Katagum', code: 'BA12'},
      {_id: '79', name: 'Kirfi', code: 'BA13'},
      {_id: '80', name: 'Misau', code: 'BA14'},
      {_id: '81', name: 'Ningi', code: 'BA15'},
      {_id: '82', name: 'Shira', code: 'BA16'},
      {_id: '83', name: 'Tafawa-Balewa', code: 'BA17'},
      {_id: '84', name: 'Toro', code: 'BA18'},
      {_id: '85', name: 'Warji', code: 'BA19'},
      {_id: '86', name: 'Zaki', code: 'BA20'},
    ],
  },
  {
    _id: '7',
    code: 'BE',
    name: 'Benue',
    geoPoliticalZone: 'North Central(Middle Belt)',
    long: 8.129983901977539,
    lat: 7.190399646759033,
    hcKey: 'ng-be',
    lgas: [
      {_id: '95', name: 'Ado', code: 'BN01'},
      {_id: '96', name: 'Agatu', code: 'BN02'},
      {_id: '97', name: 'Apa', code: 'BN03'},
      {_id: '98', name: 'Buruku', code: 'BN04'},
      {_id: '99', name: 'Gboko', code: 'BN05'},
      {_id: '100', name: 'Guma', code: 'BN06'},
      {_id: '101', name: 'Gwer East', code: 'BN07'},
      {_id: '102', name: 'Gwer West', code: 'BN08'},
      {_id: '103', name: 'Katsina-Ala', code: 'BN09'},
      {_id: '104', name: 'Konshisha', code: 'BN10'},
      {_id: '105', name: 'Kwande', code: 'BN11'},
      {_id: '106', name: 'Logo', code: 'BN12'},
      {_id: '107', name: 'Makurdi', code: 'BN13'},
      {_id: '108', name: 'Obi', code: 'BN14'},
      {_id: '109', name: 'Ogbadibo', code: 'BN15'},
      {_id: '110', name: 'Oju', code: 'BN17'},
      {_id: '111', name: 'Okpokwu', code: 'BN18'},
      {_id: '112', name: 'Ohimini', code: 'BN16'},
      {_id: '113', name: 'Oturkpo', code: 'BN19'},
      {_id: '114', name: 'Tarka', code: 'BN20'},
      {_id: '115', name: 'Ukum', code: 'BN21'},
      {_id: '116', name: 'Ushongo', code: 'BN22'},
      {_id: '117', name: 'Vandeikya ', code: 'BN23'},
    ],
  },
  {
    _id: '9',
    code: 'CR',
    name: 'Cross River',
    geoPoliticalZone: 'South South',
    long: 8.330023765563965,
    lat: 4.960406303405762,
    hcKey: 'ng-cr',
    lgas: [
      {_id: '145', name: 'Abi', code: 'CR01'},
      {_id: '146', name: 'Akamkpa ', code: 'CR02'},
      {_id: '147', name: 'Akpabuyo', code: 'CR03'},
      {_id: '148', name: 'Bakassi', code: 'CR04'},
      {_id: '149', name: 'Bekwara', code: 'CR05'},
      {_id: '150', name: 'Biase', code: 'CR06'},
      {_id: '151', name: 'Boki', code: 'CR07'},
      {_id: '152', name: 'Calabar Municipality', code: 'CR08'},
      {_id: '153', name: 'Calabar South ', code: 'CR09'},
      {_id: '154', name: 'Etung ', code: 'CR10'},
      {_id: '155', name: 'Ikom', code: 'CR11'},
      {_id: '156', name: 'Obanliku ', code: 'CR12'},
      {_id: '157', name: 'Obubra', code: 'CR13'},
      {_id: '158', name: 'Obudu', code: 'CR14'},
      {_id: '159', name: 'Odukpani', code: 'CR15'},
      {_id: '160', name: 'Ogoja', code: 'CR16'},
      {_id: '161', name: 'Yakurr', code: 'CR17'},
      {_id: '162', name: 'Yala', code: 'CR18'},
      {_id: '763', name: 'Odupkani', code: 'CR19'},
    ],
  },
  {
    _id: '12',
    code: 'ED',
    name: 'Edo',
    geoPoliticalZone: 'South South',
    long: 5.6200079917907715,
    lat: 6.340477466583252,
    hcKey: 'ng-ed',
    lgas: [
      {_id: '201', name: 'Akoko Edo', code: 'ED01'},
      {_id: '202', name: 'Egor', code: 'ED02'},
      {_id: '203', name: 'Esan Central', code: 'ED03'},
      {_id: '204', name: 'Esan North-East', code: 'ED04'},
      {_id: '205', name: 'Esan South-East', code: 'ED05'},
      {_id: '206', name: 'Esan West', code: 'ED06'},
      {_id: '207', name: 'Etsako Central', code: 'ED07'},
      {_id: '208', name: 'Etsako East', code: 'ED08'},
      {_id: '209', name: 'Etsako West', code: 'ED09'},
      {_id: '210', name: 'Igueben', code: 'ED10'},
      {_id: '211', name: 'Ikpoba Okha', code: 'ED11'},
      {_id: '212', name: 'Oredo', code: 'ED12'},
      {_id: '213', name: 'Orhionwon', code: 'ED13'},
      {_id: '214', name: 'Ovia North-East', code: 'ED14'},
      {_id: '215', name: 'Ovia SouthWest', code: 'ED15'},
      {_id: '216', name: 'Owan East', code: 'ED16'},
      {_id: '217', name: 'Owan West', code: 'ED17'},
      {_id: '218', name: 'Uhunmwonde', code: 'ED18'},
    ],
  },
  {
    _id: '15',
    code: 'GO',
    name: 'Gombe',
    geoPoliticalZone: 'North East',
    long: 11.169953346252441,
    lat: 10.29044246673584,
    hcKey: 'ng-go',
    lgas: [
      {_id: '252', name: 'Akko', code: 'GM01'},
      {_id: '253', name: 'Balanga', code: 'GM02'},
      {_id: '254', name: 'Billiri', code: 'GM03'},
      {_id: '255', name: 'Dukku', code: 'GM04'},
      {_id: '256', name: 'Funakaye', code: 'GM05'},
      {_id: '257', name: 'Gombe', code: 'GM06'},
      {_id: '258', name: 'Kaltungo', code: 'GM07'},
      {_id: '259', name: 'Kwami', code: 'GM08'},
      {_id: '260', name: 'Nafada/Bajoga', code: 'GM09'},
      {_id: '261', name: 'Shomgom', code: 'GM10'},
      {_id: '262', name: 'Yemaltu Deba', code: 'GM11'},
    ],
  },
  {
    _id: '17',
    code: 'JI',
    name: 'Jigawa',
    geoPoliticalZone: 'North West',
    long: 9.350334167480469,
    lat: 11.799189567565918,
    hcKey: 'ng-ji',
    lgas: [
      {_id: '290', name: 'Auyo', code: 'JG01'},
      {_id: '291', name: 'Babura', code: 'JG02'},
      {_id: '292', name: 'Birni Kudu', code: 'JG03'},
      {_id: '293', name: 'Biriniwa', code: 'JG04'},
      {_id: '294', name: 'Gagarawa', code: 'JG05'},
      {_id: '295', name: 'Buji', code: 'JG06'},
      {_id: '296', name: 'Dutse', code: 'JG07'},
      {_id: '297', name: 'Garki', code: 'JG08'},
      {_id: '298', name: 'Gumel', code: 'JG09'},
      {_id: '299', name: 'Guri', code: 'JG10'},
      {_id: '300', name: 'Gwaram', code: 'JG11'},
      {_id: '301', name: 'Gwiwa', code: 'JG12'},
      {_id: '302', name: 'Hadejia', code: 'JG13'},
      {_id: '303', name: 'Jahun', code: 'JG14'},
      {_id: '304', name: 'Kafin Hausa', code: 'JG15'},
      {_id: '305', name: 'Kaugama', code: 'JG16'},
      {_id: '306', name: 'Kazaure', code: 'JG17'},
      {_id: '307', name: 'Kiri Kasamma', code: 'JG18'},
      {_id: '308', name: 'Kiyawa', code: 'JG19'},
      {_id: '309', name: 'Maigatari', code: 'JG20'},
      {_id: '310', name: 'Malam Madori', code: 'JG21'},
      {_id: '311', name: 'Miga', code: 'JG22'},
      {_id: '312', name: 'Ringim', code: 'JG23'},
      {_id: '313', name: 'Roni', code: 'JG24'},
      {_id: '314', name: 'Sule-Tankarkar', code: 'JG25'},
      {_id: '315', name: 'Taura', code: 'JG26'},
      {_id: '316', name: 'Yankwashi', code: 'JG27'},
    ],
  },
  {
    _id: '19',
    code: 'KN',
    name: 'Kano',
    geoPoliticalZone: 'North West',
    long: 8.520037651062012,
    lat: 11.999977111816406,
    hcKey: 'ng-kn',
    lgas: [
      {_id: '340', name: 'Ajingi', code: 'KN01'},
      {_id: '341', name: 'Albasu', code: 'KN02'},
      {_id: '342', name: 'Bagwai', code: 'KN03'},
      {_id: '343', name: 'Bebeji', code: 'KN04'},
      {_id: '344', name: 'Bichi', code: 'KN05'},
      {_id: '345', name: 'Bunkure', code: 'KN06'},
      {_id: '346', name: 'Dala', code: 'KN07'},
      {_id: '347', name: 'Dambatta', code: 'KN08'},
      {_id: '348', name: 'Dawakin Kudu', code: 'KN09'},
      {_id: '349', name: 'Dawakin Tofa', code: 'KN10'},
      {_id: '350', name: 'Doguwa', code: 'KN11'},
      {_id: '351', name: 'Fagge', code: 'KN12'},
      {_id: '352', name: 'Gabasawa', code: 'KN13'},
      {_id: '353', name: 'Garko', code: 'KN14'},
      {_id: '354', name: 'Garun Mallam', code: 'KN15'},
      {_id: '355', name: 'Gaya', code: 'KN16'},
      {_id: '356', name: 'Gezawa', code: 'KN17'},
      {_id: '357', name: 'Gwale', code: 'KN18'},
      {_id: '358', name: 'Gwarzo', code: 'KN19'},
      {_id: '359', name: 'Kabo', code: 'KN20'},
      {_id: '360', name: 'Kano Municipal', code: 'KN21'},
      {_id: '361', name: 'Karaye', code: 'KN22'},
      {_id: '362', name: 'Kibiya', code: 'KN23'},
      {_id: '363', name: 'Kiru', code: 'KN24'},
      {_id: '364', name: 'kumbotso', code: 'KN25'},
      {_id: '365', name: 'Kunchi', code: 'KN26'},
      {_id: '366', name: 'Kura', code: 'KN27'},
      {_id: '367', name: 'Madobi', code: 'KN28'},
      {_id: '368', name: 'Makoda', code: 'KN29'},
      {_id: '369', name: 'Minjibir', code: 'KN30'},
      {_id: '370', name: 'Nasarawa', code: 'KN31'},
      {_id: '371', name: 'Rano', code: 'KN32'},
      {_id: '372', name: 'Rimin Gado', code: 'KN33'},
      {_id: '373', name: 'Rogo', code: 'KN34'},
      {_id: '374', name: 'Shanono', code: 'KN35'},
      {_id: '375', name: 'Sumaila', code: 'KN36'},
      {_id: '376', name: 'Takai', code: 'KN37'},
      {_id: '377', name: 'Tarauni', code: 'KN38'},
      {_id: '378', name: 'Tofa', code: 'KN39'},
      {_id: '379', name: 'Tsanyawa', code: 'KN40'},
      {_id: '380', name: 'Tudun Wada', code: 'KN41'},
      {_id: '381', name: 'Ungogo', code: 'KN42'},
      {_id: '382', name: 'Warawa', code: 'KN43'},
      {_id: '383', name: 'Wudil', code: 'KN44'},
    ],
  },
  {
    _id: '34',
    code: 'TA',
    name: 'Taraba',
    geoPoliticalZone: 'North East',
    long: 9.780012130737305,
    lat: 7.870409965515137,
    hcKey: 'ng-ta',
    lgas: [
      {_id: '681', name: 'Ardo-kola', code: 'TR01'},
      {_id: '682', name: 'Bali', code: 'TR02'},
      {_id: '683', name: 'Donga', code: 'TR03'},
      {_id: '684', name: 'Gashaka', code: 'TR04'},
      {_id: '685', name: 'Gassol', code: 'TR05'},
      {_id: '686', name: 'Ibi', code: 'TR06'},
      {_id: '687', name: 'Jalingo', code: 'TR07'},
      {_id: '688', name: 'Karin-Lamido', code: 'TR08'},
      {_id: '689', name: 'Kurmi', code: 'TR09'},
      {_id: '690', name: 'Lau', code: 'TR10'},
      {_id: '691', name: 'Sardauna', code: 'TR11'},
      {_id: '692', name: 'Takum', code: 'TR12'},
      {_id: '693', name: 'Ussa', code: 'TR13'},
      {_id: '694', name: 'Wukari', code: 'TR14'},
      {_id: '695', name: 'Yorro', code: 'TR15'},
      {_id: '696', name: 'Zing', code: 'TR16'},
    ],
  },
  {
    _id: '20',
    code: 'KT',
    name: 'Katsina',
    geoPoliticalZone: 'North West',
    long: 7.320007801055908,
    lat: 11.520393371582031,
    hcKey: 'ng-kt',
    lgas: [
      {_id: '384', name: 'Bakori', code: 'KT01'},
      {_id: '385', name: 'Batagarawa', code: 'KT02'},
      {_id: '386', name: 'Batsari', code: 'KT03'},
      {_id: '387', name: 'Baure', code: 'KT04'},
      {_id: '388', name: 'Bindawa', code: 'KT05'},
      {_id: '389', name: 'Charanchi', code: 'KT06'},
      {_id: '390', name: 'Dan Musa', code: 'KT07'},
      {_id: '391', name: 'Dandume', code: 'KT08'},
      {_id: '392', name: 'Danja', code: 'KT09'},
      {_id: '393', name: 'Daura', code: 'KT10'},
      {_id: '394', name: 'Dutsi', code: 'KT11'},
      {_id: '395', name: 'Dutsin-Ma', code: 'KT12'},
      {_id: '396', name: 'Faskari', code: 'KT13'},
      {_id: '397', name: 'Funtua', code: 'KT14'},
      {_id: '398', name: 'Ingawa', code: 'KT15'},
      {_id: '399', name: 'Jibia', code: 'KT16'},
      {_id: '400', name: 'Kafur', code: 'KT17'},
      {_id: '401', name: 'Kaita', code: 'KT18'},
      {_id: '402', name: 'Kankara', code: 'KT19'},
      {_id: '403', name: 'Kankia', code: 'KT20'},
      {_id: '404', name: 'Katsina', code: 'KT21'},
      {_id: '405', name: 'Kurfi', code: 'KT22'},
      {_id: '406', name: 'Kusada', code: 'KT23'},
      {_id: '407', name: "Mai'Adua", code: 'KT24'},
      {_id: '408', name: 'Malumfashi', code: 'KT25'},
      {_id: '409', name: 'Mani', code: 'KT26'},
      {_id: '410', name: 'Mashi', code: 'KT27'},
      {_id: '411', name: 'Matazuu', code: 'KT28'},
      {_id: '412', name: 'Musawa', code: 'KT29'},
      {_id: '413', name: 'Rimi', code: 'KT30'},
      {_id: '414', name: 'Sabuwa', code: 'KT31'},
      {_id: '415', name: 'Safana', code: 'KT32'},
      {_id: '416', name: 'Sandamu', code: 'KT33'},
      {_id: '417', name: 'Zango', code: 'KT34'},
    ],
  },
  {
    _id: '22',
    code: 'KO',
    name: 'Kogi',
    geoPoliticalZone: 'North Central(Middle Belt)',
    long: 6.7399396896362305,
    lat: 7.800388336181641,
    hcKey: 'ng-ko',
    lgas: [
      {_id: '439', name: 'Adavi', code: 'KG01'},
      {_id: '440', name: 'Ajaokuta', code: 'KG02'},
      {_id: '441', name: 'Ankpa', code: 'KG03'},
      {_id: '442', name: 'Bassa', code: 'KG04'},
      {_id: '443', name: 'Dekina', code: 'KG05'},
      {_id: '444', name: 'Ibaji', code: 'KG06'},
      {_id: '445', name: 'Idah', code: 'KG07'},
      {_id: '446', name: 'Igalamela-Odolu', code: 'KG08'},
      {_id: '447', name: 'Ijumu', code: 'KG09'},
      {_id: '762', name: 'Lokoja', code: 'KG22'},
      {_id: '448', name: 'Kabba/Bunu', code: 'KG10'},
      {_id: '449', name: 'Kogi', code: 'KG11'},
      {_id: '450', name: 'Kotonkarfe', code: 'KG12'},
      {_id: '451', name: 'Mopa-Muro', code: 'KG13'},
      {_id: '452', name: 'Ofu', code: 'KG14'},
      {_id: '453', name: 'Ogori/Mangongo', code: 'KG15'},
      {_id: '454', name: 'Okehi', code: 'KG16'},
      {_id: '455', name: 'Okene', code: 'KG17'},
      {_id: '456', name: 'Olamaboro', code: 'KG18'},
      {_id: '457', name: 'Omala', code: 'KG19'},
      {_id: '458', name: 'Yagba East', code: 'KG20'},
      {_id: '459', name: 'Yagba West', code: 'KG21'},
    ],
  },
  {
    _id: '24',
    code: 'LA',
    name: 'Lagos',
    geoPoliticalZone: 'South West',
    long: 3.391530990600586,
    lat: 6.443261623382568,
    hcKey: 'ng-la',
    lgas: [
      {_id: '476', name: 'Agege', code: 'LG01'},
      {_id: '477', name: 'Ajeromi-Ifelodun', code: 'LG02'},
      {_id: '478', name: 'Alimosho', code: 'LG03'},
      {_id: '479', name: 'Amuwo-Odofin', code: 'LG04'},
      {_id: '480', name: 'Apapa', code: 'LG05'},
      {_id: '481', name: 'Badagry', code: 'LG06'},
      {_id: '482', name: 'Epe', code: 'LG07'},
      {_id: '483', name: 'Eti-Osa', code: 'LG08'},
      {_id: '484', name: 'Ibeju/Lekki', code: 'LG09'},
      {_id: '485', name: 'Ifako-Ijaye', code: 'LG10'},
      {_id: '486', name: 'Ikeja', code: 'LG11'},
      {_id: '487', name: 'Ikorodu', code: 'LG12'},
      {_id: '488', name: 'Kosofe', code: 'LG13'},
      {_id: '489', name: 'Lagos Island', code: 'LG14'},
      {_id: '490', name: 'Lagos Mainland', code: 'LG15'},
      {_id: '491', name: 'Mushin', code: 'LG16'},
      {_id: '492', name: 'Ojo', code: 'LG17'},
      {_id: '493', name: 'Oshodi-Isolo', code: 'LG18'},
      {_id: '494', name: 'Shomolu', code: 'LG19'},
      {_id: '495', name: 'Surulere', code: 'LG20'},
      {_id: '496', name: 'Abule', code: 'LG21'},
      {_id: '497', name: 'Adeniyi-Jones', code: 'LG22'},
      {_id: '498', name: 'Agidingbi', code: 'LG23'},
      {_id: '499', name: 'Aguda', code: 'LG24'},
      {_id: '500', name: 'Ajah', code: 'LG25'},
      {_id: '501', name: 'Ajegunle', code: 'LG26'},
      {_id: '502', name: 'Akerele', code: 'LG27'},
      {_id: '503', name: 'Akoka', code: 'LG28'},
      {_id: '504', name: 'Alaba', code: 'LG29'},
      {_id: '505', name: 'Alagomeji', code: 'LG30'},
      {_id: '506', name: 'Alausa', code: 'LG31'},
      {_id: '507', name: 'Anthony Village', code: 'LG31'},
      {_id: '508', name: 'Bariga', code: 'LG32'},
      {_id: '509', name: 'Coker', code: 'LG33'},
      {_id: '510', name: 'Dolphin Estate', code: 'LG34'},
      {_id: '511', name: 'Dopemu', code: 'LG35'},
      {_id: '512', name: 'Ebute Metta', code: 'LG36'},
      {_id: '513', name: 'Festac Town', code: 'LG37'},
      {_id: '514', name: 'Gbagada', code: 'LG38'},
      {_id: '515', name: 'Idumota', code: 'LG39'},
      {_id: '516', name: 'Ijesha', code: 'LG40'},
      {_id: '517', name: 'Ijora', code: 'LG41'},
      {_id: '518', name: 'Ikoyi', code: 'LG42'},
      {_id: '519', name: 'Ilasamaja', code: 'LG43'},
      {_id: '520', name: 'Ilupeju', code: 'LG44'},
      {_id: '521', name: 'Iwaya', code: 'LG45'},
      {_id: '522', name: 'Jibowu', code: 'LG46'},
      {_id: '523', name: 'Ketu', code: 'LG47'},
      {_id: '524', name: 'Ladipo', code: 'LG48'},
      {_id: '525', name: 'Lawanson', code: 'LG49'},
      {_id: '526', name: 'Lekki', code: 'LG50'},
      {_id: '527', name: 'Marina', code: 'LG51'},
      {_id: '528', name: 'Maryland', code: 'LG52'},
      {_id: '529', name: 'Masha', code: 'LG53'},
      {_id: '530', name: 'Maza Maza', code: 'LG54'},
      {_id: '531', name: 'Mende', code: 'LG55'},
      {_id: '532', name: 'Mile 2', code: 'LG56'},
      {_id: '533', name: 'Obalende', code: 'LG57'},
      {_id: '534', name: 'Obanikoro', code: 'LG58'},
      {_id: '535', name: 'Ogba', code: 'LG59'},
      {_id: '536', name: 'Ogudu', code: 'LG60'},
      {_id: '537', name: 'Ojodu', code: 'LG61'},
      {_id: '538', name: 'Ojodu Berger', code: 'LG62'},
      {_id: '539', name: 'Ojota', code: 'LG63'},
      {_id: '540', name: 'Ojuelegba', code: 'LG64'},
      {_id: '541', name: 'Olodi', code: 'LG65'},
      {_id: '542', name: 'Onigbongbo', code: 'LG66'},
      {_id: '543', name: 'Onipanu', code: 'LG67'},
      {_id: '544', name: 'Oniru', code: 'LG68'},
      {_id: '545', name: 'Opebi', code: 'LG69'},
      {_id: '546', name: 'Oregun', code: 'LG70'},
      {_id: '547', name: 'Palmgrove', code: 'LG71'},
      {_id: '548', name: 'Papa Ajao', code: 'LG72'},
      {_id: '549', name: 'Sabo', code: 'LG73'},
      {_id: '550', name: 'Satellite Town', code: 'LG74'},
      {_id: '551', name: 'Takwa Bay', code: 'LG75'},
      {_id: '552', name: 'Tinubu Square', code: 'LG76'},
      {_id: '553', name: 'Victoria Garden City', code: 'LG77'},
      {_id: '554', name: 'Victoria Island', code: 'LG78'},
      {_id: '555', name: 'Yaba', code: 'LG79'},
    ],
  },
  {
    _id: '26',
    code: 'NI',
    name: 'Niger',
    geoPoliticalZone: 'North Central(Middle Belt)',
    long: 5.469939708709717,
    lat: 10.400359153747559,
    hcKey: 'ng-ni',
    lgas: [
      {_id: '509', name: 'Agaie', code: 'NG01'},
      {_id: '510', name: 'Agwara', code: 'NG02'},
      {_id: '511', name: 'Bida', code: 'NG03'},
      {_id: '512', name: 'Borgu', code: 'NG04'},
      {_id: '513', name: 'Bosso', code: 'NG05'},
      {_id: '524', name: 'Chanchaga', code: 'NG16'},
      {_id: '514', name: 'Edati', code: 'NG06'},
      {_id: '515', name: 'Gbako', code: 'NG07'},
      {_id: '516', name: 'Gurara', code: 'NG08'},
      {_id: '517', name: 'Katcha', code: 'NG09'},
      {_id: '518', name: 'Kontagora', code: 'NG10'},
      {_id: '519', name: 'Lapai', code: 'NG11'},
      {_id: '520', name: 'Lavun', code: 'NG12'},
      {_id: '521', name: 'Magama', code: 'NG13'},
      {_id: '522', name: 'Mariga', code: 'NG14'},
      {_id: '523', name: 'Mashegu', code: 'NG15'},
      {_id: '525', name: 'Mokwa', code: 'NG17'},
      {_id: '526', name: 'Muya', code: 'NG18'},
      {_id: '527', name: 'Paikoro', code: 'NG19'},
      {_id: '528', name: 'Rafi', code: 'NG20'},
      {_id: '529', name: 'Rijau', code: 'NG21'},
      {_id: '530', name: 'Shiroro', code: 'NG22'},
      {_id: '531', name: 'Suleja', code: 'NG23'},
      {_id: '532', name: 'Tafa', code: 'NG24'},
      {_id: '533', name: 'Wushishi', code: 'NG25'},
    ],
  },
  {
    _id: '27',
    code: 'OG',
    name: 'Ogun',
    geoPoliticalZone: 'South West',
    long: 3.350017547607422,
    lat: 7.160427093505859,
    hcKey: 'ng-og',
    lgas: [
      {_id: '534', name: 'Abeokuta North', code: 'OG01'},
      {_id: '535', name: 'Abeokuta South', code: 'OG02'},
      {_id: '536', name: 'Ado-Odo/Ota', code: 'OG03'},
      {_id: '537', name: 'Egbado North', code: 'OG04'},
      {_id: '538', name: 'Egbado South', code: 'OG05'},
      {_id: '539', name: 'Ewekoro', code: 'OG06'},
      {_id: '540', name: 'Remo North', code: 'OG07'},
      {_id: '541', name: 'Ifo', code: 'OG08'},
      {_id: '542', name: 'Ijebu East', code: 'OG09'},
      {_id: '543', name: 'Ijebu North', code: 'OG10'},
      {_id: '544', name: 'Ijebu Ode', code: 'OG11'},
      {_id: '545', name: 'Ikenne', code: 'OG12'},
      {_id: '546', name: 'Ijebu North East', code: 'OG13'},
      {_id: '547', name: 'Imeko-Afon', code: 'OG14'},
      {_id: '548', name: 'Ipokia', code: 'OG15'},
      {_id: '549', name: 'Obafemi-Owode', code: 'OG16'},
      {_id: '550', name: 'Odeda', code: 'OG17'},
      {_id: '551', name: 'Odogbolu', code: 'OG18'},
      {_id: '552', name: 'Ogun Waterside', code: 'OG19'},
      {_id: '553', name: 'Shagamu', code: 'OG20'},
      {_id: '767', name: 'Yewa North', code: 'OG21'},
    ],
  },
  {
    _id: '29',
    code: 'OS',
    name: 'Osun',
    geoPoliticalZone: 'South West',
    long: 4.17999267578125,
    lat: 7.6299591064453125,
    hcKey: 'ng-os',
    lgas: [
      {_id: '572', name: 'Atakumosa East', code: 'OS01'},
      {_id: '573', name: 'Atakumosa West', code: 'OS02'},
      {_id: '574', name: 'Afyedade', code: 'OS03'},
      {_id: '575', name: 'Afyedire', code: 'OS04'},
      {_id: '576', name: 'Boluwaduro', code: 'OS05'},
      {_id: '577', name: 'Boripe', code: 'OS06'},
      {_id: '578', name: 'Ede North', code: 'OS07'},
      {_id: '579', name: 'Ede South', code: 'OS08'},
      {_id: '580', name: 'Egbedore', code: 'OS09'},
      {_id: '581', name: 'Ejigbo', code: 'OS10'},
      {_id: '582', name: 'Ife Central', code: 'OS11'},
      {_id: '583', name: 'Ife East', code: 'OS12'},
      {_id: '584', name: 'Ife North', code: 'OS13'},
      {_id: '585', name: 'Ife South', code: 'OS14'},
      {_id: '586', name: 'Ifedayo', code: 'OS15'},
      {_id: '587', name: 'Ifelodun', code: 'OS16'},
      {_id: '588', name: 'Ila', code: 'OS17'},
      {_id: '589', name: 'Ilesha East', code: 'OS18'},
      {_id: '590', name: 'Ilesha West', code: 'OS19'},
      {_id: '591', name: 'Irepodun', code: 'OS20'},
      {_id: '592', name: 'Irewole', code: 'OS21'},
      {_id: '593', name: 'Isokan', code: 'OS22'},
      {_id: '594', name: 'Iwo', code: 'OS23'},
      {_id: '760', name: 'Obokun', code: 'OS24'},
      {_id: '761', name: 'Osogbo', code: 'OS25'},
      {_id: '764', name: 'Oriade', code: 'OS26'},
      {_id: '765', name: 'Olorunda', code: 'OS27'},
    ],
  },
  {
    _id: '30',
    code: 'OY',
    name: 'Oyo',
    geoPoliticalZone: 'South West',
    long: 3.5900027751922607,
    lat: 7.970016002655029,
    hcKey: 'ng-oy',
    lgas: [
      {_id: '595', name: 'Afijio', code: 'OY01'},
      {_id: '596', name: 'Akinyele', code: 'OY02'},
      {_id: '597', name: 'Atiba', code: 'OY03'},
      {_id: '598', name: 'Atigbo', code: 'OY04'},
      {_id: '599', name: 'Egbeda', code: 'OY05'},
      {_id: '600', name: 'Ibadan North', code: 'OY06'},
      {_id: '601', name: 'Ibadan North East', code: 'OY07'},
      {_id: '602', name: 'Ibadan North West', code: 'OY08'},
      {_id: '603', name: 'Oyo', code: 'OY09'},
      {_id: '604', name: 'Ibadan South East', code: 'OY10'},
      {_id: '605', name: 'Ibarapa', code: 'OY11'},
      {_id: '606', name: 'Ibarapa North', code: 'OY12'},
      {_id: '607', name: 'Ido', code: 'OY13'},
      {_id: '608', name: 'Saki West', code: 'OY14'},
      {_id: '609', name: 'Ifeloju', code: 'OY15'},
      {_id: '610', name: 'Irepo', code: 'OY16'},
      {_id: '611', name: 'Iseyin', code: 'OY17'},
      {_id: '612', name: 'Itesiwju', code: 'OY18'},
      {_id: '613', name: 'Iwajowa', code: 'OY19'},
      {_id: '614', name: 'Olorunsogo', code: 'OY20'},
      {_id: '615', name: 'Kajola', code: 'OY21'},
      {_id: '616', name: 'Lagelu', code: 'OY22'},
      {_id: '617', name: 'Ogbomosho North', code: 'OY23'},
      {_id: '766', name: 'Oluyole', code: 'OY24'},
      {_id: '768', name: 'Oyo West', code: 'OY25'},
    ],
  },
  {
    _id: '32',
    code: 'RI',
    name: 'Rivers',
    geoPoliticalZone: 'South South',
    long: 7.010000705718994,
    lat: 4.810002326965332,
    hcKey: 'ng-ri',
    lgas: [
      {_id: '635', name: 'Ahoada East', code: 'RV01'},
      {_id: '636', name: 'Ahoada West', code: 'RV02'},
      {_id: '637', name: 'Akuku Toru', code: 'RV03'},
      {_id: '638', name: 'Andoni', code: 'RV04'},
      {_id: '639', name: 'Asari-Toru', code: 'RV05'},
      {_id: '640', name: 'Bonny', code: 'RV06'},
      {_id: '641', name: 'Degema', code: 'RV07'},
      {_id: '642', name: 'Eleme', code: 'RV08'},
      {_id: '643', name: 'Emohua', code: 'RV09'},
      {_id: '644', name: 'Etche', code: 'RV10'},
      {_id: '645', name: 'Gokana', code: 'RV11'},
      {_id: '646', name: 'Ikwerre', code: 'RV12'},
      {_id: '647', name: 'Khana', code: 'RV13'},
      {_id: '648', name: 'Obia/Akpor', code: 'RV14'},
      {_id: '649', name: 'Abua/Odual', code: 'RV15'},
      {_id: '650', name: 'Ogba/Egbema/Ndoni', code: 'RV16'},
      {_id: '651', name: 'Ogu/Bolo', code: 'RV17'},
      {_id: '652', name: 'Okrika', code: 'RV18'},
      {_id: '653', name: 'Omumma', code: 'RV19'},
      {_id: '654', name: 'Opobo/Nkoro', code: 'RV20'},
      {_id: '655', name: 'Oyigbo', code: 'RV21'},
      {_id: '656', name: 'Port-Harcourt', code: 'RV22'},
      {_id: '657', name: 'Tai', code: 'RV23'},
    ],
  },
  {
    _id: '36',
    code: 'ZA',
    name: 'Zamfara',
    geoPoliticalZone: 'North West',
    long: 6.659996509552002,
    lat: 12.170405387878418,
    hcKey: 'ng-za',
    lgas: [
      {_id: '714', name: 'Anka', code: 'ZA01'},
      {_id: '715', name: 'Bakura', code: 'ZA02'},
      {_id: '716', name: 'Bukkuyum', code: 'ZA03'},
      {_id: '717', name: 'Bungudu', code: 'ZA04'},
      {_id: '718', name: 'Gummi', code: 'ZA05'},
      {_id: '719', name: 'Gusau', code: 'ZA06'},
      {_id: '720', name: 'Kaura Namoda', code: 'ZA07'},
      {_id: '721', name: 'Birnin Magaji', code: 'ZA08'},
      {_id: '722', name: 'Maradun', code: 'ZA09'},
      {_id: '723', name: 'Maru', code: 'ZA10'},
      {_id: '724', name: 'Shinkafi', code: 'ZA11'},
      {_id: '725', name: 'Talata Mafara', code: 'ZA12'},
      {_id: '726', name: 'Tsafe', code: 'ZA13'},
      {_id: '727', name: 'Zurmi', code: 'ZA14'},
      {_id: '728', name: 'Yunusari', code: 'YB16'},
    ],
  },
  {
    _id: '4',
    code: 'AN',
    name: 'Anambra',
    geoPoliticalZone: 'South East',
    long: 7.069997310638428,
    lat: 6.210433483123779,
    hcKey: 'ng-an',
    lgas: [
      {_id: '46', name: 'Aguata', code: 'AN01'},
      {_id: '47', name: 'Anambra East', code: 'AN02'},
      {_id: '48', name: 'Anambra West', code: 'AN03'},
      {_id: '49', name: 'Anaocha', code: 'AN04'},
      {_id: '50', name: 'Awka North', code: 'AN05'},
      {_id: '51', name: 'Awka South', code: 'AN06'},
      {_id: '52', name: 'Ayamelum', code: 'AN07'},
      {_id: '53', name: 'Dunukofia', code: 'AN08'},
      {_id: '54', name: 'Ekwusigo', code: 'AN09'},
      {_id: '55', name: 'Idemili North', code: 'AN10'},
      {_id: '56', name: 'Idemili south', code: 'AN11'},
      {_id: '57', name: 'Ihiala', code: 'AN12'},
      {_id: '58', name: 'Njikoka', code: 'AN13'},
      {_id: '59', name: 'Nnewi North', code: 'AN14'},
      {_id: '60', name: 'Nnewi South', code: 'AN15'},
      {_id: '61', name: 'Ogbaru', code: 'AN16'},
      {_id: '62', name: 'Onitsha North', code: 'AN17'},
      {_id: '63', name: 'Onitsha South', code: 'AN18'},
      {_id: '64', name: 'Orumba North', code: 'AN19'},
      {_id: '65', name: 'Orumba South', code: 'AN20'},
      {_id: '66', name: 'Oyi', code: 'AN21'},
    ],
  },
  {
    _id: '6',
    code: 'BY',
    name: 'Bayelsa',
    geoPoliticalZone: 'South South',
    long: 5.974209785461426,
    lat: 4.739069938659668,
    hcKey: 'ng-by',
    lgas: [
      {_id: '87', name: 'Brass', code: 'BY01'},
      {_id: '88', name: 'Ekeremor', code: 'BY02'},
      {_id: '89', name: 'Kolokuma/Opokuma', code: 'BY03'},
      {_id: '90', name: 'Nembe', code: 'BY04'},
      {_id: '91', name: 'Ogbia', code: 'BY05'},
      {_id: '92', name: 'Sagbama', code: 'BY06'},
      {_id: '93', name: 'Southern Jaw', code: 'BY07'},
      {_id: '94', name: 'Yenegoa ', code: 'BY08'},
    ],
  },
  {
    _id: '8',
    code: 'BO',
    name: 'Borno',
    geoPoliticalZone: 'North East',
    long: 12.189994812011719,
    lat: 10.62042236328125,
    hcKey: 'ng-bo',
    lgas: [
      {_id: '118', name: 'Abadam', code: 'BO01'},
      {_id: '119', name: 'Askira/Uba', code: 'BO02'},
      {_id: '120', name: 'Bama', code: 'BO03'},
      {_id: '121', name: 'Bayo', code: 'BO04'},
      {_id: '122', name: 'Biu', code: 'BO05'},
      {_id: '123', name: 'Chibok', code: 'BO06'},
      {_id: '124', name: 'Damboa', code: 'BO07'},
      {_id: '125', name: 'Dikwa', code: 'BO08'},
      {_id: '126', name: 'Gubio', code: 'BO09'},
      {_id: '127', name: 'Guzamala', code: 'BO10'},
      {_id: '128', name: 'Gwoza', code: 'BO11'},
      {_id: '129', name: 'Hawul', code: 'BO12'},
      {_id: '130', name: 'Jere', code: 'BO13'},
      {_id: '131', name: 'Kaga', code: 'BO14'},
      {_id: '132', name: 'Kala/Balge', code: 'BO15'},
      {_id: '133', name: 'Konduga', code: 'BO16'},
      {_id: '134', name: 'Kukawa', code: 'BO17'},
      {_id: '135', name: 'Kwaya Kusar', code: 'BO18'},
      {_id: '136', name: 'Mafa', code: 'BO19'},
      {_id: '137', name: 'Magumeri', code: 'BO20'},
      {_id: '138', name: 'Maiduguri Metro', code: 'BO21'},
      {_id: '139', name: 'Marte', code: 'BO22'},
      {_id: '140', name: 'Mobbar', code: 'BO23'},
      {_id: '141', name: 'Monguno', code: 'BO24'},
      {_id: '142', name: 'Ngala', code: 'BO25'},
      {_id: '143', name: 'Nganzai', code: 'BO26'},
      {_id: '144', name: 'Shani', code: 'BO27'},
    ],
  },
  {
    _id: '35',
    code: 'YO',
    name: 'Yobe',
    geoPoliticalZone: 'North East',
    long: 11.966004371643066,
    lat: 11.748995780944824,
    hcKey: 'ng-yo',
    lgas: [
      {_id: '697', name: 'Bade', code: 'YB01'},
      {_id: '698', name: 'Bursari', code: 'YB02'},
      {_id: '699', name: 'Damaturu', code: 'YB03'},
      {_id: '700', name: 'Fika', code: 'YB04'},
      {_id: '701', name: 'Fune', code: 'YB05'},
      {_id: '702', name: 'Geidam', code: 'YB06'},
      {_id: '703', name: 'Gujba', code: 'YB07'},
      {_id: '704', name: 'Gulani', code: 'YB08'},
      {_id: '705', name: 'Jakusko', code: 'YB09'},
      {_id: '706', name: 'Karasuwa', code: 'YB10'},
      {_id: '707', name: 'Machina', code: 'YB11'},
      {_id: '708', name: 'Nangere', code: 'YB12'},
      {_id: '709', name: 'Nguru', code: 'YB13'},
      {_id: '710', name: 'Potiskum', code: 'YB14'},
      {_id: '711', name: 'Tarmua', code: 'YB15'},
      {_id: '712', name: 'Yunusari', code: 'YB16'},
      {_id: '713', name: 'Yusufari', code: 'YB17'},
    ],
  },
  {
    _id: '13',
    code: 'EK',
    name: 'Ekiti',
    geoPoliticalZone: 'South West',
    long: 5.219980716705322,
    lat: 7.630372524261475,
    hcKey: 'ng-ek',
    lgas: [
      {_id: '219', name: 'Ado Ekiti', code: 'EK01'},
      {_id: '220', name: 'Aiyekire', code: 'EK02'},
      {_id: '221', name: 'Efon', code: 'EK03'},
      {_id: '222', name: 'Ekiti-East', code: 'EK04'},
      {_id: '223', name: 'Ekiti-South West', code: 'EK05'},
      {_id: '224', name: 'Ekiti West', code: 'EK06'},
      {_id: '225', name: 'Emure', code: 'EK07'},
      {_id: '226', name: 'Ido/Osi', code: 'EK08'},
      {_id: '227', name: 'Ijero', code: 'EK09'},
      {_id: '228', name: 'Ikare', code: 'EK10'},
      {_id: '229', name: 'Ikole', code: 'EK11'},
      {_id: '230', name: 'Ilejemeje.', code: 'EK12'},
      {_id: '231', name: 'Irepodun/Ifelodun', code: 'EK13'},
      {_id: '232', name: 'Ise/Orun ', code: 'EK14'},
      {_id: '233', name: 'Moba', code: 'EK15'},
      {_id: '234', name: 'Oye', code: 'EK16'},
    ],
  },
  {
    _id: '23',
    code: 'KW',
    name: 'Kwara',
    geoPoliticalZone: 'North Central(Middle Belt)',
    long: 4.5499958992004395,
    lat: 8.490010261535645,
    hcKey: 'ng-kw',
    lgas: [
      {_id: '460', name: 'Asa', code: 'KW01'},
      {_id: '461', name: 'Baruten', code: 'KW02'},
      {_id: '462', name: 'Edu', code: 'KW03'},
      {_id: '463', name: 'Ekiti', code: 'KW04'},
      {_id: '464', name: 'Ifelodun', code: 'KW05'},
      {_id: '465', name: 'Ilorin East', code: 'KW06'},
      {_id: '466', name: 'Ilorin South', code: 'KW07'},
      {_id: '467', name: 'Ilorin West', code: 'KW08'},
      {_id: '468', name: 'Irepodun', code: 'KW09'},
      {_id: '469', name: 'Kaiama', code: 'KW10'},
      {_id: '470', name: 'Moro', code: 'KW11'},
      {_id: '471', name: 'Offa', code: 'KW12'},
      {_id: '472', name: 'Oke-Ero', code: 'KW13'},
      {_id: '473', name: 'Isin', code: 'KW14'},
      {_id: '474', name: 'Oyun', code: 'KW15'},
      {_id: '475', name: 'Pategi', code: 'KW16'},
    ],
  },
]

//Redux constants



//Export Redux constants