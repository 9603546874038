const { SURVEY_TEMPLATE } = require("../constants")

export const activeTemplateReducer = (state={id:null, name: '', details: {screening_question: [], questionnaire: []}}, {type, payload}) => {
    if(type === SURVEY_TEMPLATE.SET_ACTIVE_TEMPLATE_ID){
        let newState = {...state};
        newState.id = payload;
        return newState;
    }

    if(type === SURVEY_TEMPLATE.SET_ACTIVE_TEMPLATE_NAME){
        let newState = {...state};

        newState.name = payload;

        newState.details.survey_name = payload;


        return newState;
    }

    if(type === SURVEY_TEMPLATE.SET_SINGLE_TEMPLATE){
        let newState = {...state};

        newState.details = payload;

        return newState
    }



    if(type === SURVEY_TEMPLATE.SET_TEMPLATE_SCREENING_QUESTION){
        let newState = {...state};

        newState.details.screening_question = payload;

        return newState
    }

    if(type === SURVEY_TEMPLATE.SET_TEMPLATE_QUESTIONNAIRE){
        let newState = {...state};

        newState.details.questionnaire = payload;

        return newState
    }

    return state;
}

export const saveTemplateQuestionNotifyReducer = (state ='', {type, payload} ) => {
    if(type === SURVEY_TEMPLATE.SAVE_TEMPLATE_QUESTION_NOTIFY){
        return payload;
    }

    return state;
}