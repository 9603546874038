const theme = {
  fontFamily: {
    avenir: ["Avenir", "sans-serif"],
  },
  colors: {
    green: {
      700: "#2ABA14",
      800: "#61D926",
      900: "#19311B",
    },
    red: {
      700: "#C82E29",
    },
    pink: {
      500: "#E81880",
    },
    yellow: {
      600: "#A58A00",
    },
    blue: {
      900: "#1F0EBC",
    },
    gray: {
      100: "#656565",
      200: "#00000069",
    },
  },
};

export { theme };
