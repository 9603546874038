import React from "react";
import styled from "styled-components";
import PaymentDetails from "./PaymentDetails";
import { useSelector } from "react-redux";
import { UserInfo } from "./WalletInfo";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

import BaseScaffold from "../BaseScaffold";
import device from "../../utils/breakPoints";

const WalletManagement = () => {
  const userProfile = useSelector((state) => state.userProfile);
  console.log("user profile from", userProfile);
  const descriptions = [
    { id: 1, title: "Email", value: userProfile.email },
    { id: 2, title: "Contact Information", value: userProfile.phone },

    {
      id: 4,
      title: "State",
      value: userProfile.state ? userProfile.state : "Not available",
    },
    {
      id: 5,
      title: "LGA",
      value: userProfile.lga ? userProfile.lga : "Not available",
    },
  ];

  const historyHeader = [
    { title: "Id", dataIndex: "id", key: "id" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Status", dataIndex: "status", key: "status" },
  ];
  const bankDetails = [
    {
      ...userProfile,
    },
  ];
  const bankInfoHeader = [
    { title: "Bank Name", dataIndex: "bank_name", key: "bank_name" },
    {
      title: "Account Number",
      dataIndex: "account_number",
      key: "account_number",
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
    },
  ];
  const surveyTableHeader = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "Survey Name", dataIndex: "name", key: "name" },
    {
      title: "Total no. of quest.",
      dataIndex: "questions",
      key: "questions",
    },
    { title: "Amount Paid", dataIndex: "amount", key: "amount" },
    { title: "Purchased Date", dataIndex: "date", key: "date" },
    { title: "Status", dataIndex: "status", key: "status" },
  ];
  return (
    <BaseScaffold title="User Profile">
      <StyledMainContainer>
        <div>
          <UserInfo
            name={userProfile.name}
            userType={
              userProfile.user_type === "business" ? "Sponsor" : "Participant"
            }
            image={
              userProfile.pics ? (
                <UserImage src={`data:image/png;base64,${userProfile.pics}`} />
              ) : (
                <Avatar
                  size={{
                    xs: 40,
                    sm: 40,
                    md: 70,
                    lg: 80,
                    xl: 100,
                    xxl: 40,
                  }}
                  icon={<UserOutlined />}
                />
              )
            }
            descriptions={descriptions}
          />
        </div>
        <div>
          <PaymentDetails
            balance={userProfile.wallet_balance}
            historyHeader={historyHeader}
            bankDetails={bankDetails}
            bankInfoHeader={bankInfoHeader}
            surveyTableHeader={surveyTableHeader}
          />
        </div>
      </StyledMainContainer>
    </BaseScaffold>
  );
};

const UserImage = styled.img`
  height: 8rem;
  margin-top: 16px;
  width: 8rem;
  border-radius: 50%;
`;

const StyledMainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div:first-child {
    flex-basis: 100%;
  }

  & > div:last-child {
    flex-basis: 100%;
  }

  @media ${device.laptop} {
    & > div:first-child {
      flex-basis: 40%;
    }

    & > div:last-child {
      flex-basis: 60%;
    }
  }
`;

export default WalletManagement;
