import React, {useState} from 'react';
import { Checkbox, Radio, Input, Select} from 'antd';
import styled from 'styled-components';

const { Option } = Select;

const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;

export const CheckboxOption = (props) => {
    const [checkedList, setCheckedList] = useState([]);

    const onChange = (checkedValues) => {
        setCheckedList(checkedValues);
    }

    const optionList = props?.options?.map((item, index) => {
        return  <div key={index}>
                <Checkbox  value={item}>{item}</Checkbox>
                <br/>
            </div>
    })

    return (
        <CheckboxGroup /* value={checkedList} */ onChange={onChange}>
            {optionList}
        </CheckboxGroup>
    );
};

export const RadioGroupOption = (props) => {
    /* const [value, setValue] = useState(1);

    const onChange = (e) => {
        setValue(e.target.value);
    } */

    const optionList = props.options?.map((item, index) => {
             return  <div key={index}>
                        <Radio  value={item}>{item}</Radio>
                        <br/>
                    </div>
    })
    return (
        <Radio.Group onChange={props.onChange} value={props.value}>
            {optionList}
        </Radio.Group>
    )
}

export const SingleInput = (props) => {
    return (
        <Input onChange={props.onChange}/>
    )
}

export const MultiLineInput = (props) => {
    return (
        <TextArea rows={4}  onChange={props.onChange}/>
    )
}

export const DropDown = (props) => {

    const optionList = props?.options?.map((item, index) => {
        return  <div key={index}>
                   <Option key={index} value={item}>{item}</Option>
               </div>
    })

    return (
        <StyledSelect defaultValue={props.options[0]} onChange={props.onChange}>
              {optionList}
        </StyledSelect>
    )
}


const StyledSelect = styled(Select)`
    width: 100%;
`

