import React from "react";

export const User = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 490 512"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <path d="M299 106.668c0 58.91-47.758 106.664-106.668 106.664-58.91 0-106.664-47.754-106.664-106.664C85.668 47.758 133.422 0 192.332 0 251.242 0 299 47.758 299 106.668zm0 0M246.39 443.734c-11.113-19.242-8.894-42.945 4.333-59.734-13.227-16.79-15.446-40.469-4.332-59.734l17.261-29.868c9.493-16.445 27.2-26.664 46.207-26.664 2.602 0 5.16.192 7.7.555.707-1.75 1.601-3.394 2.476-5.059-11.52-4.542-23.957-7.23-37.035-7.23H101.668C45.793 256 .332 301.46.332 357.332V432c0 8.832 7.168 16 16 16h232.512zm0 0" />
      <path d="M463.332 394.004c.426-3.305 1-6.59 1-10.004 0-3.434-.598-6.7-1-10.004l19.219-14.55c4.289-3.243 5.484-9.173 2.797-13.844l-17.258-29.868c-2.692-4.652-8.43-6.593-13.38-4.504l-22.12 9.325c-5.399-4.118-11.137-7.743-17.473-10.39l-2.945-23.49c-.68-5.331-5.203-9.343-10.578-9.343h-34.496c-5.38 0-9.922 4.012-10.582 9.344l-2.946 23.488c-6.336 2.648-12.093 6.293-17.472 10.39l-22.121-9.324a10.649 10.649 0 00-13.375 4.504l-17.262 29.868c-2.688 4.648-1.492 10.578 2.797 13.843l19.218 14.551c-.425 3.305-1 6.59-1 10.004 0 3.434.598 6.7 1 10.004l-19.218 14.55c-4.29 3.243-5.485 9.173-2.797 13.844l17.262 29.891c2.687 4.649 8.425 6.59 13.375 4.5l22.12-9.344c5.4 4.118 11.137 7.743 17.473 10.39l2.946 23.49c.66 5.331 5.183 9.343 10.558 9.343h34.496c5.375 0 9.922-4.012 10.582-9.344l2.946-23.488c6.336-2.648 12.093-6.293 17.468-10.39l22.125 9.324a10.649 10.649 0 0013.375-4.504l17.258-29.887c2.688-4.652 1.496-10.582-2.793-13.844zm-79 32.664c-23.57 0-42.664-19.094-42.664-42.668s19.094-42.668 42.664-42.668C407.906 341.332 427 360.426 427 384s-19.094 42.668-42.668 42.668zm0 0" />
    </svg>
  );
};
