import React from "react";

import NavBar from "../components/atoms/Navbar/index";
import { Spring } from "react-spring/renderprops";
import { HeroContainer } from "./Style";

const BaseScaffold = ({ title, children }) => {
  return (
    <>
      <Spring
        from={{ opacity: 0.1, marginTop: +15 }}
        to={{ opacity: 1, marginTop: 0 }}
        config={{ duration: 700, delay: 200 }}
      >
        {(props) => (
          <HeroContainer style={props}>
            <NavBar title={title} />
            {children}
          </HeroContainer>
        )}
      </Spring>
    </>
  );
};

export default BaseScaffold;
