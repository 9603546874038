import React from "react";
import { SearchContainer, TopFilterContainer } from "./Style";
import {
  SearchInput,
  LinkContainer,
  Text,
  IconBg,
  FilterCon,
  FilterInput,
} from "../surveys/Styles";
import {
  FunnelIcon,
  PlusIcon,
  Trash,
} from "../../components/atoms/icons/index";

const Filter = ({ toggle }) => {
  return (
    <SearchContainer top="true">
      <SearchInput placeholder="Search..." agent={true} />

      <LinkContainer onClick={() => toggle()}>
        <IconBg>
          <PlusIcon />
        </IconBg>
        <Text>Add New Agent</Text>
      </LinkContainer>
    </SearchContainer>
  );
};
export default Filter;
