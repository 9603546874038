import nextId from "react-id-generator";

export const CreateSurveyTabs = [
  {
    addQuestion: "Add Question",
    editQuestion: "Edit Question",
    editSurvey: "Edit Survey",
  },
];

export const QuestionTypes = {
  singleLineText: "SINGLE_LINE_TEXT",
  multiLineText: "MUTLI_LINE_TEXT",
  multiChoice: "MULTI_CHOICE",
  checkboxes: "CHECKBOXES",
  dropdown: "DROPDOWN",
};

// export type SingleQuestion = {
//   _id: string
//   type: QuestionTypes
//   title: string
//   placeholder?: string
//   options?: {
//     _id: string
//     content: string
//   }[]
// }

export const QuestionDescriptions = [
  {
    text: "Single Line Text",
    type: QuestionTypes.singleLineText,
  },
  {
    text: "Multiple Line Text",
    type: QuestionTypes.multiLineText,
  },
  {
    text: "Multiple Choice",
    type: QuestionTypes.multiChoice,
  },
  {
    text: "Checkboxes",
    type: QuestionTypes.checkboxes,
  },
  {
    text: "Dropdown",
    type: QuestionTypes.dropdown,
  },
];

export const InitQuestions = {
  [QuestionTypes.multiChoice]: () => ({
    _id: nextId(),
    type: QuestionTypes.multiChoice,
    title: "Select a choice",
    options: [
      {
        _id: nextId(),
        content: "First choice",
      },
      {
        _id: nextId(),
        content: "Second choice",
      },
      {
        _id: nextId(),
        content: "Third choice",
      },
    ],
  }),
  [QuestionTypes.checkboxes]: () => ({
    _id: nextId(),
    type: QuestionTypes.checkboxes,
    title: "Check All That Apply",
    options: [
      {
        _id: nextId(),
        content: "First choice",
      },
      {
        _id: nextId(),
        content: "Second choice",
      },
      {
        _id: nextId(),
        content: "Third choice",
      },
    ],
  }),
  [QuestionTypes.singleLineText]: () => ({
    _id: nextId(),
    type: QuestionTypes.singleLineText,
    title: "Untitled",
    placeholder: "",
  }),
  [QuestionTypes.multiLineText]: () => ({
    _id: nextId(),
    type: QuestionTypes.multiLineText,
    title: "Untitled",
    placeholder: "",
  }),
  [QuestionTypes.dropdown]: () => ({
    _id: nextId(),
    type: QuestionTypes.dropdown,
    title: "Select a choice",
    options: [
      {
        _id: nextId(),
        content: "First choice",
      },
      {
        _id: nextId(),
        content: "Second choice",
      },
      {
        _id: nextId(),
        content: "Third choice",
      },
    ],
  })};
