import React from "react";
import { Typography } from "antd";
import BaseScaffold from "../BaseScaffold";

const AgentDashboard = ({ onOpenNav }) => {
  return (
    <BaseScaffold title="Agent Dashboard">
      <Typography.Text>Welcome</Typography.Text>
    </BaseScaffold>
  );
};
export default AgentDashboard;
