import React from "react";
import { GraphContainer, Title, LineChart } from "./Styles";
import { NavLink } from "../Table/Styles";
import { Navbar, Menu, MenuItem } from "../atoms/TableNavs";
import { LinesChart } from "../../components/atoms/charts/index";

const Graph = () => {
  return (
    <GraphContainer>
      <Title>OVERVIEW USER STATS</Title>
      <Navbar>
        <Menu defaultSelectedKeys={["1"]}>
          <MenuItem key="1">Day</MenuItem>
          <MenuItem key="2">Week</MenuItem>
          <MenuItem key="3">Month</MenuItem>
          <MenuItem key="4">Year</MenuItem>
        </Menu>
      </Navbar>
      <LineChart>
        <LinesChart />
      </LineChart>
    </GraphContainer>
  );
};
export default Graph;
