import React, { useState } from "react";
import { useHistory } from "react-router";
import { Spring } from "react-spring/renderprops";
import { useDispatch } from "react-redux";
import LoginHero from "../assets/images/login_hero.jpg";
import { Eye, EyeOff, Locked } from "../components/atoms/icons/index";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import {
  HeroContainer,
  LoginContainer,
  Logo,
  Hr,
  Title,
  InputContainer,
  Forgot,
  LoginButton,
  NewloggerContainer,
  NewloggerAccount,
  Newlogger,
  ToggleAgent,
  LoginButton2,
} from "./Styles";
import { Form } from "../register/Styles";
import surverplus from "../assets/images/surveypluslogo.svg";
import { PrimaryButton } from "../globalComponents/buttons";
import styled from "styled-components";

import { addEmailAction, addPasswordAction } from "../actions/index";
import { Input, Button, Checkbox } from "antd";

const Login = (props) => {
  const dispatcher = useDispatch();

  const handleEmailChange = (e) => {
    dispatcher(addEmailAction(e.target.value.trim()));
  };

  const keyPressed = (e, props) => {
    if (e.keyCode === 13) {
      props.onLogin();
    }
  };

  const handlePasswordChange = (e) => {
    dispatcher(addPasswordAction(e.target.value.trim()));
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Spring
      from={{ opacity: 0.3, transform: "translateY(-5px)" }}
      to={{ opacity: 1, transform: "translateY(0)" }}
      config={{ duration: 500, delay: 100 }}
    >
      {(containerProps) => (
        <HeroContainer style={containerProps} src={LoginHero}>
          <Spring
            from={{ opacity: 0, marginTop: +250 }}
            to={{ opacity: 1, marginTop: 0 }}
            config={{ duration: 500, delay: 400 }}
          >
            {(prop) => (
              <LoginContainer style={prop}>
                <Logo src={surverplus} />
                <Hr />
                <Title style={{ color: props.color }}>{props.title}</Title>

                <Form
                  layout="vertical"
                  onKeyDown={(event) => keyPressed(event, props)}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <InputContainer>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input password!",
                        },
                        {
                          required: true,
                          message: "Please input a valid password!",
                        },
                      ]}
                    >
                      <StyledInput
                        bordered={false}
                        prefix={
                          <UserOutlined className="site-form-item-icon" />
                        }
                        admin={props.isAdmin}
                        placeholder="Enter Email"
                        type="email"
                        onChange={handleEmailChange}
                      />{" "}
                    </Form.Item>

                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <StylePassword
                        bordered={false}
                        admin={props.isAdmin}
                        prefix={
                          <LockOutlined className="site-form-item-icon" />
                        }
                        type="password"
                        placeholder="Password"
                        onChange={handlePasswordChange}
                      />
                    </Form.Item>
                  </InputContainer>

                  {/*  <Forgot>Forgot Password</Forgot> */}
                  <PrimaryButton
                    htmlType="submit"
                    admin={props.isAdmin}
                    onClick={props.onLogin}
                    buttonText="Login"
                  />
                </Form>

                <ToggleAgent
                  onClick={props.toggleAgent}
                  style={{ color: props.color }}
                >
                  {props.userType}
                </ToggleAgent>
              </LoginContainer>
            )}
          </Spring>
        </HeroContainer>
      )}
    </Spring>
  );
};

export const StyledInput = styled(Input)`
  &&& {
    width: 100%;
    height: 25px;
    font-size: 0.9rem;
    padding: 0 20px;
    border: none;
    border-bottom: 1px solid #00000029;
    margin: 20px auto;
    outline: none !important;
    &:focus,
    :hover {
      border-bottom: ${({ admin }) =>
        admin ? "1px solid #249B00" : "1px solid #0E6180"};
      box-shadow: none;
      outline: none;
    }
  }
`;

export const StylePassword = styled(Input.Password)`
   &&&{ width: 100%;
  height: 25px;
  border: none;
  font-size: 0.9rem;
  padding: 0 20px;
  border-bottom: 1px solid #00000029; 
   ${
     "" /* border-bottom:${({ admin }) =>
     admin ? "1px solid #249B00" : "1px solid #0E6180"}; */
   }
  margin: 20px auto;
  outline:none !important;
  &:focus,
  &:hover,
  &:active {
  border-bottom:${({ admin }) =>
    admin ? "1px solid #249B00" : "1px solid #0E6180"};
  box-shadow: none;
  outline: none;
  }
  }}
`;

export default Login;
