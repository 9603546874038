import { MANAGE_USER } from "../constants/index";

export const fetchPaymentHistoryReducer = (state = "", { type, payload }) => {
  if (type === MANAGE_USER.FETCH_PAYMENT_HISTORY) {
    return payload;
  }
  return state;
};

export const paymentHistoryReducer = (
  state = { paymentHistory: [], page: 1, totalHistory: 0 },
  { type, payload }
) => {
  if (type === MANAGE_USER.PAYMENT_HISTORY) {
    let historyState = { ...state };
    historyState.paymentHistory = payload.payment_history;
    historyState.page = payload.pages;
    return historyState;
  }
  return state;
};
