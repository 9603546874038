import React, { useEffect } from "react";
import Login from "./index";
import { adminColor } from "../globalComponents/globalAccet";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { notificationAlert } from "../functions/alertNotification";
import useModal from "antd/lib/modal/useModal";
import { adminLoginAction, loginNotificationAction } from "../actions/index";

export const AdminLogin = (props) => {
  const loginFormData = useSelector((state) => state.loginFormData);
  const loginNotification = useSelector((state) => state.loginNotification);
  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || {
    from: { pathname: "/dashboard", state: { from: location } },
  };
  const dispatcher = useDispatch();

  useEffect(() => {
    if (loginNotification.status === "success") {
      notificationAlert("success", "Successful", loginNotification.message);
      history.replace(from);
      history.push("/dashboard");
    }
    if (loginNotification.status === "failed") {
      notificationAlert("error", "Failed", loginNotification.message);
    }
    if (loginNotification.status !== "") {
      dispatcher(loginNotificationAction({ status: "", message: "" }));
    }
  }, [loginNotification]);

  const handleLogin = (values) => {
    if (loginFormData.email !== "" && loginFormData.password !== "") {
      dispatcher(adminLoginAction(loginFormData));
    } else {
      notificationAlert(
        "error",
        "Incomplete credentials",
        "Please provide your email and password"
      );
    }
  };

  const switchToAgent = () => {
    history.push(`/agent`);
  };
  return (
    <>
      <Login
        color={adminColor}
        title="Admin/Agent Login"
        onLogin={handleLogin}
        toggleAgent={switchToAgent}
        userType="Switch to Agent"
        isAdmin={true}
        /* onFinish={handleLogin} */
      />
    </>
  );
};
