import React, { useRef, useEffect, useState } from "react";
import Chart from "chart.js";
import { theme } from '../../../styles/theme';
import {useSelector} from 'react-redux';

const datasets = [
  {
    backgroundColor: [["#5eff00"], ["#ff4d00"]], //[theme.colors.pink[500], theme.colors.green[800]],
    // borderColor: [["#5eff00"]], //[theme.colors.pink[500], theme.colors.green[800]],
    data: [3564, 2109],
    fill: false,
  },
];

export const Doughnut = () => {
  const adminOverView = useSelector(state => state.adminOverView);
  const [dataSetState, setDataSet] = useState(datasets);

  const ref = useRef(null);
  const chart = useRef(null);

  useEffect(() => {
    let temporalDataSet = [...dataSetState];
    temporalDataSet[0].data = [adminOverView.sponsors, adminOverView.participants];
    setDataSet(temporalDataSet);
  }, [adminOverView])

  useEffect(() => {
    if (ref.current) {
      chart.current = new Chart(ref.current, {
        type: "pie",
        data: {
          labels: ["Sponsor Accounts", "Participant Accounts"],
          datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            align: "start",
            position: "bottom",
            labels: {
              fontSize: 10,
            },
          },
          cutoutPercentage: 90,
        },
      });
    }
  }, [dataSetState]);

  return (
    <canvas
      ref={ref}
      aria-label="Doughnut Chart displaying data"
      role="img"
      width="400"
      height="230"
    >
      <span>Your browser does not support the canvas element.</span>
    </canvas>
  );
};
