import React from "react";
import styled from "styled-components";
import device from "../../../../utils/breakPoints";

export const Uploads = (props) => {
  const uploadData = props.data.map((item) => (
    <StyledCard key={item.title}>
      <div>
        <Stats>{item?.stats}</Stats>
        <Title>{item?.title}</Title>
        {item?.title === "Agents Data Upload" ? props.children : null}
      </div>
    </StyledCard>
  ));
  return (
    <Container>
      <HeroContainer>
        <CardContainer> {uploadData}</CardContainer>{" "}
      </HeroContainer>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
`;
const HeroContainer = styled.div`
  width: 92%;
  margin: 0 auto;
  border-radius: 9px;
  background: #ffffff;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${"" /* text-align: center; */}
  ${"" /* padding: 20px 10px; */}
  ${"" /* height: 150px; */}

  @media${device.laptop} {
    flex-wrap: nowrap;
  }
`;
const Stats = styled.h2`
  font-weight: bold;
  font-size: 40px;
`;
const Title = styled.h4`
  font-weight: 500;
  font-size: 20px;
`;
const StyledCard = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  padding: 20px 10px;
  justify-content: center;
  border-bottom: 1px solid #00000019;
  &:last-child {
    border-right: none;
  }

  @media${device.laptop} {
    border-right: 1px solid #00000019;
    border-bottom: none;
  }
`;
