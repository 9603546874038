import { useState } from "react";
import endpoints from "../helpers/endpoints";
import http from "../../../sideEffects/http";
import { useDispatch } from "react-redux";
import { fetchSurveyAssignedUsers } from "../../../actions";
import { notificationAlert } from "../../../functions/alertNotification";

export default function useUnassignUsers() {
  const [selectedUserId, setSelectedUsers] = useState([]);
  const dispatcher = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [unassignModalOpen, setUnassignModalOpen] = useState(false);

  const unassignUsers = async ({ surveyId, page }) => {
    try {
      setLoading(true);
      await http.post(`${endpoints.unassignUsers}${surveyId}`, {
        ids: selectedUserId,
      });

      dispatcher(
        fetchSurveyAssignedUsers({
          surveyId: surveyId,
          page: page,
        })
      );
      setLoading(false);
      setUnassignModalOpen(false);
      setSelectedUsers([]);
      notificationAlert(
        "success",
        "Successful",
        "Selected users have been unassigned"
      );
    } catch (error) {
      setLoading(false);
      notificationAlert("error", "Error occurred", "Please try again");
    }
  };

  return {
    unassignUsers,
    isLoading,
    unassignModalOpen,
    onUnassignModalCancel: () => setUnassignModalOpen(false),
    openUnassignModal: () => setUnassignModalOpen(true),
    setSelectedUsers,
    selectedUserId,
  };
}
