import styled from "styled-components";
import { Statistic } from "antd";
import { device } from "../../../globalComponents/mediaQueries";

export const StatsContainer = styled.div`
  ${"" /* display: grid; */}
  box-sizing:border-box;
  display: flex;
  ${"" /* width:100%; */}
  align-items: center;
`;
export const SingleStats = styled.div`
  height: 180px;
  box-sizing: border-box;
  ${"" /* width: 200px; */}
  background-color: ${(props) => props.color};
  box-shadow: 0px 3px 6px #00000029;
  text-align: center;
  margin: 10px 0;
  border-radius: 3px;
  color: white;
  opacity: 1;
`;
export const StatsTitle = styled.p`
  text-align: center;
  padding-top: 0.5rem;
  font-weight: 300;
  font-size: small;
  color: white;
`;
export const StyledOutput = styled(Statistic)`
  & .ant-statistic-content-value-int {
    text-align: center !important;
    color: white !important;
  }
  @media ${device.laptop} {
    & .ant-statistic-content-value-int {
      font-size: 50px !important;
    }
  }
`;
export const StatsValue = styled.h1`
  margin-top: 1rem;
  color: white;
`;

export const StatsRule = styled.hr`
  background-color: ${(props) => (props.color ? props.color : "white")};
  margin-top: -1rem;
`;
export const StatsFooter = styled.small`
  text-align: center;
  color: white;

  font-size: 14px;
`;
