import styled from "styled-components";

/* export const HeroContainer = styled.div`
width:100%;
` */

export const HeroContainer = styled.div`
  width: 100%;
  position: relative;
`;
