import { LOGIN, MANAGE_PARTICIPANTS } from "../constants"

export const loginParticipantNotificationReducer = (state = '', {type, payload}) => {
    if(type === LOGIN.PARTICIPANT_LOGIN_NOTIFICATION){
        return payload;
    }

    return state;
}

export const participantDetailsReducer = (state = {}, {type, payload}) => {
    if(type === MANAGE_PARTICIPANTS.PARTICIPANT_DETAILS){
        return payload;
    }

    return state
}