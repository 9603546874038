import React from 'react'

export const Logout  = () => {
  return (
    // <!-- Generated by IcoMoon.io -->
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
    >
      <title>logout</title>
      <path d="M2.988 3.762v10.477h6.012v1.512h-6.012q-0.598 0-1.037-0.457t-0.439-1.055v-10.477q0-0.598 0.439-1.055t1.037-0.457h6.012v1.512h-6.012zM12.762 5.238l3.727 3.762-3.727 3.762-1.055-1.090 1.934-1.934h-7.629v-1.477h7.629l-1.934-1.969z" />
    </svg>
  )
}
