import { UTILS } from "../constants";

export const modalReducer = (state = false, { type, payload }) => {
  if (type === UTILS.MODALOPEN) {
    return payload;
  }
  if (type === UTILS.MODALCLOSE) {
    return payload;
  }
  return state;
};
