import React, { useRef, useEffect } from "react";
import Chart from "chart.js";
import { theme } from "../../../styles/theme";

const datasets = [
  {
    label: "Business Accounts",
    backgroundColor: "#ff4d00", //theme.colors.pink[500],
    borderColor: "#ff4d00", //theme.colors.pink[500],
    data: [2000, 1000, 2500, 1500, 3500, 3000, 6000],
    fill: false,
  },
  {
    label: "Participant Accounts",
    fill: false,
    backgroundColor: "#5eff00", //theme.colors.green[800],
    borderColor: "#5eff00", //theme.colors.green[700],
    data: [3000, 1500, 4500, 2000, 3500, 4000, 5000],
  },
];

export const LinesChart = () => {
  const ref = useRef(null);
  const chart = useRef(null);
  useEffect(() => {
    if (ref.current) {
      chart.current = new Chart(ref.current, {
        type: "line",
        data: {
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
          ],
          datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: "bottom",
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  suggestedMax: 8000,
                },
              },
            ],
          },
          layout: {
            padding: {
              top: 5,
              left: 15,
              right: 15,
              bottom: 15,
            },
          },
        },
      });
    }
  }, []);

  return (
    <canvas
      ref={ref}
      aria-label="Line Chart displaying data"
      role="img"
      width="350"
      height="350"
    >
      <span>Your browser does not support the canvas element.</span>
    </canvas>
  );
};
