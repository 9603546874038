import React, {useEffect} from 'react';
import styled from 'styled-components';
import { FiPlus, FiMinus, FiTrash2, FiCheck, FiCheckCircle } from "react-icons/fi";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import { adminColor } from '../../../../globalComponents/globalAccet';
import { device } from '../../../../globalComponents/mediaQueries';
import {useQuestionContext} from '../questionContext';
import { useDispatch, useSelector } from 'react-redux';
import { deleteQuestionAction, deleteQuestionNotificationAction, fetchSingleTemplateAction } from '../../../../actions';
import { notificationAlert } from '../../../../functions/alertNotification';



export const SingleQuestionScaffold = (props) => {
    const {questionList, setQuestion} = useQuestionContext();
    const deleteQuestionNotification = useSelector(state => state.deleteQuestionNotification);
    const dispatcher = useDispatch();


   

    const onMoveUp = (questionId) => {
        
        let temporalQuestionList = questionList.slice();
        let selectedQuestion = temporalQuestionList.find(item => item.questionId === questionId);

        let indexOfSelectedQuestion = temporalQuestionList.findIndex(item => item.questionId === questionId);

        if(indexOfSelectedQuestion !== 0){
            console.log('MOVE UP');
            let questionBefore = temporalQuestionList.find((item, index) => index === indexOfSelectedQuestion - 1);

            temporalQuestionList.splice(indexOfSelectedQuestion - 1, 1, selectedQuestion);
    
            temporalQuestionList.splice(indexOfSelectedQuestion, 1, questionBefore);

            console.log(questionList, temporalQuestionList);
    
            setQuestion(temporalQuestionList);
        }
        

    }

    const onMoveDown = (questionId) => {
        let temporalQuestionList = questionList.slice();
        let selectedQuestion = temporalQuestionList.find(item => item.questionId === questionId);

        let indexOfSelectedQuestion = temporalQuestionList.findIndex(item => item.questionId === questionId);

        if(indexOfSelectedQuestion !== temporalQuestionList.length - 1){
            let questionAfter = temporalQuestionList.find((item, index) => index === indexOfSelectedQuestion + 1);

            temporalQuestionList.splice(indexOfSelectedQuestion + 1, 1, selectedQuestion);
    
            temporalQuestionList.splice(indexOfSelectedQuestion, 1, questionAfter);
    
            setQuestion(temporalQuestionList);
        }
    }

    const handleQuestionSelect = (questionId) => {
        const newQuestionList = questionList.slice().map(item => {
            if(item.questionId === questionId){
                item.isSelect = true;
                return item;
            }

            item.isSelect = false;
            return item;
        })


        setQuestion(newQuestionList);
    }

    const getSelectedQuestion = (questionId) => {
        return questionList.slice().find(item => item.questionId === questionId);
    }

    const onDeleteQuestion = (questionId) => { 

        if(getSelectedQuestion(questionId).isSaved){
            dispatcher(deleteQuestionAction(questionId));
        }else{
            const newQuestionList = questionList.slice().filter(item => item.questionId !== questionId);

            setQuestion(newQuestionList) 
        }
        

   
    }

    /* const onAddQuestion = (questionId) => {
    } */

    
    return (
        <StyledSingleQuestionScaffold isSelect={getSelectedQuestion(props.questionId)?.isSelect} onMouseDown={() => handleQuestionSelect(props.questionId)}>
            <StyledHeaderContainer>
                <StyledHeader>
                    <p>{props.questionNumber}.</p>
                    <p>{props.question}</p>
                </StyledHeader>
                {getSelectedQuestion(props.questionId).isSaved && <StyledFiCheck/>}
            </StyledHeaderContainer>
            <StyledBody>
                {props.children}
            </StyledBody>
            {
            getSelectedQuestion(props.questionId)?.isSelect 
            && 
            <StyledBottonSheet>
                    <ActionButton onActionClick={() => onMoveUp(props.questionId)}  icon={<IoMdArrowDropup/>}/>
                    <ActionButton onActionClick={() => onMoveDown(props.questionId)} icon={<IoMdArrowDropdown/>}/>
                     <ActionButton onActionClick={() => onDeleteQuestion(props.questionId)} icon={<FiTrash2/>}/>
                    {/* <ActionButton onActionClick={() => onAddQuestion(props.questionId)} icon={<FiPlus/>}/>  */} 
            </StyledBottonSheet>}
        </StyledSingleQuestionScaffold>
    );
};


const ActionButton = (props) => {
    return (
        <StyledActionButton onClick={props.onActionClick}>
            {props.icon}
        </StyledActionButton>
    )
}


const StyledSingleQuestionScaffold = styled.div`
    margin-bottom: 10px;
    background-color: ${props => props.isSelect? "#EDF2F7": "#fff"};
    color: #414141;
    cursor: pointer;


    & p{
        font-size: 16px;
    }



    @media ${device.laptop}{
        & p{
            font-size: 1.1vw;
        }
    }
`

const StyledBottonSheet = styled.div`
    display: flex;
    justify-content: flex-end;
    padding:  0px 10px 10px 10px;
`

const StyledActionContainer = styled.div`
    display: flex;
    border-radius: 5px;
  
    overflow: hidden;
`

const StyledActionButton = styled.div`
    background-color: ${adminColor};
    color: #fff;
    padding: 5px;
    font-size: 18px;
    cursor: pointer;

    &:first-child{
        border-radius: 5px 0px 0px 5px;
    }

    &:last-child{
        border-radius: 0px 5px 5px 0px;
    }

    &:not(:first-child), &:not(:last-child){
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
    }

    @media ${device.laptop}{
        font-size: 1.4vw;
    }
`

const StyledBody = styled.div`
    width: 100%;
    padding: 0 10px;
    padding-bottom: 10px;
 

    @media ${device.laptop}{
        width: 50%;
    }
`

const StyledHeader = styled.div`
    display: flex;
    padding: 10px 10px 0px 10px;

    & p:first-child{
        color: #00000029;
        margin-right: 10px;
    }
`

const StyledHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
`

const StyledFiCheck = styled(FiCheck)`
    color: ${adminColor};
    font-weight: bolder;
    font-size: 24px;

    @media ${device.laptop}{
        font-size: 1.8vw;
    }
`