import React from "react";
import styled from 'styled-components';
import {Row, Col} from 'antd';
import { theme } from "./styles/theme";
import { device } from "./globalComponents/mediaQueries";
import {useHistory} from 'react-router-dom';


const ErrorPage = (props) => {
  const history = useHistory();

  const handleClick = () => {
    history.goBack();
  }

  return (
      <Row>
        <Col sm={{span: 24}}>
          <StyledNonFoundPage>
            <h1>404</h1>
            <StyledButton onClick={handleClick}>Go to Back</StyledButton>
          </StyledNonFoundPage>
        </Col>
      </Row>
  );
};

const StyledNonFoundPage = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;



  & h1{
    font-size: 40px;
    color: red;
  }

  @media ${device.laptop}{
    & h1{
    font-size: 4vw;
    }
  }
`

const StyledButton = styled.button`
  border: none;
  background-color: ${theme.colors.green[700]};
  color: #fff;
  padding: 10px 10px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bolder;

  &:focus{
    outline: none;
  }

  @media ${device.laptop}{
    font-size: 1.4vw;
  }
`
export default ErrorPage;
