import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { device } from '../../../../globalComponents/mediaQueries';
import { Select, Input, Empty } from 'antd';
import { FiTrash2 , FiPlus, FiCircle, FiChevronDown, FiSquare, FiPlusSquare} from 'react-icons/fi';
import { DynamicOption } from './../elements/DynamicOption';
import {useQuestionContext} from '../questionContext';
import {questionTypes} from '../questionTypes'

const { Option } = Select;

export const EditQuestionBoard = (props) => {
    const {questionList, setQuestion} = useQuestionContext();
    const [selectedquestionIndex, setSelectedQuestionIndex] = useState(0);
    const [selectedQuestionType, setSelectedQuestionType] = useState()




    useEffect(() => {
        setSelectedQuestionIndex(getSelectedQuestionIndex() + 1);
        setSelectedQuestionType(getQuestionType())
    }, [questionList])

    const replaceQuestion = (questionList, selectedQuestion) => {
        const newQuestionList = questionList.slice().map(item => {
            if(item.questionId === selectedQuestion.questionId){
                return selectedQuestion;
            }
 
            return item;
        })
 
        setQuestion(newQuestionList);
    }


    const getSelectedQuestionIndex = () => {
        return questionList.slice().findIndex(item => item?.isSelect);
    }

    const getSelectedQuestion = () => {
        return questionList.slice().find(item => item?.isSelect)
    }


    const handleOptionChange = (e, index) => {
        const selectedQuestion = getSelectedQuestion();

        const optionList = selectedQuestion.options.map((option, idx) => {
            if(index === idx){
                return e.target.value;
            }

            return option;
        });

        selectedQuestion.options = optionList;

        replaceQuestion(questionList, selectedQuestion);

    }

    const handleOptionDelete = (index) => {
        const selectedQuestion = getSelectedQuestion();
        
       if(selectedQuestion.options.length > 1){
            const newOptions = selectedQuestion.options.filter((item, idx) => index !== idx);

            selectedQuestion.options = newOptions;

            replaceQuestion(questionList, selectedQuestion);
       }

    }

    const handleAddOption = () => {
        const selectedQuestion = getSelectedQuestion();

        selectedQuestion.options.push(`option${selectedQuestion.options.length + 1}`);

        replaceQuestion(questionList, selectedQuestion);
    }

    const handleQuestionChange = (e) => {
       const selectedQuestion = getSelectedQuestion();
       selectedQuestion.question = e.target.value;

       replaceQuestion(questionList, selectedQuestion);
    }

   /*  const optionList = getSelectedQuestion()?.options?.map((item, index) => {
        return <DynamicOption
                    key={index}
                    optionValue={item}
                    onOptionChange={(e) => handleOptionChange(e, index)}
                    onOptionDelete={() => handleOptionDelete(index)}
                />
    }); */


    const isQuestionSelected = () => {
        return questionList.slice().some(item => item.isSelect);
    }



    const questionNumberList = questionList.slice().map((item, index)=> {
        return <Option key={index} value={`${index + 1}`}>{index + 1}</Option>
    })


    const questionTypeList = ['Radio Group', 'Checkbox', 'Dropdown'].map((item, index)=> {
        return <Option key={index} value={item}>{item}</Option>
    })



    const handleChangeQuestionType = (value) => {

       
    const selectedQuestion = getSelectedQuestion();

        let newQuestionType = '';

        switch(value){
            case 'Radio Group':
                newQuestionType =  questionTypes.radioGroup;
                break;
            case 'Checkbox':
                newQuestionType = questionTypes.checkBox;
                break;
            case 'Dropdown':
                newQuestionType = questionTypes.dropDown;
                break;
            default: 
                return;
            
        }

        selectedQuestion.type = newQuestionType;

        replaceQuestion(questionList, selectedQuestion);

        setSelectedQuestionType(value); 

    }


  
    const handleChangeSelectedQuestion = (value) => {
        const newQuestionList = questionList.slice().map((item, index) => {
            if(`${index + 1}` === value){
                item.isSelect = true;

                return item;
            }

            item.isSelect = false;

            return item;
        })


        setQuestion(newQuestionList);
    }


    const getQuestionType = () => {
        switch(getSelectedQuestion()?.type){
            case questionTypes.radioGroup:
                return 'Radio Group';
            case questionTypes.checkBox:
                return 'Checkbox';
            case questionTypes.dropDown:
                return 'Dropdown';
            default:
                return;
        }
    }



    const getOptionIcon = () => {
        switch(getSelectedQuestion()?.type){
            case questionTypes.radioGroup:
                return <StyledFiCircle/>;
            case questionTypes.checkBox:
                return <StyledFiSquare/>;
            case questionTypes.dropDown:
                return <StyledFiChevronDown/>;
            default:
                return;
        }
    }




    const optionList = getSelectedQuestion()?.options?.map((item, index) => {
        return <DynamicOption
                    key={index}
                    optionValue={item}
                    onOptionChange={(e) => handleOptionChange(e, index)}
                    onOptionDelete={() => handleOptionDelete(index)}
                    optionIcon = {getOptionIcon()}
                />
    });


   
   

  
    return !isQuestionSelected()?(
        <StyledEmpty/>
    ) : (

        <>
             <StyledSelectQuestionSection>
                <p>Select Question</p>
                <StyledSelect onChange = {handleChangeSelectedQuestion} value={selectedquestionIndex}  defaultValue={selectedquestionIndex}  bordered={false}>
                    {questionNumberList}
                </StyledSelect>
            </StyledSelectQuestionSection>

           {getSelectedQuestion()?.type !== questionTypes.singleLine && <StyledSelectQuestionSection>
                <p>Select Question Type</p>
                <StyledSelect onChange = {handleChangeQuestionType} value={selectedQuestionType} style={{width: '50%', color: '#E48900'}}  defaultValue={getQuestionType()}  bordered={true}>
                    {questionTypeList}
                </StyledSelect>
            </StyledSelectQuestionSection>}
            <StyledEditQuestionBoard> 
                <StyledQuestionSection>
                    <p>{getSelectedQuestionIndex() + 1}.</p>
                    <Input.TextArea autoSize={true} onChange={handleQuestionChange} value={getSelectedQuestion()?.question} placeholder="Borderless" bordered={false} />
                </StyledQuestionSection>
                {optionList}
               {
               optionList
               && 
                <StyledAddQuestion onClick={handleAddOption}>
                    <StyledAddIcon> 
                        <StyledFiPlus/>
                        <p>Add New Option</p>
                    </StyledAddIcon>
                </StyledAddQuestion>}
            </StyledEditQuestionBoard>
        </>
        
    );
};

const StyledEditQuestionBoard = styled.div`
    border: 0.5px solid #00000029;
    border-radius: 5px;
    width: 95%;
    margin: 0 auto;
    margin-top: 10px;
    padding: 10px;

    & p{
        font-size: 16px;
        margin-bottom: 0px;
    }

    @media ${device.laptop}{
        width: 90%;
        & p{
            font-size: 1vw;
        }

    }
`

const StyledSelectQuestionSection = styled.div`
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    & p{
        font-size: 16px;
        margin-bottom: 0px;
    }

    @media ${device.laptop}{
        width: 90%;
        & p{
            font-size: 1vw;
        }
    }

`

const StyledSelect = styled(Select)`
    border-bottom: 1px solid #ccc;
    font-size: 1vw;

    


    &.ant-select:not(.ant-select-customize-input) .ant-select-selector{
        border-radius: 5px;
    }
`

const StyledQuestionSection = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;

    & p{
        margin-bottom: 0px;
    }

    & p:first-child{
        margin-right: 10px;
        color: #A8A8A8;
    }
`

const StyledDynamicOption = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid #A8A8A8;
    margin-bottom: 15px;

`

const StyledOptionName = styled.div`
    display: flex;
    justify-content: flex-start;

    & div:first-child{
        margin-right: 10px;
        color: #A8A8A8;
    }
`

const StyledFiTrash2 = styled(FiTrash2)`
    font-size: 16px;
    cursor: pointer;

    @media ${device.laptop}{
        font-size: 1vw;
    }
`

const StyledFiSquare = styled(FiSquare)`
    font-size: 16px;

    @media ${device.laptop}{
        font-size: 1vw;
    }
`

const StyledAddQuestion = styled.div`

`

const StyledAddIcon = styled.div`
    display: flex;
    place-items: center;
    color: #656565;
    cursor: pointer;
`

const StyledFiPlus = styled(FiPlus)`
 background-color: #61D926;
 font-size: 20px;
 color: #fff;
 margin-right: 10px;

 @media ${device.laptop}{
    font-size: 1.4vw;
 }
`

const StyledEmpty = styled(Empty)`
    margin-top: 30px;
`



const StyledFiCircle = styled(FiCircle)`
    font-size: 16px;

    @media ${device.laptop}{
        font-size: 1vw;
    }
`

const StyledFiChevronDown= styled(FiChevronDown)`
    font-size: 16px;

    @media ${device.laptop}{
        font-size: 1vw;
    }
`
