import React from 'react';
import styled from 'styled-components';
import { device } from '../../../globalComponents/mediaQueries';


export const Board = (props) => {
    return (
        <StyledCreateQuestionnaire>
           <StyledTitleBar>
               <p>{props.title}</p>
           </StyledTitleBar>
           <StyledBody>
               {props.children}
           </StyledBody>
        </StyledCreateQuestionnaire>
    );
}

const StyledCreateQuestionnaire = styled.div`
    flex-basis: 45%;
    border-radius: 5px;
    overflow: hidden;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0px 1px 3px 0px rgba(188,202,209,0.5);
    height: 100vh;
    &:nth-child(2){
        margin-top: 20px;
    }

    @media ${device.laptop}{
        margin-left: 0px;
        margin-right: 0px;
        &:nth-child(2){
        margin-top: 0px;
        }

     }
`

const StyledTitleBar = styled.div`
    background-color: #61D926;
    padding: 10px;
    color: #fff;
     font-size: 16px;
     & p{
         margin-bottom: 0px;
     }

     @media ${device.laptop}{
         font-size: 1.2vw;
     }
`

const StyledBody = styled.div`
    color: #000;
    padding: 20px;
    overflow: auto;
    height: 90%;

`